import { ApiProperties, ApiEndpoint, ApiDocumentation, ApiOperationType, CacheLevel, ApiDocPage, ApiDocTestFormProperty, ApiDocTestFormPropertyType, ApiRelationship } from "projects/core-lib/src/lib/api/ApiModels";
import { Log } from "projects/core-lib/src/lib/helpers/helper";

declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";

import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as m5pay from "projects/core-lib/src/lib/models/ngModelsPayment5";
import * as wm5 from "projects/core-lib/src/lib/models/ngWalletModels";

import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as Enumerable from "linq";
import { ApiHelper } from "projects/core-lib/src/lib/api/ApiHelper";
import { supportsPassiveEventListeners } from "@angular/cdk/platform";


export class Api {


  //#region Helper Functions

  /**
  This method returns an array of all of the api properties methods available in this class.
  */
  public static GetListOfApiPropertiesMethods(): string[] {
    let list: string[] = [];
    list = Object.getOwnPropertyNames(Api).filter(function (p) {
      return p !== "GetListOfApiPropertiesMethods" && p !== "GetApi" && p !== "getApiRelationships" && typeof Api[p] === "function";
    });
    return list;
  }
  /**
  This method returns the ApiProperties object for the requested api name.  This can be used to
  get api properties object dynamically.
  */
  public static GetApi(apiName: string, version: number = AppConfig.apiVersion, suppressErrorReporting: boolean = false): ApiProperties {
    if (!version) {
      version = AppConfig.apiVersion;
    }
    try {
      const api: ApiProperties = Api[apiName](version);
      // If we don't have an id we can populate it here since we accessed this by method name and
      // the method name is our default id name.
      if (!api.id) {
        api.id = apiName;
      }
      return api;
    } catch (err) {
      if (!suppressErrorReporting) {
        Log.errorMessage(`Exception getting api for ${apiName} with version ${version}`);
        Log.errorMessage(err);
      }
    }
  }


  public static getApiRelationships(): ApiRelationship[] {

    const relationships: ApiRelationship[] = [];

    // Start by stepping through our APIs and gathering the api name and any parent api reference
    const apiNames = Api.GetListOfApiPropertiesMethods();
    for (const apiName of apiNames) {
      const api: ApiProperties = Api.GetApi(apiName);
      if (!api) {
        console.error(`Unable to get relationships for unknown api ${apiName}`);
        continue;
      }
      const relationship: ApiRelationship = new ApiRelationship();
      relationship.apiName = apiName;
      relationship.parent = api.parentApi;
      relationships.push(relationship);
    }

    // Now step through our relationship list and gather list of children for each api
    const linq = Enumerable.from(relationships);
    relationships.forEach((relationship: ApiRelationship, index: number, array: ApiRelationship[]) => {
      const children = linq.where(x => x.parent === relationship.apiName).toArray();
      relationship.children = Enumerable.from(children).select(x => x.apiName).toArray();
    });

    return relationships;

  }

  //#endregion Helper Functions


  //#region Asset APIs

  public static Asset(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Asset");
    //api.id = "Asset";
    api.version = version;
    api.documentation.objectDescription = "Asset";
    api.documentation.objectPrimaryKey = "AssetId";
    api.documentation.objectDescriptionPropertyNames = ["Title", "FriendlyName", "ExternalAssetId", "NamedIdentifier"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Asset;
    api.documentation.requestAndResponseDataModelObject = new m5.AssetEditViewModel();
    api.documentation.documentationUrlBase = "/asset/";
    api.documentation.securityAccessArea = Constants.AccessArea.Asset;
    api.pathVariables = "{assetId}";
    api.pathModelProperties = "AssetId";
    api.cacheName = "assetObjectCache";
    api.impactedPickListIds = [Constants.PickList._Asset, Constants.PickList._InvoiceMessage, Constants.PickList._NotificationMessage];
    api.useStandardEndpoints(`/${m.RouteSegment.Assets}`, new m5.AssetListViewModel(), Constants.DataModelName.AssetList, false);
    // Custom copy has extra parameters beyond our normal copy endpoint
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Assets}?source={assetId}&language={language}&draft={draft}&newVersion={newVersion}&copyFeedback={copyFeedback}`, ApiOperationType.Copy));
    api.endpoints.slice(-1)[0].pathVariables = ["{assetId}", "{language}", "{draft}", "{newVersion}", "{copyFeedback}"];
    api.endpoints.slice(-1)[0].pathModelProperties = ["AssetId", "Language", "Draft", "NewVersion", "CopyFeedback"];
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.overviewText = `
This API copies an asset to a new asset.  The copy is performed via a post with an empty body and the source asset id specified via a source url parameter.
Other optional url parameters include:
<br/><br/>
<ul>
<li>language - If the new asset is intended to be a translation of the source asset the new language can be specified here.</li>
<li>draft - If the new asset should have visibility set to draft this should be true.</li>
<li>newVersion - If the new asset is intended to be a new version of the current asset this should be true.</li>
<li>copyFeedback - If the new asset should have a copy of the source asset feedback this should be true.</li>
</ul>
`;
    return api;
  };

  public static AssetCopyAttachments(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AssetCopyAttachments");
    api.version = version;
    api.documentation.objectDescription = "Asset";
    api.documentation.objectPrimaryKey = "AssetId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Asset;
    api.documentation.requestAndResponseDataModelObject = new m5.AssetEditViewModel();
    api.documentation.documentationUrlBase = "/asset-copy-attachment/";
    api.documentation.securityAccessArea = Constants.AccessArea.Asset;
    api.pathVariables = "{assetId}";
    api.pathModelProperties = "AssetId";
    api.cacheName = "assetObjectCache";
    api.endpoints.push(new ApiEndpoint("/assets?sourceOwnerResourceType={sourceOwnerResourceType}&sourceOwnerResourceId={sourceOwnerResourceId}&targetOwnerResourceType={targetOwnerResourceType}&targetOwnerResourceId={targetOwnerResourceId}", ApiOperationType.Copy));
    api.endpoints.slice(-1)[0].pathVariables = ["{sourceOwnerResourceType}", "{sourceOwnerResourceId}", "{targetOwnerResourceType}", "{targetOwnerResourceId}"];
    api.endpoints.slice(-1)[0].pathModelProperties = ["SourceOwnerResourceType", "SourceOwnerResourceId", "TargetOwnerResourceType", "TargetOwnerResourceId"];
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Copy Assets By Owner";
    api.endpoints.slice(-1)[0].documentation.menuText = "Copy Assets By Owner";
    api.endpoints.slice(-1)[0].documentation.overviewText = `
This API copies all assets that belong to one owner (e.g. attachments) to another owner.  The copy is performed via a post with an empty body and the source and target asset owner specified via url parameters.
Other optional url parameters include:
<br/><br/>
<ul>
<li>targetOwnerResourceType - If the assets will have a different owner type than the source owner type the new type can be specified here.</li>
</ul>
`;
    return api;
  };

  public static AssetAddFromUpload(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AssetAddFromUpload");
    api.version = version;
    api.documentation.objectDescription = "Asset";
    api.documentation.objectPrimaryKey = "AssetId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Asset;
    api.documentation.requestAndResponseDataModelObject = new m5.AssetEditViewModel();
    api.documentation.documentationUrlBase = "/asset/add-from-upload/";
    api.documentation.securityAccessArea = Constants.AccessArea.Asset;
    api.pathVariables = "{assetId}";
    api.pathModelProperties = "AssetId";
    api.cacheName = "assetObjectCache";
    api.endpoints.push(new ApiEndpoint("/assets/actions/upload", ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Add Asset With Upload";
    api.endpoints.slice(-1)[0].documentation.menuText = "Add Asset With Upload";
    api.endpoints.slice(-1)[0].documentation.overviewText = `
This API can be used to create a new asset with an accompanying upload as a multipart/form-data form submission.
<br/><br/>
The form-data should have a part named \Data\ which will be the JSON of the model and a part named \file\ which will be the file being uploaded.
<br/><br/>
For example:
<br/><br/>
-----FormBoundarywbQz2dC18SuP0563
<br/>
Content-Disposition: form-data; name=\Data\
<br/>
{\AssetId\:null,\ParentAssetId\:null,\ParentAssetTitle\:\\,\Title\:\\,....}
<br/><br/>
------FormBoundarywbQz2dC18SuP0563
<br/>
Content-Disposition: form-data; name=\file\; filename=\picture.jpg\
<br/>
Content-Type: image/jpg;
`;
    return api;
  };

  public static AssetActionValidate(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AssetActionValidate");
    api.version = version;
    api.documentation.objectDescription = "Asset Validation";
    api.documentation.objectPrimaryKey = "AssetId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Asset;
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/asset/validate/";
    api.documentation.securityAccessArea = Constants.AccessArea.Asset;
    api.pathVariables = ["{assetId}", "{friendlyName}"];
    api.pathModelProperties = ["AssetId", "FriendlyName"];
    api.cacheName = "assetObjectCache";
    api.parentApi = "Asset";
    api.endpoints.push(new ApiEndpoint("/assets/{assetId}/actions/validate/{friendlyName}", ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Validate Asset";
    api.endpoints.slice(-1)[0].documentation.menuText = "Validate Asset";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API can be used to check that an asset is valid for download.";
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "check";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Validate";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static AssetActionAnalyze(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AssetActionAnalyze");
    api.version = version;
    api.documentation.objectDescription = "Asset Analyze";
    api.documentation.objectPrimaryKey = "AssetId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Asset;
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/asset/analyze/";
    api.documentation.securityAccessArea = Constants.AccessArea.Asset;
    api.pathVariables = ["{assetId}"];
    api.pathModelProperties = ["AssetId"];
    api.cacheName = "assetObjectCache";
    api.parentApi = "Asset";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Assets}/{assetId}/${m.RouteSegment.Analyze}`, ApiOperationType.Add));
    return api;
  };

  public static AssetActionDownload(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AssetActionDownload");
    api.version = version;
    api.documentation.objectDescription = "Asset";
    api.documentation.objectPrimaryKey = "AssetId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Asset;
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/asset/download/";
    api.documentation.securityAccessArea = Constants.AccessArea.Asset;
    api.pathVariables = ["{assetId}", "{friendlyName}"];
    api.pathModelProperties = ["AssetId", "FriendlyName"];
    api.cacheName = "assetObjectCache";
    api.parentApi = "Asset";
    api.endpoints.push(new ApiEndpoint("/assets/{assetId}/actions/download/{friendlyName}", ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Download Asset";
    api.endpoints.slice(-1)[0].documentation.menuText = "Download Asset";
    api.endpoints.slice(-1)[0].documentation.overviewText = `
This API can be used to download the asset.  The friendly name parameter is not used by the API but can be helpful when a URL is visible by users.
<br/><br/>
The API response will have a Content-Type matching the content type of the asset and a payload of the binary contents of the asset.  It is the responsibility
of the API caller to save the contents as a file.  This is not directly possible in javascript since there is no access to a user's file system from within
the browser.  One method of doing this is to append the authentication token as a token string parameter to the API url and assigning that to the
window.location.href property (e.g. \https://the.api.url/assets/{assetId}/actions/download/{friendlyName}?token=eyJ0eXAiOiJKV1QNiJ9...\) which will then trigger
the browser's standard open/save behavior.  Since the use of an API Key in a browser session is not considered safe it is not supported as a query
string parameter.  Note that the authentication token is not required for public assets.
`;
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "cloud-download";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Download API Call";
    api.endpoints.slice(-1)[0].documentation.testButton2Icon = "download";
    api.endpoints.slice(-1)[0].documentation.testButton2Text = "Download";
    api.endpoints.slice(-1)[0].documentation.testButton2Action = "download";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static AssetActionView(version: number = AppConfig.apiVersion): ApiProperties {
    const api = this.AssetActionDownload(version);
    api.id = "AssetActionView";
    api.documentation.documentationUrlBase = "/asset/view/";
    // clear endpoints
    api.endpoints = [];
    api.endpoints.push(new ApiEndpoint("/assets/{assetId}/actions/view/{friendlyName}", ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "View Asset";
    api.endpoints.slice(-1)[0].documentation.menuText = "View Asset";
    api.endpoints.slice(-1)[0].documentation.overviewText = `
This API can be used to view the asset.  The friendly name parameter is not used by the API but can be helpful when a URL is visible by users.
<br/><br/>
The API response will have a Content-Type matching the content type of the asset and a payload of the binary contents of the asset.  It is the responsibility
of the API caller to present the contents of the file for viewing.  To help facilitate this the file can also be referenced as a URL which can be included as
the source for an image tag, iframe tag, etc.  When doing that append the authentication token as a token string parameter to the API url
(e.g. \https://the.api.url/assets/{assetId}/actions/view/{friendlyName}?token=eyJ0eXAiOiJKV1QNiJ9...\).
Note that the authentication token is not required for public assets.
`;
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "tv";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "View API Call";
    api.endpoints.slice(-1)[0].documentation.testButton2Icon = "tv";
    api.endpoints.slice(-1)[0].documentation.testButton2Text = "View File";
    api.endpoints.slice(-1)[0].documentation.testButton2Action = "download";
    return api;
  };

  public static AssetShareActionDownload(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AssetShareActionDownload");
    api.version = version;
    api.documentation.objectDescription = "Asset";
    api.documentation.objectPrimaryKey = "AssetId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Asset;
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/asset/share/download/";
    api.documentation.securityAccessArea = Constants.AccessArea.Asset;
    api.pathVariables = ["{readOnlyShareId}", "{friendlyName}"];
    api.pathModelProperties = ["ReadOnlyShareId", "FriendlyName"];
    api.cacheName = "assetObjectCache";
    api.excludeFromAutomatedTesting = true;
    api.endpoints.push(new ApiEndpoint("/assets/{readOnlyShareId}/download/{friendlyName}", ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Download Shared Asset";
    api.endpoints.slice(-1)[0].documentation.menuText = "Download Shared Asset";
    api.endpoints.slice(-1)[0].documentation.overviewText = `
This API can be used to download the asset.  The friendly name parameter is not used by the API but can be helpful when a URL is visible by users.
<br/><br/>
The API response will have a Content-Type matching the content type of the asset and a payload of the binary contents of the asset.  It is the responsibility
of the API caller to save the contents as a file.  This is not directly possible in javascript since there is no access to a user's file system from within
the browser.  One method of doing this is to append the authentication token as a token string parameter to the API url and assigning that to the
window.location.href property (e.g. \https://the.api.url/assets/{readOnlyShareId}/download/{friendlyName}?token=eyJ0eXAiOiJKV1QNiJ9...\) which will then trigger
the browser's standard open/save behavior.  Since the use of an API Key in a browser session is not considered safe it is not supported as a query
string parameter.  Note that the authentication token is not required for public assets.
`;
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "cloud-download";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Download API Call";
    api.endpoints.slice(-1)[0].documentation.testButton2Icon = "download";
    api.endpoints.slice(-1)[0].documentation.testButton2Text = "Download";
    api.endpoints.slice(-1)[0].documentation.testButton2Action = "download";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static AssetShareActionView(version: number = AppConfig.apiVersion): ApiProperties {
    const api = this.AssetShareActionDownload(version);
    api.id = "AssetShareActionView";
    api.documentation.documentationUrlBase = "/asset/share/view/";
    // clear endpoints
    api.endpoints = [];
    api.endpoints.push(new ApiEndpoint("/assets/{readOnlyShareId}/view/{friendlyName}", ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "View Shared Asset";
    api.endpoints.slice(-1)[0].documentation.menuText = "View Shared Asset";
    api.endpoints.slice(-1)[0].documentation.overviewText = `
This API can be used to view the asset.  The friendly name parameter is not used by the API but can be helpful when a URL is visible by users.
<br/><br/>
The API response will have a Content-Type matching the content type of the asset and a payload of the binary contents of the asset.  It is the responsibility
of the API caller to present the contents of the file for viewing.  To help facilitate this the file can also be referenced as a URL which can be included as
the source for an image tag, iframe tag, etc.  When doing that append the authentication token as a token string parameter to the API url
(e.g. \https://the.api.url/assets/{readOnlyShareId}/view/{friendlyName}?token=eyJ0eXAiOiJKV1QNiJ9...\).
Note that the authentication token is not required for public assets.
`;
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "tv";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "View API Call";
    api.endpoints.slice(-1)[0].documentation.testButton2Icon = "tv";
    api.endpoints.slice(-1)[0].documentation.testButton2Text = "View File";
    api.endpoints.slice(-1)[0].documentation.testButton2Action = "download";
    return api;
  };

  public static AssetSeeAlso(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AssetSeeAlso");
    api.version = version;
    api.documentation.objectDescription = "Asset";
    api.documentation.objectPrimaryKey = "AssetId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.AssetSeeAlso;
    api.documentation.requestAndResponseDataModelObject = new m5.AssetOptionsSeeAlsoResultViewModel();
    api.documentation.documentationUrlBase = "/asset-see-also/";
    api.documentation.securityAccessArea = Constants.AccessArea.Asset;
    api.pathVariables = ["{assetId}", "{seeAlsoLinkTemplate}"];
    api.pathModelProperties = ["AssetId", "SeeAlsoLinkTemplate"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Assets}/{assetId}/${m.RouteSegment.SeeAlso}?seeAlsoLinkTemplate={seeAlsoLinkTemplate}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.overviewText = `
This API gets the see also articles for the specified asset.  It also returns Meta.Html property with suggested html for the see also links.
`;
    return api;
  };

  public static AssetText(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AssetText");
    api.version = version;
    api.documentation.objectDescription = "Asset";
    api.documentation.objectDescriptionPlural = "Assets";
    api.documentation.objectPrimaryKey = "AssetId";
    api.documentation.objectDescriptionPropertyNames = ["Title"];
    api.documentation.requestAndResponseDataModelDocumentationName = "";
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/asset-text/";
    api.documentation.securityAccessArea = Constants.AccessArea.Asset;
    api.pathVariables = ["{assetId}", "{contextResourceType}", "{contextResourceId}", "{contextResourceId2}", "{context}"];
    api.pathModelProperties = ["AssetId", "ContextResourceType", "ContextResourceId", "ContextResourceId2", "Context"];
    api.cacheName = "assetText";
    api.cacheLevel = CacheLevel.ChangesOften;
    api.parentApi = "Asset";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Assets}/{assetId}/${m.RouteSegment.Text}?contextResourceType={contextResourceType}&contextResourceId={contextResourceId}&contextResourceId2={contextResourceId2}&context={context}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.menuText = "Get Asset Text";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This api retrieves asset text either static or as output from program.";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static AssetScriptTest(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AssetScriptTest");
    api.version = version;
    api.documentation.objectDescription = "Asset";
    api.documentation.objectDescriptionPlural = "Assets";
    api.documentation.objectPrimaryKey = "AssetId";
    api.documentation.objectDescriptionPropertyNames = ["Title"];
    api.documentation.requestAndResponseDataModelDocumentationName = "";
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/asset-script-test/";
    api.documentation.securityAccessArea = Constants.AccessArea.Asset;
    api.pathVariables = ["{assetId}"];
    api.pathModelProperties = ["AssetId"];
    api.cacheName = null; //"staticObjectCache";
    api.parentApi = "Asset";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Assets}/{assetId}/${m.RouteSegment.Test}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.menuText = "Test Asset Source Code";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This api tests source code to verify that it is valid.";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static AssetFile(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AssetFile");
    api.version = version;
    api.documentation.objectDescription = "Asset File";
    api.documentation.objectPrimaryKey = "FileName";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.AssetFile;
    api.documentation.requestAndResponseDataModelObject = new m5.AssetFileEditViewModel();
    api.documentation.documentationUrlBase = "/asset/file/";
    api.documentation.securityAccessArea = Constants.AccessArea.Asset;
    api.pathVariables = ["{assetId}", "{fileName}"];
    api.pathModelProperties = ["AssetId", "FileName"];
    api.cacheName = "assetObjectCache";
    api.parentApi = "Asset";
    api.useStandardEndpoints(`/${m.RouteSegment.Assets}/{assetId}/${m.RouteSegment.Files}`);
    return api;
  };

  public static AssetFileAsNewChildAsset(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AssetFileAsNewChildAsset");
    api.version = version;
    api.documentation.objectDescription = "Asset File";
    api.documentation.objectPrimaryKey = "FileName";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.AssetFile;
    api.documentation.requestAndResponseDataModelObject = new m5.AssetFileEditViewModel();
    api.documentation.documentationUrlBase = "/asset/file-new-child/";
    api.documentation.securityAccessArea = Constants.AccessArea.Asset;
    api.pathVariables = ["{assetId}", "{fileName}"];
    api.pathModelProperties = ["AssetId", "FileName"];
    api.cacheName = "assetObjectCache";
    api.parentApi = "Asset";
    api.endpoints.push(new ApiEndpoint("/assets/{assetId}/children/files", ApiOperationType.Add));
    return api;
  };


  public static AssetFileActionDownload(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AssetFileActionDownload");
    api.version = version;
    api.documentation.objectDescription = "Asset File";
    api.documentation.objectPrimaryKey = "FileName";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.AssetFile;
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/asset/file/download/";
    api.documentation.securityAccessArea = Constants.AccessArea.Asset;
    api.pathVariables = ["{assetId}", "{fileName}", "{friendlyName}"];
    api.pathModelProperties = ["AssetId", "FileName", "FriendlyName"];
    api.cacheName = "assetObjectCache";
    api.parentApi = "Asset";
    api.endpoints.push(new ApiEndpoint("/assets/{assetId}/files/{fileName}/actions/download/{friendlyName}", ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Download Asset File";
    api.endpoints.slice(-1)[0].documentation.menuText = "Download Asset File";
    api.endpoints.slice(-1)[0].documentation.overviewText = `
This API can be used to download the asset.  The file name parameter can be a specific file name for a file associated with this asset or
a file role of \Asset\ or \Thumbnail\ for a generic way to reference the asset file or thumbnail file (if any).
<br/><br/>
The friendly name parameter is not used by the API but can be helpful when a URL is visible by users.
<br/><br/>
The API response will have a Content-Type matching the content type of the asset and a payload of the binary contents of the asset.  It is the responsibility
of the API caller to save the contents as a file.  This is not directly possible in javascript since there is no access to a user's file system from within
the browser.  One method of doing this is to append the authentication token as a token string parameter to the API url and assigning that to the
window.location.href property (e.g. \https://the.api.url/assets/{assetId}/files/{fileName}/actions/download/{friendlyName}?token=eyJ0eXAiOiJKV1QNiJ9...\) which will then trigger
the browser's standard open/save behavior.  Since the use of an API Key in a browser session is not considered safe it is not supported as a query
string parameter.  Note that the authentication token is not required for public assets.
`;
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "cloud-download";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Download API Call";
    api.endpoints.slice(-1)[0].documentation.testButton2Icon = "download";
    api.endpoints.slice(-1)[0].documentation.testButton2Text = "Download File";
    api.endpoints.slice(-1)[0].documentation.testButton2Action = "download";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static AssetFileActionView(version: number = AppConfig.apiVersion): ApiProperties {
    const api = this.AssetFileActionDownload(version);
    api.id = "AssetFileActionView";
    api.documentation.documentationUrlBase = "/asset/file/view/";
    // clear endpoints
    api.endpoints = [];
    api.endpoints.push(new ApiEndpoint("/assets/{assetId}/files/{fileName}/actions/view/{friendlyName}", ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "View Asset File";
    api.endpoints.slice(-1)[0].documentation.menuText = "View Asset File";
    api.endpoints.slice(-1)[0].documentation.overviewText = `
This API can be used to view the asset.  The file name parameter can be a specific file name for a file associated with this asset or
a file role of \Asset\ or \Thumbnail\ for a generic way to reference the asset file or thumbnail file (if any).
<br/><br/>
The friendly name parameter is not used by the API but can be helpful when a URL is visible by users.
<br/><br/>
The API response will have a Content-Type matching the content type of the asset and a payload of the binary contents of the asset.  It is the responsibility
of the API caller to present the contents of the file for viewing.  To help facilitate this the file can also be referenced as a URL which can be included as
the source for an image tag, iframe tag, etc.  When doing that append the authentication token as a token string parameter to the API url
(e.g. \https://the.api.url/assets/{assetId}/files/{fileName}/actions/view/{friendlyName}?token=eyJ0eXAiOiJKV1QNiJ9...\).
Note that the authentication token is not required for public assets.
`;
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "tv";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "View API Call";
    api.endpoints.slice(-1)[0].documentation.testButton2Icon = "tv";
    api.endpoints.slice(-1)[0].documentation.testButton2Text = "View File";
    api.endpoints.slice(-1)[0].documentation.testButton2Action = "download";
    return api;
  };

  public static AssetFileActionUpload(version: number = AppConfig.apiVersion): ApiProperties {
    const api = this.AssetFileActionDownload(version);
    api.id = "AssetFileActionUpload";
    api.documentation.documentationUrlBase = "/asset/file/upload/";
    // clear endpoints
    api.endpoints = [];
    api.endpoints.push(new ApiEndpoint("/assets/{assetId}/files/actions/upload", ApiOperationType.Call));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Upload Asset File";
    api.endpoints.slice(-1)[0].documentation.menuText = "Upload Asset File";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API can be used to upload the asset file as a multipart/form-data form submission.`;
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "cloud-upload";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Upload API Call";
    return api;
  };

  public static AssetSelection(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AssetSelection");
    api.version = version;
    api.documentation.objectDescription = "Asset Selection";
    api.documentation.objectPrimaryKey = "AssetSelectionId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "Value01", "Value02", "Value03"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.AssetSelection;
    api.documentation.requestAndResponseDataModelObject = new m5.AssetSelectionEditViewModel();
    api.documentation.documentationUrlBase = "/asset/selection/";
    api.documentation.securityAccessArea = Constants.AccessArea.AssetSelection;
    api.pathVariables = ["{assetId}", "{assetSelectionId}"];
    api.pathModelProperties = ["AssetId", "AssetSelectionId"];
    api.cacheName = "assetObjectCache";
    api.parentApi = "Asset";
    api.useStandardEndpoints(`/${m.RouteSegment.Assets}/{assetId}/${m.RouteSegment.Selections}`, null, null, true);
    return api;
  };

  public static AssetRelated(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AssetRelated");
    api.version = version;
    api.documentation.objectDescription = "Related Asset";
    api.documentation.objectPrimaryKey = "AssetId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.AssetReference;
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/asset/related/";
    api.documentation.securityAccessArea = Constants.AccessArea.Asset;
    api.pathVariables = "{assetId}";
    api.pathModelProperties = "AssetId";
    api.cacheName = "assetObjectCache";
    api.parentApi = "Asset";
    api.endpoints.push(new ApiEndpoint("/assets/{assetId}/related?page={page}&size={size}&sort={sort}&filterId={filterId}&filter={filter}&q={q}&expand={expand}", ApiOperationType.List));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API retrieves a list of child and referenced assets.";
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = new m5.AssetListViewModel();
    return api;
  };

  public static AssetReference(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AssetReference");
    api.version = version;
    api.documentation.objectDescription = "Asset Reference";
    api.documentation.objectPrimaryKey = ["AssetId", "ReferencedAssetId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.AssetReference;
    api.documentation.requestAndResponseDataModelObject = new m5.AssetReferenceEditViewModel();
    api.documentation.documentationUrlBase = "/asset/reference/";
    api.documentation.securityAccessArea = Constants.AccessArea.Asset;
    api.pathVariables = ["{assetId}", "{assetReferenceId}"];
    api.pathModelProperties = ["AssetId", "AssetReferenceId"];
    api.cacheName = "assetObjectCache";
    api.parentApi = "Asset";
    api.useStandardEndpoints(`/${m.RouteSegment.Assets}/{assetId}/${m.RouteSegment.References}`, null, null, true);
    return api;
  };

  public static AssetVisibility(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AssetVisibility");
    api.version = version;
    api.documentation.objectDescription = "Asset Visibility";
    api.documentation.objectPrimaryKey = "AssetVisibilityId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.AssetVisibility;
    api.documentation.requestAndResponseDataModelObject = new m5.AssetVisibilityEditViewModel();
    api.documentation.documentationUrlBase = "/asset/visibility/";
    api.documentation.securityAccessArea = Constants.AccessArea.Visibility;
    api.pathVariables = ["{assetId}", "{assetVisibilityId}"];
    api.pathModelProperties = ["AssetId", "AssetVisibilityId"];
    api.cacheName = "assetObjectCache";
    api.parentApi = "Asset";
    api.useStandardEndpoints(`/${m.RouteSegment.Assets}/{assetId}/${m.RouteSegment.Visibility}`, null, null, true);
    return api;
  };

  public static AssetAccessLog(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AssetAccessLog");
    api.version = version;
    api.documentation.objectDescription = "Asset Access Log";
    api.documentation.objectPrimaryKey = "AssetAccessLogId";
    api.documentation.objectDescriptionPropertyNames = ["AccessedByContactName", "AccessDateTime"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.AssetAccessLog;
    api.documentation.requestAndResponseDataModelObject = new m5.AssetAccessLogEditViewModel();
    api.documentation.documentationUrlBase = "/asset/access-log/";
    api.documentation.securityAccessArea = Constants.AccessArea.AssetAccessLog;
    api.pathVariables = ["{assetId}", "{assetAccessLogId}"];
    api.pathModelProperties = ["AssetId", "AssetAccessLogId"];
    api.cacheName = "assetObjectCache";
    api.parentApi = "Asset";
    api.useStandardEndpoints(`/${m.RouteSegment.Assets}/{assetId}/${m.RouteSegment.AccessLog}`, null, null, false);
    // List docs at array position 0
    api.endpoints.slice(0)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(0)[0].documentation.overviewText = "This API retrieves a list asset access log entries for the specified asset id.  A special filter option of \"CurrentUser\" can be used " +
      "to represent the current authorized user or current user's ip address when looking for previous access log entries for this user and asset.";
    return api;
  };

  public static AssetFeedback(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AssetFeedback");
    api.version = version;
    api.documentation.objectDescription = "Asset Feedback";
    api.documentation.objectDescriptionPlural = api.documentation.objectDescription;
    api.documentation.objectPrimaryKey = "AssetFeedbackId";
    api.documentation.objectDescriptionPropertyNames = ["FeedbackByContactName", "FeedbackDateTime"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.AssetFeedback;
    api.documentation.requestAndResponseDataModelObject = new m5.AssetFeedbackEditViewModel();
    api.documentation.documentationUrlBase = "/asset/feedback/";
    api.documentation.securityAccessArea = Constants.AccessArea.Feedback;
    api.pathVariables = ["{assetId}", "{assetFeedbackId}"];
    api.pathModelProperties = ["AssetId", "AssetFeedbackId"];
    api.cacheName = "assetObjectCache";
    api.parentApi = "Asset";
    api.useStandardEndpoints(`/${m.RouteSegment.Assets}/{assetId}/${m.RouteSegment.Feedback}`, null, null, false);
    // List docs at array position 0
    api.endpoints.slice(0)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(0)[0].documentation.overviewText = "This API retrieves a list asset feedback items for the specified asset id.  A special filter option of \"CurrentUser\" can be used " +
      "to represent the current authorized user or current user's ip address when looking for previously submitted feedback.";
    return api;
  };

  public static AssetLicense(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AssetLicense");
    api.version = version;
    api.documentation.objectDescription = "Asset License";
    api.documentation.objectPrimaryKey = "AssetLicenseId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.AssetLicense;
    api.documentation.requestAndResponseDataModelObject = new m5.AssetLicenseViewModel();
    api.documentation.documentationUrlBase = "/asset-license/";
    api.documentation.securityAccessArea = Constants.AccessArea.AssetLicense;
    api.pathVariables = "{assetLicenseId}";
    api.pathModelProperties = "AssetLicenseId";
    api.cacheName = "systemCache";
    api.useStandardEndpoints(`/${m.RouteSegment.AssetLicenses}`, null, "", true);
    return api;
  };

  //#endregion


  //#region Billing APIs

  public static BillingProfile(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("BillingProfile");
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Billing Profile";
    api.documentation.objectPrimaryKey = "BillingProfileId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalBillingProfileId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.BillingProfile;
    api.documentation.requestAndResponseDataModelObject = new m5.BillingProfileEditViewModel();
    api.documentation.documentationUrlBase = "/billing-profile/";
    api.documentation.securityAccessArea = Constants.AccessArea.BillingProfile;
    api.pathVariables = "{billingProfileId}";
    api.pathModelProperties = "BillingProfileId";
    api.cacheName = "billingCache";
    api.useStandardEndpoints(`/${m.RouteSegment.BillingProfiles}`, new m5.BillingProfileListViewModel(), Constants.DataModelName.BillingProfileList, true);
    return api;
  };

  public static BillingReportProfile(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("BillingReportProfile");
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Billing Report Profile";
    api.documentation.objectPrimaryKey = "BillingReportProfileId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.BillingReportProfile;
    api.documentation.requestAndResponseDataModelObject = new m5.BillingReportProfileEditViewModel();
    api.documentation.documentationUrlBase = "/billing-report-profile/";
    api.documentation.securityAccessArea = Constants.AccessArea.BillingReportProfile;
    api.pathVariables = "{billingReportProfileId}";
    api.pathModelProperties = "BillingReportProfileId";
    api.cacheName = "billingCache";
    api.useStandardEndpoints(`/${m.RouteSegment.BillingReportProfiles}`, new m5.BillingReportProfileListViewModel(), Constants.DataModelName.BillingReportProfileList, true);
    return api;
  };

  public static BillingReport(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("BillingReport");
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Billing Report";
    api.documentation.objectPrimaryKey = "BillingReportId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.BillingReport;
    api.documentation.requestAndResponseDataModelObject = new m5.BillingReportEditViewModel();
    api.documentation.documentationUrlBase = "/billing-report-profile/report/";
    api.documentation.securityAccessArea = Constants.AccessArea.BillingReport;
    api.pathVariables = ["{billingReportProfileId}", "{billingReportId}"];
    api.pathModelProperties = ["BillingReportProfileId", "BillingReportId"];
    api.cacheName = "billingCache";
    api.useStandardEndpoints(`/${m.RouteSegment.BillingReportProfiles}/{billingReportProfileId}/${m.RouteSegment.Reports}`, null, null, true);
    return api;
  };

  public static BillingAccount(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("BillingAccount");
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Billing Account";
    api.documentation.objectPrimaryKey = "BillingAccountId";
    api.documentation.objectDescriptionPropertyNames = ["AccountNumber", "ExternalAccountNumber", "AccountName"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.BillingAccount;
    api.documentation.requestAndResponseDataModelObject = new m5.BillingAccountEditViewModel();
    api.documentation.documentationUrlBase = "/billing-account/";
    api.documentation.securityAccessArea = Constants.AccessArea.BillingAccount;
    api.pathVariables = "{billingAccountId}";
    api.pathModelProperties = "BillingAccountId";
    api.cacheName = "billingCache";
    api.useStandardEndpoints(`/${m.RouteSegment.BillingAccounts}`, new m5.BillingAccountListViewModel(), Constants.DataModelName.BillingAccountList, true);
    return api;
  };

  public static BillingTransaction(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("BillingTransaction");
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Billing Transaction";
    api.documentation.objectPrimaryKey = "BillingTransactionId";
    api.documentation.objectDescriptionPropertyNames = ["TransactionNumber", "ExternalTransactionNumber", "ContactName"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.BillingTransaction;
    api.documentation.requestAndResponseDataModelObject = new m5.BillingTransactionEditViewModel();
    api.documentation.documentationUrlBase = "/billing-transaction/";
    api.documentation.securityAccessArea = Constants.AccessArea.BillingTransaction;
    api.pathVariables = "{billingTransactionId}";
    api.pathModelProperties = "BillingTransactionId";
    api.cacheName = "billingCache";
    api.useStandardEndpoints(`/${m.RouteSegment.BillingTransactions}`, new m5.BillingTransactionListViewModel(), Constants.DataModelName.BillingTransactionList, true);
    return api;
  };

  public static BillingTransactionDetail(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("BillingTransactionDetail");
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Billing Transaction Detail";
    api.documentation.objectPrimaryKey = "BillingTransactionDetailId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.BillingTransactionDetail;
    api.documentation.requestAndResponseDataModelObject = new m5.BillingTransactionDetailEditViewModel();
    api.documentation.documentationUrlBase = "/billing-transaction/detail/";
    api.documentation.securityAccessArea = Constants.AccessArea.BillingTransactionDetail;
    api.pathVariables = ["{billingTransactionId}", "{billingTransactionDetailId}"];
    api.pathModelProperties = ["BillingTransactionId", "BillingTransactionDetailId"];
    api.cacheName = "billingCache";
    api.useStandardEndpoints(`/${m.RouteSegment.BillingTransactions}/{billingTransactionId}/${m.RouteSegment.Details}`, new m5.BillingTransactionListViewModel(), Constants.DataModelName.BillingTransactionList, true);
    return api;
  };

  public static BillingEvent(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("BillingEvent");
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Billing Event";
    api.documentation.objectPrimaryKey = "BillingEventId";
    api.documentation.objectDescriptionPropertyNames = ["ExternalBillingEventId", "RequestedItemId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.BillingEvent;
    api.documentation.requestAndResponseDataModelObject = new m5.BillingEventEditViewModel();
    api.documentation.documentationUrlBase = "/billing-event/";
    api.documentation.securityAccessArea = Constants.AccessArea.BillingEvent;
    api.pathVariables = "{billingEventId}";
    api.pathModelProperties = "BillingEventId";
    api.cacheName = null;
    api.useStandardEndpoints(`/${m.RouteSegment.BillingEvents}`);
    return api;
  };

  public static BillingEventBulkAdd(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("BillingEventBulkAdd");
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Billing Event Bulk Add";
    api.documentation.objectPrimaryKey = "BillingEventId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.BillingEvent;
    api.documentation.requestAndResponseDataModelObject = [new m5.BillingEventEditViewModel(), new m5.BillingEventEditViewModel()];
    api.documentation.documentationUrlBase = "/billing-event/bulk-add/";
    api.documentation.securityAccessArea = Constants.AccessArea.BillingEvent;
    api.pathVariables = "{billingEventId}";
    api.pathModelProperties = "BillingEventId";
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.BillingEvents}/${m.RouteSegment.BulkAdd}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Billing Event Bulk Add";
    api.endpoints.slice(-1)[0].documentation.menuText = "Billing Event Bulk Add";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API is utilized to bulk add a collection of billing event objects.`;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = true;
    return api;
  };

  public static Package(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Package");
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Package";
    api.documentation.objectPrimaryKey = "PackageId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalPackageId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Package;
    api.documentation.requestAndResponseDataModelObject = new m5.PackageEditViewModel();
    api.documentation.documentationUrlBase = "/package/";
    api.documentation.securityAccessArea = Constants.AccessArea.Package;
    api.pathVariables = "{packageId}";
    api.pathModelProperties = "PackageId";
    api.cacheName = "billingCache";
    api.impactedPickListIds = [Constants.PickList.___Package_PackageCode, Constants.PickList.___Package_PackageType, Constants.PickList.___Package_PackageClass];
    api.useStandardEndpoints(`/${m.RouteSegment.Packages}`, new m5.PackageListViewModel(), Constants.DataModelName.PackageList, true);
    return api;
  };

  public static PackageItem(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("PackageItem");
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Package Item";
    api.documentation.objectPrimaryKey = "PackageItemId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PackageItem;
    api.documentation.requestAndResponseDataModelObject = new m5.PackageItemEditViewModel();
    api.documentation.documentationUrlBase = "/package/item/";
    api.documentation.securityAccessArea = Constants.AccessArea.PackageItem;
    api.pathVariables = ["{packageId}", "{packageItemId}"];
    api.pathModelProperties = ["PackageId", "PackageItemId"];
    api.cacheName = "billingCache";
    api.parentApi = "Package";
    api.useStandardEndpoints(`/${m.RouteSegment.Packages}/{packageId}/${m.RouteSegment.Items}`);
    return api;
  };

  public static PackageRateAdjustment(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("PackageRateAdjustment");
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Package Rate Adjustment";
    api.documentation.objectPrimaryKey = "PackageRateAdjustmentId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PackageRateAdjustment;
    api.documentation.requestAndResponseDataModelObject = new m5.PackageRateAdjustmentEditViewModel();
    api.documentation.documentationUrlBase = "/package/rate-adjustment/";
    api.documentation.securityAccessArea = Constants.AccessArea.PackageRateAdjustment;
    api.pathVariables = ["{packageId}", "{packageRateAdjustmentId}"];
    api.pathModelProperties = ["PackageId", "PackageRateAdjustmentId"];
    api.cacheName = "billingCache";
    api.parentApi = "Package";
    api.useStandardEndpoints(`/${m.RouteSegment.Packages}/{packageId}/${m.RouteSegment.RateAdjustments}`);
    return api;
  };

  public static PackageAssociation(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("PackageAssociation");
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Package Association";
    api.documentation.objectPrimaryKey = "PackageAssociationId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PackageAssociation;
    api.documentation.requestAndResponseDataModelObject = new m5.PackageAssociationEditViewModel();
    api.documentation.documentationUrlBase = "/package/association/";
    api.documentation.securityAccessArea = Constants.AccessArea.PackageAssociation;
    api.pathVariables = ["{packageId}", "{packageAssociationId}"];
    api.pathModelProperties = ["PackageId", "PackageAssociationId"];
    api.cacheName = "billingCache";
    api.parentApi = "Package";
    api.useStandardEndpoints(`/${m.RouteSegment.Packages}/{packageId}/${m.RouteSegment.Associations}`);
    return api;
  };

  public static PackageInventory(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("PackageInventory");
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Package Inventory";
    api.documentation.objectPrimaryKey = "PackageInventoryId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PackageInventory;
    api.documentation.requestAndResponseDataModelObject = new m5.PackageAssociationEditViewModel();
    api.documentation.documentationUrlBase = "/package/inventory/";
    api.documentation.securityAccessArea = Constants.AccessArea.PackageInventory;
    api.pathVariables = ["{packageId}", "{packageInventoryId}"];
    api.pathModelProperties = ["PackageId", "PackageInventoryId"];
    api.cacheName = "billingCache";
    api.parentApi = "Package";
    api.useStandardEndpoints(`/${m.RouteSegment.Packages}/{packageId}/${m.RouteSegment.Inventory}`);
    return api;
  };

  public static Item(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Item");
    api.version = version;
    api.documentation.objectDescription = "Item";
    api.documentation.objectPrimaryKey = "ItemId";
    api.documentation.objectDescriptionPropertyNames = ["ItemNumber", "ExternalItemNumber", "Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Item;
    api.documentation.requestAndResponseDataModelObject = new m5.ItemEditViewModel();
    api.documentation.documentationUrlBase = "/item/";
    api.documentation.securityAccessArea = Constants.AccessArea.Item;
    api.pathVariables = "{itemId}";
    api.pathModelProperties = "ItemId";
    api.cacheName = "itemCache";
    api.impactedPickListIds = [Constants.PickList._Item];
    api.useStandardEndpoints(`/${m.RouteSegment.Items}`, null, null, true);
    return api;
  }

  public static ItemRate(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ItemRate");
    api.version = version;
    api.documentation.objectDescription = "Item Rate";
    api.documentation.objectPrimaryKey = "ItemRateId";
    api.documentation.objectDescriptionPropertyNames = ["RateId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ItemRate;
    api.documentation.requestAndResponseDataModelObject = new m5.ItemRateEditViewModel();
    api.documentation.documentationUrlBase = "/item-rate/";
    api.documentation.securityAccessArea = Constants.AccessArea.ItemRate;
    api.pathVariables = ["{itemId}", "{itemRateId}"];
    api.pathModelProperties = ["ItemId", "ItemRateId"];
    api.cacheName = "itemCache";
    api.parentApi = "Item";
    api.useStandardEndpoints(`/${m.RouteSegment.Items}/{itemId}/${m.RouteSegment.Rates}`, null, null, true);
    return api;
  };

  public static ItemList(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ItemList");
    api.version = version;
    api.documentation.objectDescription = "Item List";
    api.documentation.objectPrimaryKey = "ItemListId";
    api.documentation.objectDescriptionPropertyNames = [""];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ItemList;
    api.documentation.requestAndResponseDataModelObject = new m5.ItemListEditViewModel();
    api.documentation.documentationUrlBase = "/item-list/";
    api.documentation.securityAccessArea = Constants.AccessArea.ItemList;
    api.pathVariables = ["{itemId}", "{itemListId}"];
    api.pathModelProperties = ["ItemId", "ItemRateId"];
    api.cacheName = "itemCache";
    api.parentApi = "Item";
    api.useStandardEndpoints(`/${m.RouteSegment.Items}/{itemId}/${m.RouteSegment.Lists}`, null, null, true);
    return api;
  };

  public static PackageOccurrence(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("PackageOccurrence");
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Package Occurrence";
    api.documentation.objectPrimaryKey = "PackageOccurrenceId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PackageOccurrence;
    api.documentation.requestAndResponseDataModelObject = new m5.PackageOccurrenceEditViewModel();
    api.documentation.documentationUrlBase = "/package-occurrence/";
    api.documentation.securityAccessArea = Constants.AccessArea.PackageOccurrence;
    api.pathVariables = "{packageOccurrenceId}";
    api.pathModelProperties = "PackageOccurrenceId";
    api.cacheName = "billingCache";
    api.useStandardEndpoints(`/${m.RouteSegment.PackageOccurrences}`, new m5.PackageOccurrenceListViewModel(), Constants.DataModelName.PackageOccurrenceList, true);
    return api;
  };

  public static PackageOccurrenceItem(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("PackageOccurrenceItem");
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Package Occurrence Item";
    api.documentation.objectPrimaryKey = "PackageOccurrenceItemId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PackageOccurrenceItem;
    api.documentation.requestAndResponseDataModelObject = new m5.PackageOccurrenceItemEditViewModel();
    api.documentation.documentationUrlBase = "/package-occurrence/item/";
    api.documentation.securityAccessArea = Constants.AccessArea.PackageOccurrenceItem;
    api.pathVariables = ["{packageOccurrenceId}", "{packageOccurrenceItemId}"];
    api.pathModelProperties = ["PackageOccurrenceId", "PackageOccurrenceItemId"];
    api.cacheName = "billingCache";
    api.parentApi = "PackageOccurrence";
    api.useStandardEndpoints(`/${m.RouteSegment.PackageOccurrences}/{packageOccurrenceId}/${m.RouteSegment.Items}`);
    return api;
  };

  public static PackageOccurrenceRateAdjustment(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("PackageOccurrenceRateAdjustment");
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Package Occurrence Rate Adjustment";
    api.documentation.objectPrimaryKey = "PackageRateAdjustmentId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PackageOccurrenceRateAdjustment;
    api.documentation.requestAndResponseDataModelObject = new m5.PackageOccurrenceRateAdjustmentEditViewModel();
    api.documentation.documentationUrlBase = "/package-occurrence/rate-adjustment/";
    api.documentation.securityAccessArea = Constants.AccessArea.PackageOccurrenceRateAdjustment;
    api.pathVariables = ["{packageOccurrenceId}", "{packageRateAdjustmentId}"];
    api.pathModelProperties = ["PackageOccurrenceId", "PackageRateAdjustmentId"];
    api.cacheName = "billingCache";
    api.parentApi = "PackageOccurrence";
    api.useStandardEndpoints(`/${m.RouteSegment.PackageOccurrences}/{packageOccurrenceId}/${m.RouteSegment.RateAdjustments}`);
    return api;
  };

  public static Purchase(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Purchase");
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Purchase";
    api.documentation.objectPrimaryKey = null;
    api.documentation.requestDataModelDocumentationName = Constants.DataModelName.PurchaseRequest;
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.PurchaseResponse;
    api.documentation.securityAccessArea = Constants.AccessArea.PurchaseCheckout;

    var model = new m5.PurchaseRequestModel();
    model.Customer = new m5.CustomerEditViewModel();
    model.BillingAccount = new m5.BillingAccountEditViewModel();
    model.Packages.push(new m5.PurchaseRequestPackageModel());
    model.Payment = new m5.PurchaseRequestPaymentModel();
    model.Payment.CreditCard = new m5pay.PaymentMethodCreditCardEditViewModel();
    model.Payment.ExternalPayment = new m5.PurchaseRequestExternalPaymentModel();
    model.Attributes = {};
    model.Cargo = {};
    //model.Options
    api.documentation.requestDataModelObject = model;

    const response = new m5.PurchaseResponseModel();
    response.ContactInformation = new m5.PurchaseResponseContactInformationModel();
    response.Packages.push(new m5.PurchaseResponsePackageModel());
    api.documentation.responseDataModelObject = response;

    api.documentation.documentationUrlBase = "/billing/purchases/";
    api.pathVariables = ""; //"{paymentTransactionId}";
    api.pathModelProperties = ""; //"PaymentTransactionId";
    api.cacheName = null; //"purchaseObjectCache";
    api.endpoints.push(new ApiEndpoint("/billing/purchases", ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.testFormJsonOnly = true;
    api.endpoints.slice(-1)[0].documentation.overviewTemplateUrl = "PurchaseOverview"; // "modules/endpoint/assets/custom/purchaseOverview.html";
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.resultCodesTemplateUrl = "ResultCodePurchase";
    //api.endpoints.slice(-1)[0].documentation.resultCodesText =
    //  "<h4>Possible Result Codes</h4>" +
    //  "When the response ResultCode is SubsystemSpecificError (100) the purchase response and/or package response objects may have one of the following values for the ResponseCode: " +
    //  "<br/><br/>" +
    //  "<ib-api-docs-purchase-result-codes></ib-api-docs-purchase-result-codes>" +
    //  "Under other scenarios these result codes may be found in the response ResultCode: " +
    //  "<br/><br/>" +
    //  "<ib-api-docs-standard-result-codes></ib-api-docs-standard-result-codes>"
    api.endpoints.slice(-1)[0].documentation.pages.push(new ApiDocPage("Examples", "", "PurchaseExamples")); // "modules/endpoint/assets/custom/purchaseExamples.html"));
    return api;
  };

  public static PurchaseStatus(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("PurchaseStatus");
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Purchase Status";
    api.documentation.objectPrimaryKey = null;
    api.documentation.requestDataModelDocumentationName = "";
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.PurchaseResponse;
    api.documentation.securityAccessArea = Constants.AccessArea.PurchaseCheckout;

    const response = new m5.PurchaseResponseModel();
    response.ContactInformation = new m5.PurchaseResponseContactInformationModel();
    response.Packages.push(new m5.PurchaseResponsePackageModel());
    api.documentation.responseDataModelObject = response;

    api.documentation.documentationUrlBase = "/billing/purchase-status/";
    api.pathVariables = "{transactionId}";
    api.pathModelProperties = "TransactionId";
    api.cacheName = null; //"purchaseObjectCache";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Billing}/${m.RouteSegment.Purchases}/{transactionId}`, ApiOperationType.Get));
    return api;
  };



  public static TaxConfiguration(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("TaxConfiguration");
    api.modules = ["tax"];
    api.version = version;
    api.documentation.objectDescription = "Tax Configuration";
    api.documentation.objectPrimaryKey = "TaxConfigurationId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.TaxConfiguration;
    api.documentation.requestAndResponseDataModelObject = new m5.TaxConfigurationEditViewModel();
    api.documentation.documentationUrlBase = "/tax/configuration/";
    api.documentation.securityAccessArea = Constants.AccessArea.TaxConfiguration;
    api.pathVariables = "{taxConfigurationId}";
    api.pathModelProperties = "TaxConfigurationId";
    api.cacheName = "taxCache";
    api.impactedPickListIds = [Constants.PickList._TaxConfiguration];
    api.useStandardEndpoints(`/${m.RouteSegment.Tax}/${m.RouteSegment.Configurations}`, null, null, true);
    return api;
  };

  public static TaxGeocode(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("TaxGeocode");
    api.modules = ["tax"];
    api.version = version;
    api.documentation.objectDescription = "Tax Geocode";
    api.documentation.objectPrimaryKey = "TaxGeocodeId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "Geocode"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.TaxGeocode;
    api.documentation.requestAndResponseDataModelObject = new m5.TaxGeocodeEditViewModel();
    api.documentation.documentationUrlBase = "/tax/geocode/";
    api.documentation.securityAccessArea = Constants.AccessArea.TaxGeocode;
    api.pathVariables = "{taxGeocodeId}";
    api.pathModelProperties = "TaxGeocodeId";
    api.cacheName = "taxCache";
    api.impactedPickListIds = [Constants.PickList._Geocode];
    api.useStandardEndpoints(`/${m.RouteSegment.Tax}/${m.RouteSegment.Geocodes}`, null, null, true);
    return api;
  };

  public static TaxGeocodeLookup(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("TaxGeocodeLookup");
    api.modules = ["tax"];
    api.version = version;
    api.documentation.objectDescription = "Tax Geocode Lookup";
    api.documentation.objectPrimaryKey = "TaxGeocodeLookupId";
    api.documentation.objectDescriptionPropertyNames = ["Value", "Geocode"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.TaxGeocodeLookup;
    api.documentation.requestAndResponseDataModelObject = new m5.TaxGeocodeLookupEditViewModel();
    api.documentation.documentationUrlBase = "/tax/geocode-lookup/";
    api.documentation.securityAccessArea = Constants.AccessArea.TaxGeocodeLookup;
    api.pathVariables = "{taxGeocodeLookupId}";
    api.pathModelProperties = "TaxGeocodeLookupId";
    api.cacheName = "taxCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Tax}/${m.RouteSegment.GeocodeLookups}`, null, null, true);
    return api;
  };

  public static TaxJurisdiction(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("TaxJurisdiction");
    api.modules = ["tax"];
    api.version = version;
    api.documentation.objectDescription = "Tax Jurisdiction";
    api.documentation.objectPrimaryKey = "TaxJurisdictionId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.TaxJurisdiction;
    api.documentation.requestAndResponseDataModelObject = new m5.TaxJurisdictionEditViewModel();
    api.documentation.documentationUrlBase = "/tax/jurisdiction/";
    api.documentation.securityAccessArea = Constants.AccessArea.TaxJurisdiction;
    api.pathVariables = "{taxJurisdictionId}";
    api.pathModelProperties = "TaxJurisdictionId";
    api.cacheName = "taxCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Tax}/${m.RouteSegment.Jurisdictions}`, null, null, true);
    return api;
  };

  public static TaxMatrix(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("TaxMatrix");
    api.modules = ["tax"];
    api.version = version;
    api.documentation.objectDescription = "Tax Matrix";
    api.documentation.objectPrimaryKey = "TaxMatrixId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "Geocode"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.TaxMatrix;
    api.documentation.requestAndResponseDataModelObject = new m5.TaxMatrixEditViewModel();
    api.documentation.documentationUrlBase = "/tax/matrix/";
    api.documentation.securityAccessArea = Constants.AccessArea.TaxMatrix;
    api.pathVariables = "{taxMatrixId}";
    api.pathModelProperties = "TaxMatrixId";
    api.cacheName = "taxCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Tax}/${m.RouteSegment.Matrix}`, null, null, true);
    return api;
  };

  public static TaxTransactionDetail(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("TaxTransactionDetail");
    api.modules = ["tax"];
    api.version = version;
    api.documentation.objectDescription = "Tax Transaction Detail";
    api.documentation.objectPrimaryKey = "TaxTransactionDetailId";
    api.documentation.objectDescriptionPropertyNames = ["TaxAmount"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.TaxTransactionDetail;
    api.documentation.requestAndResponseDataModelObject = new m5.TaxTransactionDetailEditViewModel();
    api.documentation.documentationUrlBase = "/tax/transaction-detail/";
    api.documentation.securityAccessArea = Constants.AccessArea.TaxTransactionDetail;
    api.pathVariables = "{taxTransactionDetailId}";
    api.pathModelProperties = "TaxTransactionDetailId";
    api.cacheName = "taxCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Tax}/${m.RouteSegment.TransactionDetails}`, null, null, true);
    return api;
  };

  public static TaxCalculate(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("TaxCalculate");
    api.modules = ["tax"];
    api.version = version;
    api.documentation.objectDescription = "Tax Calc";
    api.documentation.objectPrimaryKey = "";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.TaxCalculation;
    api.documentation.requestAndResponseDataModelObject = new m5.TaxCalculationViewModel();
    api.documentation.documentationUrlBase = "/tax/action/calc/";
    api.pathVariables = ["{contactId}", "{contactType}", "{taxContactType}", "{billingAccountId}", "{packageCode}", "{taxCalculationMethod}", "{taxScenarioId}", "{taxItemCode}", "{quantity}", "{isoCurrencyCode}", "{amount}", "{taxEventDateTime}"];
    api.pathModelProperties = ["ContactId", "ContactType", "TaxContactType", "BillingAccountId", "PackageCode", "TaxCalculationMethod", "TaxScenarioId", "TaxItemCode", "Quantity", "IsoCurrencyCode", "Amount", "TaxEventDateTime"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Tax}/${m.RouteSegment.Actions}/${m.RouteSegment.Calc}?contactId={contactId}&contactType={contactType}&taxContactType={taxContactType}&billingAccountId={billingAccountId}&packageCode={packageCode}&taxCalculationMethod={taxCalculationMethod}&taxScenarioId={taxScenarioId}&taxItemCode={taxItemCode}&quantity={quantity}&isoCurrencyCode={isoCurrencyCode}&amount={amount}&taxEventDateTime={taxEventDateTime}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Tax Calculation";
    api.endpoints.slice(-1)[0].documentation.menuText = "Tax Calculation";
    api.endpoints.slice(-1)[0].documentation.overviewText = `
This API is utilized to calculate taxes for the scenario presented in the query string parameters.
<br/><br/>
At a minimum query string parameters are needed to identify tax jurisdictions, tax rates, and the amount to be taxed.
Custom attributes that should be used are specified as additional query string parameters.
<br/><br/>
For example: https://api....com/${m.RouteSegment.Tax}/${m.RouteSegment.Actions}/${m.RouteSegment.Calc}?packageCode=WiFi&isoCurrencyCode=USD&amount=8&originationCity=DTW
`;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = true;
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = new m5.TaxCalculationViewModel();
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = Constants.DataModelName.TaxCalculation;
    api.endpoints.slice(-1)[0].documentation.testFormAllowFreeFormQueryString = true;
    api.endpoints.slice(-1)[0].documentation.testFormAllowFreeFormQueryStringTooltip = "Attributes used for tax calculation that should be used for identification of tax jurisdictions and/or tax rates are specified as query string parameters.  For example: originationCity=DTW";

    return api;
  };

  //#endregion


  //#region Case Template APIs

  public static CaseTemplate(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("CaseTemplate");
    api.version = version;
    api.documentation.objectDescription = "Case Template";
    api.documentation.objectPrimaryKey = "CaseTemplateId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalCaseTemplateId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.CaseTemplate;
    api.documentation.requestAndResponseDataModelObject = new m5.CaseTemplateEditViewModel();
    api.documentation.documentationUrlBase = "/case-template/";
    api.documentation.securityAccessArea = Constants.AccessArea.CaseTemplate;
    api.pathVariables = "{caseTemplateId}";
    api.pathModelProperties = "CaseTemplateId";
    api.cacheName = "caseTemplateObjectCache";
    api.impactedPickListIds = [Constants.PickList._CaseTemplate];
    api.useStandardEndpoints(`/${m.RouteSegment.CaseTemplates}`, new m5.CaseTemplateListViewModel(), Constants.DataModelName.CaseTemplateList, true);
    return api;
  };

  //#endregion


  //#region Case APIs

  public static Case(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Case");
    api.version = version;
    api.documentation.objectDescription = "Case";
    api.documentation.objectPrimaryKey = "CaseId";
    api.documentation.objectDescriptionPropertyNames = ["Title", "ExternalCaseNumber", "CaseNumber"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Case;
    api.documentation.requestAndResponseDataModelObject = new m5.CaseEditViewModel();
    api.documentation.documentationUrlBase = "/case/";
    api.documentation.securityAccessArea = Constants.AccessArea.Cases;
    api.pathVariables = "{caseId}";
    api.pathModelProperties = "CaseId";
    api.cacheName = "caseObjectCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Cases}`, new m5.CaseListViewModel(), Constants.DataModelName.CaseList, true);
    return api;
  };

  public static CaseAssign(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("CaseAssign");
    api.version = version;
    api.documentation.objectDescription = "Case Assign";
    api.documentation.objectPrimaryKey = "CaseId";
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.Case;
    api.documentation.responseDataModelObject = new m5.CaseEditViewModel();
    api.documentation.documentationUrlBase = "/case/assign/";
    api.documentation.securityAccessArea = Constants.AccessArea.Cases;
    api.pathVariables = ["{caseId}", "{currentContactId}", "{newContactId}"];
    api.pathModelProperties = ["CaseId", "CurrentContactId", "NewContactId"];
    api.cacheName = null;
    api.parentApi = "Case";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Cases}/{caseId}/${m.RouteSegment.Assign}/{currentContactId}/{newContactId}`, ApiOperationType.Edit));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Assign Case";
    api.endpoints.slice(-1)[0].documentation.menuText = "Assign Case";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API endpoint is used assign a case to a contact.";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static CaseReviewSubmitRequest(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("CaseReviewSubmitRequest");
    api.version = version;
    api.documentation.objectDescription = "Case Review Request";
    api.documentation.objectPrimaryKey = "CaseId";
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.Case;
    api.documentation.responseDataModelObject = new m5.CaseEditViewModel();
    api.documentation.documentationUrlBase = "/case/review-request/";
    api.documentation.securityAccessArea = Constants.AccessArea.Cases;
    api.pathVariables = ["{caseId}", "{nextReviewContactId}"];
    api.pathModelProperties = ["CaseId", "NextReviewContactId"];
    api.cacheName = null;
    api.parentApi = "Case";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Cases}/{caseId}/${m.RouteSegment.ReviewSubmit}/{nextReviewContactId}`, ApiOperationType.Edit));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Request Case Review";
    api.endpoints.slice(-1)[0].documentation.menuText = "Request Case Review";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API endpoint is used to request a case be reviewed.";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static CaseReviewRetractRequest(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("CaseReviewRetractRequest");
    api.version = version;
    api.documentation.objectDescription = "Case Retract Review Request";
    api.documentation.objectPrimaryKey = "CaseId";
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.Case;
    api.documentation.responseDataModelObject = new m5.CaseEditViewModel();
    api.documentation.documentationUrlBase = "/case/retract-review-request/";
    api.documentation.securityAccessArea = Constants.AccessArea.Cases;
    api.pathVariables = ["{caseId}"];
    api.pathModelProperties = ["CaseId"];
    api.cacheName = null;
    api.parentApi = "Case";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Cases}/{caseId}/${m.RouteSegment.ReviewRetract}`, ApiOperationType.Edit));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Retract Case Review Request";
    api.endpoints.slice(-1)[0].documentation.menuText = "Retract Case Review Request";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API endpoint is used to retract a request that the case be reviewed.";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static CaseCorrespondence(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("CaseCorrespondence");
    api.version = version;
    api.documentation.objectDescription = "Case Correspondence";
    api.documentation.objectPrimaryKey = "CaseCorrespondenceId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.CaseCorrespondence;
    api.documentation.requestAndResponseDataModelObject = new m5.CaseCorrespondenceEditViewModel();
    api.documentation.documentationUrlBase = "/case/correspondence/";
    api.documentation.securityAccessArea = Constants.AccessArea.CaseCorrespondence;
    api.pathVariables = ["{caseId}", "{caseCorrespondenceId}"];
    api.pathModelProperties = ["CaseId", "CaseCorrespondenceId"];
    api.cacheName = "caseObjectCache";
    api.parentApi = "Case";
    api.useStandardEndpoints(`/${m.RouteSegment.Cases}/{caseId}/${m.RouteSegment.Correspondence}`, null, null, true);
    return api;
  };

  //#endregion



  //#region Process APIs

  public static ProcessTemplate(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ProcessTemplate");
    api.version = version;
    api.documentation.objectDescription = "Process Template";
    api.documentation.objectPrimaryKey = "ProcessTemplateId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalProcessTemplateId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ProcessTemplate;
    api.documentation.requestAndResponseDataModelObject = new m5.ProcessTemplateEditViewModel();
    api.documentation.documentationUrlBase = "/process-template/";
    api.documentation.securityAccessArea = Constants.AccessArea.ProcessTemplate;
    api.pathVariables = "{processTemplateId}";
    api.pathModelProperties = "ProcessTemplateId";
    api.cacheName = "processTemplateObjectCache";
    api.impactedPickListIds = [Constants.PickList._ProcessTemplate];
    api.useStandardEndpoints(`/${m.RouteSegment.ProcessTemplates}`, null, null, true);
    return api;
  };

  public static ProcessTemplateStep(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ProcessTemplateStep");
    api.version = version;
    api.documentation.objectDescription = "Process Template Step";
    api.documentation.objectPrimaryKey = "ProcessTemplateStepId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "StepActionId", "StepNumber"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ProcessTemplateStep;
    api.documentation.requestAndResponseDataModelObject = new m5.ProcessTemplateStepEditViewModel();
    api.documentation.documentationUrlBase = "/process-template/step/";
    api.documentation.securityAccessArea = Constants.AccessArea.ProcessTemplateStep;
    api.pathVariables = ["{processTemplateId}", "{processTemplateStepId}"];
    api.pathModelProperties = ["ProcessTemplateId", "ProcessTemplateStepId"];
    api.cacheName = "processTemplateObjectCache";
    api.parentApi = "ProcessTemplate";
    api.useStandardEndpoints(`/${m.RouteSegment.ProcessTemplates}/{processTemplateId}/${m.RouteSegment.Steps}`, null, null, true);
    return api;
  };

  public static Process(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Process");
    api.version = version;
    api.documentation.objectDescription = "Process";
    api.documentation.objectPrimaryKey = "ProcessId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ProcessDateTime"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Process;
    api.documentation.requestAndResponseDataModelObject = new m5.ProcessEditViewModel();
    api.documentation.documentationUrlBase = "/process/";
    api.documentation.securityAccessArea = Constants.AccessArea.Process;
    api.pathVariables = "{processId}";
    api.pathModelProperties = "ProcessId";
    api.cacheName = "processObjectCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Processes}`, null, null, true);
    return api;
  };

  public static ProcessApproval(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ProcessApproval");
    api.version = version;
    api.documentation.objectDescription = "Process Approval";
    api.documentation.requestDataModelDocumentationName = Constants.DataModelName.Review;
    api.documentation.requestDataModelObject = new m5.ReviewViewModel();
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.Process;
    api.documentation.responseDataModelObject = new m5.ProcessEditViewModel();
    api.documentation.documentationUrlBase = "/process/approval/";
    api.documentation.securityAccessArea = Constants.AccessArea.Process;
    api.pathVariables = "{processId}";
    api.pathModelProperties = "ProcessId";
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Processes}/{processId}/${m.RouteSegment.Approve}`, ApiOperationType.Edit));
    return api;
  };

  public static ProcessStep(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ProcessStep");
    api.version = version;
    api.documentation.objectDescription = "Process Step";
    api.documentation.objectPrimaryKey = "ProcessStepId";
    api.documentation.objectDescriptionPropertyNames = ["StepActionId", "StepNumber"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ProcessStep;
    api.documentation.requestAndResponseDataModelObject = new m5.ProcessStepEditViewModel();
    api.documentation.documentationUrlBase = "/process/step/";
    api.documentation.securityAccessArea = Constants.AccessArea.ProcessStep;
    api.pathVariables = ["{processId}", "{processStepId}"];
    api.pathModelProperties = ["ProcessId", "ProcessStepId"];
    api.cacheName = "processObjectCache";
    api.parentApi = "Process";
    api.useStandardEndpoints(`/${m.RouteSegment.Processes}/{processId}/${m.RouteSegment.Steps}`, new m5.ProcessStepListViewModel(), Constants.DataModelName.ProcessStepList, false);
    return api;
  };

  public static InternalExecuteStep(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("InternalExecuteStep");
    api.version = version;
    api.documentation.objectDescription = "Process Template Step";
    api.documentation.objectPrimaryKey = "ProcessTemplateStepId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ProcessTemplateStep;
    api.documentation.requestAndResponseDataModelObject = new m5.ProcessTemplateStepEditViewModel();
    api.documentation.documentationUrlBase = "/internal/execute/step/";
    api.pathVariables = [];
    api.pathModelProperties = [];
    api.cacheName = null;
    api.parentApi = null;
    api.endpoints.push(new ApiEndpoint(`${m.RouteSegment.Internal}/${m.RouteSegment.Execute}/${m.RouteSegment.Step}`, ApiOperationType.Call));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.overviewText = "The internal execute step API is utilized to execute a process template step object as an independent operation not part of the typical process workflow.";
    return api;
  };

  //#endregion



  //#region System APIs

  public static Currency(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Currency");
    api.version = version;
    api.documentation.objectDescription = "Currency";
    api.documentation.objectPrimaryKey = "CurrencyId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "IsoCurrencyCode"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Currency;
    api.documentation.requestAndResponseDataModelObject = new m5.CurrencyEditViewModel();
    api.documentation.documentationUrlBase = "/currency/";
    api.documentation.securityAccessArea = Constants.AccessArea.Currency;
    api.documentation.readOnly = true;
    api.pathVariables = "{currencyId}";
    api.pathModelProperties = "CurrencyId";
    api.cacheName = "staticObjectCache";
    api.cacheLevel = CacheLevel.PseudoStatic;
    api.impactedPickListIds = [Constants.PickList._Currency];
    api.useStandardEndpoints(`/${m.RouteSegment.Currency}`, new m5.CurrencyListViewModel(), Constants.DataModelName.CurrencyList);
    return api;
  };

  public static TimeZone(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("TimeZone");
    api.version = version;
    api.documentation.objectDescription = "Time Zone";
    api.documentation.objectPrimaryKey = "TimeZoneId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "SystemTimeZoneId", "UtcOffset", "TimeZoneId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.TimeZone;
    api.documentation.requestAndResponseDataModelObject = new m5.TimeZoneEditViewModel();
    api.documentation.documentationUrlBase = "/time-zone/";
    api.documentation.securityAccessArea = Constants.AccessArea.TimeZone;
    api.documentation.readOnly = true;
    api.pathVariables = "{timeZoneId}";
    api.pathModelProperties = "TimeZoneId";
    api.cacheName = "staticObjectCache";
    api.cacheLevel = CacheLevel.PseudoStatic;
    api.impactedPickListIds = [Constants.PickList._TimeZone];
    api.useStandardEndpoints(`/${m.RouteSegment.TimeZones}`, new m5.TimeZoneListViewModel(), Constants.DataModelName.TimeZoneList, true);
    return api;
  };

  public static DataTableSupport(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("DataTableSupport");
    api.version = version;
    api.documentation.objectDescription = "Data Table Support";
    api.documentation.objectDescriptionPlural = "Data Table Support";
    api.documentation.objectPrimaryKey = "DataTableSupportId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "TableName"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.DataTableSupport;
    api.documentation.requestAndResponseDataModelObject = new m5.DataTableSupportViewModel();
    api.documentation.documentationUrlBase = "/data-table-support/";
    api.documentation.securityAccessArea = Constants.AccessArea.DataTableSupport;
    api.documentation.readOnly = true;
    api.pathVariables = "{dataTableSupportId}";
    api.pathModelProperties = "DataTableSupportId";
    api.cacheName = "systemCache";
    api.useStandardEndpoints(`/${m.RouteSegment.DataTableSupport}`, null, "", true);
    return api;
  };

  public static DataTableSupportTest(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("DataTableSupportTest");
    api.version = version;
    api.documentation.objectDescription = "Data Table Support";
    api.documentation.objectDescriptionPlural = "Data Table Support";
    api.documentation.objectPrimaryKey = "DataTableSupportId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "TableName"];
    api.documentation.requestAndResponseDataModelDocumentationName = ""; //Constants.DataModelName.DataTableSupport;
    api.documentation.requestAndResponseDataModelObject = null; //new m5.DataTableSupportViewModel();
    api.documentation.documentationUrlBase = "/data-table-support-test/";
    api.documentation.securityAccessArea = Constants.AccessArea.DataTableSupport;
    api.documentation.readOnly = true;
    api.pathVariables = ["{dataTableSupportId}", "{itemToTest}"];
    api.pathModelProperties = ["DataTableSupportId", "ItemToTest"];
    api.cacheName = null; //"staticObjectCache";
    api.parentApi = "DataTableSupport";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.DataTableSupport}/{dataTableSupportId}/${m.RouteSegment.Test}/{itemToTest}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.menuText = "Test Data Table Support Source Code";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This api tests source code to verify that it is valid.";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static DataTableSupportSensitiveInformationHandler(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("DataTableSupportSensitiveInformationHandler");
    api.version = version;
    api.documentation.objectDescription = "Data Table Support Sensitive Information Handler";
    api.documentation.objectPrimaryKey = "";
    api.documentation.objectDescriptionPropertyNames = ["PropertyName"];
    api.documentation.requestAndResponseDataModelDocumentationName = "";
    api.documentation.requestAndResponseDataModelObject = new m5.DataTableSupportSensitiveInformationHandlerViewModel();
    api.documentation.securityAccessArea = Constants.AccessArea.DataTableSupport;
    api.documentation.readOnly = true;
    // Documentation only object with no api endpoints since it is lives inside another object
    return api;
  };


  public static FileServer(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("FileServer");
    api.version = version;
    api.documentation.objectDescription = "File Server";
    api.documentation.objectPrimaryKey = "FileServerId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.FileServer;
    api.documentation.requestAndResponseDataModelObject = new m5.FileServerEditViewModel();
    api.documentation.documentationUrlBase = "/file-server/";
    api.documentation.securityAccessArea = Constants.AccessArea.FileServer;
    api.pathVariables = "{FileServerId}";
    api.pathModelProperties = "FileServerId";
    api.cacheName = "systemCache";
    api.useStandardEndpoints(`/${m.RouteSegment.FileServers}`, null, null);
    return api;
  };


  public static DataImportDefinition(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("DataImportDefinition");
    api.version = version;
    api.documentation.objectDescription = "Data Import Definition";
    api.documentation.objectPrimaryKey = "DataImportDefinitionId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.DataImportDefinition;
    api.documentation.requestAndResponseDataModelObject = new m5.DataImportDefinitionViewModel();
    api.documentation.documentationUrlBase = "/data-import-definition/";
    api.documentation.securityAccessArea = Constants.AccessArea.DataImportDefinition;
    api.pathVariables = "{dataImportDefinitionId}";
    api.pathModelProperties = "DataImportDefinitionId";
    api.cacheName = "systemCache";
    api.useStandardEndpoints(`/${m.RouteSegment.DataImportDefinitions}`, null, "", true);
    return api;
  };


  public static MailServer(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("MailServer");
    api.version = version;
    api.documentation.objectDescription = "Mail Server";
    api.documentation.objectDescriptionPlural = "Mail Servers";
    api.documentation.objectPrimaryKey = "MailServerId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.MailServer;
    api.documentation.requestAndResponseDataModelObject = new m5.MailServerViewModel();
    api.documentation.documentationUrlBase = "/mail-server/";
    api.documentation.securityAccessArea = Constants.AccessArea.MailServer;
    api.pathVariables = "{mailServerId}";
    api.pathModelProperties = "MailServerId";
    api.cacheName = "systemCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Mail}/${m.RouteSegment.Servers}`, null, "", true);
    return api;
  };

  public static MailAddressAction(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("MailAddressAction");
    api.version = version;
    api.documentation.objectDescription = "Mail Address Action";
    api.documentation.objectDescriptionPlural = "Mail Address Actions";
    api.documentation.objectPrimaryKey = "MailAddressActionId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.MailAddressAction;
    api.documentation.requestAndResponseDataModelObject = new m5.MailAddressActionViewModel();
    api.documentation.documentationUrlBase = "/mail-address-action/";
    api.documentation.securityAccessArea = Constants.AccessArea.MailAddressAction;
    api.pathVariables = "{mailAddressActionId}";
    api.pathModelProperties = "MailAddressActionId";
    api.cacheName = "systemCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Mail}/${m.RouteSegment.Addresses}/${m.RouteSegment.Actions}`, null, "", true);
    return api;
  };

  public static Log(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Log");
    api.version = version;
    api.documentation.objectDescription = "Log";
    api.documentation.objectPrimaryKey = "LogId";
    api.documentation.objectDescriptionPropertyNames = ["LogDateTime"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Log;
    api.documentation.requestAndResponseDataModelObject = new m5.LogEditViewModel();
    api.documentation.documentationUrlBase = "/log/";
    api.documentation.securityAccessArea = Constants.AccessArea.Log;
    api.pathVariables = "{logId}";
    api.pathModelProperties = "LogId";
    api.cacheName = "logCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Logs}`, new m5.LogListViewModel(), Constants.DataModelName.LogList);
    return api;
  };

  public static Filter(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Filter");
    api.version = version;
    api.documentation.objectDescription = "Filter";
    api.documentation.objectPrimaryKey = "FilterId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ContactName"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Filter;
    api.documentation.requestAndResponseDataModelObject = new m5.FilterEditViewModel();
    api.documentation.documentationUrlBase = "/filter/";
    api.documentation.securityAccessArea = Constants.AccessArea.Filter;
    api.pathVariables = "{filterId}";
    api.pathModelProperties = "FilterId";
    api.cacheName = "staticObjectCache";
    api.impactedPickListIds = [Constants.PickList._Filter];
    api.useStandardEndpoints(`/${m.RouteSegment.Filters}`, null, null, true);
    return api;
  };

  public static FilterTest(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("FilterTest");
    api.version = version;
    api.documentation.objectDescription = "Filter";
    api.documentation.objectPrimaryKey = "FilterId";
    api.documentation.requestAndResponseDataModelDocumentationName = ""; //Constants.DataModelName.Filter;
    api.documentation.requestAndResponseDataModelObject = null; //new m5.FilterEditViewModel();
    api.documentation.documentationUrlBase = "/filter-test/";
    api.documentation.securityAccessArea = Constants.AccessArea.Filter;
    api.pathVariables = "{filterId}";
    api.pathModelProperties = "FilterId";
    api.cacheName = null; //"staticObjectCache";
    api.parentApi = "Filter";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Filters}/{filterId}/${m.RouteSegment.Test}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.menuText = "Test Filter";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This api tests a filter to verify that it is valid.";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static Note(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Note");
    api.version = version;
    api.documentation.objectDescription = "Note";
    api.documentation.objectPrimaryKey = "NoteId";
    api.documentation.objectDescriptionPropertyNames = ["NoteDateTime"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Note;
    api.documentation.requestAndResponseDataModelObject = new m5.NoteEditViewModel();
    api.documentation.documentationUrlBase = "/note/";
    api.documentation.securityAccessArea = Constants.AccessArea.Note;
    api.pathVariables = "{noteId}";
    api.pathModelProperties = "NoteId";
    api.cacheName = "systemCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Notes}`, null, null, false);
    return api;
  };

  public static TaskListTemplate(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("TaskListTemplate");
    api.version = version;
    api.documentation.objectDescription = "Task List Template";
    api.documentation.objectPrimaryKey = "TaskListTemplateId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.TaskListTemplate;
    api.documentation.requestAndResponseDataModelObject = new m5.TaskListTemplateEditViewModel();
    api.documentation.documentationUrlBase = "/task-list-template/";
    api.documentation.securityAccessArea = Constants.AccessArea.TaskListTemplate;
    api.pathVariables = "{taskListTemplateId}";
    api.pathModelProperties = "TaskListTemplateId";
    api.cacheName = "taskListTemplateObjectCache";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.useStandardEndpoints(`/${m.RouteSegment.TaskListTemplates}`, null, null, true);
    return api;
  };

  public static TaskTemplate(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("TaskTemplate");
    api.version = version;
    api.documentation.objectDescription = "Task Template";
    api.documentation.objectPrimaryKey = "TaskTemplateId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.TaskTemplate;
    api.documentation.requestAndResponseDataModelObject = new m5.TaskTemplateEditViewModel();
    api.documentation.documentationUrlBase = "/task-list-template/task/";
    api.documentation.securityAccessArea = Constants.AccessArea.TaskTemplate;
    api.pathVariables = ["{taskListTemplateId}", "{taskTemplateId}"];
    api.pathModelProperties = ["TaskListTemplateId", "TaskTemplateId"];
    api.cacheName = "taskObjectCache";
    api.parentApi = "TaskListTemplate";
    api.useStandardEndpoints(`/${m.RouteSegment.TaskListTemplates}/{taskListTemplateId}/${m.RouteSegment.Tasks}`, null, null, true);
    return api;
  };

  public static TaskSubtaskTemplate(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("TaskSubtaskTemplate");
    api.version = version;
    api.documentation.objectDescription = "Subtask Template";
    api.documentation.objectPrimaryKey = "TaskSubtaskTemplateId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.TaskSubtaskTemplate;
    api.documentation.requestAndResponseDataModelObject = new m5.TaskSubtaskTemplateEditViewModel();
    api.documentation.documentationUrlBase = "/task-list-template/task/subtask/";
    api.documentation.securityAccessArea = Constants.AccessArea.TaskSubtaskTemplate;
    api.pathVariables = ["{taskListTemplateId}", "{taskTemplateId}", "{taskSubtaskTemplateId}"];
    api.pathModelProperties = ["TaskListTemplateId", "TaskTemplateId", "TaskSubtaskTemplateId"];
    api.cacheName = "taskObjectCache";
    api.parentApi = "TaskTemplate";
    api.useStandardEndpoints(`/${m.RouteSegment.TaskListTemplates}/{taskListTemplateId}/${m.RouteSegment.Tasks}/{taskTemplateId}/${m.RouteSegment.Subtasks}`, null, null, true);
    return api;
  };

  public static TaskList(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("TaskList");
    api.version = version;
    api.documentation.objectDescription = "Task List";
    api.documentation.objectPrimaryKey = "TaskListId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.TaskList;
    api.documentation.requestAndResponseDataModelObject = new m5.TaskListEditViewModel();
    api.documentation.documentationUrlBase = "/task-list/";
    api.documentation.securityAccessArea = Constants.AccessArea.TaskList;
    api.pathVariables = "{taskListId}";
    api.pathModelProperties = "TaskListId";
    api.cacheName = "taskObjectCache";
    api.useStandardEndpoints(`/${m.RouteSegment.TaskLists}`, null, null, true);
    return api;
  };

  public static Task(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Task");
    api.version = version;
    api.documentation.objectDescription = "Task";
    api.documentation.objectPrimaryKey = "TaskId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Task;
    api.documentation.requestAndResponseDataModelObject = new m5.TaskEditViewModel();
    api.documentation.documentationUrlBase = "/task-list/task/";
    api.documentation.securityAccessArea = Constants.AccessArea.Task;
    api.pathVariables = ["{taskListId}", "{taskId}"];
    api.pathModelProperties = ["TaskListId", "TaskId"];
    api.cacheName = "taskObjectCache";
    api.parentApi = "TaskList";
    api.useStandardEndpoints(`/${m.RouteSegment.TaskLists}/{taskListId}/${m.RouteSegment.Tasks}`, null, null, true);
    return api;
  };

  public static TaskSubtask(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("TaskSubtask");
    api.version = version;
    api.documentation.objectDescription = "Subtask";
    api.documentation.objectPrimaryKey = "TaskSubtaskId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.TaskSubtask;
    api.documentation.requestAndResponseDataModelObject = new m5.TaskSubtaskEditViewModel();
    api.documentation.documentationUrlBase = "/task-list/task/subtask/";
    api.documentation.securityAccessArea = Constants.AccessArea.TaskSubtask;
    api.pathVariables = ["{taskListId}", "{taskId}", "{taskSubtaskId}"];
    api.pathModelProperties = ["TaskListId", "TaskId", "TaskSubtaskId"];
    api.cacheName = "taskObjectCache";
    api.parentApi = "Task";
    api.useStandardEndpoints(`/${m.RouteSegment.TaskLists}/{taskListId}/${m.RouteSegment.Tasks}/{taskId}/${m.RouteSegment.Subtasks}`, null, null, true);
    return api;
  };

  public static UserTask(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("UserTask");
    api.version = version;
    api.documentation.objectDescription = "Task";
    api.documentation.objectPrimaryKey = "TaskId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Task;
    api.documentation.requestAndResponseDataModelObject = new m5.TaskEditViewModel();
    api.documentation.documentationUrlBase = "/task/";
    api.documentation.securityAccessArea = Constants.AccessArea.Task;
    api.pathVariables = ["{taskId}"];
    api.pathModelProperties = ["TaskId"];
    api.cacheName = "taskObjectCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Tasks}`, null, null, false);
    return api;
  };

  public static UserTaskList(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("UserTaskList");
    api.version = version;
    api.documentation.objectDescription = "Task Listing";
    api.documentation.objectPrimaryKey = null;
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Task;
    api.documentation.requestAndResponseDataModelObject = new m5.TaskEditViewModel();
    api.documentation.documentationUrlBase = "/task/listing/";
    api.documentation.securityAccessArea = Constants.AccessArea.TaskList;
    api.cacheName = null;
    api.pathVariables = ["{assignmentType}", "{includeGroups}", "{includeCompleted}", "{includePending}"];
    api.pathModelProperties = ["assignmentType", "includeGroups", "includeCompleted", "includePending"];
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Tasks}/${m.RouteSegment.List}?assignmentType={assignmentType}&includeGroups={includeGroups}&includeCompleted={includeCompleted}&includePending={includePending}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Task Listing";
    api.endpoints.slice(-1)[0].documentation.menuText = "Task Listing";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API endpoint is used to get a list of tasks assigned to, assigned by, or supervised by the current user.  " +
      "The assignmentType parameters should be 'AssignedTo', 'AssignedBy', or 'Supervisor'.";
    return api;
  };

  public static UserTaskStatus(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("UserTaskStatus");
    api.version = version;
    api.documentation.objectDescription = "Task Status";
    api.documentation.objectPrimaryKey = null;
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.TaskListStatus;
    api.documentation.requestAndResponseDataModelObject = new m5.TaskListStatusViewModel();
    api.documentation.documentationUrlBase = "/task/status/";
    api.documentation.securityAccessArea = Constants.AccessArea.Task;
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Tasks}/${m.RouteSegment.Status}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Task Status";
    api.endpoints.slice(-1)[0].documentation.menuText = "Task Status";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API endpoint is used to get a status for tasks assigned to the current user.";
    return api;
  };

  public static UserTaskAccept(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("UserTaskAccept");
    api.version = version;
    api.documentation.objectDescription = "Task Accepted";
    api.documentation.objectPrimaryKey = null;
    api.documentation.requestDataModelDocumentationName = Constants.DataModelName.Review;
    api.documentation.requestDataModelObject = new m5.ReviewViewModel();
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.Task;
    api.documentation.responseDataModelObject = new m5.TaskEditViewModel();
    api.documentation.documentationUrlBase = "/task/accepted/";
    api.documentation.securityAccessArea = Constants.AccessArea.Task;
    api.pathVariables = ["{taskId}"];
    api.pathModelProperties = ["TaskId"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Tasks}/{taskId}/${m.RouteSegment.Accept}`, ApiOperationType.Edit));
    return api;
  };

  public static UserTaskReject(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("UserTaskReject");
    api.version = version;
    api.documentation.objectDescription = "Task Rejected";
    api.documentation.objectPrimaryKey = null;
    api.documentation.requestDataModelDocumentationName = Constants.DataModelName.Review;
    api.documentation.requestDataModelObject = new m5.ReviewViewModel();
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.Task;
    api.documentation.responseDataModelObject = new m5.TaskEditViewModel();
    api.documentation.documentationUrlBase = "/task/rejected/";
    api.documentation.securityAccessArea = Constants.AccessArea.Task;
    api.pathVariables = ["{taskId}"];
    api.pathModelProperties = ["TaskId"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Tasks}/{taskId}/${m.RouteSegment.Reject}`, ApiOperationType.Edit));
    return api;
  };

  public static UserTaskSign(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("UserTaskSign");
    api.version = version;
    api.documentation.objectDescription = "Task Sign";
    api.documentation.objectPrimaryKey = null;
    api.documentation.requestDataModelDocumentationName = Constants.DataModelName.Review;
    api.documentation.requestDataModelObject = new m5.ReviewViewModel();
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.Task;
    api.documentation.responseDataModelObject = new m5.TaskEditViewModel();
    api.documentation.documentationUrlBase = "/task/sign/";
    api.documentation.securityAccessArea = Constants.AccessArea.Task;
    api.pathVariables = ["{taskId}"];
    api.pathModelProperties = ["TaskId"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Tasks}/{taskId}/${m.RouteSegment.Sign}`, ApiOperationType.Edit));
    return api;
  };

  public static Visibility(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Visibility");
    api.version = version;
    api.documentation.objectDescription = "Visibility";
    api.documentation.objectPrimaryKey = "VisibilityId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Visibility;
    api.documentation.requestAndResponseDataModelObject = new m5.VisibilityEditViewModel();
    api.documentation.documentationUrlBase = "/visibility/";
    api.documentation.securityAccessArea = Constants.AccessArea.Visibility;
    api.pathVariables = "{visibilityId}";
    api.pathModelProperties = "VisibilityId";
    api.cacheName = "staticObjectCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Visibility}`, null, null, false);
    return api;
  };

  public static Alias(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Alias");
    api.version = version;
    api.documentation.objectDescription = "Alias";
    api.documentation.objectPrimaryKey = "AliasId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Alias;
    api.documentation.requestAndResponseDataModelObject = new m5.AliasEditViewModel();
    api.documentation.documentationUrlBase = "/alias/";
    api.documentation.securityAccessArea = Constants.AccessArea.Alias;
    api.pathVariables = "{aliasId}";
    api.pathModelProperties = "AliasId";
    api.cacheName = "staticObjectCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Aliases}`, null, null, false);
    return api;
  };

  public static Reference(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Reference");
    api.version = version;
    api.documentation.objectDescription = "Reference";
    api.documentation.objectPrimaryKey = "ReferenceId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Reference;
    api.documentation.requestAndResponseDataModelObject = new m5.ReferenceEditViewModel();
    api.documentation.documentationUrlBase = "/reference/";
    api.documentation.securityAccessArea = Constants.AccessArea.Reference;
    api.pathVariables = "{referenceId}";
    api.pathModelProperties = "ReferenceId";
    api.cacheName = "staticObjectCache";
    api.useStandardEndpoints(`/${m.RouteSegment.References}`, null, null, false);
    return api;
  };

  public static ObjectStatus(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ObjectStatus");
    api.version = version;
    api.documentation.objectDescription = "Object Status";
    api.documentation.objectPrimaryKey = "ObjectStatusId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "StatusCode", "Information"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ObjectStatus;
    api.documentation.requestAndResponseDataModelObject = new m5.ObjectStatusEditViewModel();
    api.documentation.documentationUrlBase = "/object-status/";
    api.documentation.securityAccessArea = Constants.AccessArea.ObjectStatus;
    api.pathVariables = "{objectStatusId}";
    api.pathModelProperties = "ObjectStatusId";
    api.cacheName = "objectStatusCache";
    api.useStandardEndpoints(`/${m.RouteSegment.ObjectStatus}`, null, null, false);
    return api;
  };

  public static WatchEvent(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("WatchEvent");
    api.version = version;
    api.documentation.objectDescription = "Watch Event";
    api.documentation.objectPrimaryKey = "WatchEventId";
    api.documentation.objectDescriptionPropertyNames = ["WatchedResourceType", "Message", "PayloadId", "EventDateTime"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.WatchEvent;
    api.documentation.requestAndResponseDataModelObject = new m5.WatchEventEditViewModel();
    api.documentation.documentationUrlBase = "/watch-events/";
    api.documentation.securityAccessArea = Constants.AccessArea.WatchEvent;
    api.pathVariables = "{watchEventId}";
    api.pathModelProperties = "WatchEventId";
    api.cacheName = "logCache";
    api.useStandardEndpoints(`/${m.RouteSegment.WatchEvents}`, new m5.WatchEventListViewModel(), Constants.DataModelName.WatchEventList);
    return api;
  };

  public static WatchEventSystemAdd(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("WatchEventSystemAdd");
    api.version = version;
    api.documentation.objectDescription = "Watch Event";
    api.documentation.objectPrimaryKey = "WatchEventId";
    api.documentation.objectDescriptionPropertyNames = ["WatchedResourceType", "Message", "PayloadId", "EventDateTime"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.WatchEventSystemAdd;
    api.documentation.requestAndResponseDataModelObject = new m5.WatchEventSystemAddViewModel();
    api.documentation.documentationUrlBase = "/watch-events-system-add/";
    api.documentation.securityAccessArea = Constants.AccessArea.WatchEvent;
    api.pathVariables = "{watchEventId}";
    api.pathModelProperties = "WatchEventId";
    api.cacheName = "logCache";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.WatchEvents}/${m.RouteSegment.System}`, ApiOperationType.Add));
    return api;
  };

  public static WatchEventSystemMostRecent(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("WatchEventSystemMostRecent");
    api.version = version;
    api.documentation.objectDescription = "Watch Event";
    api.documentation.objectPrimaryKey = "WatchEventId";
    api.documentation.objectDescriptionPropertyNames = ["WatchedResourceType", "Message", "PayloadId", "EventDateTime"];
    api.documentation.requestAndResponseDataModelDocumentationName = "";
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/watch-events-system-most-recent/";
    api.documentation.securityAccessArea = Constants.AccessArea.WatchEvent;
    api.pathVariables = ["{watchedResourceType}", "{payloadId}", "{payloadVersion}", "{payloadContains}"];
    api.pathModelProperties = ["WatchedResourceType", "PayloadId", "PayloadVersion", "PayloadContains"];
    api.cacheName = "logCache";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.WatchEvents}/${m.RouteSegment.System}/${m.RouteSegment.MostRecent}?watchedResourceType={watchedResourceType}&payloadId={payloadId}&payloadVersion={payloadVersion}&payloadContains={payloadContains}`, ApiOperationType.Get));
    return api;
  };

  public static SystemSettingOne(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("SystemSettingOne");
    api.version = version;
    api.documentation.objectDescription = "Setting";
    api.documentation.objectPrimaryKey = ["Category", "Attribute"]; // We need this as PK for proper caching on this api call
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Setting;
    api.documentation.requestAndResponseDataModelObject = new m5.SettingEditViewModel();
    api.documentation.documentationUrlBase = "/system-setting-one/";
    api.documentation.securityAccessArea = Constants.AccessArea.Setting;
    api.pathVariables = ["{category}", "{attribute}"];
    api.pathModelProperties = ["Category", "Attribute"];
    api.cacheName = "staticObjectCache";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.useStandardEndpoints(`/${m.RouteSegment.System}/${m.RouteSegment.Settings}/{category}`);
    // This api add/post method requires the attribute as as part of the route
    const add = api.endpoints.find(x => x.type === ApiOperationType.Add);
    if (add) {
      add.path = `/${m.RouteSegment.System}/${m.RouteSegment.Settings}/{category}/{attribute}`;
    }
    return api;
  };

  public static SystemSettings(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("SystemSettings");
    api.version = version;
    api.documentation.objectDescription = "System Settings";
    api.documentation.objectPrimaryKey = "AsOf";
    api.documentation.requestAndResponseDataModelDocumentationName = ""; // TODO Constants.DataModelName.Visibility;
    api.documentation.requestAndResponseDataModelObject = new m5.SystemSettings();
    api.documentation.documentationUrlBase = "/system-settings/";
    api.documentation.securityAccessArea = Constants.AccessArea.Setting;
    api.pathVariables = null;
    api.pathModelProperties = null;
    api.cacheName = "systemSettingsCache";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.endpoints.push(new ApiEndpoint("/system/settings", ApiOperationType.Get));
    api.endpoints.push(new ApiEndpoint("/system/settings", ApiOperationType.Edit));
    return api;
  };

  //#endregion



  //#region Security APIs

  public static ApiAccess(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ApiAccess");
    api.version = version;
    api.documentation.objectDescription = "Api Access";
    api.documentation.objectPrimaryKey = "ApiAccessId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ApiAccess;
    api.documentation.requestAndResponseDataModelObject = new m5.ApiAccessEditViewModel();
    api.documentation.documentationUrlBase = "/api-access/";
    api.documentation.securityAccessArea = Constants.AccessArea.ApiAccess;
    api.pathVariables = "{apiAccessId}";
    api.pathModelProperties = "ApiAccessId";
    api.cacheName = "staticObjectCache";
    api.useStandardEndpoints(`/${m.RouteSegment.ApiAccess}`);
    return api;
  };

  public static ApiAccessClient(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ApiAccessClient");
    api.version = version;
    api.documentation.objectDescription = "Api Access Client";
    api.documentation.objectPrimaryKey = "ApiAccessClientId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ApiAccessClient;
    api.documentation.requestAndResponseDataModelObject = new m5.ApiAccessClientEditViewModel();
    api.documentation.documentationUrlBase = "/api-access/client/";
    api.documentation.securityAccessArea = Constants.AccessArea.ApiAccessClient;
    api.pathVariables = ["{apiAccessId}", "{apiAccessClientId}"];
    api.pathModelProperties = ["ApiAccessId", "ApiAccessClientId"];
    api.cacheName = "staticObjectCache";
    api.parentApi = "ApiAccess";
    api.useStandardEndpoints(`/${m.RouteSegment.ApiAccess}/{apiAccessId}/${m.RouteSegment.Clients}`);
    return api;
  };


  public static Encryption(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Encryption");
    api.version = version;
    api.documentation.objectDescription = "Encryption";
    api.documentation.objectDescriptionPlural = api.documentation.objectDescription;
    api.documentation.objectPrimaryKey = "Tag";
    api.documentation.requestAndResponseDataModelDocumentationName = ""; // TMI ... Constants.DataModelName.Encryption;
    api.documentation.requestAndResponseDataModelObject = new m5.EncryptionEditViewModel();
    api.documentation.documentationUrlBase = "/encryption/";
    api.documentation.securityAccessArea = Constants.AccessArea.MessageExchange;
    api.pathVariables = ["{tag}", "{keyType}", "{keyLength}", "{asOf}"];
    api.pathModelProperties = ["Tag", "KeyType", "KeyLength", "AsOf"];
    api.cacheName = "staticObjectCache";
    api.endpoints.push(new ApiEndpoint("/encryption/{tag}?keyType={keyType}&keyLength={keyLength}&asOf={asOf}", ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Get Encryption Key";
    api.endpoints.slice(-1)[0].documentation.menuText = "Get Encryption Key";
    api.endpoints.slice(-1)[0].documentation.overviewText = `
This API endpoint retrieves an encryption key that can be used for encryption of data that may need to be encrypted while at rest and/or
submitted as an encrypted value using the X-Encryption-Key-Tag and X-Encrypted-Properties headers.
<br/><br/>
The url for this API endpoint /encryption/{tag}?keyType={keyType}&keyLength={keyLength}&asOf={asOf} has the following parameters:
<br/><br/>
<ul>
<li>Tag - When this value is not 'any' the encryption key for the specified tag is retrieved and all other parameters are ignored.</li>
<li>Key Type - When the specified tag is 'any' this is the desired key type.  Possible values include RSA, AES, and VisaCheckout with a default value of RSA.</li>
<li>
Key Length - When the specified tag is 'any' this is the desired key length.
Possible values for RSA are 1024, 2048, 3072, and 4096 with a default of 2048.
Possible values for AES are 128, 192, and 256 with a default of 256.
Possible values for VisaCheckout are 0.
</li>
<li>
As Of - When the specified tag is 'any' this is the as-of date for the encryption key.
If a key already exists of the desired type and length that was created on or after
the as-of date it will be returned.  Otherwise, a new key of the desired type and length will be created.  The as-of date needs to be
specified in yyyy-mm-dd format.  The default as-of date is 30-days ago.
</li>
</ul>
Notes:
<ol>
<li>When RSA encryption is used it should be used with OAEP padding.</li>
<li>For public key encryption the PrivateKey property will always be null as only the public key is provided.</li>
<li>For Visa Checkout the PrivateKey property will always be null and the PublicKey property will be the Visa Checkout API Key.</li>
<li>New Visa Checkout keys are not created dynamically based on the as-of date.  The most recent Visa Checkout API Key will be provided if one is not available matching the as-of date.</li>
<li>
A practical implementation may include an encryption tag and key in a system configuration for fallback purposes when there is a need to encrypt data and
this API is not returning a tag and key to utilize.
</li>
</ol>
`;
    //api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    //api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = false;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressPromptForOwnerKey = true;
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static EncryptionEcho(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("EncryptionEcho");
    api.version = version;
    api.documentation.objectDescription = "Encryption Echo";
    api.documentation.objectDescriptionPlural = api.documentation.objectDescription;
    api.documentation.objectPrimaryKey = "Tag";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.EncryptionEcho;
    api.documentation.requestAndResponseDataModelObject = new m5.EncryptionEchoEditViewModel();
    api.documentation.documentationUrlBase = "/encryption/echo/";
    api.documentation.securityAccessArea = Constants.AccessArea.MessageExchange;
    api.excludeFromAutomatedTesting = true;
    api.endpoints.push(new ApiEndpoint("/encryption/echo", ApiOperationType.Call));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.overviewText = "The Encryption Echo API is utilized to test encryption by sending a plain text and encrypted text value with an encryption key tag and having those values echoed back in reverse so the reply returns an encrypted version of the plain text and a plain text version of the encrypted text." +
      "<br/><br/>" +
      "If the specified tag is for RSA encryption note that it will use OAEP padding for this echo.";
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "refresh";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Echo";
    return api;
  };

  //#endregion


  //#region Job APIs

  public static JobType(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("JobType");
    api.version = version;
    api.documentation.objectDescription = "Job Type";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.JobType;
    api.documentation.requestAndResponseDataModelObject = new m5.JobTypeViewModel();
    api.documentation.documentationUrlBase = "/job-type/";
    api.cacheName = "jobObjectCache";
    api.endpoints.push(new ApiEndpoint("/job-types?page={page}&size={size}&sort={sort}&filterId={filterId}&filter={filter}&q={q}&expand={expand}", ApiOperationType.List));
    return api;
  };

  public static Job(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Job");
    api.version = version;
    api.documentation.objectDescription = "Job";
    api.documentation.objectPrimaryKey = "JobId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "Type"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Job;
    api.documentation.requestAndResponseDataModelObject = new m5.JobEditViewModel();
    api.documentation.documentationUrlBase = "/job/all/";
    api.documentation.securityAccessArea = Constants.AccessArea.Job;
    api.pathVariables = "{jobId}";
    api.pathModelProperties = "JobId";
    api.cacheName = "jobObjectCache";
    api.impactedPickListIds = [Constants.PickList._Job];
    api.useStandardEndpoints(`/${m.RouteSegment.Jobs}/all`, null, null, true, true, true);
    return api;
  };

  public static JobActionStart(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("JobActionStart");
    api.version = version;
    api.documentation.objectDescription = "Job";
    api.documentation.objectPrimaryKey = "JobId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Job;
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/job/actions/start/";
    api.documentation.securityAccessArea = Constants.AccessArea.Job;
    api.pathVariables = "{jobId}";
    api.pathModelProperties = "JobId";
    api.cacheName = "jobObjectCache";
    api.parentApi = "Job";
    api.endpoints.push(new ApiEndpoint("/jobs/all/{jobId}/actions/start", ApiOperationType.Call));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Start Job";
    api.endpoints.slice(-1)[0].documentation.menuText = "Start Job";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API updates the specified job to indicate it should start now.  The actual starting of the job will depend on available resources in the grid of application servers responsible for running jobs.";
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "play";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Start Job";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static JobActionStop(version: number = AppConfig.apiVersion): ApiProperties {
    var api = this.JobActionStart(version);
    api.id = "JobActionStop";
    api.documentation.documentationUrlBase = "/job/actions/stop/";
    // clear endpoints
    api.endpoints = [];
    api.endpoints.push(new ApiEndpoint("/jobs/all/{jobId}/actions/stop", ApiOperationType.Call));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Stop Job";
    api.endpoints.slice(-1)[0].documentation.menuText = "Stop Job";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API updates the specified job to indicate it should stop.  The actual stopping of the job will happen the next time the job checks to see if it was cancelled.  " +
      "Note that jobs that are configured to run real-time will be restarted by an application server unless the job has first been updated to be disabled or to change the job type so it is no longer a real-time job.";
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "ban";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Stop Job";
    return api;
  };


  //#endregion



  //#region Data APIs


  public static FreeFormTextParser(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("FreeFormTextParser");
    api.version = version;
    api.documentation.objectDescription = "Free-Form Text Parser";
    api.documentation.objectPrimaryKey = "FreeFormTextParserId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.FreeFormTextParser;
    api.documentation.requestAndResponseDataModelObject = new m5.FreeFormTextParserViewModel();
    api.documentation.documentationUrlBase = "/free-form-text-parser/";
    api.documentation.securityAccessArea = Constants.AccessArea.FreeFormTextParser;
    api.pathVariables = "{freeFormTextParserId}";
    api.pathModelProperties = "FreeFormTextParserId";
    api.cacheName = "systemCache";
    api.useStandardEndpoints(`/${m.RouteSegment.FreeFormTextParsers}`, null, "", true);
    return api;
  };


  public static DataSource(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("DataSource");
    api.version = version;
    api.documentation.objectDescription = "Data Source";
    api.documentation.objectPrimaryKey = "DataSourceId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.DataSource;
    api.documentation.requestAndResponseDataModelObject = new m5.DataSourceEditViewModel();
    api.documentation.documentationUrlBase = "/data-source/";
    api.documentation.securityAccessArea = Constants.AccessArea.DataSource;
    api.pathVariables = "{dataSourceId}";
    api.pathModelProperties = "DataSourceId";
    api.cacheName = "systemCache";
    api.useStandardEndpoints(`/${m.RouteSegment.DataSources}`, new m5.DataSourceListViewModel(), Constants.DataModelName.DataSourceList, false);
    return api;
  };


  public static Query(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Query");
    api.version = version;
    api.documentation.objectDescription = "Query";
    api.documentation.objectPrimaryKey = "QueryId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalQueryId", "ContactName"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Query;
    api.documentation.requestAndResponseDataModelObject = new m5.QueryEditViewModel();
    api.documentation.documentationUrlBase = "/query/";
    api.documentation.securityAccessArea = Constants.AccessArea.Query;
    api.pathVariables = "{queryId}";
    api.pathModelProperties = "QueryId";
    api.cacheName = "systemCache";
    api.impactedPickListIds = [Constants.PickList._Query];
    api.useStandardEndpoints(`/${m.RouteSegment.Queries}`, new m5.QueryListViewModel(), Constants.DataModelName.QueryList, true);
    // Custom endpoint for executing query
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Queries}/{queryId}/${m.RouteSegment.Execute}`, ApiOperationType.Call));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.requestDataModelObject = new m5.QueryExecutionRequestOptionsViewModel();
    api.endpoints.slice(-1)[0].documentation.requestDataModelDocumentationName = Constants.DataModelName.QueryExecutionRequestOptions;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = true;
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = [];
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = "";
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = false;
    api.endpoints.slice(-1)[0].documentation.title = "Execute Query";
    api.endpoints.slice(-1)[0].documentation.menuText = "Execute Query";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This api endpoint executes a query and optionally returns query results.";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static QueryRun(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("QueryRun");
    api.version = version;
    api.documentation.objectDescription = "Query";
    api.documentation.objectPrimaryKey = "QueryId";
    api.documentation.requestAndResponseDataModelDocumentationName = ""; //Constants.DataModelName.Filter;
    api.documentation.requestAndResponseDataModelObject = null; //new m5.FilterEditViewModel();
    api.documentation.documentationUrlBase = "/query-run/";
    api.documentation.securityAccessArea = Constants.AccessArea.Query;
    api.pathVariables = "{queryId}";
    api.pathModelProperties = "QueryId";
    api.cacheName = null; //"staticObjectCache";
    api.parentApi = "Query";
    api.endpoints.push(new ApiEndpoint("/queries/{queryId}/run", ApiOperationType.Call));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.requestDataModelObject = new m5.QueryExecutionRequestEditViewModel();
    api.endpoints.slice(-1)[0].documentation.requestDataModelDocumentationName = Constants.DataModelName.QueryExecutionRequest;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = true;
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = new m5.QueryExecutionStatusViewModel();
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = Constants.DataModelName.QueryExecutionStatus;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = true;
    api.endpoints.slice(-1)[0].documentation.title = "Run Query";
    api.endpoints.slice(-1)[0].documentation.menuText = "Run Query";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This api runs a query and optionally returns query results.";
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    api.endpoints.push(new ApiEndpoint("/queries/{queryId}/download?dataSourceGroup={dataSourceGroup}&dataSourceDescription={dataSourceDescription}&format={format}&fileName={fileName}&title={title}&includeAsOfTime={includeAsOfTime}", ApiOperationType.Get));
    api.endpoints.slice(-1)[0].pathVariables = ["{queryId}", "{dataSourceGroup}", "{dataSourceDescription}", "{format}", "{fileName}", "{title}", "{includeAsOfTime}"];
    api.endpoints.slice(-1)[0].pathModelProperties = ["QueryId", "DataSourceGroup", "DataSourceDescription", "Format", "FileName", "Title", "IncludeAsOfTime"];
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Download Query";
    api.endpoints.slice(-1)[0].documentation.menuText = "Download Query";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This api downloads query results in the requested format.  The only file formats currently supported are csv, tsv, xml, json, and xlsx.";
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "download";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Download";
    //api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    api.endpoints.slice(-1)[0].documentation.pathModelPropertyTriggeringDownload = "Format";
    let downloadTestForm: ApiDocTestFormProperty[] = [];
    downloadTestForm.push(new ApiDocTestFormProperty("QueryId", ApiDocTestFormPropertyType.Number));
    downloadTestForm.push(new ApiDocTestFormProperty("DataSourceGroup"));
    downloadTestForm.push(new ApiDocTestFormProperty("DataSourceDescription"));
    downloadTestForm.push(new ApiDocTestFormProperty("Format", ApiDocTestFormPropertyType.Select, "", "", null, ["", "csv", "tsv", "psv", "xml", "json", "xlsx"]));
    downloadTestForm.push(new ApiDocTestFormProperty("FileName"));
    downloadTestForm.push(new ApiDocTestFormProperty("Title"));
    downloadTestForm.push(new ApiDocTestFormProperty("IncludeAsOfTime", ApiDocTestFormPropertyType.Bool, false));
    api.endpoints.slice(-1)[0].documentation.testFormProperties = downloadTestForm;
    return api;
  };

  public static QueryOutputConfiguration(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("QueryOutputConfiguration");
    api.version = version;
    api.documentation.objectDescription = "Query Output Configuration";
    api.documentation.objectPrimaryKey = "QueryOutputConfigurationId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "Title"];
    api.documentation.requestAndResponseDataModelDocumentationName = "";
    api.documentation.requestAndResponseDataModelObject = new m5.QueryOutputConfigurationEditViewModel();
    api.documentation.securityAccessArea = Constants.AccessArea.Query;
    // Documentation only object with no api endpoints since it is lives inside another object
    return api;
  };

  public static QueryOutputPropertyFormat(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("QueryOutputPropertyFormat");
    api.version = version;
    api.documentation.objectDescription = "Query Property Format";
    api.documentation.objectPrimaryKey = "QueryOutputPropertyFormatId";
    api.documentation.objectDescriptionPropertyNames = ["PropertyName", "Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = "";
    api.documentation.requestAndResponseDataModelObject = new m5.QueryOutputPropertyFormatEditViewModel();
    api.documentation.securityAccessArea = Constants.AccessArea.Query;
    // Documentation only object with no api endpoints since it is lives inside another object
    return api;
  };

  public static QueryVariable(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("QueryVariable");
    api.version = version;
    api.documentation.objectDescription = "Query Variable";
    api.documentation.objectPrimaryKey = "PropertyConfigurationId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "PropertyName"];
    api.documentation.requestAndResponseDataModelDocumentationName = "";
    api.documentation.requestAndResponseDataModelObject = new m.PropertyMetaDataViewModel();
    api.documentation.securityAccessArea = Constants.AccessArea.Query;
    // Documentation only object with no api endpoints since it is lives inside another object
    return api;
  };

  public static QueryEventListByQuery(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("QueryEventListByQuery");
    api.version = version;
    api.documentation.objectDescription = "Query Event";
    api.documentation.objectPrimaryKey = "QueryEventId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "Tag"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.QueryEvent;
    api.documentation.requestAndResponseDataModelObject = new m5.QueryEventViewModel();
    api.documentation.documentationUrlBase = "/query-event-list/";
    api.documentation.securityAccessArea = Constants.AccessArea.QueryEvent;
    api.pathVariables = "{queryId}";
    api.pathModelProperties = "QueryId";
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Queries}/{queryId}/${m.RouteSegment.Events}?days={days}&scope={scope}&page={page}&size={size}&sort={sort}&filterId={filterId}&filter={filter}&q={q}&expand={expand}`, ApiOperationType.List));
    return api;
  };

  public static QueryEvent(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("QueryEvent");
    api.version = version;
    api.documentation.objectDescription = "Query Event";
    api.documentation.objectPrimaryKey = "QueryEventId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "Tag"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.QueryEvent;
    api.documentation.requestAndResponseDataModelObject = new m5.QueryEventViewModel();
    api.documentation.documentationUrlBase = "/query-event/";
    api.documentation.securityAccessArea = Constants.AccessArea.QueryEvent;
    api.pathVariables = "{queryEventId}";
    api.pathModelProperties = "QueryEventId";
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Queries}/${m.RouteSegment.Events}?page={page}&size={size}&sort={sort}&filterId={filterId}&filter={filter}&q={q}&expand={expand}`, ApiOperationType.List));
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Queries}/${m.RouteSegment.Events}/{queryEventId}`, ApiOperationType.Get));
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Queries}/${m.RouteSegment.Events}/{queryEventId}`, ApiOperationType.Delete));
    return api;
  };

  public static QueryEventRawData(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("QueryEventRawData");
    api.version = version;
    api.documentation.objectDescription = "Query Event Raw Data";
    api.documentation.objectPrimaryKey = "QueryEventId";
    api.documentation.objectDescriptionPropertyNames = []; //["Description", "Tag"];
    api.documentation.requestAndResponseDataModelDocumentationName = null; //Constants.DataModelName.QueryEvent;
    api.documentation.requestAndResponseDataModelObject = {}; //new m5.QueryEventViewModel();
    api.documentation.documentationUrlBase = "/query-event-raw-data/";
    api.documentation.securityAccessArea = Constants.AccessArea.QueryEventFile;
    api.pathVariables = "{queryEventId}";
    api.pathModelProperties = "QueryEventId";
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Queries}/${m.RouteSegment.Events}/{queryEventId}/${m.RouteSegment.Data}?page={page}&size={size}&sort={sort}&filterId={filterId}&filter={filter}&q={q}&expand={expand}`, ApiOperationType.List));
    return api;
  };

  public static QueryEventFileDownload(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("QueryEventFileDownload");
    api.version = version;
    api.documentation.objectDescription = "Query Event";
    api.documentation.objectPrimaryKey = "QueryEventId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "Tag"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.QueryEvent;
    api.documentation.requestAndResponseDataModelObject = new m5.QueryEventViewModel();
    api.documentation.documentationUrlBase = "/query-event-file-download/";
    api.documentation.securityAccessArea = Constants.AccessArea.QueryEventFile;
    api.pathVariables = ["{queryEventId}", "{queryEventFileId}"];
    api.pathModelProperties = ["QueryEventId", "QueryEventFileId"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Queries}/${m.RouteSegment.Events}/{queryEventId}/${m.RouteSegment.Files}/{queryEventFileId}/${m.RouteSegment.Download}`, ApiOperationType.Get));
    return api;
  };

  //#endregion



  //#region Attribute APIs

  public static AttributeRawAttribute(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AttributeRawAttribute");
    api.version = version;
    api.documentation.objectDescription = "Attribute";
    api.documentation.objectPrimaryKey = "AttributeId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Attribute;
    api.documentation.requestAndResponseDataModelObject = new m5.AttributeEditViewModel();
    api.documentation.documentationUrlBase = "/attribute/raw-attribute/";
    api.documentation.securityAccessArea = Constants.AccessArea.Attribute;
    api.pathVariables = "{attributeId}";
    api.pathModelProperties = "AttributeId";
    api.cacheName = "staticObjectCache";
    api.useStandardEndpoints(`/${m.RouteSegment.RawAttributes}`, null, null, true);
    return api;
  };

  public static AttributeSet(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AttributeSet");
    api.version = version;
    api.documentation.objectDescription = "Attribute Set";
    api.documentation.objectPrimaryKey = "AttributeSetId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "Name", "AttributeSetTarget"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.AttributeSet;
    api.documentation.requestAndResponseDataModelObject = new m5.AttributeSetEditViewModel();
    api.documentation.documentationUrlBase = "/attribute-set/";
    api.documentation.securityAccessArea = Constants.AccessArea.AttributeSet;
    api.pathVariables = "{attributeSetId}";
    api.pathModelProperties = "AttributeSetId";
    api.cacheName = "staticObjectCache";
    api.useStandardEndpoints(`/${m.RouteSegment.AttributeSets}`, null, null, true);
    return api;
  };

  public static AttributeSetConfiguration(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AttributeSetConfiguration");
    api.version = version;
    api.documentation.objectDescription = "Attribute Configuration";
    api.documentation.objectPrimaryKey = "AttributeConfigurationId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.AttributeConfiguration;
    api.documentation.requestAndResponseDataModelObject = new m5.AttributeConfigurationEditViewModel();
    api.documentation.documentationUrlBase = "/attribute-set/configuration/";
    api.documentation.securityAccessArea = Constants.AccessArea.AttributeConfiguration;
    api.pathVariables = ["{attributeSetId}", "{attributeConfigurationId}"];
    api.pathModelProperties = ["AttributeSetId", "AttributeConfigurationId"];
    api.cacheName = "staticObjectCache";
    api.parentApi = "AttributeSet";
    api.useStandardEndpoints(`/${m.RouteSegment.AttributeSets}/{attributeSetId}/${m.RouteSegment.Details}`, null, null, true);
    return api;
  };

  public static AttributeSetAttribute(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AttributeSetAttribute");
    api.version = version;
    api.documentation.objectDescription = "Attribute";
    api.documentation.objectPrimaryKey = "AttributeId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Attribute;
    api.documentation.requestAndResponseDataModelObject = new m5.AttributeEditViewModel();
    api.documentation.documentationUrlBase = "/attribute-set/attribute/";
    api.documentation.securityAccessArea = Constants.AccessArea.Attribute;
    api.pathVariables = ["{attributeSetId}", "{attributeId}"];
    api.pathModelProperties = ["AttributeSetId", "AttributeId"];
    api.cacheName = "staticObjectCache";
    api.parentApi = "AttributeSet";
    api.useStandardEndpoints(`/${m.RouteSegment.AttributeSets}/{attributeSetId}/${m.RouteSegment.Attributes}`, null, null, true);
    return api;
  };

  public static AttributeSetRawAttribute(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AttributeSetRawAttribute");
    api.version = version;
    api.documentation.objectDescription = "Attribute";
    api.documentation.objectPrimaryKey = "AttributeId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Attribute;
    api.documentation.requestAndResponseDataModelObject = new m5.AttributeEditViewModel();
    api.documentation.documentationUrlBase = "/attribute-set/raw-attribute/";
    api.documentation.securityAccessArea = Constants.AccessArea.Attribute;
    api.pathVariables = ["{attributeSetId}", "{attributeId}"];
    api.pathModelProperties = ["AttributeSetId", "AttributeId"];
    api.cacheName = "staticObjectCache";
    api.parentApi = "AttributeSet";
    api.useStandardEndpoints(`/${m.RouteSegment.AttributeSets}/{attributeSetId}/${m.RouteSegment.RawAttributes}`, null, null, true);
    return api;
  };

  //#endregion


  //#region Contact APIs

  public static Contact(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Contact");
    api.version = version;
    api.documentation.objectDescription = "Contact";
    api.documentation.objectPrimaryKey = "ContactId";
    api.documentation.objectDescriptionPropertyNames = ["ContactName", "ExternalContactId", "LastName", "FirstName", "Email"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Contact;
    api.documentation.requestAndResponseDataModelObject = new m5.ContactEditViewModel();
    api.documentation.securityAccessArea = Constants.AccessArea.CONTACT;
    api.documentation.documentationUrlBase = "/contact/";
    api.pathVariables = "{contactId}";
    api.pathModelProperties = "ContactId";
    api.cacheName = "contactCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Contacts}`, new m5.ContactListViewModel(), Constants.DataModelName.ContactList, false);
    return api;
  };

  public static ContactPreferences(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ContactPreferences");
    api.version = version;
    api.documentation.objectDescription = "Contact Preferences";
    api.documentation.objectPrimaryKey = ["ContactId", "Preferences"];
    api.documentation.objectDescriptionPropertyNames = [];
    api.documentation.requestAndResponseDataModelDocumentationName = "";
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/contact-preferences/";
    api.documentation.securityAccessArea = Constants.AccessArea.Setting;
    api.pathVariables = ["{contactId}", "{preferences}", "{setting}"];
    api.pathModelProperties = ["ContactId", "Preferences", "Setting"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Contacts}/{contactId}/${m.RouteSegment.Preferences}/{preferences}`, ApiOperationType.Get));
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Contacts}/{contactId}/${m.RouteSegment.Preferences}/{preferences}`, ApiOperationType.Add));
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Contacts}/{contactId}/${m.RouteSegment.Preferences}/{preferences}`, ApiOperationType.Edit));
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Contacts}/{contactId}/${m.RouteSegment.Preferences}/{preferences}`, ApiOperationType.Delete));
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Contacts}/{contactId}/${m.RouteSegment.Preferences}/{preferences}/{setting}`, ApiOperationType.Call));
    return api;
  };

  public static ContactBulkAdd(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ContactBulkAdd");
    api.version = version;
    api.documentation.objectDescription = "Contact";
    api.documentation.objectPrimaryKey = "ContactId";
    api.documentation.objectDescriptionPropertyNames = ["ContactName", "ExternalContactId", "LastName", "FirstName", "Email"];
    api.documentation.requestDataModelDocumentationName = Constants.DataModelName.ContactBulkAddRequest;
    api.documentation.requestDataModelObject = new m5.ContactBulkAddRequestViewModel();
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.ContactBulkAddResult;
    api.documentation.responseDataModelObject = new m5.ContactBulkAddResultViewModel();
    api.documentation.documentationUrlBase = "/contact/bulk-add/";
    api.documentation.securityAccessArea = Constants.AccessArea.CONTACT;
    api.pathVariables = "{contactId}";
    api.pathModelProperties = "ContactId";
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Contacts}/${m.RouteSegment.BulkAdd}`, ApiOperationType.Add));
    return api;
  };

  public static Customer(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Customer");
    api.version = version;
    api.documentation.objectDescription = "Customer";
    api.documentation.objectPrimaryKey = "CustomerId";
    api.documentation.objectDescriptionPropertyNames = ["CustomerName", "ExternalCustomerId", "LastName", "FirstName"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Customer;
    api.documentation.requestAndResponseDataModelObject = new m5.CustomerEditViewModel();
    api.documentation.documentationUrlBase = "/customer/";
    api.documentation.securityAccessArea = Constants.AccessArea.Customer;
    api.pathVariables = "{customerId}";
    api.pathModelProperties = "CustomerId";
    api.cacheName = "contactCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Customers}`, new m5.CustomerListViewModel(), Constants.DataModelName.CustomerList, false, true, true);
    return api;
  };

  public static CustomerBulkAdd(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("CustomerBulkAdd");
    api.version = version;
    api.documentation.objectDescription = "Customer";
    api.documentation.objectPrimaryKey = "CustomerId";
    api.documentation.objectDescriptionPropertyNames = ["CustomerName", "ExternalCustomerId", "LastName", "FirstName", "Email"];
    api.documentation.requestDataModelDocumentationName = Constants.DataModelName.ContactBulkAddRequest;
    api.documentation.requestDataModelObject = new m5.ContactBulkAddRequestViewModel();
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.ContactBulkAddResult;
    api.documentation.responseDataModelObject = new m5.ContactBulkAddResultViewModel();
    api.documentation.documentationUrlBase = "/customer/bulk-add/";
    api.documentation.securityAccessArea = Constants.AccessArea.Customer;
    api.pathVariables = "{customerId}";
    api.pathModelProperties = "CustomerId";
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Customers}/${m.RouteSegment.BulkAdd}`, ApiOperationType.Add));
    return api;
  };

  public static Location(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Location");
    api.version = version;
    api.documentation.objectDescription = "Location";
    api.documentation.objectPrimaryKey = "LocationId";
    api.documentation.objectDescriptionPropertyNames = ["LocationName", "ExternalLocationId", "LastName", "FirstName"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Location;
    api.documentation.requestAndResponseDataModelObject = new m5.LocationEditViewModel();
    api.documentation.documentationUrlBase = "/locations/";
    api.documentation.securityAccessArea = Constants.AccessArea.Location;
    api.pathVariables = "{locationId}";
    api.pathModelProperties = "LocationId";
    api.cacheName = "contactCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Locations}`, new m5.LocationListViewModel(), Constants.DataModelName.LocationList, false);
    return api;
  };

  public static Vendor(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Vendor");
    api.version = version;
    api.documentation.objectDescription = "Vendor";
    api.documentation.objectPrimaryKey = "VendorId";
    api.documentation.objectDescriptionPropertyNames = ["VendorName", "ExternalVendorId", "LastName", "FirstName"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Vendor;
    api.documentation.requestAndResponseDataModelObject = new m5.VendorEditViewModel();
    api.documentation.documentationUrlBase = "/vendor/";
    api.documentation.securityAccessArea = Constants.AccessArea.Vendor;
    api.pathVariables = "{vendorId}";
    api.pathModelProperties = "VendorId";
    api.cacheName = "contactCache";
    api.impactedPickListIds = [Constants.PickList._Vendor];
    api.useStandardEndpoints(`/${m.RouteSegment.Vendors}`, new m5.VendorListViewModel(), Constants.DataModelName.VendorList, false);
    return api;
  };

  public static Warehouse(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Warehouse");
    api.version = version;
    api.documentation.objectDescription = "Warehouse";
    api.documentation.objectPrimaryKey = "WarehouseId";
    api.documentation.objectDescriptionPropertyNames = ["WarehouseName", "ExternalWarehouseId", "LastName", "FirstName"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Warehouse;
    api.documentation.requestAndResponseDataModelObject = new m5.WarehouseEditViewModel();
    api.documentation.documentationUrlBase = "/warehouse/";
    api.documentation.securityAccessArea = Constants.AccessArea.Warehouse;
    api.pathVariables = "{warehouseId}";
    api.pathModelProperties = "WarehouseId";
    api.cacheName = "contactCache";
    api.impactedPickListIds = [Constants.PickList._Warehouse];
    api.useStandardEndpoints(`/${m.RouteSegment.Warehouses}`, new m5.WarehouseListViewModel(), Constants.DataModelName.WarehouseList, false);
    return api;
  };

  public static Group(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Group");
    api.version = version;
    api.documentation.objectDescription = "Group";
    api.documentation.objectPrimaryKey = "GroupId";
    api.documentation.objectDescriptionPropertyNames = ["GroupName", "ExternalGroupId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Group;
    api.documentation.requestAndResponseDataModelObject = new m5.GroupEditViewModel();
    api.documentation.documentationUrlBase = "/group/";
    api.documentation.securityAccessArea = Constants.AccessArea.Group;
    api.pathVariables = "{groupId}";
    api.pathModelProperties = "GroupId";
    api.cacheName = "contactCache";
    api.impactedPickListIds = [Constants.PickList._Group];
    api.useStandardEndpoints(`/${m.RouteSegment.Groups}`, new m5.GroupListViewModel(), Constants.DataModelName.GroupList, true);
    return api;
  };

  public static GroupMembership(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("GroupMembership");
    api.version = version;
    api.documentation.objectDescription = "Group Membership";
    api.documentation.objectPrimaryKey = "ContactGroupMembershipId";
    api.documentation.objectDescriptionPropertyNames = ["MemberContactName", "Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.GroupMembership;
    api.documentation.requestAndResponseDataModelObject = new m5.ContactGroupMembershipEditViewModel();
    api.documentation.documentationUrlBase = "/group/";
    api.documentation.securityAccessArea = Constants.AccessArea.ContactGroupMembership;
    api.pathVariables = "{contactGroupMembershipId}";
    api.pathModelProperties = "ContactGroupMembershipId";
    api.cacheName = "contactCache";
    // Documentation only object with no api endpoints since it is lives inside another object
    //api.useStandardEndpoints(`/${m.RouteSegment.Groups}`, new m5.GroupListViewModel(), Constants.DataModelName.GroupList, true);
    return api;
  };

  public static Directory(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Directory");
    api.version = version;
    api.documentation.objectDescription = "Directory";
    api.documentation.objectPrimaryKey = "DirectoryId";
    api.documentation.objectDescription = "Directory"; // User";
    api.documentation.objectDescriptionPlural = "Directory";
    api.documentation.objectDescriptionPropertyNames = ["DirectoryName", "ExternalDirectoryId", "LastName", "FirstName"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Directory;
    api.documentation.requestAndResponseDataModelObject = new m5.DirectoryEditViewModel();
    api.documentation.documentationUrlBase = "/directory/";
    api.documentation.securityAccessArea = Constants.AccessArea.Directory;
    api.pathVariables = "{directoryId}";
    api.pathModelProperties = "DirectoryId";
    api.cacheName = "contactCache";
    api.impactedPickListIds = [Constants.PickList._Directory];
    api.useStandardEndpoints(`/${m.RouteSegment.Directory}`, new m5.DirectoryListViewModel(), Constants.DataModelName.DirectoryList, false, true, true);
    return api;
  };

  public static DirectoryBulkAdd(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("DirectoryBulkAdd");
    api.version = version;
    api.documentation.objectDescription = "Directory";
    api.documentation.objectPrimaryKey = "DirectoryId";
    api.documentation.objectDescriptionPropertyNames = ["DirectoryName", "ExternalDirectoryId", "LastName", "FirstName", "Email"];
    api.documentation.requestDataModelDocumentationName = Constants.DataModelName.ContactBulkAddRequest;
    api.documentation.requestDataModelObject = new m5.ContactBulkAddRequestViewModel();
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.ContactBulkAddResult;
    api.documentation.responseDataModelObject = new m5.ContactBulkAddResultViewModel();
    api.documentation.documentationUrlBase = "/directory/bulk-add/";
    api.documentation.securityAccessArea = Constants.AccessArea.Directory;
    api.pathVariables = "{directoryId}";
    api.pathModelProperties = "DirectoryId";
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Directory}/${m.RouteSegment.BulkAdd}`, ApiOperationType.Add));
    return api;
  };

  public static ContactChildContact(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ContactChildContact");
    api.version = version;
    api.documentation.objectDescription = "Contact";
    api.documentation.objectName = "Contact";
    api.documentation.objectPrimaryKey = "ContactId";
    api.documentation.objectDescriptionPropertyNames = ["ContactName", "ExternalContactId", "LastName", "FirstName"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Contact;
    api.documentation.requestAndResponseDataModelObject = new m5.ChildContactEditViewModel();
    api.documentation.documentationUrlBase = "/contact/child-contacts/";
    api.documentation.securityAccessArea = Constants.AccessArea.CONTACT;
    api.pathVariables = ["{parentContactId}", "{contactId}"];
    api.pathModelProperties = ["ParentContactId", "ContactId"];
    api.cacheName = "contactCache";
    api.parentApi = "Customer";
    api.useStandardEndpoints(`/${m.RouteSegment.Contacts}/{parentContactId}/${m.RouteSegment.Contacts}`, new m5.ChildContactListViewModel(), Constants.DataModelName.ContactList, false);
    return api;
  };

  public static ContactInventory(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ContactInventory");
    api.version = version;
    api.documentation.objectDescription = "Contact Inventory";
    api.documentation.objectName = "Inventory";
    api.documentation.objectPrimaryKey = "InventoryId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Inventory;
    api.documentation.requestAndResponseDataModelObject = new m5.InventoryEditViewModel();
    api.documentation.documentationUrlBase = "/contact/inventory/";
    api.documentation.securityAccessArea = Constants.AccessArea.Inventory;
    api.pathVariables = ["{contactId}", "{inventoryId}"];
    api.pathModelProperties = ["ContactId", "InventoryId"];
    api.cacheName = "inventoryCache";
    api.parentApi = "Customer";
    api.useStandardEndpoints(`/${m.RouteSegment.Contacts}/{contactId}/${m.RouteSegment.Inventory}`, new m5.InventoryListViewModel(), Constants.DataModelName.InventoryList, false);
    return api;
  };

  public static ContactAssetAccessLog(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ContactAssetAccessLog");
    api.version = version;
    api.documentation.objectDescription = "Contact Asset Access Log";
    api.documentation.objectPrimaryKey = "AssetAccessLogId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.AssetAccessLog;
    api.documentation.requestAndResponseDataModelObject = new m5.AssetAccessLogEditViewModel();
    api.documentation.documentationUrlBase = "/contact/asset-access-log/";
    api.documentation.securityAccessArea = Constants.AccessArea.AssetAccessLog;
    api.pathVariables = ["{contactId}", "{assetAccessLogId}"];
    api.pathModelProperties = ["ContactId", "AssetAccessLogId"];
    api.cacheName = "assetObjectCache";
    api.parentApi = "Customer";
    api.useStandardEndpoints(`/${m.RouteSegment.Contacts}/{contactId}/${m.RouteSegment.AssetAccessLog}`, null, "", false);
    return api;
  };

  public static ContactNoticeList(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ContactNoticeList");
    api.version = version;
    api.documentation.objectDescription = "Contact Notice";
    api.documentation.objectPrimaryKey = "AssetId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Asset;
    api.documentation.requestAndResponseDataModelObject = new m5.AssetEditViewModel();
    api.documentation.documentationUrlBase = "/contact/notice/";
    api.documentation.securityAccessArea = Constants.AccessArea.Asset;
    api.pathVariables = "{contactId}";
    api.pathModelProperties = "ContactId";
    api.cacheName = "noticeCache";
    api.parentApi = "Customer";
    api.endpoints.push(new ApiEndpoint("/contacts/{contactId}/notice", ApiOperationType.List));
    return api;
  };

  public static ContactNoticeAdd(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ContactNoticeAdd");
    api.version = version;
    api.documentation.objectDescription = "Contact Notice";
    api.documentation.objectPrimaryKey = "AssetAccessLogId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.AssetAccessLog;
    api.documentation.requestAndResponseDataModelObject = new m5.AssetAccessLogEditViewModel();
    api.documentation.documentationUrlBase = "/contact/notice/";
    api.documentation.securityAccessArea = Constants.AccessArea.Asset;
    api.pathVariables = ["{contactId}", "{assetId}"];
    api.pathModelProperties = ["AccessedByContactId", "AssetId"];
    api.cacheName = "noticeCache";
    api.excludeFromAutomatedTesting = true;
    api.endpoints.push(new ApiEndpoint("/contacts/{contactId}/notice/{assetId}", ApiOperationType.Add));
    return api;
  };

  public static ContactGroup(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ContactGroup");
    api.version = version;
    api.documentation.objectDescription = "Group Membership";
    api.documentation.objectPrimaryKey = "ContactGroupMembershipId";
    api.documentation.objectDescriptionPropertyNames = ["GroupContactName", "Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.GroupMembership;
    api.documentation.requestAndResponseDataModelObject = new m5.ContactGroupMembershipEditViewModel();
    api.documentation.documentationUrlBase = "/contact-group/";
    api.documentation.securityAccessArea = Constants.AccessArea.ContactGroupMembership;
    api.pathVariables = ["{contactId}", "{contactGroupMembershipId}"];
    api.pathModelProperties = ["ContactId", "ContactGroupMembershipId"];
    api.cacheName = "contactCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Contacts}/{contactId}/${m.RouteSegment.Groups}`, null, "", false);
    return api;
  };

  public static ContactRole(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ContactRole");
    api.version = version;
    api.documentation.objectDescription = "Contact Role";
    api.documentation.objectPrimaryKey = "ContactRoleId";
    api.documentation.objectDescriptionPropertyNames = ["RoleDescription"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ContactRole;
    api.documentation.requestAndResponseDataModelObject = new m5.ContactRoleEditViewModel();
    api.documentation.documentationUrlBase = "/contact/role/";
    api.documentation.securityAccessArea = Constants.AccessArea.ContactRole;
    api.pathVariables = ["{contactId}", "{contactRoleId}"];
    api.pathModelProperties = ["ContactId", "ContactRoleId"];
    api.cacheName = "contactCache";
    api.parentApi = "Directory";
    api.useStandardEndpoints(`/${m.RouteSegment.Contacts}/{contactId}/${m.RouteSegment.Roles}`, null, "", false);
    return api;
  };

  public static ContactWorkSchedule(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ContactWorkSchedule");
    api.version = version;
    api.documentation.objectDescription = "Contact Work Schedule";
    api.documentation.objectPrimaryKey = "ContactWorkScheduleId";
    api.documentation.objectDescriptionPropertyNames = ["Days"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ContactWorkSchedule;
    api.documentation.requestAndResponseDataModelObject = new m5.ContactWorkScheduleEditViewModel();
    api.documentation.documentationUrlBase = "/contact/work-schedule/";
    api.documentation.securityAccessArea = Constants.AccessArea.ContactWorkSchedule;
    api.pathVariables = ["{contactId}", "{contactWorkScheduleId}"];
    api.pathModelProperties = ["ContactId", "ContactWorkScheduleId"];
    api.cacheName = "contactCache";
    api.parentApi = "Directory";
    api.useStandardEndpoints(`/${m.RouteSegment.Contacts}/{contactId}/${m.RouteSegment.WorkSchedules}`, null, "", false);
    return api;
  };

  public static ContactWorkScheduleException(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ContactWorkScheduleException");
    api.version = version;
    api.documentation.objectDescription = "Contact Work Schedule Exception";
    api.documentation.objectPrimaryKey = "ContactWorkScheduleExceptionId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ContactWorkScheduleException;
    api.documentation.requestAndResponseDataModelObject = new m5.ContactWorkScheduleExceptionEditViewModel();
    api.documentation.documentationUrlBase = "/contact/work-schedule-exception/";
    api.documentation.securityAccessArea = Constants.AccessArea.ContactWorkScheduleException;
    api.pathVariables = ["{contactId}", "{contactWorkScheduleExceptionId}"];
    api.pathModelProperties = ["ContactId", "ContactWorkScheduleExceptionId"];
    api.cacheName = "contactCache";
    api.parentApi = "Directory";
    api.useStandardEndpoints(`/${m.RouteSegment.Contacts}/{contactId}/${m.RouteSegment.WorkScheduleExceptions}`, null, "", false);
    return api;
  };

  public static ContactExternalAuthentication(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ContactExternalAuthentication");
    api.version = version;
    api.documentation.objectDescription = "Contact External Authentication";
    api.documentation.objectPrimaryKey = "ContactExternalAuthenticationId";
    api.documentation.objectDescriptionPropertyNames = ["AuthenticationService", "DisplayName", "UserName"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ContactExternalAuthentication;
    api.documentation.requestAndResponseDataModelObject = new m5.ContactExternalAuthenticationEditViewModel();
    api.documentation.documentationUrlBase = "/contact/external-authentication/";
    api.documentation.securityAccessArea = Constants.AccessArea.ContactExternalAuthentication;
    api.pathVariables = ["{contactId}", "{contactExternalAuthenticationId}"];
    api.pathModelProperties = ["ContactId", "ContactExternalAuthenticationId"];
    api.cacheName = "contactCache";
    api.parentApi = "Directory";
    api.useStandardEndpoints(`/${m.RouteSegment.Contacts}/{contactId}/${m.RouteSegment.ExternalAuthentications}`, null, "", false);
    return api;
  };

  public static ContactDuplicateManagementMerge(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ContactDuplicateManagementMerge");
    api.version = version;
    api.documentation.objectDescription = "Merge Duplicate Contact";
    api.documentation.objectDescriptionPlural = api.documentation.objectDescription;
    api.documentation.objectPrimaryKey = null;
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ManagementMerge;
    api.documentation.requestAndResponseDataModelObject = new m5.MergeEditViewModel();
    api.documentation.documentationUrlBase = "/contact/duplicate/management/merge/";
    api.documentation.securityAccessArea = Constants.AccessArea.CONTACT;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Contacts}/${m.RouteSegment.Duplicate}/${m.RouteSegment.Management}/${m.RouteSegment.Merge}`, ApiOperationType.Call));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Merge Duplicate Contact";
    api.endpoints.slice(-1)[0].documentation.menuText = "Merge Duplicate Contact";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API is used to merge two objects.  The data from the second object is merged into the first object and then the second object is deleted.";
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "exchange";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Merge";
    return api;
  };

  public static ContactDuplicateManagementNotDuplicate(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ContactDuplicateManagementNotDuplicate");
    api.version = version;
    api.documentation.objectDescription = "Not Duplicate Contact";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ManagementNotDuplicate;
    api.documentation.requestAndResponseDataModelObject = new m5.NotDuplicateObjectEditViewModel();
    api.documentation.documentationUrlBase = "/contact/duplicate/management/not-duplicate/";
    api.documentation.securityAccessArea = Constants.AccessArea.Setting;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Contacts}/${m.RouteSegment.Duplicate}/${m.RouteSegment.Management}/${m.RouteSegment.NotDuplicate}`, ApiOperationType.Call));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Not Duplicate Contact";
    api.endpoints.slice(-1)[0].documentation.menuText = "Not Duplicate Contact";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API is used to mark that two objects are not duplicates.  This will prevent them from appearing in the possible duplicate object list in the future.";
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "ban";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Not Duplicate";
    return api;
  };

  public static ContactDuplicateManagementPossibleDuplicates(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ContactDuplicateManagementPossibleDuplicates");
    api.version = version;
    api.documentation.objectDescription = "Possible Duplicate Contacts";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ManagementPossibleDuplicate;
    api.documentation.requestAndResponseDataModelObject = new m5.PossibleDuplicateObjectEditViewModel();
    api.documentation.documentationUrlBase = "/contact/duplicate/management/possible-duplicates/";
    api.documentation.securityAccessArea = Constants.AccessArea.CONTACT;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Contacts}/${m.RouteSegment.Duplicate}/${m.RouteSegment.Management}/${m.RouteSegment.PossibleDuplicates}?size={size}&expand={expand}`, ApiOperationType.List));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Possible Duplicate Contacts";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API gets a list of possible duplicates.  The size parameter is used to limit the number of possible duplicates that are returned.  The expand parameter is the type of object to check for duplicates.  Possible expand values include: Customer, User, Vendor, Warehouse.";
    return api;
  };

  public static ContactDuplicateManagementPossibleDuplicateChildSynopsis(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ContactDuplicateManagementPossibleDuplicateChildSynopsis");
    api.version = version;
    api.documentation.objectDescription = "Possible Duplicate Contact Child Synopsis";
    api.documentation.objectDescriptionPlural = api.documentation.objectDescription;
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ManagementPossibleDuplicateChildSynopsis;
    api.documentation.requestAndResponseDataModelObject = new m5.SynopsisObjectEditViewModel();
    api.documentation.documentationUrlBase = "/contact/duplicate/management/possible-duplicates/child-synopsis/";
    api.pathVariables = ["{contactType}", "{contactId}"];
    api.pathModelProperties = ["ContactType", "ContactId"];
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Contacts}/${m.RouteSegment.Duplicate}/${m.RouteSegment.Management}/${m.RouteSegment.PossibleDuplicates}/${m.RouteSegment.ChildSynopsis}/{contactType}/{contactId}`, ApiOperationType.List));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Possible Duplicate Contact Child Synopsis";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API gets a list of synopsis objects for all child records of a possible duplicate.  This provides the ability to choose which items should be discarded as part of a merge operation.";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static SalesOpportunity(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("SalesOpportunity");
    api.version = version;
    api.documentation.objectDescription = "Sales Opportunity";
    api.documentation.objectPrimaryKey = "SalesOpportunityId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ContactName"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.SalesOpportunity;
    api.documentation.requestAndResponseDataModelObject = new m5.SalesOpportunityEditViewModel();
    api.documentation.documentationUrlBase = "/sales-opportunity/";
    api.documentation.securityAccessArea = Constants.AccessArea.SalesOpportunity;
    api.pathVariables = "{salesOpportunityId}";
    api.pathModelProperties = "SalesOpportunityId";
    api.cacheName = "staticObjectCache";
    api.useStandardEndpoints(`/${m.RouteSegment.SalesOpportunities}`, null, null, true);
    return api;
  };

  //#endregion


  //#region Management APIs

  public static DocumentationTestData(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("DocumentationTestData");
    api.version = version;
    api.documentation.objectDescription = "Documentation";
    api.documentation.requestAndResponseDataModelDocumentationName = ""; // TODOConstants.DataModelName.Asset;
    api.documentation.requestAndResponseDataModelObject = {}; // TODO new m5.Model();
    api.documentation.documentationUrlBase = ""; // This doesn't really appear in the API documentation it's used to render the API documentation
    api.documentation.readOnly = true;
    api.pathVariables = "";
    api.pathModelProperties = "";
    api.excludeFromAutomatedTesting = true;
    api.endpoints.push(new ApiEndpoint("/documentation/test/data", ApiOperationType.Get));
    return api;
  };

  public static DocumentationRawDataModel(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("DocumentationRawDataModel");
    api.version = version;
    api.documentation.objectDescription = "Documentation";
    api.documentation.objectPrimaryKey = "ModelName";
    api.documentation.requestAndResponseDataModelDocumentationName = ""; // TODOConstants.DataModelName.Asset;
    api.documentation.requestAndResponseDataModelObject = {}; // TODO new m5.Model();
    api.documentation.documentationUrlBase = ""; // This doesn't really appear in the API documentation it's used to render the API documentation
    api.documentation.readOnly = true;
    api.pathVariables = "{modelName}";
    api.pathModelProperties = "ModelName";
    api.cacheLevel = CacheLevel.Static;
    api.cacheName = "staticObjectCache";
    api.endpoints.push(new ApiEndpoint("/documentation/data-models?page={page}&size={size}&sort={sort}&filterId={filterId}&filter={filter}&q={q}&expand={expand}", ApiOperationType.List));
    api.endpoints.push(new ApiEndpoint("/documentation/raw-data-models/{modelName}", ApiOperationType.Get));
    return api;
  };

  public static DocumentationDataModel(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("DocumentationDataModel");
    api.version = version;
    api.documentation.objectDescription = "Documentation";
    api.documentation.objectPrimaryKey = "ModelName";
    api.documentation.requestAndResponseDataModelDocumentationName = ""; // TODOConstants.DataModelName.Asset;
    api.documentation.requestAndResponseDataModelObject = {}; // TODO new m5.Model();
    api.documentation.documentationUrlBase = ""; // This doesn't really appear in the API documentation it's used to render the API documentation
    api.documentation.readOnly = true;
    api.pathVariables = "{modelName}";
    api.pathModelProperties = "ModelName";
    api.cacheLevel = CacheLevel.Static;
    api.cacheName = "staticObjectCache";
    api.endpoints.push(new ApiEndpoint("/documentation/data-models?page={page}&size={size}&sort={sort}&filterId={filterId}&filter={filter}&q={q}&expand={expand}", ApiOperationType.List));
    api.endpoints.push(new ApiEndpoint("/documentation/data-models/{modelName}", ApiOperationType.Get));
    return api;
  };

  public static DocumentationDataModelOptions(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("DocumentationDataModelOptions");
    api.version = version;
    api.documentation.objectDescription = "Documentation";
    api.documentation.objectPrimaryKey = ["ModelName", "PropertyName"];
    api.documentation.requestAndResponseDataModelDocumentationName = ""; // TODO Constants.DataModelName.DataOption;
    api.documentation.requestAndResponseDataModelObject = new m5core.PickListSelectionViewModel();
    api.documentation.documentationUrlBase = ""; // This doesn't appear in the docs but perhaps it should as a way for people to get these valid options
    api.documentation.readOnly = true;
    api.pathVariables = ["{modelName}", "{propertyName}"];
    api.pathModelProperties = ["ModelName", "PropertyName"];
    api.cacheLevel = CacheLevel.Static;
    api.cacheName = "staticObjectCache";
    api.excludeFromAutomatedTesting = true;
    api.endpoints.push(new ApiEndpoint("/documentation/data-options/{modelName}/{propertyName}", ApiOperationType.List));
    return api;
  };

  public static DocumentationApiConfig(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("DocumentationApiConfig");
    api.version = version;
    api.documentation.objectDescription = "Documentation";
    api.documentation.objectPrimaryKey = "";
    api.documentation.requestAndResponseDataModelDocumentationName = ""; // TODO Constants.DataModelName.DataOption;
    api.documentation.requestAndResponseDataModelObject = {}
    api.documentation.documentationUrlBase = ""; // This doesn't appear in the docs but perhaps it should as a way for people to get these valid options
    api.documentation.readOnly = true;
    api.pathVariables = "{apiEndpointId}";
    api.pathModelProperties = "ApiEndpointId";
    api.cacheLevel = CacheLevel.Static;
    api.cacheName = "staticObjectCache";
    api.excludeFromAutomatedTesting = true;
    api.endpoints.push(new ApiEndpoint("/documentation/api-config?apiEndpointId={apiEndpointId}", ApiOperationType.Get));
    return api;
  };

  // TODO retire this
  public static QueryList(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("QueryList");
    api.version = version;
    api.documentation.objectDescription = "Query";
    api.documentation.requestAndResponseDataModelDocumentationName = ""; // TODO Constants.DataModelName.ContactExternalAuthentication;
    api.documentation.requestAndResponseDataModelObject = {}; // TODO this is dynamic so nothing to document here? new m5.Query();
    api.documentation.documentationUrlBase = ""; // This is a hidden API that we don't document because we don't want people going crazy with it
    api.documentation.readOnly = true;
    api.cacheName = "systemCache";
    api.endpoints.push(new ApiEndpoint("/system/query?page={page}&size={size}&sort={sort}&filterId={filterId}&filter={filter}&q={q}&expand={expand}", ApiOperationType.List));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API retrieves a list of query results.  This is valid for system administrators only.";
    return api;
  };

  //#endregion


  //#region Notification APIs

  public static NotificationContact(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("NotificationContact");
    api.version = version;
    api.documentation.objectDescription = "Notification Contact";
    api.documentation.objectPrimaryKey = "NotificationContactId";
    api.documentation.objectDescriptionPropertyNames = ["ContactName", "NotificationContactType"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.NotificationContact;
    api.documentation.requestAndResponseDataModelObject = new m5.NotificationContactEditViewModel();
    api.documentation.documentationUrlBase = "/notification/contact/";
    api.documentation.securityAccessArea = Constants.AccessArea.NotificationContact;
    api.pathVariables = "{notificationContactId}";
    api.pathModelProperties = "NotificationContactId";
    api.cacheName = "notificationObjectCache";
    api.impactedPickListIds = [Constants.PickList._NotificationContact];
    api.useStandardEndpoints(`/${m.RouteSegment.NotificationContacts}`, null, "", true);
    return api;
  };

  public static NotificationOptOut(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("NotificationOptOut");
    api.version = version;
    api.documentation.objectDescription = "Notification Opt Out";
    api.documentation.objectPrimaryKey = "NotificationOptOutId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.NotificationOptOut;
    api.documentation.requestAndResponseDataModelObject = new m5.NotificationOptOutEditViewModel();
    api.documentation.documentationUrlBase = "/notification/opt-out/";
    api.documentation.securityAccessArea = Constants.AccessArea.NotificationOptOut;
    api.pathVariables = "{notificationOptOutId}";
    api.pathModelProperties = "NotificationOptOutId";
    api.cacheName = "notificationObjectCache";
    api.useStandardEndpoints(`/${m.RouteSegment.NotificationOptOuts}`);
    return api;
  };

  public static NotificationGroup(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("NotificationGroup");
    api.version = version;
    api.documentation.objectDescription = "Notification Group";
    api.documentation.objectPrimaryKey = "NotificationGroupId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "Title", "Summary"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.NotificationGroup;
    api.documentation.requestAndResponseDataModelObject = new m5.NotificationGroupEditViewModel();
    api.documentation.documentationUrlBase = "/notification/group/";
    api.documentation.securityAccessArea = Constants.AccessArea.NotificationGroup;
    api.pathVariables = "{notificationGroupId}";
    api.pathModelProperties = "NotificationGroupId";
    api.cacheName = "notificationCache";
    api.impactedPickListIds = [Constants.PickList._NotificationGroup];
    api.useStandardEndpoints(`/${m.RouteSegment.NotificationGroups}`, null, null, true);
    return api;
  };

  public static NotificationGroupDetail(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("NotificationGroupDetail");
    api.version = version;
    api.documentation.objectDescription = "Notification Group Detail";
    api.documentation.objectPrimaryKey = "NotificationGroupDetailId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ContactName", "ContactInformation"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.NotificationGroupDetail;
    api.documentation.requestAndResponseDataModelObject = new m5.NotificationGroupDetailEditViewModel();
    api.documentation.documentationUrlBase = "/notification/group/detail/";
    api.documentation.securityAccessArea = Constants.AccessArea.NotificationGroupDetail;
    api.pathVariables = ["{notificationGroupId}", "{notificationGroupDetailId}"];
    api.pathModelProperties = ["NotificationGroupId", "NotificationGroupDetailId"];
    api.cacheName = "notificationCache";
    api.parentApi = "NotificationGroup";
    api.useStandardEndpoints(`/${m.RouteSegment.NotificationGroups}/{notificationGroupId}/${m.RouteSegment.Details}`);
    return api;
  };

  public static NotificationEvent(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("NotificationEvent");
    api.version = version;
    api.documentation.objectDescription = "Notification Event";
    api.documentation.objectPrimaryKey = "NotificationEventId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "Type"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.NotificationEvent;
    api.documentation.requestAndResponseDataModelObject = new m5.NotificationEventEditViewModel();
    api.documentation.documentationUrlBase = "/notification/event/";
    api.documentation.securityAccessArea = Constants.AccessArea.NotificationEvent;
    api.pathVariables = "{notificationEventId}";
    api.pathModelProperties = "NotificationEventId";
    api.cacheName = "notificationCache";
    api.useStandardEndpoints(`/${m.RouteSegment.NotificationEvents}`);
    return api;
  };

  public static Notification(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Notification");
    api.version = version;
    api.documentation.objectDescription = "Notification";
    api.documentation.objectPrimaryKey = "NotificationId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ContactName", "ContactInformation"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Notification;
    api.documentation.requestAndResponseDataModelObject = new m5.NotificationEditViewModel();
    api.documentation.documentationUrlBase = "/notification/event/notification";
    api.documentation.securityAccessArea = Constants.AccessArea.Notification;
    api.pathVariables = ["{notificationEventId}", "{notificationId}"];
    api.pathModelProperties = ["NotificationEventId", "NotificationId"];
    api.cacheName = "notificationCache";
    api.parentApi = "NotificationEvent";
    api.useStandardEndpoints(`/${m.RouteSegment.NotificationEvents}/{notificationEventId}/${m.RouteSegment.Notifications}`);
    return api;
  };

  public static ActionLink(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ActionLink");
    api.version = version;
    api.documentation.objectDescription = "Action Link";
    api.documentation.objectPrimaryKey = "ActionLinkId";
    api.documentation.objectDescriptionPropertyNames = ["ActionLinkId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ActionLink;
    api.documentation.requestAndResponseDataModelObject = new m5.ActionLinkEditViewModel();
    api.documentation.documentationUrlBase = "/action-link/";
    api.documentation.securityAccessArea = Constants.AccessArea.ActionLink;
    api.pathVariables = "{actionLinkId}";
    api.pathModelProperties = "ActionLinkId";
    api.cacheName = null
    api.useStandardEndpoints(`/${m.RouteSegment.ActionLinks}`, new m5.ActionLinkEditViewModel(), null, false);
    return api;
  };

  public static AlarmRule(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AlarmRule");
    api.version = version;
    api.documentation.objectDescription = "Alarm Rule";
    api.documentation.objectPrimaryKey = "AlarmRuleId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.AlarmRule;
    api.documentation.requestAndResponseDataModelObject = new m5.AlarmRuleEditViewModel();
    api.documentation.documentationUrlBase = "/alarm-rule/";
    api.documentation.securityAccessArea = Constants.AccessArea.AlarmRule;
    api.pathVariables = "{alarmRuleId}";
    api.pathModelProperties = "AlarmRuleId";
    api.cacheName = "notificationCache";
    api.useStandardEndpoints(`/${m.RouteSegment.AlarmRules}`);
    return api;
  };

  public static AlarmRuleNotification(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AlarmRuleNotification");
    api.version = version;
    api.documentation.objectDescription = "Alarm Rule Notification";
    api.documentation.objectPrimaryKey = ["AlarmRuleId", "ContactId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.AlarmRuleNotification;
    api.documentation.requestAndResponseDataModelObject = new m5.AlarmRuleNotificationEditViewModel();
    api.documentation.documentationUrlBase = "/alarm-rule/notification/";
    api.documentation.securityAccessArea = Constants.AccessArea.AlarmRuleNotification;
    api.pathVariables = ["{alarmRuleId}", "{contactId}"];
    api.pathModelProperties = ["AlarmRuleId", "ContactId"];
    api.cacheName = "notificationCache";
    api.parentApi = "AlarmRule";
    api.useStandardEndpoints(`/${m.RouteSegment.AlarmRules}/{alarmRuleId}/${m.RouteSegment.Notifications}`);
    return api;
  };

  public static ServerResourceAlarmRule(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ServerResourceAlarmRule");
    api.version = version;
    api.documentation.objectDescription = "Server Resource Alarm Rule";
    api.documentation.objectPrimaryKey = "ServerResourceAlarmRuleId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "AlarmMessage"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ServerResourceAlarmRule;
    api.documentation.requestAndResponseDataModelObject = new m5.ServerResourceAlarmRuleViewModel();
    api.documentation.documentationUrlBase = "/server-resource-alarm-rule/";
    api.documentation.securityAccessArea = Constants.AccessArea.ServerResourceAlarmRule;
    api.pathVariables = "{serverResourceAlarmRuleId}";
    api.pathModelProperties = "ServerResourceAlarmRuleId";
    api.cacheName = "systemCache";
    api.useStandardEndpoints(`/${m.RouteSegment.ServerResources}/${m.RouteSegment.AlarmRules}`, null, "", true);
    return api;
  };


  //#endregion


  //#region Inventory APIs

  public static InventoryType(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("InventoryType");
    api.modules = ["inventory"];
    api.version = version;
    api.documentation.objectDescription = "Inventory Type";
    api.documentation.objectPrimaryKey = "InventoryTypeId";
    api.documentation.objectDescriptionPropertyNames = ["Type", "Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.InventoryType;
    api.documentation.requestAndResponseDataModelObject = new m5.InventoryTypeEditViewModel();
    api.documentation.documentationUrlBase = "/inventory-type/";
    api.documentation.securityAccessArea = Constants.AccessArea.InventoryType;
    api.pathVariables = "{inventoryTypeId}";
    api.pathModelProperties = "InventoryTypeId";
    api.cacheName = "inventoryCache";
    api.impactedPickListIds = [Constants.PickList._InventoryType, Constants.PickList._InventoryTypeVersion];
    api.useStandardEndpoints(`/${m.RouteSegment.InventoryTypes}`, new m5.InventoryTypeListViewModel(), Constants.DataModelName.InventoryTypeList, true);
    return api;
  }

  public static InventoryTypeVersion(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("InventoryTypeVersion");
    api.modules = ["inventory"];
    api.version = version;
    api.documentation.objectDescription = "Inventory Type Version";
    api.documentation.objectPrimaryKey = "InventoryTypeVersionId";
    api.documentation.objectDescriptionPropertyNames = ["Version", "Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.InventoryTypeVersion;
    api.documentation.requestAndResponseDataModelObject = new m5.InventoryTypeVersionEditViewModel();
    api.documentation.documentationUrlBase = "/inventory-type-version/";
    api.documentation.securityAccessArea = Constants.AccessArea.InventoryTypeVersion;
    api.pathVariables = ["{inventoryTypeId}", "{inventoryTypeVersionId}"];
    api.pathModelProperties = ["InventoryTypeId", "InventoryTypeVersionId"];
    api.cacheName = "inventoryCache";
    api.impactedPickListIds = [Constants.PickList._InventoryTypeVersion];
    api.parentApi = "InventoryType";
    api.useStandardEndpoints(`/${m.RouteSegment.InventoryTypes}/{inventoryTypeId}/${m.RouteSegment.Versions}`, null, null, true);
    return api;
  };

  public static InventoryTypeVersionAssignCase(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("InventoryTypeVersionAssignCase");
    api.modules = ["inventory"];
    api.version = version;
    api.documentation.objectDescription = "Inventory Type Version Assign Case";
    api.documentation.objectPrimaryKey = "";
    api.documentation.requestAndResponseDataModelDocumentationName = "";
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/inventory-type-version-assign-case/";
    api.documentation.securityAccessArea = Constants.AccessArea.Cases;
    api.pathVariables = ["{inventoryTypeId}", "{inventoryTypeVersionId}", "{caseId}"];
    api.pathModelProperties = ["InventoryTypeId", "InventoryTypeVersionId", "CaseId"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.InventoryTypes}/{inventoryTypeId}/${m.RouteSegment.Versions}/{inventoryTypeVersionId}/${m.RouteSegment.Assign}/${m.RouteSegment.Cases}/{caseId}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Inventory Type Version Assign Case";
    api.endpoints.slice(-1)[0].documentation.menuText = "Inventory Type Version Assign Case";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API is utilized to assign an inventory type version to a case.`;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = true;
    //api.endpoints.slice(-1)[0].documentation.responseDataModelObject = new m5.AuthorizationCodeEditViewModel();
    //api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = Constants.DataModelName.AuthorizationCode;
    return api;
  };

  public static InventoryTypeVersionRemoveCase(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("InventoryTypeVersionRemoveCase");
    api.modules = ["inventory"];
    api.version = version;
    api.documentation.objectDescription = "Inventory Type Version Remove Case";
    api.documentation.objectPrimaryKey = "";
    api.documentation.requestAndResponseDataModelDocumentationName = "";
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/inventory-type-version-remove-case/";
    api.documentation.securityAccessArea = Constants.AccessArea.Cases;
    api.pathVariables = ["{inventoryTypeId}", "{inventoryTypeVersionId}", "{caseId}"];
    api.pathModelProperties = ["InventoryTypeId", "InventoryTypeVersionId", "CaseId"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.InventoryTypes}/{inventoryTypeId}/${m.RouteSegment.Versions}/{inventoryTypeVersionId}/${m.RouteSegment.Remove}/${m.RouteSegment.Cases}/{caseId}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Inventory Type Version Remove Case";
    api.endpoints.slice(-1)[0].documentation.menuText = "Inventory Type Version Remove Case";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API is utilized to remove an inventory type version from a case.`;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = true;
    //api.endpoints.slice(-1)[0].documentation.responseDataModelObject = new m5.AuthorizationCodeEditViewModel();
    //api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = Constants.DataModelName.AuthorizationCode;
    return api;
  };

  public static InventoryTypeVersionCreateReleaseNotes(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("InventoryTypeVersionCreateReleaseNotes");
    api.modules = ["inventory"];
    api.version = version;
    api.documentation.objectDescription = "Inventory Type Version Create Release Notes";
    api.documentation.objectPrimaryKey = "";
    api.documentation.requestDataModelDocumentationName = Constants.DataModelName.InventoryTypeVersionReleaseNoteCreateRequestModel;
    api.documentation.requestDataModelObject = new m5.InventoryTypeVersionReleaseNoteCreateRequestModel();
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.Asset;
    api.documentation.responseDataModelObject = new m5.AssetEditViewModel();
    api.documentation.documentationUrlBase = "/inventory-type-version-create-release-notes/";
    api.pathVariables = ["{inventoryTypeId}", "{inventoryTypeVersionId}"];
    api.pathModelProperties = ["InventoryTypeId", "InventoryTypeVersionId"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.InventoryTypes}/{inventoryTypeId}/${m.RouteSegment.Versions}/{inventoryTypeVersionId}/${m.RouteSegment.Notes}/${m.RouteSegment.Create}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Inventory Type Version Create Release Notes";
    api.endpoints.slice(-1)[0].documentation.menuText = "Inventory Type Version Create Release Notes";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API is utilized to create release notes for an inventory type version.`;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = true;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = true;
    return api;
  };

  public static InventoryTypeVersionBatchAdd(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("InventoryTypeVersionBatchAdd");
    api.modules = ["inventory"];
    api.version = version;
    api.documentation.objectDescription = "Inventory Type Version Batch Add";
    api.documentation.objectPrimaryKey = "";
    api.documentation.requestDataModelDocumentationName = Constants.DataModelName.InventoryTypeVersionBatchAddModel;
    api.documentation.requestDataModelObject = new m5.InventoryTypeVersionBatchAddModel();
    api.documentation.responseDataModelDocumentationName = "";
    api.documentation.responseDataModelObject = null;
    api.documentation.documentationUrlBase = "/inventory-type-version-batch-add/";
    api.pathVariables = ["{inventoryTypeId}"];
    api.pathModelProperties = ["InventoryTypeId"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.InventoryTypes}/{inventoryTypeId}/${m.RouteSegment.Versions}/${m.RouteSegment.BatchAdd}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Inventory Type Version Batch Add";
    api.endpoints.slice(-1)[0].documentation.menuText = "Inventory Type Version Batch Add";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API is utilized to create a batch of versions.`;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = true;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = false;
    return api;
  };

  public static InventoryQuantityType(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("InventoryQuantityType");
    api.modules = ["inventory"];
    api.version = version;
    api.documentation.objectDescription = "Inventory Quantity Type";
    api.documentation.objectPrimaryKey = "InventoryQuantityTypeId";
    api.documentation.objectDescriptionPropertyNames = ["QuantityType", "Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.InventoryQuantityType;
    api.documentation.requestAndResponseDataModelObject = new m5.InventoryQuantityTypeEditViewModel();
    api.documentation.documentationUrlBase = "/inventory-quantity-type/";
    api.documentation.securityAccessArea = Constants.AccessArea.InventoryQuantityType;
    api.pathVariables = "{inventoryQuantityTypeId}";
    api.pathModelProperties = "InventoryQuantityTypeId";
    api.cacheName = "inventoryCache";
    api.impactedPickListIds = [Constants.PickList._InventoryQuantityType];
    api.useStandardEndpoints(`/${m.RouteSegment.InventoryQuantityTypes}`, null, null, true);
    return api;
  }

  public static Inventory(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Inventory");
    api.modules = ["inventory"];
    api.version = version;
    api.documentation.objectDescription = "Inventory";
    api.documentation.objectDescriptionPlural = api.documentation.objectDescription; // avoid default y => ies conversion
    api.documentation.objectPrimaryKey = "InventoryId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "TrackingNumber", "SerialNumber"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Inventory;
    api.documentation.requestAndResponseDataModelObject = new m5.InventoryEditViewModel();
    api.documentation.documentationUrlBase = "/inventory/";
    api.documentation.securityAccessArea = Constants.AccessArea.Inventory;
    api.pathVariables = "{inventoryId}";
    api.pathModelProperties = "InventoryId";
    api.cacheName = "inventoryCache";
    api.parentApi = "InventoryType";
    api.useStandardEndpoints(`/${m.RouteSegment.Inventory}`, new m5.InventoryListViewModel(), Constants.DataModelName.InventoryList, true);

    // Setup that is common across all expiring reports
    const expiringReportPathVariables = ["{includeExpirationRangeFilter}", "{sortByExpiration}", "{inventoryType}", "{expirationPastDays}", "{expirationFutureDays}", "{downloadFormat}", "{downloadIncludeAllProperties}"];
    const expiringReportPathProperties = ["IncludeExpirationRangeFilter", "SortByExpiration", "InventoryType", "ExpirationPastDays", "ExpirationFutureDays", "DownloadFormat", "DownloadIncludeAllProperties"];
    const expiringReportTestForm: ApiDocTestFormProperty[] = [];
    expiringReportTestForm.push(new ApiDocTestFormProperty("IncludeExpirationRangeFilter", ApiDocTestFormPropertyType.Bool, true));
    expiringReportTestForm.push(new ApiDocTestFormProperty("SortByExpiration", ApiDocTestFormPropertyType.Bool, true));
    expiringReportTestForm.push(new ApiDocTestFormProperty("InventoryType"));
    expiringReportTestForm.push(new ApiDocTestFormProperty("ExpirationPastDays", ApiDocTestFormPropertyType.Number, 30));
    expiringReportTestForm.push(new ApiDocTestFormProperty("ExpirationFutureDays", ApiDocTestFormPropertyType.Number, 30));
    expiringReportTestForm.push(new ApiDocTestFormProperty("DownloadFormat", ApiDocTestFormPropertyType.Select, "", "", null, ["", "csv", "tsv", "xml", "json", "xlsx"]));
    expiringReportTestForm.push(new ApiDocTestFormProperty("DownloadIncludeAllProperties", ApiDocTestFormPropertyType.Bool));

    const expiringMaintenanceReport = new ApiEndpoint(`/inventory/reports/${m5.ApiReportId.InventoryExpiringMaintenance}?includeExpirationRangeFilter={includeExpirationRangeFilter}&sortByExpiration={sortByExpiration}&inventoryType={inventoryType}&expirationPastDays={expirationPastDays}&expirationFutureDays={expirationFutureDays}&downloadFormat={downloadFormat}&downloadIncludeAllProperties={downloadIncludeAllProperties}`,
      ApiOperationType.Report, m5.ApiReportId.InventoryExpiringMaintenance);
    expiringMaintenanceReport.pathVariables = expiringReportPathVariables;
    expiringMaintenanceReport.pathModelProperties = expiringReportPathProperties;
    expiringMaintenanceReport.documentation = new ApiDocumentation();
    expiringMaintenanceReport.documentation.objectDescriptionPlural = "Inventory Expiring Maintenance";
    expiringMaintenanceReport.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.InventoryExpiringItem;
    expiringMaintenanceReport.documentation.testFormProperties = expiringReportTestForm;
    expiringMaintenanceReport.documentation.pathModelPropertyTriggeringDownload = "DownloadFormat";
    expiringMaintenanceReport.documentation.responseDataModelObjectFromDataModelDocumentation = true;
    api.endpoints.push(expiringMaintenanceReport);

    const expiringWarrantyReport = new ApiEndpoint(`/inventory/reports/${m5.ApiReportId.InventoryExpiringWarranty}?includeExpirationRangeFilter={includeExpirationRangeFilter}&sortByExpiration={sortByExpiration}&inventoryType={inventoryType}&expirationPastDays={expirationPastDays}&expirationFutureDays={expirationFutureDays}&downloadFormat={downloadFormat}&downloadIncludeAllProperties={downloadIncludeAllProperties}`,
      ApiOperationType.Report, m5.ApiReportId.InventoryExpiringWarranty);
    expiringWarrantyReport.pathVariables = expiringReportPathVariables;
    expiringWarrantyReport.pathModelProperties = expiringReportPathProperties;
    expiringWarrantyReport.documentation = new ApiDocumentation();
    expiringWarrantyReport.documentation.objectDescriptionPlural = "Inventory Expiring Warranty";
    expiringWarrantyReport.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.InventoryExpiringItem;
    expiringWarrantyReport.documentation.testFormProperties = expiringReportTestForm;
    expiringWarrantyReport.documentation.pathModelPropertyTriggeringDownload = "DownloadFormat";
    expiringWarrantyReport.documentation.responseDataModelObjectFromDataModelDocumentation = true;
    api.endpoints.push(expiringWarrantyReport);

    const expiringLeaseReport = new ApiEndpoint(`/inventory/reports/${m5.ApiReportId.InventoryExpiringLease}?includeExpirationRangeFilter={includeExpirationRangeFilter}&sortByExpiration={sortByExpiration}&inventoryType={inventoryType}&expirationPastDays={expirationPastDays}&expirationFutureDays={expirationFutureDays}&downloadFormat={downloadFormat}&downloadIncludeAllProperties={downloadIncludeAllProperties}`,
      ApiOperationType.Report, m5.ApiReportId.InventoryExpiringLease);
    expiringLeaseReport.pathVariables = expiringReportPathVariables;
    expiringLeaseReport.pathModelProperties = expiringReportPathProperties;
    expiringLeaseReport.documentation = new ApiDocumentation();
    expiringLeaseReport.documentation.objectDescriptionPlural = "Inventory Expiring Lease";
    expiringLeaseReport.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.InventoryExpiringItem;
    expiringLeaseReport.documentation.testFormProperties = expiringReportTestForm;
    expiringLeaseReport.documentation.pathModelPropertyTriggeringDownload = "DownloadFormat";
    expiringLeaseReport.documentation.responseDataModelObjectFromDataModelDocumentation = true;
    api.endpoints.push(expiringLeaseReport);

    return api;
  }

  public static InventoryHistory(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("InventoryHistory");
    api.modules = ["inventory"];
    api.version = version;
    api.documentation.objectDescription = "Inventory History";
    api.documentation.objectPrimaryKey = "InventoryHistoryId";
    api.documentation.objectDescriptionPropertyNames = ["BeginningDateTime"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.InventoryHistory;
    api.documentation.requestAndResponseDataModelObject = new m5.InventoryHistoryEditViewModel();
    api.documentation.documentationUrlBase = "/inventory-history/";
    api.documentation.securityAccessArea = Constants.AccessArea.InventoryHistory;
    api.pathVariables = ["{inventoryId}", "{inventoryHistoryId}"];
    api.pathModelProperties = ["InventoryId", "InventoryHistoryId"];
    api.cacheName = "inventoryCache";
    api.parentApi = "Inventory";
    api.useStandardEndpoints(`/${m.RouteSegment.Inventory}/{inventoryId}/${m.RouteSegment.History}`);
    return api;
  };

  public static InventoryLocation(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("InventoryLocation");
    api.modules = ["inventory"];
    api.version = version;
    api.documentation.objectDescription = "Inventory Location";
    api.documentation.objectPrimaryKey = "InventoryLocationId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "LocationContactName"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.InventoryLocation;
    api.documentation.requestAndResponseDataModelObject = new m5.InventoryLocationEditViewModel();
    api.documentation.documentationUrlBase = "/inventory-location/";
    api.documentation.securityAccessArea = Constants.AccessArea.InventoryLocation;
    api.pathVariables = ["{inventoryId}", "{inventoryLocationId}"];
    api.pathModelProperties = ["InventoryId", "InventoryLocationId"];
    api.cacheName = "inventoryCache";
    api.parentApi = "Inventory";
    api.useStandardEndpoints(`/${m.RouteSegment.Inventory}/{inventoryId}/${m.RouteSegment.Locations}`);
    return api;
  };

  public static InventoryEvent(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("InventoryEvent");
    api.modules = ["inventory"];
    api.version = version;
    api.documentation.objectDescription = "Inventory Event";
    api.documentation.objectPrimaryKey = "InventoryEventId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalInventoryEventId", "TrackingNumber"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.InventoryEvent;
    api.documentation.requestAndResponseDataModelObject = new m5.InventoryEventEditViewModel();
    api.documentation.documentationUrlBase = "/inventory-event/";
    api.documentation.securityAccessArea = Constants.AccessArea.InventoryEvent;
    api.pathVariables = "{inventoryEventId}";
    api.pathModelProperties = "InventoryEventId";
    api.cacheName = null;
    api.useStandardEndpoints(`/${m.RouteSegment.InventoryEvents}`);
    return api;
  };

  public static InventoryEventBulkAdd(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("InventoryEventBulkAdd");
    api.modules = ["inventory"];
    api.version = version;
    api.documentation.objectDescription = "Inventory Event Bulk Add";
    api.documentation.objectPrimaryKey = "InventoryEventId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.InventoryEvent;
    api.documentation.requestAndResponseDataModelObject = [new m5.InventoryEventEditViewModel(), new m5.InventoryEventEditViewModel()];
    api.documentation.documentationUrlBase = "/inventory-event/bulk-add/";
    api.documentation.securityAccessArea = Constants.AccessArea.InventoryEvent;
    api.pathVariables = "{inventoryEventId}";
    api.pathModelProperties = "InventoryEventId";
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.InventoryEvents}/${m.RouteSegment.BulkAdd}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Inventory Event Bulk Add";
    api.endpoints.slice(-1)[0].documentation.menuText = "Inventory Event Bulk Add";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API is utilized to bulk add a collection of inventory event objects.`;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = true;
    return api;
  };


  //#endregion


  //#region Associations


  public static Association(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Association");
    api.version = version;
    api.documentation.objectDescription = "Association";
    api.documentation.objectPrimaryKey = "AssociationId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalAssociationId", "AssociationValue"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Association;
    api.documentation.requestAndResponseDataModelObject = new m5.AssociationEditViewModel();
    api.documentation.documentationUrlBase = "/association/";
    api.documentation.securityAccessArea = Constants.AccessArea.Association;
    api.pathVariables = "{associationId}";
    api.pathModelProperties = "AssociationId";
    api.cacheName = null;
    api.useStandardEndpoints(`/${m.RouteSegment.Associations}`, new m5.AssociationListViewModel(), Constants.DataModelName.AssociationList, false);
    return api;
  };


  public static AuthorizationCode(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AuthorizationCode");
    api.version = version;
    api.documentation.objectDescription = "Authorization Code";
    api.documentation.objectPrimaryKey = "AssociationId";
    api.documentation.objectDescriptionPropertyNames = ["AuthorizationCode", "Description", "ExternalAssociationId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.AuthorizationCode;
    api.documentation.requestAndResponseDataModelObject = new m5.AuthorizationCodeEditViewModel();
    api.documentation.documentationUrlBase = "/authorization-code/";
    api.documentation.securityAccessArea = Constants.AccessArea.Association;
    api.pathVariables = "{associationId}";
    api.pathModelProperties = "AssociationId";
    api.cacheName = null;
    api.useStandardEndpoints(`/${m.RouteSegment.AuthorizationCodes}`, new m5.AuthorizationCodeListViewModel(), Constants.DataModelName.AuthorizationCodeList, false);
    return api;
  };


  public static AuthorizationCodeBulkAdd(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AuthorizationCodeBulkAdd");
    api.version = version;
    api.documentation.objectDescription = "Authorization Code Bulk Add";
    api.documentation.objectPrimaryKey = "AssociationId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.AuthorizationCode;
    api.documentation.requestAndResponseDataModelObject = [new m5.AuthorizationCodeEditViewModel(), new m5.AuthorizationCodeEditViewModel()];
    api.documentation.documentationUrlBase = "/authorization-code/bulk-add/";
    api.documentation.securityAccessArea = Constants.AccessArea.Association;
    api.pathVariables = "{associationId}";
    api.pathModelProperties = "AssociationId";
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.AuthorizationCodes}/${m.RouteSegment.BulkAdd}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Authorization Code Bulk Add";
    api.endpoints.slice(-1)[0].documentation.menuText = "Authorization Code Bulk Add";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API is utilized to bulk add a collection of authorization code objects.`;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = true;
    //api.endpoints.slice(-1)[0].documentation.responseDataModelObject = new m5.AuthorizationCodeEditViewModel();
    //api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = Constants.DataModelName.AuthorizationCode;
    return api;
  };


  public static AuthorizationCodeGroup(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AuthorizationCodeGroup");
    api.version = version;
    api.documentation.objectDescription = "Authorization Code Group";
    api.documentation.objectPrimaryKey = "AssociationId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.AuthorizationCodeList;
    api.documentation.requestAndResponseDataModelObject = new m5.AuthorizationCodeListViewModel();
    api.documentation.documentationUrlBase = "/authorization-code/group/";
    api.documentation.securityAccessArea = Constants.AccessArea.Association;
    api.pathVariables = "{authorizationCodeGroup}";
    api.pathModelProperties = "AuthorizationCodeGroup";
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.AuthorizationCodes}/${m.RouteSegment.Groups}/{authorizationCodeGroup}`, ApiOperationType.List));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Authorization Code Group";
    api.endpoints.slice(-1)[0].documentation.menuText = "Authorization Code Group";
    api.endpoints.slice(-1)[0].documentation.overviewText =
      `This API is utilized to get information about all authorization codes that are valid for the specified authorization code group.`;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = true;
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = new m5.AuthorizationCodeListViewModel();
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = Constants.DataModelName.AuthorizationCodeList;
    return api;
  };


  public static AuthorizationCodeCheck(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AuthorizationCodeCheck");
    api.version = version;
    api.documentation.objectDescription = "AuthorizationCodeCheck";
    api.documentation.objectPrimaryKey = "AuthorizationCode";
    api.documentation.documentationUrlBase = "/authorization-code/check/";
    api.documentation.securityAccessArea = Constants.AccessArea.Association;
    api.pathVariables = ["{authorizationCode}", "{gracePeriodSeconds}"];
    api.pathModelProperties = ["AuthorizationCode", "GracePeriodSeconds"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.AuthorizationCodes}/${m.RouteSegment.Actions}/${m.RouteSegment.Check}/{authorizationCode}?gracePeriodSeconds={gracePeriodSeconds}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Authorization Code Check";
    api.endpoints.slice(-1)[0].documentation.menuText = "Authorization Code Check";
    api.endpoints.slice(-1)[0].documentation.overviewText =
      `
This API is utilized to check if an authorization code is valid.  When checking if an authorization code is valid
there is an optional query string parameter specifying possible grace period seconds which can be used to extend
the time range within which an authorization code is considered to be valid.
<br/><br/>
Examples:
<br/><br/>
https://api...com/authorization-codes/actions/check/G7C2BA83EE
<br/><br/>
https://api...com/authorization-codes/actions/check/G7C2BA83EE?gracePeriodSeconds=60
<br/><br/>
`;
    //api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = true;
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = new m5.AuthorizationCodeEditViewModel();
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = Constants.DataModelName.AuthorizationCode;
    return api;
  };

  //#endregion


  //#region Cache

  public static CachePackageSignup(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("CachePackageSignup");
    api.version = version;
    api.documentation.objectName = "CachePackageSignup";
    api.documentation.objectDescription = "Cache Package Signup";
    api.documentation.objectPrimaryKey = "PackageOccurrenceId";
    api.documentation.objectDescriptionPropertyNames = ["ProductName", "PackageCode", "PackageType"];
    api.documentation.documentationUrlBase = "/cache-package-signup/";
    api.documentation.responseDataModelObject = new m5.CachePackageSignupEditViewModel();
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.CachePackageSignup;
    api.documentation.securityAccessArea = Constants.AccessArea.CachePackageSignup;
    api.pathVariables = ["{packageOccurrenceId}"];
    api.pathModelProperties = ["PackageOccurrenceId"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Cache}/${m.RouteSegment.PackageSignups}?page={page}&size={size}&sort={sort}&filterId={filterId}&filter={filter}&q={q}&expand={expand}`, ApiOperationType.List));
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Cache}/${m.RouteSegment.PackageSignups}/{packageOccurrenceId}`, ApiOperationType.Get));
    return api;
  };

  //#endregion


  //#region Activity

  public static Activity(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Activity");
    api.version = version;
    api.documentation.objectDescription = "Activity";
    api.documentation.objectPrimaryKey = "";
    api.documentation.documentationUrlBase = "/activity/";
    api.documentation.securityAccessArea = Constants.AccessArea.SR;
    api.pathVariables = [];
    api.pathModelProperties = [];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Activity}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Add Activity";
    api.endpoints.slice(-1)[0].documentation.menuText = "Add Activity";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API is utilized to add activity usage records.";
    api.endpoints.slice(-1)[0].documentation.requestDataModelObject = new m5.ActivityEditViewModel();
    api.endpoints.slice(-1)[0].documentation.requestDataModelDocumentationName = Constants.DataModelName.Activity;
    api.endpoints.slice(-1)[0].documentation.responseDataModelIsNull = true;
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = "";
    return api;
  };

  //#endregion


  //#region Voucher

  public static VoucherBatch(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("VoucherBatch");
    api.version = version;
    api.documentation.objectDescription = "Voucher Batch";
    api.documentation.objectPrimaryKey = "VoucherBatchId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalVoucherBatchId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.VoucherBatch;
    api.documentation.requestAndResponseDataModelObject = new m5.VoucherBatchEditViewModel();
    api.documentation.documentationUrlBase = "/voucher-batch/";
    api.documentation.securityAccessArea = Constants.AccessArea.VoucherBatch;
    api.pathVariables = "{voucherBatchId}";
    api.pathModelProperties = "VoucherBatchId";
    api.cacheName = "voucherCache";
    api.impactedPickListIds = [Constants.PickList._ProcessTemplate];
    api.useStandardEndpoints(`/${m.RouteSegment.Vouchers}/${m.RouteSegment.Batches}`);
    return api;
  };

  public static VoucherBatchRefreshStatsAll(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("VoucherBatchRefreshStatsAll");
    api.version = version;
    api.documentation.objectDescription = "Voucher Batch Refresh All Stats";
    api.documentation.objectPrimaryKey = null;
    api.documentation.objectDescriptionPropertyNames = [];
    api.documentation.requestAndResponseDataModelDocumentationName = null;
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/voucher-batch/refresh-stats-all/";
    api.documentation.securityAccessArea = Constants.AccessArea.VoucherBatch;
    api.pathVariables = [];
    api.pathModelProperties = [];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Vouchers}/${m.RouteSegment.Batches}/${m.RouteSegment.RefreshStats}`, ApiOperationType.Edit));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Voucher Batch Refresh All Stats";
    api.endpoints.slice(-1)[0].documentation.menuText = "Voucher Batch Refresh All Stats";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API endpoint is utilized to refresh stats for all voucher batches.`;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = false;
    return api;
  };

  public static VoucherBatchRefreshStats(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("VoucherBatchRefreshStats");
    api.version = version;
    api.documentation.objectDescription = "Voucher Batch Refresh Stats";
    api.documentation.objectPrimaryKey = "VoucherBatchId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalVoucherBatchId"];
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.VoucherBatch;
    api.documentation.responseDataModelObject = new m5.VoucherBatchEditViewModel();
    api.documentation.documentationUrlBase = "/voucher-batch/refresh-stats/";
    api.documentation.securityAccessArea = Constants.AccessArea.VoucherBatch;
    api.pathVariables = ["{voucherBatchId}"];
    api.pathModelProperties = ["VoucherBatchId"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Vouchers}/${m.RouteSegment.Batches}/{voucherBatchId}/${m.RouteSegment.RefreshStats}`, ApiOperationType.Edit));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Voucher Batch Refresh Stats";
    api.endpoints.slice(-1)[0].documentation.menuText = "Voucher Batch Refresh Stats";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API endpoint is utilized to refresh stats for the specified voucher batch.`;
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = true;
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = new m5.VoucherBatchEditViewModel();
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = Constants.DataModelName.VoucherBatch;
    return api;
  };

  public static VoucherBatchSuspend(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("VoucherBatchSuspend");
    api.version = version;
    api.documentation.objectDescription = "Voucher Batch Suspend";
    api.documentation.objectPrimaryKey = "VoucherBatchId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalVoucherBatchId"];
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.VoucherBatch;
    api.documentation.responseDataModelObject = new m5.VoucherBatchEditViewModel();
    api.documentation.documentationUrlBase = "/voucher-batch/suspend/";
    api.documentation.securityAccessArea = Constants.AccessArea.VoucherBatch;
    api.pathVariables = ["{voucherBatchId}"];
    api.pathModelProperties = ["VoucherBatchId"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Vouchers}/${m.RouteSegment.Batches}/{voucherBatchId}/${m.RouteSegment.Suspend}`, ApiOperationType.Edit));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Voucher Batch Suspend";
    api.endpoints.slice(-1)[0].documentation.menuText = "Voucher Batch Suspend";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API endpoint is utilized to suspend all vouchers for the specified voucher batch.`;
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = true;
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = new m5.VoucherBatchEditViewModel();
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = Constants.DataModelName.VoucherBatch;
    return api;
  };

  public static VoucherBatchAvailableVoucherCount(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("VoucherBatchAvailableVoucherCount");
    api.version = version;
    api.documentation.objectDescription = "Voucher Batch Available Voucher Count";
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.Number;
    api.documentation.responseDataModelObject = 0;
    api.documentation.documentationUrlBase = "/voucher-batch/available-count/";
    api.documentation.securityAccessArea = Constants.AccessArea.VoucherBatch;
    api.pathVariables = ["{voucherBatchId}"];
    api.pathModelProperties = ["VoucherBatchId"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Vouchers}/${m.RouteSegment.Batches}/{voucherBatchId}/${m.RouteSegment.Available}/${m.RouteSegment.Count}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Voucher Batch Available Voucher Count";
    api.endpoints.slice(-1)[0].documentation.menuText = "Voucher Batch Available Voucher Count";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API endpoint is utilized to get the available voucher count for the specified voucher batch.  The data property is the count.`;
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = false;
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = 0;
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = Constants.DataModelName.Number;
    return api;
  };

  public static VoucherBatchNextSerialNumber(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("VoucherBatchNextSerialNumber");
    api.version = version;
    api.documentation.objectDescription = "Voucher Batch Next Serial Number";
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.Number;
    api.documentation.responseDataModelObject = 0;
    api.documentation.documentationUrlBase = "/voucher-batch/next-serial-number/";
    api.documentation.securityAccessArea = Constants.AccessArea.VoucherBatch;
    api.pathVariables = null;
    api.pathModelProperties = null;
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Vouchers}/${m.RouteSegment.Batches}/${m.RouteSegment.NextSerialNumber}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Voucher Batch Next Serial Number";
    api.endpoints.slice(-1)[0].documentation.menuText = "Voucher Batch Next Serial Number";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API endpoint is utilized to get the next serial number for the specified voucher batch.  The data property is the next serial number.`;
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = false;
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = 0;
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = Constants.DataModelName.Number;
    return api;
  };

  public static VoucherBatchVisibility(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("VoucherBatchVisibility");
    api.version = version;
    api.documentation.objectDescription = "Voucher Batch Visibility";
    api.documentation.objectPrimaryKey = "VisibilityId";
    api.documentation.objectDescriptionPropertyNames = ["VisibilityType", "VisibilityTypeId2", "VisibilityTypeId2EndRange"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.VoucherBatchVisibility;
    api.documentation.requestAndResponseDataModelObject = new m5.VoucherBatchVisibilityEditViewModel();
    api.documentation.documentationUrlBase = "/voucher-batch/visibility/";
    api.documentation.securityAccessArea = Constants.AccessArea.Visibility;
    api.pathVariables = ["{voucherBatchId}", "{visibilityId}"];
    api.pathModelProperties = ["VoucherBatchId", "VisibilityId"];
    api.cacheName = "voucherCache";
    api.parentApi = "VoucherBatch";
    api.useStandardEndpoints(`/${m.RouteSegment.Vouchers}/${m.RouteSegment.Batches}/{voucherBatchId}/${m.RouteSegment.Visibility}`);
    return api;
  };

  public static VoucherBatchRestrictionRule(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("VoucherBatchRestrictionRule");
    api.version = version;
    api.documentation.objectDescription = "Voucher Batch Restriction Rule";
    api.documentation.objectPrimaryKey = "RestrictionRuleId";
    api.documentation.objectDescriptionPropertyNames = ["RestrictionRuleTemplate"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.VoucherBatchRestrictionRules;
    api.documentation.requestAndResponseDataModelObject = new m5.VoucherBatchRestrictionRuleEditViewModel();
    api.documentation.documentationUrlBase = "/voucher-batch/restriction-rules/";
    api.documentation.securityAccessArea = Constants.AccessArea.RestrictionRule;
    api.pathVariables = ["{voucherBatchId}", "{restrictionRuleId}"];
    api.pathModelProperties = ["VoucherBatchId", "RestrictionRuleId"];
    api.cacheName = "voucherCache";
    api.parentApi = "VoucherBatch";
    api.useStandardEndpoints(`/${m.RouteSegment.Vouchers}/${m.RouteSegment.Batches}/{voucherBatchId}/${m.RouteSegment.RestrictionRules}`);
    return api;
  };

  public static VoucherLot(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("VoucherLot");
    api.version = version;
    api.documentation.objectDescription = "Voucher Lot";
    api.documentation.objectPrimaryKey = "VoucherLotId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalVoucherLotId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.VoucherLot;
    api.documentation.requestAndResponseDataModelObject = new m5.VoucherLotEditViewModel();
    api.documentation.documentationUrlBase = "/voucher-batch/lot/";
    api.documentation.securityAccessArea = Constants.AccessArea.VoucherLot;
    api.pathVariables = ["{voucherBatchId}", "{voucherLotId}"];
    api.pathModelProperties = ["VoucherBatchId", "VoucherLotId"];
    api.cacheName = "voucherCache";
    api.parentApi = "VoucherBatch";
    api.useStandardEndpoints(`/${m.RouteSegment.Vouchers}/${m.RouteSegment.Batches}/{voucherBatchId}/${m.RouteSegment.Lots}`);
    return api;
  };

  public static VoucherLotActivate(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("VoucherLotActivate");
    api.version = version;
    api.documentation.objectDescription = "Voucher Lot Activate";
    api.documentation.objectPrimaryKey = "VoucherLotId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalVoucherLotId"];
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.VoucherLot;
    api.documentation.responseDataModelObject = new m5.VoucherLotEditViewModel();
    api.documentation.documentationUrlBase = "/voucher-batch/lot/activate/";
    api.documentation.securityAccessArea = Constants.AccessArea.VoucherLot;
    api.pathVariables = ["{voucherBatchId}", "{voucherLotId}"];
    api.pathModelProperties = ["VoucherBatchId", "VoucherLotId"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Vouchers}/${m.RouteSegment.Batches}/{voucherBatchId}/${m.RouteSegment.Lots}/{voucherLotId}/${m.RouteSegment.Activate}`, ApiOperationType.Edit));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Voucher Lot Activate";
    api.endpoints.slice(-1)[0].documentation.menuText = "Voucher Lot Activate";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API endpoint is utilized to activate the specified voucher lot.  This process will also create vouchers for the lot.`;
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = true;
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = new m5.VoucherLotEditViewModel();
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = Constants.DataModelName.VoucherLot;
    return api;
  };

  public static VoucherLotSuspend(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("VoucherLotSuspend");
    api.version = version;
    api.documentation.objectDescription = "Voucher Lot Suspend";
    api.documentation.objectPrimaryKey = "VoucherLotId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalVoucherLotId"];
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.VoucherLot;
    api.documentation.responseDataModelObject = new m5.VoucherLotEditViewModel();
    api.documentation.documentationUrlBase = "/voucher-batch/lot/suspend/";
    api.documentation.securityAccessArea = Constants.AccessArea.VoucherLot;
    api.pathVariables = ["{voucherBatchId}", "{voucherLotId}"];
    api.pathModelProperties = ["VoucherBatchId", "VoucherLotId"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Vouchers}/${m.RouteSegment.Batches}/{voucherBatchId}/${m.RouteSegment.Lots}/{voucherLotId}/${m.RouteSegment.Suspend}`, ApiOperationType.Edit));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Voucher Lot Suspend";
    api.endpoints.slice(-1)[0].documentation.menuText = "Voucher Lot Suspend";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API endpoint is utilized to suspend the specified voucher lot.`;
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = true;
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = new m5.VoucherLotEditViewModel();
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = Constants.DataModelName.VoucherLot;
    return api;
  };

  public static Voucher(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Voucher");
    api.version = version;
    api.documentation.objectDescription = "Vouchers";
    api.documentation.objectPrimaryKey = "VoucherId";
    api.documentation.objectDescriptionPropertyNames = ["VoucherCode", "VoucherSerialNumber"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Voucher;
    api.documentation.requestAndResponseDataModelObject = new m5.VoucherEditViewModel();
    api.documentation.documentationUrlBase = "/voucher-batch/lot/voucher/";
    api.documentation.securityAccessArea = Constants.AccessArea.Voucher;
    api.pathVariables = ["{voucherBatchId}", "{voucherLotId}", "{voucherId}"];
    api.pathModelProperties = ["VoucherBatchId", "VoucherLotId", "VoucherId"];
    api.cacheName = "voucherCache";
    api.parentApi = "VoucherLot";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Vouchers}/${m.RouteSegment.Batches}/{voucherBatchId}/${m.RouteSegment.Lots}/{voucherLotId}/${m.RouteSegment.Vouchers}?page={page}&size={size}&sort={sort}&filterId={filterId}&filter={filter}&q={q}&expand={expand}`, ApiOperationType.List));
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Vouchers}/${m.RouteSegment.Batches}/{voucherBatchId}/${m.RouteSegment.Lots}/{voucherLotId}/${m.RouteSegment.Vouchers}/{voucherId}`, ApiOperationType.Get));
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Vouchers}/${m.RouteSegment.Batches}/{voucherBatchId}/${m.RouteSegment.Lots}/{voucherLotId}/${m.RouteSegment.Vouchers}/${m.RouteSegment.Export}?sort={sort}&filterId={filterId}&filter={filter}&q={q}&expand={expand}`, ApiOperationType.Export));
    // Read-only so no add, edit, delete, etc. endpoints here
    return api;
  };

  public static VoucherAddFromList(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("VoucherAddFromList");
    api.version = version;
    api.documentation.objectDescription = "Voucher Add From List";
    api.documentation.objectPrimaryKey = null;
    api.documentation.objectDescriptionPropertyNames = [];
    api.documentation.requestDataModelDocumentationName = Constants.DataModelName.VoucherAddFromListAddViewModel;
    api.documentation.requestDataModelObject = new m5.VoucherAddFromListAddViewModel();
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.None;
    api.documentation.responseDataModelObject = null;
    api.documentation.documentationUrlBase = "/voucher-batch/lot/voucher/add-from-list/";
    api.documentation.securityAccessArea = Constants.AccessArea.Voucher;
    api.pathVariables = ["{voucherBatchId}", "{voucherLotId}"];
    api.pathModelProperties = ["VoucherBatchId", "VoucherLotId"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Vouchers}/${m.RouteSegment.Batches}/{voucherBatchId}/${m.RouteSegment.Lots}/{voucherLotId}/${m.RouteSegment.Vouchers}/${m.RouteSegment.AddFromList}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Add List of Vouchers";
    api.endpoints.slice(-1)[0].documentation.menuText = "Add List of Vouchers";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API endpoint is used to add a list of vouchers to voucher batches configured to allow manually adding vouchers.`;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = true;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = false;
    api.endpoints.slice(-1)[0].documentation.requestDataModelObject = new m5.VoucherAddFromListAddViewModel();
    api.endpoints.slice(-1)[0].documentation.requestDataModelDocumentationName = Constants.DataModelName.VoucherAddFromListAddViewModel;
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = null;
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = Constants.DataModelName.None;
    return api;
  };

  public static VoucherReserve(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("VoucherReserve");
    api.version = version;
    api.documentation.objectDescription = "Voucher";
    api.documentation.objectPrimaryKey = "VoucherId";
    api.documentation.objectDescriptionPropertyNames = ["VoucherCode", "VoucherSerialNumber"];
    api.documentation.requestDataModelDocumentationName = Constants.DataModelName.None;
    api.documentation.requestDataModelObject = null;
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.Voucher;
    api.documentation.responseDataModelObject = new m5.VoucherEditViewModel();
    api.documentation.documentationUrlBase = "/voucher-batch/lot/voucher/reserve/";
    api.documentation.securityAccessArea = Constants.AccessArea.Voucher;
    api.pathVariables = ["{voucherBatchId}", "{voucherLotId}"];
    api.pathModelProperties = ["VoucherBatchId", "VoucherLotId"];
    api.cacheName = "voucherCache";
    api.parentApi = "VoucherLot";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Vouchers}/${m.RouteSegment.Batches}/{voucherBatchId}/${m.RouteSegment.Lots}/{voucherLotId}/${m.RouteSegment.Vouchers}/${m.RouteSegment.Reserve}`, ApiOperationType.Edit));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Voucher Reserve";
    api.endpoints.slice(-1)[0].documentation.menuText = "Voucher Reserve";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API endpoint is used to reserve a voucher in the specified batch and lot.  To reserve a voucher from any lot in the batch use 0 for the lot id.`;
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = true;
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = new m5.VoucherEditViewModel();
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = Constants.DataModelName.Voucher;
    return api;
  };

  public static VoucherSuspend(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("VoucherSuspend");
    api.version = version;
    api.documentation.objectDescription = "Voucher";
    api.documentation.objectPrimaryKey = "VoucherId";
    api.documentation.objectDescriptionPropertyNames = ["VoucherCode", "VoucherSerialNumber"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.None;
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/voucher-batch/lot/voucher/suspend/";
    api.documentation.securityAccessArea = Constants.AccessArea.Voucher;
    api.pathVariables = ["{voucherBatchId}", "{voucherLotId}", "{voucherId}", "{startingSerialNumber}", "{endingSerialNumber}", "{voucherCode}"];
    api.pathModelProperties = ["VoucherBatchId", "VoucherLotId", "VoucherId", "StartingSerialNumber", "EndingSerialNumber", "VoucherCode"];
    api.cacheName = "voucherCache";
    api.parentApi = "VoucherLot";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Vouchers}/${m.RouteSegment.Batches}/{voucherBatchId}/${m.RouteSegment.Lots}/{voucherLotId}/${m.RouteSegment.Vouchers}/${m.RouteSegment.Suspend}?voucherId={voucherId}&startingSerialNumber={startingSerialNumber}&endingSerialNumber={endingSerialNumber}&voucherCode={voucherCode}`, ApiOperationType.Edit));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Voucher Suspend";
    api.endpoints.slice(-1)[0].documentation.menuText = "Voucher Suspend";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API endpoint is used to suspend one or more vouchers based on the query string properties provided.`;
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = false;
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = null;
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = Constants.DataModelName.None;
    return api;
  };

  public static VoucherReset(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("VoucherReset");
    api.version = version;
    api.documentation.objectDescription = "Voucher";
    api.documentation.objectPrimaryKey = "VoucherId";
    api.documentation.objectDescriptionPropertyNames = ["VoucherCode", "VoucherSerialNumber"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.None;
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/voucher-batch/lot/voucher/reset/";
    api.documentation.securityAccessArea = Constants.AccessArea.Voucher;
    api.pathVariables = ["{voucherBatchId}", "{voucherLotId}", "{voucherId}", "{startingSerialNumber}", "{endingSerialNumber}", "{voucherCode}"];
    api.pathModelProperties = ["VoucherBatchId", "VoucherLotId", "VoucherId", "StartingSerialNumber", "EndingSerialNumber", "VoucherCode"];
    api.cacheName = "voucherCache";
    api.parentApi = "VoucherLot";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Vouchers}/${m.RouteSegment.Batches}/{voucherBatchId}/${m.RouteSegment.Lots}/{voucherLotId}/${m.RouteSegment.Vouchers}/${m.RouteSegment.Reset}?voucherId={voucherId}&startingSerialNumber={startingSerialNumber}&endingSerialNumber={endingSerialNumber}&voucherCode={voucherCode}`, ApiOperationType.Edit));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Voucher Reset";
    api.endpoints.slice(-1)[0].documentation.menuText = "Voucher Reset";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API endpoint is used to reset one or more suspended vouchers based on the query string properties provided.`;
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = false;
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = null;
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = Constants.DataModelName.None;
    return api;
  };

  public static VoucherFind(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("VoucherFind");
    api.version = version;
    api.documentation.objectDescription = "Voucher Find";
    api.documentation.objectPrimaryKey = "VoucherCode";
    api.documentation.documentationUrlBase = "/voucher/find/";
    api.documentation.securityAccessArea = Constants.AccessArea.Voucher;
    api.pathVariables = ["{voucherCode}"];
    api.pathModelProperties = ["VoucherCode"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Vouchers}/${m.RouteSegment.Actions}/${m.RouteSegment.Find}/{voucherCode}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Voucher Find";
    api.endpoints.slice(-1)[0].documentation.menuText = "Voucher Find";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API endpoint is used to find the voucher for the specified voucher code.`;
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = true;
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = new m5.VoucherEditViewModel();
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = Constants.DataModelName.Voucher;
    return api;
  };

  public static VoucherCheck(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("VoucherCheck");
    api.version = version;
    api.documentation.objectDescription = "Voucher Check";
    api.documentation.objectPrimaryKey = "VoucherCode";
    api.documentation.documentationUrlBase = "/voucher/check/";
    api.documentation.securityAccessArea = Constants.AccessArea.Voucher;
    api.pathVariables = ["{voucherCode}", "{isoCurrencyCode}", "{amount}"];
    api.pathModelProperties = ["VoucherCode", "IsoCurrencyCode", "Amount"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Vouchers}/${m.RouteSegment.Actions}/${m.RouteSegment.Check}/{voucherCode}?isoCurrencyCode={isoCurrencyCode}&amount={amount}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Voucher Check";
    api.endpoints.slice(-1)[0].documentation.menuText = "Voucher Check";
    api.endpoints.slice(-1)[0].documentation.overviewText = `
This API is utilized to check if a voucher is valid for the specified scenario (i.e. currency, amount, and any optional visibility constraints).
<br/><br/>
Vouchers can be configured with visibilities rules that restrict the scenario under which a voucher can be used.
Voucher visibility rules that should be checked are specified as query string parameters after the iso currency code
and amount query string parameters.
<br/><br/>
For example: https://api....com/vouchers/actions/check/qk83u2m3?isoCurrencyCode=USD&amount=7.99&state=MI&marketType=Commercial
`;
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = true;
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = new m5.VoucherCheckResponseModel();
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = Constants.DataModelName.VoucherCheck;
    api.endpoints.slice(-1)[0].documentation.resultCodesTemplateUrl = "ResultCodeVoucher";
    //api.endpoints.slice(-1)[0].documentation.resultCodesText =
    //  "<h4>Possible Result Codes</h4>" +
    //  "When the response ResultCode is SubsystemSpecificError (100) the voucher response may have one of the following values for the ResponseCode: " +
    //  "<br/><br/>" +
    //  "<ib-api-docs-voucher-check-result-codes></ib-api-docs-voucher-check-result-codes>" +
    //  "Under other scenarios these result codes may be found in the response ResultCode: " +
    //  "<br/><br/>" +
    //  "<ib-api-docs-standard-result-codes></ib-api-docs-standard-result-codes>"
    api.endpoints.slice(-1)[0].documentation.testFormAllowFreeFormQueryString = true;
    api.endpoints.slice(-1)[0].documentation.testFormAllowFreeFormQueryStringTooltip = "Voucher visibility rules that should be checked are specified as query string parameters.  For example: state=MI&marketType=Commercial";
    return api;
  };

  //#endregion


  //#region Sync APIs


  public static SyncDataStore(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("SyncDataStore");
    api.version = version;
    api.documentation.objectDescription = "Sync Data Store";
    api.documentation.objectPrimaryKey = "SyncDataStoreId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.SyncDataStore;
    api.documentation.requestAndResponseDataModelObject = new m5.SyncDataStoreEditViewModel();
    api.documentation.documentationUrlBase = "/sync/data-store/";
    api.documentation.securityAccessArea = Constants.AccessArea.SyncDataStore;
    api.documentation.readOnly = true;
    api.pathVariables = "{syncDataStoreId}";
    api.pathModelProperties = "SyncDataStoreId";
    api.cacheName = "syncCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Sync}/${m.RouteSegment.DataStores}`);
    return api;
  };

  public static SyncPublisherArticleType(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("SyncPublisherArticleType");
    api.version = version;
    api.documentation.objectDescription = "Sync Publisher Article Type";
    api.documentation.objectPrimaryKey = "SyncPublisherArticleTypeId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.SyncPublisherArticleType;
    api.documentation.requestAndResponseDataModelObject = new m5.SyncPublisherArticleTypeEditViewModel();
    api.documentation.documentationUrlBase = "/sync/publisher-article-type/";
    api.documentation.securityAccessArea = Constants.AccessArea.SyncPublisherArticleType;
    api.documentation.readOnly = true;
    api.pathVariables = ["{syncDataStoreId}", "{syncPublisherArticleTypeId}"];
    api.pathModelProperties = ["SyncDataStoreId", "SyncPublisherArticleTypeId"];
    api.cacheName = "syncCache";
    api.parentApi = "SyncDataStore";
    api.useStandardEndpoints(`/${m.RouteSegment.Sync}/${m.RouteSegment.DataStores}/{syncDataStoreId}/${m.RouteSegment.PublisherArticleTypes}`);
    return api;
  };

  public static SyncSubscription(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("SyncSubscription");
    api.version = version;
    api.documentation.objectDescription = "Sync Subscription";
    api.documentation.objectPrimaryKey = "SyncSubscriptionId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.SyncSubscription;
    api.documentation.requestAndResponseDataModelObject = new m5.SyncSubscriptionEditViewModel();
    api.documentation.documentationUrlBase = "/sync/subscription/";
    api.documentation.securityAccessArea = Constants.AccessArea.SyncSubscription;
    api.documentation.readOnly = true;
    api.pathVariables = ["{syncPublisherArticleTypeId}", "{syncSubscriptionId}"];
    api.pathModelProperties = ["SyncPublisherArticleTypeId", "SyncSubscriptionId"];
    api.cacheName = "syncCache";
    api.parentApi = "SyncPublisherArticleType";
    api.useStandardEndpoints(`/${m.RouteSegment.Sync}/${m.RouteSegment.PublisherArticleTypes}/{syncPublisherArticleTypeId}/${m.RouteSegment.Subscriptions}`);
    return api;
  };

  public static SyncArticle(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("SyncArticle");
    api.version = version;
    api.documentation.objectDescription = "Sync Article";
    api.documentation.objectPrimaryKey = "SyncArticleId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.SyncArticle;
    api.documentation.requestAndResponseDataModelObject = new m5.SyncArticleEditViewModel();
    api.documentation.documentationUrlBase = "/sync/article/";
    api.documentation.securityAccessArea = Constants.AccessArea.SyncArticle;
    api.documentation.readOnly = true;
    api.pathVariables = "{syncArticleId}";
    api.pathModelProperties = "SyncArticleId";
    api.cacheName = "syncCache";
    api.endpoints.push(new ApiEndpoint("/sync/articles?page={page}&size={size}&sort={sort}&filterId={filterId}&filter={filter}&q={q}&expand={expand}", ApiOperationType.List));
    api.endpoints.push(new ApiEndpoint("/sync/articles/{syncArticleId}", ApiOperationType.Get));
    //api.endpoints.push(new ApiEndpoint("/sync/articles", ApiOperationType.Add));
    //api.endpoints.push(new ApiEndpoint("/sync/articles/{syncArticleId}", ApiOperationType.Edit));
    //api.endpoints.push(new ApiEndpoint("/sync/articles/{syncArticleId}", ApiOperationType.Delete));
    return api;
  };

  //#endregion






  public static WalletSecurityAuthenticate(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("WalletSecurityAuthenticate");
    api.version = version;
    api.documentation.objectDescription = "Authenticate";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.WalletSecurityAuthenticate;
    api.documentation.requestAndResponseDataModelObject = new wm5.AuthenticatedUserViewModel();
    api.documentation.documentationUrlBase = "/wallet/security/authenticate/";
    api.brands = ["Wallet"];
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Security}/${m.RouteSegment.Authenticate}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Authenticate";
    api.endpoints.slice(-1)[0].documentation.menuText = "Authenticate";
    api.endpoints.slice(-1)[0].documentation.overviewText = `
Authentication is a required part of every API request by inclusion of an Authorization header or X-Auth-Key header.
Since each API request is authenticated, this authenticate API endpoint is not required.  It can be used to validate
authentication credentials or to get additional information as to why given authentication credentials
are not valid.
<br /><br />
Use the <a href='#/wallet/security/login'>login</a> API to retrieve the token to use in the Authorization header.
<br /><br />
There are no query string parameters on this get request.  The authorization information is carried in
the Authorization and/or X-Auth-Key headers.  For Example:
<br /><br />
Authorization = Bearer eyJ0eXAiOiJKV5QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzZWxmIiwiYXVkIjoiaHR0cDovL2liLmF
<br/>
X-Auth-Key = 4E7-DKQ7PJ8-3VHOZ9U
`;
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "lock";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Authenticate";
    return api;
  };

  //public static WalletRole(version: number = AppConfig.ApiVersion): ApiProperties {
  //    const api = new ApiProperties();
  //    api.version = version;
  //    api.documentation.objectDescription = "Role";
  //    api.documentation.objectPrimaryKey = "RoleId";
  //    api.documentation.objectDescriptionPropertyNames = ["Description"];
  //    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.WalletRole;
  //    api.documentation.requestAndResponseDataModelObject = new wm5.RoleEditViewModel();
  //    api.documentation.documentationUrlBase = "/wallet/role/";
  //    api.pathVariables = "{roleId}";
  //    api.pathModelProperties = "RoleId";
  //    api.cacheName = "systemCache";
  //    api.brands = ["Wallet"];
  //    api.useStandardEndpoints(`/${m.RouteSegment.Roles}`, null, null, true);
  //    return api;
  //};

  //public static WalletRoleDetail(version: number = AppConfig.ApiVersion): ApiProperties {
  //    const api = new ApiProperties();
  //    api.version = version;
  //    api.documentation.objectDescription = "Role Detail";
  //    api.documentation.objectPrimaryKey = "RoleDetailId";
  //    api.documentation.objectDescriptionPropertyNames = ["SecurityArea"];
  //    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.WalletRoleDetail;
  //    api.documentation.requestAndResponseDataModelObject = new wm5.RoleDetailEditViewModel();
  //    api.documentation.documentationUrlBase = "/wallet/role/detail/";
  //    api.pathVariables = ["{roleId}", "{roleDetailId}"];
  //    api.pathModelProperties = ["RoleId", "RoleDetailId"];
  //    api.cacheName = "staticObjectCache";
  //    api.parentApi = "WalletRole";
  //    api.brands = ["Wallet"];
  //    api.useStandardEndpoints(`/${m.RouteSegment.Roles}/{roleId}/${m.RouteSegment.Details}`, null, null, true);
  //    return api;
  //};

  //public static WalletApiAccess(version: number = AppConfig.ApiVersion): ApiProperties {
  //    const api = new ApiProperties();
  //    api.version = version;
  //    api.documentation.objectDescription = "Api Access";
  //    api.documentation.objectPrimaryKey = "ApiAccessId";
  //    api.documentation.objectDescriptionPropertyNames = ["Description", "ApiKey"];
  //    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.WalletApiAccess;
  //    api.documentation.requestAndResponseDataModelObject = new wm5.ApiAccessEditViewModel();
  //    api.documentation.documentationUrlBase = "/wallet/api-access/";
  //    api.pathVariables = "{apiAccessId}";
  //    api.pathModelProperties = "ApiAccessId";
  //    api.cacheName = "systemCache";
  //    api.brands = ["Wallet"];
  //    api.useStandardEndpoints(`/${m.RouteSegment.ApiAccess}`, null, null, true);
  //    return api;
  //};

  //public static WalletApiAccessClient(version: number = AppConfig.ApiVersion): ApiProperties {
  //    const api = new ApiProperties();
  //    api.version = version;
  //    api.documentation.objectDescription = "Api Access Client";
  //    api.documentation.objectPrimaryKey = "ApiAccessClientId";
  //    api.documentation.objectDescriptionPropertyNames = ["Description", "ClientIdentifier"];
  //    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.WalletApiAccessClient;
  //    api.documentation.requestAndResponseDataModelObject = new wm5.ApiAccessClientEditViewModel();
  //    api.documentation.documentationUrlBase = "/wallet/api-access/client/";
  //    api.pathVariables = ["{apiAccessId}", "{apiAccessClientId}"];
  //    api.pathModelProperties = ["ApiAccessId", "ApiAccessClientId"];
  //    api.cacheName = "staticObjectCache";
  //    api.parentApi = "WalletApiAccess";
  //    api.brands = ["Wallet"];
  //    api.useStandardEndpoints(`/${m.RouteSegment.ApiAccess}/{apiAccessId}/${m.RouteSegment.Clients}`, null, null, true);
  //    return api;
  //};

  //public static WalletContact(version: number = AppConfig.ApiVersion): ApiProperties {
  //    const api = new ApiProperties();
  //    api.version = version;
  //    api.documentation.objectDescription = "Contact";
  //    api.documentation.objectPrimaryKey = "ContactId";
  //    api.documentation.objectDescriptionPropertyNames = ["ContactName", "LastName", "FirstName", "OtherName", "Email"];
  //    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.WalletContact;
  //    api.documentation.requestAndResponseDataModelObject = new wm5.ContactEditViewModel();
  //    api.documentation.documentationUrlBase = "/wallet/contact/";
  //    api.pathVariables = "{contactId}";
  //    api.pathModelProperties = "ContactId";
  //    api.cacheName = "contactCache";
  //    api.brands = ["Wallet"];
  //    api.useStandardEndpoints(`/${m.RouteSegment.Contacts}`, null, null, true);
  //    return api;
  //};

  //public static WalletContactExternalAuthentication(version: number = AppConfig.ApiVersion): ApiProperties {
  //    const api = new ApiProperties();
  //    api.version = version;
  //    api.documentation.objectDescription = "Contact External Authentication";
  //    api.documentation.objectPrimaryKey = "ContactExternalAuthenticationId";
  //    api.documentation.objectDescriptionPropertyNames = ["Description", "ClientIdentifier"];
  //    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.WalletContactExternalAuthentication;
  //    api.documentation.requestAndResponseDataModelObject = new wm5.ContactExternalAuthenticationEditViewModel();
  //    api.documentation.documentationUrlBase = "/wallet/contact/external-authentication/";
  //    api.pathVariables = ["{contactId}", "{contactExternalAuthenticationId}"];
  //    api.pathModelProperties = ["ContactId", "ContactExternalAuthenticationId"];
  //    api.cacheName = "contactCache";
  //    api.parentApi = "WalletContact";
  //    api.brands = ["Wallet"];
  //    api.useStandardEndpoints(`/${m.RouteSegment.Contacts}/{contactId}/${m.RouteSegment.ExternalAuthentications}`, null, null, true);
  //    return api;
  //};

  //public static WalletContactAccess(version: number = AppConfig.ApiVersion): ApiProperties {
  //    const api = new ApiProperties();
  //    api.version = version;
  //    api.documentation.objectDescription = "Contact Access";
  //    api.documentation.objectPrimaryKey = "ContactAccessId";
  //    api.documentation.objectDescriptionPropertyNames = ["AccessIdentifier", "AccessLocation"];
  //    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.WalletContactAccess;
  //    api.documentation.requestAndResponseDataModelObject = new wm5.ContactAccessEditViewModel();
  //    api.documentation.documentationUrlBase = "/wallet/contact/access/";
  //    api.pathVariables = ["{contactId}", "{contactAccessId}"];
  //    api.pathModelProperties = ["ContactId", "ContactAccessId"];
  //    api.cacheName = "contactCache";
  //    api.parentApi = "WalletContact";
  //    api.brands = ["Wallet"];
  //    api.useStandardEndpoints(`/${m.RouteSegment.Contacts}/{contactId}/${m.RouteSegment.Access}`, null, null, true);
  //    return api;
  //};

  //public static WalletContactRole(version: number = AppConfig.ApiVersion): ApiProperties {
  //    const api = new ApiProperties();
  //    api.version = version;
  //    api.documentation.objectDescription = "Contact Role";
  //    api.documentation.objectPrimaryKey = "ContactRoleId";
  //    api.documentation.objectDescriptionPropertyNames = ["RoleId"];
  //    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ContactRole;
  //    api.documentation.requestAndResponseDataModelObject = new wm5.ContactRoleEditViewModel();
  //    api.documentation.documentationUrlBase = "/wallet/contact/role/";
  //    api.pathVariables = ["{contactId}", "{contactRoleId}"];
  //    api.pathModelProperties = ["ContactId", "ContactRoleId"];
  //    api.cacheName = "contactCache";
  //    api.parentApi = "WalletContact";
  //    api.brands = ["Wallet"];
  //    api.useStandardEndpoints(`/${m.RouteSegment.Contacts}/{contactId}/${m.RouteSegment.Roles}`, null, null, true);
  //    return api;
  //};

  public static WalletEncryptionKeyGet(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("WalletEncryptionKeyGet");
    api.version = version;
    api.documentation.objectDescription = "Encryption";
    api.documentation.objectDescriptionPlural = api.documentation.objectDescription;
    api.documentation.objectPrimaryKey = "Tag";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.WalletEncryptionKey;
    api.documentation.requestAndResponseDataModelObject = new wm5.EncryptionViewModel();
    api.documentation.documentationUrlBase = "/wallet/encryption/key/";
    api.pathVariables = ["{tag}", "{keyType}", "{keyLength}", "{asOf}"];
    api.pathModelProperties = ["Tag", "KeyType", "KeyLength", "AsOf"];
    api.cacheName = "staticObjectCache";
    api.brands = ["Wallet"];
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Encryption}/{tag}?keyType={keyType}&keyLength={keyLength}&asOf={asOf}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Get Encryption Key";
    api.endpoints.slice(-1)[0].documentation.menuText = "Get Encryption Key";
    api.endpoints.slice(-1)[0].documentation.overviewText = `
This API endpoint retrieves an encryption key that can be used for encryption of data that may need to be encrypted while at rest and/or
submitted as an encrypted value using the X-Encryption-Key-Tag and X-Encrypted-Properties headers.
<br/><br/>
The url for this API endpoint /encryption/{tag}?keyType={keyType}&keyLength={keyLength}&asOf={asOf} has the following parameters:
<br/><br/>
<ul>
<li>Tag - When this value is not 'any' the encryption key for the specified tag is retrieved and all other parameters are ignored.</li>
<li>Key Type - When the specified tag is 'any' this is the desired key type.  Possible values include RSA, AES, and VisaCheckout with a default value of RSA.</li>
<li>
Key Length - When the specified tag is 'any' this is the desired key length.
Possible values for RSA are 1024, 2048, 3072, and 4096 with a default of 2048.
Possible values for AES are 128, 192, and 256 with a default of 256.
Possible values for VisaCheckout are 0.
</li>
<li>
As Of - When the specified tag is 'any' this is the as-of date for the encryption key.
If a key already exists of the desired type and length that was created on or after
the as-of date it will be returned.  Otherwise, a new key of the desired type and length will be created.  The as-of date needs to be
specified in yyyy-mm-dd format.  The default as-of date is 30-days ago.
</li>
</ul>
Notes:
<ol>
<li>When RSA encryption is used it should be used with OAEP padding.</li>
<li>For public key encryption the PrivateKey property will always be null as only the public key is provided.</li>
<li>For Visa Checkout the PrivateKey property will always be null and the PublicKey property will be the Visa Checkout API Key.</li>
<li>New Visa Checkout keys are not created dynamically based on the as-of date.  The most recent Visa Checkout API Key will be provided if one is not available matching the as-of date.</li>
<li>
A practical implementation may include an encryption tag and key in a system configuration for fallback purposes when there is a need to encrypt data and
this API is not returning a tag and key to utilize.
</li>
</ol>
`;
    //api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    //api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = false;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressPromptForOwnerKey = true;
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static WalletEncryptionEcho(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("WalletEncryptionEcho");
    api.version = version;
    api.documentation.objectDescription = "Encryption Echo";
    api.documentation.objectDescriptionPlural = api.documentation.objectDescription;
    api.documentation.objectPrimaryKey = "Tag";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.WalletEncryptionEcho;
    api.documentation.requestAndResponseDataModelObject = new wm5.EncryptionEchoEditViewModel();
    api.documentation.documentationUrlBase = "/wallet/encryption/echo/";
    api.excludeFromAutomatedTesting = true;
    api.brands = ["Wallet"];
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Encryption}/${m.RouteSegment.Echo}`, ApiOperationType.Call));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.overviewText = "The Encryption Echo API is utilized to test encryption by sending a plain text and encrypted text value with an encryption key tag and having those values echoed back in reverse so the reply returns an encrypted version of the plain text and a plain text version of the encrypted text." +
      "<br/><br/>" +
      "If the specified tag is for RSA encryption note that it will use OAEP padding for this echo.";
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "refresh";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Echo";
    return api;
  };

  public static WalletPaymentMethod(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("WalletPaymentMethod");
    api.version = version;
    api.documentation.objectDescription = "Payment Method";
    api.documentation.objectPrimaryKey = "PaymentMethodId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.WalletPaymentMethod;
    api.documentation.requestAndResponseDataModelObject = new wm5.PaymentMethodEditViewModel();
    api.documentation.documentationUrlBase = "/wallet/payment-method/";
    api.pathVariables = "{paymentMethodId}";
    api.pathModelProperties = "PaymentMethodId";
    api.cacheName = "paymentCache";
    api.brands = ["Wallet"];
    api.useStandardEndpoints(`/${m.RouteSegment.PaymentMethods}`, null, null, false);
    return api;
  };

  public static WalletPaymentTransaction(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("WalletPaymentTransaction");
    api.version = version;
    api.documentation.objectDescription = "Payment Transaction";
    api.documentation.objectPrimaryKey = "PaymentTransactionId";
    api.documentation.objectDescriptionPropertyNames = ["PaymentTransactionId", "TransactionPaymentId", "AccountNumberReferenceSubset"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.WalletPaymentTransaction;
    api.documentation.requestAndResponseDataModelObject = new wm5.PaymentTransactionEditViewModel();
    api.documentation.documentationUrlBase = "/wallet/payment-transaction/";
    api.pathVariables = "{paymentTransactionId}";
    api.pathModelProperties = "PaymentTransactionId";
    api.cacheName = "paymentCache";
    api.brands = ["Wallet"];
    api.useStandardEndpoints(`/${m.RouteSegment.PaymentTransactions}`, null, null, false);
    return api;
  };

  public static WalletPaymentTransactionSale(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("WalletPaymentTransactionSale");
    api.version = version;
    api.documentation.objectDescription = "Sale";
    api.documentation.objectPrimaryKey = "PaymentTransactionId";
    api.documentation.objectDescriptionPropertyNames = [];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.WalletPaymentTransactionSale;
    api.documentation.requestAndResponseDataModelObject = new wm5.PaymentTransactionSaleAddViewModel();
    api.documentation.documentationUrlBase = "/wallet/payment-transaction/sale/";
    api.pathVariables = "";
    api.pathModelProperties = "";
    api.cacheName = "paymentCache";
    api.brands = ["Wallet"];
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.PaymentTransactions}/${m.RouteSegment.Sales}`, ApiOperationType.Add));
    return api;
  };

  public static WalletPaymentTransactionRefund(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("WalletPaymentTransactionRefund");
    api.version = version;
    api.documentation.objectDescription = "Refund";
    api.documentation.objectPrimaryKey = "PaymentTransactionId";
    api.documentation.objectDescriptionPropertyNames = [];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.WalletPaymentTransactionRefund;
    api.documentation.requestAndResponseDataModelObject = new wm5.PaymentTransactionRefundAddViewModel();
    api.documentation.documentationUrlBase = "/wallet/payment-transaction/refund/";
    api.pathVariables = "";
    api.pathModelProperties = "";
    api.cacheName = "paymentCache";
    api.brands = ["Wallet"];
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.PaymentTransactions}/${m.RouteSegment.Refunds}`, ApiOperationType.Add));
    return api;
  };

  public static WalletPaymentTransactionTrace(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("WalletPaymentTransactionTrace");
    api.version = version;
    api.documentation.objectDescription = "Trace";
    api.documentation.objectPrimaryKey = "PaymentTransactionId";
    api.documentation.objectDescriptionPropertyNames = [];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.WalletPaymentTransactionTrace;
    api.documentation.requestAndResponseDataModelObject = new wm5.PaymentTransactionTraceDataViewModel();
    api.documentation.documentationUrlBase = "/wallet/payment-transaction/trace/";
    api.pathVariables = "{paymentTransactionId}";
    api.pathModelProperties = "PaymentTransactionId";
    api.cacheName = "";
    api.brands = ["Wallet"];
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.PaymentTransactions}/${m.RouteSegment.Trace}/{paymentTransactionId}`, ApiOperationType.Get));
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.PaymentTransactions}/${m.RouteSegment.Trace}`, ApiOperationType.Add));
    return api;
  };

  public static WalletPaymentNotification(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("WalletPaymentNotification");
    api.version = version;
    api.documentation.objectDescription = "Payment Notification";
    api.documentation.objectPrimaryKey = "PaymentTransactionId";
    api.documentation.requestDataModelDocumentationName = Constants.DataModelName.WalletPaymentNotification;
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.WalletPaymentTransaction;
    api.documentation.requestDataModelObject = new wm5.PaymentNotificationAddViewModel();
    api.documentation.responseDataModelObject = new wm5.PaymentTransactionEditViewModel();
    api.documentation.documentationUrlBase = "/wallet/payment-notification/";
    api.pathVariables = "{paymentTransactionId}";
    api.pathModelProperties = "PaymentTransactionId";
    api.cacheName = "paymentCache";
    api.brands = ["Wallet"];
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.PaymentNotifications}`, ApiOperationType.Add));
    return api;
  };

  public static WalletPaymentNotificationAdyenNotificationWebhookTarget(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("WalletPaymentNotificationAdyenNotificationWebhookTarget");
    api.version = version;
    api.documentation.objectName = "AdyenNotificationWebhook";
    api.documentation.objectDescription = "Adyen Notification Webhook";
    api.documentation.objectPrimaryKey = "";
    api.documentation.documentationUrlBase = "/wallet/payment-notification/adyen/notification/";
    api.pathVariables = ["{apiKey}"];
    api.pathModelProperties = ["ApiKey"];
    api.cacheName = null;
    api.brands = ["Wallet"];
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.PaymentNotifications}/${m.RouteSegment.Adyen}/${m.RouteSegment.Webhooks}/${m.RouteSegment.Notifications}/{apiKey}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Adyen Notification";
    api.endpoints.slice(-1)[0].documentation.menuText = "Adyen Notification";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API is utilized to facilitate recording payment notifications from Adyen.  " +
      "This API will log notifications submitted to this url but performs no other operations unless explicitly configured to do so.  " +
      "<br/><br/>" +
      "There are other Adyen notification webhooks for things like refund result notifications that should be used instead of this generic notification api endpoint." +
      "<br/><br/>" +
      "The api key used in the url for this api endpoint <strong>must be properly secured</strong> to only provide access to resources required to accomplish the " +
      "purposes of the webhook post.  The api key flags must to contain 'webhook' and 'adyen' to secure the endpoint.";
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.requestDataModelObject = {
      "live": "false",
      "notificationItems": [
        {
          "NotificationRequestItem": {
            "amount": {
              "currency": "EUR",
              "value": 2500
            },
            "eventCode": "REFUND",
            "eventDate": "2021-11-01T00:19:34+01:00",
            "merchantAccountCode": "YOUR_MERCHANT_ACCOUNT",
            "merchantReference": "Refund123",
            "originalReference": "8836183819713023",
            "paymentMethod": "visa",
            "pspReference": "8412534564722331",
            "reason": "Transaction hasn't been captured, refund not possible",
            "success": "false"
          }
        }
      ]
    };
    api.endpoints.slice(-1)[0].documentation.requestDataModelDocumentationName = "";
    api.endpoints.slice(-1)[0].documentation.responseDataModelIsNull = true;
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = "";
    const testForm: ApiDocTestFormProperty[] = [];
    testForm.push(new ApiDocTestFormProperty("ApiKey"));
    testForm.push(new ApiDocTestFormProperty("Body", ApiDocTestFormPropertyType.TextArea));
    testForm.slice(-1)[0].height = 10;
    testForm.slice(-1)[0].isHttpRequestBody = true;
    api.endpoints.slice(-1)[0].documentation.testFormProperties = testForm;
    api.endpoints.slice(-1)[0].documentation.testFormNoJson = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressAuthenticationHeaders = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressEncryptionHeaders = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressContentTypeHeaders = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressApiVersionHeaders = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressLocalDeviceHeaders = true;
    return api;
  };

  public static WalletPaymentProvider(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("WalletPaymentProvider");
    api.version = version;
    api.documentation.objectDescription = "Payment Provider";
    api.documentation.objectPrimaryKey = "PaymentProviderId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalPaymentProviderId", "MerchantName", "MerchantNumber"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.WalletPaymentProvider;
    api.documentation.requestAndResponseDataModelObject = new wm5.PaymentProviderEditViewModel();
    api.documentation.documentationUrlBase = "/wallet/payment-provider/";
    api.pathVariables = "{paymentProviderId}";
    api.pathModelProperties = "PaymentProviderId";
    api.cacheName = "paymentCache";
    api.brands = ["Wallet"];
    api.useStandardEndpoints(`/${m.RouteSegment.PaymentProviders}`, null, null, true);
    return api;
  };

  public static WalletPaymentProviderTrigger(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("WalletPaymentProviderTrigger");
    api.version = version;
    api.documentation.objectDescription = "Payment Provider Trigger";
    api.documentation.objectPrimaryKey = "PaymentProviderTriggerId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "EventValue"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.WalletPaymentProviderTrigger;
    api.documentation.requestAndResponseDataModelObject = new wm5.PaymentProviderTriggerEditViewModel();
    api.documentation.documentationUrlBase = "/wallet/payment-provider/trigger/";
    api.pathVariables = ["{paymentProviderId}", "{paymentProviderTriggerId}"];
    api.pathModelProperties = ["PaymentProviderId", "PaymentProviderTriggerId"];
    api.cacheName = "staticObjectCache";
    api.parentApi = "paymentCache";
    api.brands = ["Wallet"];
    api.useStandardEndpoints(`/${m.RouteSegment.PaymentProviders}/{paymentProviderId}/${m.RouteSegment.Triggers}`, null, null, true);
    return api;
  };

  public static WalletProxyCreditCardAdd(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("WalletProxyCreditCardAdd");
    api.version = version;
    api.documentation.objectDescription = "Proxy Credit Card Add";
    api.documentation.objectPrimaryKey = "";
    api.documentation.objectDescriptionPropertyNames = [""];
    api.documentation.requestAndResponseDataModelDocumentationName = "";
    api.documentation.requestAndResponseDataModelObject = {};
    api.documentation.documentationUrlBase = "/wallet/proxy/credit-card-add/";
    api.pathVariables = "{customerId}";
    api.pathModelProperties = "CustomerId";
    api.cacheName = null;
    api.parentApi = null;
    api.brands = ["Wallet"];
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Customers}/{customerId}/${m.RouteSegment.PaymentMethods}/${m.RouteSegment.PaymentMethodCreditCards}`, ApiOperationType.Add, `${m.RouteSegment.Customers}/${m.RouteSegment.PaymentMethods}/${m.RouteSegment.PaymentMethodCreditCards}.add`));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.showApiEndpointConfig = true;
    api.endpoints.slice(-1)[0].documentation.apiEndpointConfigSampleRequestObjectPropertyName = "SampleRequest";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    api.endpoints.slice(-1)[0].documentation.testFormJsonOnly = true;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.title = "Proxy Add Credit Card";
    api.endpoints.slice(-1)[0].documentation.menuText = "Proxy Add Credit Card";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This endpoint is a proxy for adding a credit card when the format is different than our standard API format." +
      "<br/><br/>" +
      "The endpoint configuration dictates:" +
      "<ul>" +
      "<li>JSON path where credit card information should be extracted</li>" +
      "<li>JSON path where token representing that credit card should be inserted</li>" +
      "<li>URL where the now modified JSON payload should be posted</li>" +
      "</ul>";
    return api;
  };

  public static WalletProxyPurchase(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("WalletProxyPurchase");
    api.version = version;
    api.documentation.objectDescription = "Proxy Purchase";
    api.documentation.objectPrimaryKey = "";
    api.documentation.objectDescriptionPropertyNames = [""];
    api.documentation.requestAndResponseDataModelDocumentationName = "";
    api.documentation.requestAndResponseDataModelObject = {};
    api.documentation.documentationUrlBase = "/wallet/proxy/purchase/";
    api.pathVariables = "";
    api.pathModelProperties = "";
    api.cacheName = null;
    api.parentApi = null;
    api.brands = ["Wallet"];
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Billing}/${m.RouteSegment.Purchases}`, ApiOperationType.Add, `${m.RouteSegment.Billing}/${m.RouteSegment.Purchases}.purchase`));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.showApiEndpointConfig = true;
    api.endpoints.slice(-1)[0].documentation.apiEndpointConfigSampleRequestObjectPropertyName = "SampleRequest";
    api.endpoints.slice(-1)[0].documentation.testFormJsonOnly = true;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.title = "Proxy Purchase";
    api.endpoints.slice(-1)[0].documentation.menuText = "Proxy Purchase";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This endpoint is a proxy for submitting a purchase that includes credit card data." +
      "<br/><br/>" +
      "The endpoint configuration dictates:" +
      "<ul>" +
      "<li>JSON path where credit card information should be extracted</li>" +
      "<li>JSON path where token representing that credit card should be inserted</li>" +
      "<li>URL where the now modified JSON payload should be posted</li>" +
      "</ul>";
    return api;
  };


}

