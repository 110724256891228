import * as Enumerable from "linq";
import moment from "moment";
import * as Handlerbars from 'handlebars';

import * as Constants from "projects/core-lib/src/lib/helpers/constants";

declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";

import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as wm5 from "projects/core-lib/src/lib/models/ngWalletModels";
import { Routes } from "@angular/router";
import { CanDeactivateGuard } from "projects/core-lib/src/lib/services/can-deactivate-guard.service";
import { Dictionary } from "projects/core-lib/src/lib/models/dictionary";
import { Log, Helper } from "projects/core-lib/src/lib/helpers/helper";


export class HandlebarsHelpers {

  /**
  Cache of compiled handlebars templates to save compile time when same template is repeatedly referenced.
  */
  public static handlebarsTemplateCache: Dictionary<Handlebars.TemplateDelegate> = new Dictionary<Handlebars.TemplateDelegate>();

  /**
   * Resolves a template with handlebars using the provided context data with optional caching of compiled template for faster reuse.
   * @param template - template string with {{handlebars}} markers.
   * @param contextData - context data to use for resolving the template with data.
   * @param templateCacheKey - an optional template cache key to use with caching of compiled templates.  Highly recommended for faster performance.
   * @param options - optional handlebars runtime options.
   */
  public static handlebarsTemplateResolve(template: string, contextData: any, templateCacheKey: string = "", options?: Handlebars.RuntimeOptions): string {

    // Register our helpers if we have not already done that in this app.
    if (!HandlebarsHelpers.handlebarsHelpersHaveBeenRegistered) {
      HandlebarsHelpers.handlebarsRegisterHelpers();
    }

    let compiled: Handlebars.TemplateDelegate = null;

    // First check to see if we've already compiled this template and have it in cache
    if (templateCacheKey && HandlebarsHelpers.handlebarsTemplateCache.containsKey(templateCacheKey)) {
      compiled = HandlebarsHelpers.handlebarsTemplateCache.item(templateCacheKey);
      //console.error(`template from cache key ${templateCacheKey}`, compiled);
    } else {
      try {
        compiled = Handlebars.compile(template);
        if (templateCacheKey) {
          HandlebarsHelpers.handlebarsTemplateCache.add(templateCacheKey, compiled);
        }
      } catch (err) {
        Log.errorMessage(`Error compiling handlebars template: ${template}`);
        Log.errorMessage(err);
      }
      //console.error(`template compiled`, compiled);
    }

    let result: string = "";
    try {
      result = compiled(contextData, options);
    } catch (err) {
      Log.errorMessage(`Error using compiled handlebars template: ${template}`);
      Log.errorMessage(err);
    }
    //console.error("result", result);

    return result;

  }


  /**
  Flag if handlebars helpers have been registered or not so we can lazy register.
  */
  public static handlebarsHelpersHaveBeenRegistered: boolean = false;

  /**
   * Registers handlebars helpers.
   */
  public static handlebarsRegisterHelpers(): void {

    // Custom helpers

    HandlebarsHelpers.handlebarsRegisterHelpersComparison();
    HandlebarsHelpers.handlebarsRegisterHelpersDate();
    HandlebarsHelpers.handlebarsRegisterHelpersNumber();
    HandlebarsHelpers.handlebarsRegisterHelpersMath();

    HandlebarsHelpers.handlebarsHelpersHaveBeenRegistered = true;

  }


  public static handlebarsRegisterHelpersComparison(): void {

    Handlebars.registerHelper("eq", function (a, b, options: Handlerbars.HelperOptions) {
      if (a === b) {
        return options.fn(this);
      } else {
        return options.inverse(this);
      }
    });

    Handlebars.registerHelper("notEq", function (a, b, options: Handlerbars.HelperOptions) {
      if (a !== b) {
        return options.fn(this);
      } else {
        return options.inverse(this);
      }
    });

    Handlebars.registerHelper("ifCond", function (...args: any[]) {

      // Last parameter is our options object so actual input parameters is argument array length-1.
      let len: number = args.length - 1;
      let options: Handlerbars.HelperOptions = args[len];

      if (len === 0) {
        Log.errorMessage("ifCond handlebars helper requires at least one comparison to perform.");
        return options.inverse(this);
      }

      let expression: string = HandlebarsHelpers.simplifyExpression(args.slice(0, len));

      // Debug
      //Log.errorMessage(`Ready to evaluate ${expression} which was simplified from ${args.slice(0, len).join(' ')}`);

      let result: boolean = false;
      try {
        result = eval(expression);
      } catch (err) {
        Log.errorMessage(`Error in expression ${expression} which was simplified from ${args.slice(0, len).join(' ')}`);
        Log.errorMessage(err);
      }

      if (result) {
        return options.fn(this);
      } else {
        return options.inverse(this);
      }

    });

    // Sometimes we want true/false not in a if block like ifCond above gives us ... for example
    // in custom forms we may decide if we're going to show something or not based on eval result
    // of true or false.  If true we return true; if false we return "" so we can do a simple
    // test for truthy/falsy rather than string comparison.
    Handlebars.registerHelper("eval", function (...args: any[]) {

      // Last parameter is our options object so actual input parameters is argument array length-1.
      let len: number = args.length - 1;
      let options: Handlerbars.HelperOptions = args[len];

      if (len === 0) {
        Log.errorMessage("eval handlebars helper requires at least one comparison to perform.");
        return "";
      }

      let expression: string = HandlebarsHelpers.simplifyExpression(args.slice(0, len));

      // Debug
      //Log.errorMessage(`Ready to evaluate ${expression} which was simplified from ${args.slice(0, len).join(' ')} (${JSON.stringify(args.slice(0, len))})`);

      let result: boolean = false;
      try {
        result = eval(expression);
      } catch (err) {
        Log.errorMessage(`Error in expression ${expression} which was simplified from ${args.slice(0, len).join(' ')} (${JSON.stringify(args.slice(0, len))})`);
        Log.errorMessage(err);
      }

      if (result) {
        return "true";
      } else {
        return "";
      }

    });

  }


  protected static compareTwoValues(left: any, comparisonOperator: string, right: any): boolean {

    if (!comparisonOperator) {
      Log.errorMessage("Comparison helper requires second parameter to be comparison operator.");
    }

    if (comparisonOperator === "=" || comparisonOperator === "==" || comparisonOperator === "====") {
      return (left === right);
    }

    if (comparisonOperator === "!=" || comparisonOperator === "!==") {
      return (left !== right);
    }

    if (comparisonOperator === "<") {
      return (left < right);
    }

    if (comparisonOperator === "<=") {
      return (left <= right);
    }

    if (comparisonOperator === ">") {
      return (left > right);
    }

    if (comparisonOperator === ">=") {
      return (left >= right);
    }

    if (Helper.equals(comparisonOperator, "StartsWith", true)) {
      return Helper.startsWith(left, right, true);
    }

    if (Helper.equals(comparisonOperator, "EndsWith", true)) {
      return Helper.endsWith(left, right, true);
    }

    if (Helper.equals(comparisonOperator, "Contains", true)) {
      return Helper.contains(left, right, true);
    }

    if (Helper.equals(comparisonOperator, "TypeOf", true)) {
      return (typeof left === right);
    }

    if (Helper.equals(comparisonOperator, "True", true)) {
      if (left) {
        return true;
      } else {
        return false;
      }
    }

    if (Helper.equals(comparisonOperator, "False", true)) {
      if (!left) {
        return true;
      } else {
        return false;
      }
    }

    Log.errorMessage(`Comparison helper comparison operator ${comparisonOperator} is not supported.  Supported values include ===, !==, <, <=, >, >=, StartsWith, EndsWith, Contains, TypeOf, True, False.`);

    return false;

  }


  protected static isComparisonOperator(comparisonOperator: string): boolean {

    let operators: string[] = ["=", "==", "====", "!=", "!==", "<", "<=", ">", ">=", "StartsWith", "EndsWith", "Contains", "TypeOf", "True", "False"];

    let found = false;
    operators.forEach(op => {
      if (Helper.equals(op, comparisonOperator, true)) {
        found = true;
      }
    });

    return found;

  }

  protected static simplifyExpression(args: any[]): string {

    // Since context values are evaluated and then passed in as arguments the array of arguments will look like this:
    // ( ( 0 == 2 || 0 == 0 ) && 1 )
    // which we simplify to
    // (  (  false  ||  true  )  &&  true  )
    // which presumably our caller will then evaluate to true/false... the passed in values look like we could just
    // join and evaluate as-is but we have custom comparison operators beyond things like == we support
    // StartsWith, EndsWith, Contains, TypeOf, etc. so this method helps simplify the expression so it's eval ready.

    let len: number = args.length;

    if (len === 0) {
      Log.errorMessage("simplifyExpression helper requires at least one comparison to perform.");
      return "";
    }

    let expression: string = "";

    try {

      // Now loop through and simplify each comparison to a true or false
      for (let i = 0; i < len; i++) {

        // See if the argument is a logical grouping argument and, if so, push into our simplified expression as-is
        let arg = "";
        if (args[i] !== undefined && args[i] !== null) {
          arg = args[i].toString();
        }
        if (arg === "(" || arg === ")" || arg === "&" || arg === "&&" || arg === "|" || arg === "||" || arg === "!") {
          expression += ` ${arg} `;
        } else {
          // Not a logical grouping argument so let's peek forward and look for comparison operator
          // if no comparison operator then let's consider a single truthy/falsy value.
          if (len >= (i + 2) && HandlebarsHelpers.isComparisonOperator(args[i + 1])) {
            let part = HandlebarsHelpers.compareTwoValues(args[i], args[i + 1], args[i + 2]);
            i += 2;
            if (part) {
              expression += " true ";
            } else {
              expression += " false ";
            }
          } else {
            // No comparison operator involved so let's test this argument for truthy/falsy
            if (args[i]) {
              expression += " true ";
            } else {
              expression += " false ";
            }
          }
        }

      }

    } catch (err) {
      Log.errorMessage(`Error in simplifyExpression for arguments ${args.join(' ')}`);
      Log.errorMessage(err);
      return "";
    }

    return expression;

  }



  public static handlebarsRegisterHelpersDate(): void {


    /**
    Accepts moment format string for formatting current date time
    https://momentjs.com/docs/#/displaying/format/

    Token	Output
    Month	M	1 2 ... 11 12
    Mo	1st 2nd ... 11th 12th
    MM	01 02 ... 11 12
    MMM	Jan Feb ... Nov Dec
    MMMM	January February ... November December
    Quarter	Q	1 2 3 4
    Qo	1st 2nd 3rd 4th
    Day of Month	D	1 2 ... 30 31
    Do	1st 2nd ... 30th 31st
    DD	01 02 ... 30 31
    Day of Year	DDD	1 2 ... 364 365
    DDDo	1st 2nd ... 364th 365th
    DDDD	001 002 ... 364 365
    Day of Week	d	0 1 ... 5 6
    do	0th 1st ... 5th 6th
    dd	Su Mo ... Fr Sa
    ddd	Sun Mon ... Fri Sat
    dddd	Sunday Monday ... Friday Saturday
    Day of Week (Locale)	e	0 1 ... 5 6
    Day of Week (ISO)	E	1 2 ... 6 7
    Week of Year	w	1 2 ... 52 53
    wo	1st 2nd ... 52nd 53rd
    ww	01 02 ... 52 53
    Week of Year (ISO)	W	1 2 ... 52 53
    Wo	1st 2nd ... 52nd 53rd
    WW	01 02 ... 52 53
    Year	YY	70 71 ... 29 30
    YYYY	1970 1971 ... 2029 2030
    Y	1970 1971 ... 9999 +10000 +10001
    Note: This complies with the ISO 8601 standard for dates past the year 9999
    Week Year	gg	70 71 ... 29 30
    gggg	1970 1971 ... 2029 2030
    Week Year (ISO)	GG	70 71 ... 29 30
    GGGG	1970 1971 ... 2029 2030
    AM/PM	A	AM PM
    a	am pm
    Hour	H	0 1 ... 22 23
    HH	00 01 ... 22 23
    h	1 2 ... 11 12
    hh	01 02 ... 11 12
    k	1 2 ... 23 24
    kk	01 02 ... 23 24
    Minute	m	0 1 ... 58 59
    mm	00 01 ... 58 59
    Second	s	0 1 ... 58 59
    ss	00 01 ... 58 59
    Fractional Second	S	0 1 ... 8 9
    SS	00 01 ... 98 99
    SSS	000 001 ... 998 999
    SSSS ... SSSSSSSSS	000[0..] 001[0..] ... 998[0..] 999[0..]
    Time Zone	z or zz	EST CST ... MST PST
    Note: as of 1.6.0, the z/zz format tokens have been deprecated from plain moment objects. Read more about it here. However, they do work if you are using a specific time zone with the moment-timezone addon.
    Z	-07:00 -06:00 ... +06:00 +07:00
    ZZ	-0700 -0600 ... +0600 +0700
    Unix Timestamp	X	1360013296
    Unix Millisecond Timestamp	x	1360013296123
    */
    Handlebars.registerHelper("currentDateTime", function (format: string) {
      let date: moment.Moment = moment();
      if (!format) {
        format = "YYYY-MM-DD";
      }
      return date.format(format);
    });

    Handlebars.registerHelper("currentDateTimeUtc", function (format: string) {
      let date: moment.Moment = moment.utc();
      if (!format) {
        format = "YYYY-MM-DD";
      }
      return date.format(format);
    });

    Handlebars.registerHelper("formatDateTime", function (dateTime: any, format: string) {
      if (!dateTime) {
        return "";
      }
      let date: moment.Moment = moment(dateTime);
      if (!format) {
        format = "YYYY-MM-DD";
      }
      return date.format(format);
    });

  }





  public static handlebarsRegisterHelpersMath(): void {

    /**
    {{add 2 3}} => 5
    {{add "2" "3"}} => 5
    {{add "Jon" "Doe"}} => Jon Doe
    */
    Handlebars.registerHelper("add", function (a, b) {
      if (Helper.isNumeric(a) && Helper.isNumeric(b)) {
        return Number(a) + Number(b);
      }
      if (typeof a === 'string' && typeof b === 'string') {
        return a + b;
      }
      return '';
    });

  }



  public static handlebarsRegisterHelpersNumber(): void {

    Handlebars.registerHelper("toFixed", function (number, digits) {
      if (!Helper.isNumeric(number)) {
        number = 0;
      }
      if (!Helper.isNumeric(digits)) {
        digits = 0;
      }
      return Number(number).toFixed(digits);
    });

  }



}
