<div [hidden]="!jsonError"
     class="alert alert-danger"
     role="alert">
  {{jsonError}}
</div>

<ib-code-editor mode="json"
                name="Edit Request Data"
                height="500px"
                [actions]="jsonActions"
                [ngModel]="getRequestDataAsJsonString()"
                (change)="onRequestDataJsonStringChange($event)"></ib-code-editor>

<div class="row mt-1 mb-2">
  <div class="{{Constants.Layout.percent33}}"></div>
  <div class="{{Constants.Layout.percent66}}">
    <ib-button *ngIf="testButtonText"
               name="Test Submit Button"
               icon="{{testButtonIcon}}"
               label="{{testButtonText}}"
               color="{{testButtonContextColor}}"
               [working]="working"
               (click)="onSubmit($event)">
    </ib-button>
    &nbsp;&nbsp;
    <ib-button *ngIf="testButton2Text && testButton2Action"
               name="Test Submit2 Button"
               icon="{{testButton2Icon}}"
               label="{{testButton2Text}}"
               color="{{testButton2ContextColor}}"
               [working]="working"
               (click)="onSubmit2($event)">
    </ib-button>
  </div>
</div>
