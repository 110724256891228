<div class="{{class}}">
  <div class="float-start me-1">
    <ng-content select="[header]"></ng-content>
  </div>
  <ib-peek [visible]="visible" [clearFloats]="clearFloats"
           [buttonWrapperClass]="'float-start ' + buttonWrapperClass" [buttonWrapperStyle]="buttonWrapperStyle"
           [buttonClass]="buttonClass" [buttonStyle]="buttonStyle">
    <ng-content select="[body]"></ng-content>
  </ib-peek>
  <div class="clearfix" *ngIf="clearFloats"></div>
</div>
