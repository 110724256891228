<div *ngIf="data">

  <div class="float-start">
    <h4>{{data.Name}} - {{data.Notes}}</h4>
  </div>

  <div class="float-end"
       style="margin: 10px 10px 10px 0;">
    <div ngbDropdown
         class="d-inline-block">
      <button class="btn btn-primary btn-sm"
              id="dropdownBasic1"
              ngbDropdownToggle><i class="far fa-sort"></i>&nbsp;Sort</button>
      <div ngbDropdownMenu
           aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem
                (click)="onSort('natural')"><i class="far fa-sort-amount-up-alt"></i>&nbsp;Natural</button>
        <button ngbDropdownItem
                (click)="onSort('asc')"><i class="far fa-sort-alpha-up"></i>&nbsp;Ascending</button>
        <button ngbDropdownItem
                (click)="onSort('desc')"><i class="far fa-sort-alpha-down-alt"></i>&nbsp;Descending</button>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <app-data-model-property-table [properties]="data.Columns"
                                 [sort]="sort"
                                 [showPartitionId]="(['Authenticated User','AuthenticationData','Partition','PartitionService','PartitionDomain'].indexOf(data.Name) > -1)">
  </app-data-model-property-table>

  <div class="clearfix"></div>

</div>
