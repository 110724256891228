<h4>The Request</h4>

<p>
  This API endpoint is used to submit a purchase request. The purchase request object
  <ib-peek>
    <app-data-model-property-table [properties]="dataModelDocumentationParts.PurchaseRequestModel"></app-data-model-property-table>
  </ib-peek>
  holds all of the information to successfully make the request.
</p>

<p>
  As part of the purchase request the customer must be identified by providing either the CustomerId or, for new customers, a customer object
  <ib-peek>
    <app-data-model-property-table [properties]="dataModelDocumentationParts.CustomerEditViewModel"></app-data-model-property-table>
  </ib-peek>
  that includes details about the customer.
</p>

<p>
  Each purchase request may optionally specify a billing account by providing either the BillingAccountId or, for new billing accounts, a billing account object
  <ib-peek>
    <app-data-model-property-table [properties]="dataModelDocumentationParts.BillingAccountEditViewModel"></app-data-model-property-table>
  </ib-peek>
  that includes details about the billing account to add. If no billing account (current or new) is specified the following steps will be followed:
</p>
<ul>
  <li>If the customer has no billing account a default billing account created for them.</li>
  <li>If the customer has one billing account the purchase process will use that account.</li>
  <li>
    If the customer has more than one billing account the purchase request will fail with response code
    <ib-peek>
      <app-custom-content type="ResultCodePurchase"></app-custom-content>
    </ib-peek>
    311 (BillingAccountTooManyFound).
  </li>
</ul>

<p>
  The purchase request includes one or more packages in the purchase request Packages property. Each package object
  <ib-peek>
    <app-data-model-property-table [properties]="dataModelDocumentationParts.PurchaseRequestPackageModel"></app-data-model-property-table>
  </ib-peek>
  includes enough information to identify the package being purchased and optional payment information
  <ib-peek>
    <app-data-model-property-table [properties]="dataModelDocumentationParts.PurchaseRequestPaymentModel"></app-data-model-property-table>
  </ib-peek>
</p>

<p>
  For packages that do not need their own payment methods the payment object
  <ib-peek>
    <app-data-model-property-table [properties]="dataModelDocumentationParts.PurchaseRequestPaymentModel"></app-data-model-property-table>
  </ib-peek>
  contains the payment information that will be used for the purchase. There are four possible payment methods provided:
</p>
<ul>
  <li>Complimentary - if there is no charge a complimentary flag can be set to true.</li>
  <li>Voucher - providing a voucher code will allow a voucher to be used for all or part of the payment.</li>
  <li>
    Credit Card - if paying by credit card specify a PaymentMethodId for an existing credit card or use the CreditCard property to attach a
    credit card object
    <ib-peek>
      <app-data-model-property-table [properties]="dataModelDocumentationParts.PaymentMethodCreditCardEditViewModel"></app-data-model-property-table>
    </ib-peek>
  </li>
  <li>
    External - if using an external payment provider like PayPal or Amazon use the ExternalPayment property to attach an external payment object
    <ib-peek>
      <app-data-model-property-table [properties]="dataModelDocumentationParts.PurchaseRequestExternalPaymentModel"></app-data-model-property-table>
    </ib-peek>
  </li>
</ul>


<br />
<h4>The Response</h4>

<p>
  Every response from the API uses the same response format
  <ib-peek>
    <app-response-body></app-response-body>
  </ib-peek>.
  Part of that common format is a ResultCode <ib-peek>
    <app-standard-result-codes></app-standard-result-codes>
  </ib-peek>
  property which can be used to gain further insights into why an api call was not successful.
</p>

<p>
  The data part of the response is the purchase response object
  <ib-peek>
    <app-data-model-property-table [properties]="dataModelDocumentationParts.PurchaseResponseModel"></app-data-model-property-table>
  </ib-peek>
  and holds response information for the request.
</p>

<p>
  Like every API endpoint, the ResultCode <ib-peek>
    <app-standard-result-codes></app-standard-result-codes>
  </ib-peek>
  provides standard information about the overall result of the process.
  Additional information specific to the purchase can be found in the purchase response object's response code
  <ib-peek>
    <app-custom-content type="ResultCodePurchase"></app-custom-content>
  </ib-peek>.
</p>

<p>
  The CustomerId property is the id of the customer from the purchase request. Subsequent purchase requests for the same customer,
  including resubmissions with changed payment information, should use this value in the request CustomerId property instead of
  providing a new customer object. Doing so allows purchases and attempted purchases to be tied to the same customer.
</p>

<p>
  The BillingAccountId property is the id of the billing account from the purchase request. Subsequent purchase requests for the same billing account,
  including resubmissions with changed payment information, should use this value in the request BillingAccountId property instead of
  providing a new billing account object. Doing so allows purchases and attempted purchases to be tied to the same billing account.
</p>

<p>
  The PaymentMethodId property is the id of the payment method from the purchase request. Subsequent purchase requests using the same credit card
  should use this value in the request PaymentMethodId property instead of providing a new credit card object. Doing so allows purchases to be tied
  to the same payment method. If the purchase failed due to faulty payment method (e.g. card declined) this PaymentMethodId should not be reused.
</p>

<p>
  The PaymentTransactionId property is the id of the payment transaction from the purchase request. If the response code of the purchase request is
  pending or timeout this can be used to <a [routerLink]="['/', 'endpoints', 'payment-transaction', 'get']">retrieve the payment transaction</a> in order
  to check it's status to determine if the transaction succeeded or not.
</p>

<p>
  The Packages property is a collection of package response objects
  <ib-peek>
    <app-data-model-property-table [properties]="dataModelDocumentationParts.PurchaseResponsePackageModel"></app-data-model-property-table>
  </ib-peek>
  with information about each package that was part of the purchase request. Including its own response code
  <ib-peek>
    <app-custom-content type="ResultCodePurchase"></app-custom-content>
  </ib-peek>.
</p>

<p>
  Some customer information that was provided with the purchase is echoed back in the response. Additionally, some purchases may not have
  basic customer information because the purchase is using a saved customer account and only the CustomerId is known at the time of
  purchase or because an external payment provider is being used and that external payment provider holds the customer information.
  In scenarios like that the purchase response may have data attached to the CustomerInformation
  <ib-peek>
    <app-data-model-property-table [properties]="dataModelDocumentationParts.PurchaseResponseContactInformationModel"></app-data-model-property-table>
  </ib-peek>
  property.
</p>
