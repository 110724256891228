<div class="loading-container">

  <ng-container *ngIf="data">

    <h2>{{data.Name}}</h2>

    <table class="table table-condensed">
      <tbody>
        <tr>
          <td><strong>Category</strong></td>
          <td>
            <a [routerLink]="['/', 'overview', 'data-model', 'tables-and-views']"
               [queryParams]="{ category: data.Category }">
              {{data.Category}}
            </a>
          </td>
        </tr>
        <tr>
          <td><strong>Description</strong></td>
          <td>{{data.Notes}}</td>
        </tr>
      </tbody>
    </table>


    <div class="float-start">
      <h4>Columns</h4>
    </div>

    <div class="float-end">
      <div ngbDropdown
           class="d-inline-block">
        <button class="btn btn-primary btn-sm"
                id="dropdownBasic1"
                ngbDropdownToggle><i class="far fa-sort"></i>&nbsp;Sort</button>
        <div ngbDropdownMenu
             aria-labelledby="dropdownBasic1">
          <button ngbDropdownItem
                  (click)="onSort('natural')"><i class="far fa-sort-amount-up-alt"></i>&nbsp;Natural</button>
          <button ngbDropdownItem
                  (click)="onSort('asc')"><i class="far fa-sort-alpha-up"></i>&nbsp;Ascending</button>
          <button ngbDropdownItem
                  (click)="onSort('desc')"><i class="far fa-sort-alpha-down-alt"></i>&nbsp;Descending</button>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>

    <app-data-model-property-table [properties]="data.Columns"
                                   [sort]="sort"
                                   [showPartitionId]="true"></app-data-model-property-table>

    <div class="clearfix"></div>

  </ng-container>

  <ngx-loading [show]="loading"
               [config]="{animationType: 'none'}"
               [template]="loadingTemplate"></ngx-loading>
  <ng-template #loadingTemplate>
    <div class="loading-template">
      <h3><i class="{{Constants.Layout.loadingIcon}}"></i>&nbsp;&nbsp;{{Constants.Layout.loadingText}}</h3>
    </div>
  </ng-template>
</div>
