<div>

  <h3>API Versions</h3>

  <br />
  <h5>Principles</h5>

  <ul>
    <li>
      The version of the API to use can be specified in the Accept, Content-Type, and/or X-Api-Version headers. If the
      API is unable to determine what version was requested the most recently released (non-beta) version will be assumed.
    </li>
    <li>A new version is created when there are breaking changes to a released (i.e. non-beta) version.</li>
    <li>
      The version prior to the current version is supported for at least one year. An attempt is made to support versions
      older than that but they are considered deprecated and are at risk of being discontinued one year after a new version
      is released.
    </li>
    <li>
      Versions may not be functionally equivalent. While the version prior to the current version may
      be supported from an interface perspective it may not have exactly the same behavior. For example,
      if a new version is introduced that eliminated a fax number property the prior version will still
      have a fax number property but that fax number may not actually be persisted since the underlying
      data store may no longer have the ability to store that fax number.
    </li>
    <li>
      Examples of API changes that are considered breaking changes which would trigger a new version:
      <ul>
        <li>Changing the data type of an existing public request or response property.</li>
        <li>Adding a new request property that is required.</li>
        <li>Removing an existing public request property that is required to not be present in the request.</li>
        <li>Removing an existing public response property.</li>
      </ul>
    </li>
    <li>
      Examples of API changes that are <strong><em>not</em></strong> considered breaking changes and, therefore, would not trigger a new version:
      <ul>
        <li>Any change prior to the version or api endpoint being officially released. If the version is listed as beta in the list of versions or the api endpoint is tagged as
          beta then it is subject to change.</li>
        <li>Adding a new uri or a new method (e.g. get, post, etc.) on an existing uri.</li>
        <li>Adding a new request property that is optional.</li>
        <li>Removing a request property provided the API still succeeds if the request property is present in the submission.</li>
        <li>Adding a new response property. Your use of the API should be able to ignore properties it is unaware of.</li>
        <li>Changing any undocumented API endpoints.</li>
        <li>Changing any response properties that are not public (i.e. documented as internal).</li>
      </ul>
    </li>
  </ul>

  <br />


  <h5>Versions</h5>

  <table class="table table-striped table-bordered table-sm table-hover">
    <thead>
      <tr>
        <th>Version</th>
        <th>Accept and/or Content-Type Headers</th>
        <th>X-Api-Version</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>5</td>
        <td>
          application/vnd.ib.api-v5+json
        </td>
        <td>5</td>
        <td>Released</td>
      </tr>
      <tr>
        <td>4</td>
        <td>
          application/vnd.ib.api-v4+json
        </td>
        <td>4</td>
        <td>Discontinued</td>
      </tr>
      <tr>
        <td>3</td>
        <td>
          application/vnd.ib.api-v3+json
        </td>
        <td>3</td>
        <td>Discontinued</td>
      </tr>
      <tr>
        <td>2</td>
        <td>
          application/vnd.ib.api-v2+json
        </td>
        <td>2</td>
        <td>Discontinued</td>
      </tr>
      <tr>
        <td>1</td>
        <td>
          application/vnd.ib.api-v1+json
        </td>
        <td>1</td>
        <td>Discontinued</td>
      </tr>
    </tbody>
  </table>

  <div style="clear:both;"></div>
  <br /><br />



  <!--<p class="lead">Change Log v2 to v3:</p>

  <table class="table table-striped table-bordered table-condensed table-hover">
  <tr>
      <th width="10%">Date</th>
      <th width="10%">Type</th>
      <th width="80%">Change</th>
  </tr>
  </table>



  <br /><br />-->
  <!--<p class="lead">Change Log V5 to v2:</p>

  <table class="table table-striped table-bordered table-condensed table-hover">
  <tr>
      <th width="10%">Date</th>
      <th width="10%">Type</th>
      <th width="80%">Change</th>
  </tr>
  </table>


  <br /><br />-->

</div>
