import * as m from "./ngCoreModels";
import * as m5pay from "./ngModelsPayment5";
import * as m5core from "./ngModelsCore5";
import * as m5web from "./ngModelsWeb5";
import * as m5sec from "./ngModelsSecurity5";
// This is generated code... DO NOT modify this file.
	export enum PropertyMetaDataAction {
		Ignore = 0,
		PostWarning = 10,
		PostError = 20,
		Fail = 21
	}
	export enum PropertyMetaDataMapMatchMode {
		First = 0,
		Exact = 1,
		All = 2
	}
	export enum PropertyMetaDataMapValueMode {
		Replace = 0,
		Append = 1
	}
	export enum InputControlTypeOption {
		Auto = 0,
		SingleLineEditor = 11,
		SingleLineEditorAutoComplete = 12,
		MultiLineEditor = 21,
		Integer = 31,
		Float = 41,
		DateTime = 51,
		Date = 52,
		CheckBox = 61,
		RadioButton = 71,
		DropDownList = 81,
		DropDown = 82,
		MultiSelectDropDownList = 83
	}
	export enum InputControlWidthOption {
		Auto = 0,
		Full = 1,
		XLarge = 14,
		Large = 15,
		Medium = 25,
		Small = 35,
		XSmall = 36
	}
// This is generated code... DO NOT modify this file.
	export enum PurchaseResponseCode {
		Unknown = -1,
		Success = 0,
		PartialSuccess = 1,
		Pending = 2,
		AuthorizationCodeAddError = 110,
		AuthorizationCodeAlreadyRegistered = 120,
		AuthorizationCodeCreationError = 130,
		AuthorizationCodeValidDateRangeError = 140,
		PackageOccurrenceAddError = 210,
		PackageOccurrenceAttributeError = 220,
		PackageNotFound = 230,
		PackageValidationError = 240,
		BillingAccountAddError = 310,
		BillingAccountNotFound = 320,
		BillingAccountTooManyFound = 321,
		ContactAddError = 410,
		ContactNotFound = 420,
		ContactTooManyFound = 421,
		PaymentMethodAddError = 510,
		PaymentMethodNotFound = 511,
		PaymentMethodEncryptionTagNotFound = 515,
		PaymentMethodEncryptionError = 516,
		PaymentMethodMissingStreetAddress = 520,
		PaymentMethodMissingCity = 530,
		PaymentMethodMissingPostalCode = 540,
		PaymentMethodMissingCardNumber = 550,
		PaymentMethodMissingCardExpiration = 560,
		PaymentMethodMissingCardId = 570,
		PaymentMethodMissingExternalPaymentProviderCredential = 575,
		PaymentMethodCardNumberNotValid = 580,
		PaymentMethodCardExpired = 590,
		PaymentProviderNotFound = 601,
		PaymentTransactionAddError = 610,
		PaymentTransactionNotValid = 615,
		PaymentTransactionWatchError = 620,
		PaymentTransactionTimeout = 630,
		PaymentTransacitonProcessingError = 640,
		PaymentTransactionDuplicated = 650,
		PaymentTransactionCardDeclined = 670,
		PaymentTransactionCardDoNotHonor = 671,
		PaymentTransactionCardLostOrStolen = 672,
		PaymentTransactionFailed = 680,
		PaymentTransactionFailedAvs = 681,
		PaymentTransactionFailedCardId = 682,
		PaymentTransactionFailedCreditLimit = 683,
		PaymentTransactionFailedInvalidInformation = 684,
		PaymentTransactionFailedInvalidCardNumber = 685,
		PaymentTransactionFailedInvalidExpirationDate = 686,
		VoucherNotFound = 710,
		VoucherOnHold = 711,
		VoucherExpired = 712,
		VoucherLapsed = 713,
		VoucherSuspended = 714,
		VourcherUsed = 715,
		VoucherStatusUnknown = 720,
		VoucherNotValidIsoCurrencyCode = 721,
		VoucherNotValidVisibility = 722,
		VoucherLotNotPendingActivation = 730,
		PurchaseRequestValidationError = 990
	}
	export enum AssetArticleGroupStructureChildContentLinkLocation {
		None = 0,
		Top = 1,
		Bottom = 2,
		TopAndBottm = 3,
		After = 4,
		AfterAndBottom = 5
	}
	export enum GroupMembershipApproval {
		Open = 0,
		Closed = 1,
		OwnerApproval = 2
	}
	export enum QueryExecutionRequestEmailRecipientType {
		To = 0,
		Cc = 1,
		Bcc = 2
	}
	export enum DuplicatePreventionTypeOption {
		None = 0,
		SameParameters = 1,
		SameParametersIgnoreAsOfDateTime = 2
	}
	export enum QueryDateType {
		None = 0,
		AsOfDate = 10,
		AsOfDateTime = 11,
		DateRange = 20,
		DateTimeRange = 21
	}
	export enum DataSourceType {
		DataFolder = 0,
		ConnectionString = 1
	}
	export enum QueryEventStatus {
		Pending = -99,
		Started = -98,
		Querying = -97,
		Exporting = -96,
		Delivering = -95,
		Success = 0,
		Error = 2147483647
	}
	export enum ExternalServiceInformationSharingLevel {
		None = 0,
		Anonymous = 1,
		Identifier = 2
	}
	export enum SensitiveInformationMaskType {
		Default = 0,
		Email = 1,
		None = 2
	}
	export enum SensitiveInformationSanitizeType {
		Default = 0,
		Email = 1,
		Clear = 2,
		Replace = 3,
		None = 4
	}
	export enum ReferenceTriggerType {
		Notification = 0,
		Case = 1,
		AssetTrigger = 2
	}
	export enum SslStartMode {
		Automatic = 0,
		Implicit = 1,
		Explicit = 2,
		None = 3
	}
	export enum SshAuthorizationMode {
		None = 0,
		MultiFactor = 1,
		Password = 2,
		PublicKey = 3,
		KeyboardInteractive = 4,
		GSSAPIWithMic = 5,
		Custom = 6
	}
	export enum EncryptionAlgorithm {
		AES = 0,
		Blowfish = 1,
		CAST = 2,
		DES = 3,
		IDEA = 4,
		RC2 = 5,
		RC4 = 6,
		TEA = 7,
		TripleDES = 8,
		Twofish = 9
	}
	export enum FileServerType {
		Local = 0,
		FTP = 2,
		SFTP = 3,
		FTPS = 4,
		HTTP = 5,
		AzureFileShare = 15
	}
	export enum VoucherCheckResponseCode {
		Unknown = -1,
		Valid = 0,
		VoucherNotFound = 710,
		VoucherOnHold = 711,
		VoucherExpired = 712,
		VoucherLapsed = 713,
		VoucherSuspended = 714,
		VourcherUsed = 715,
		VoucherStatusUnknown = 720,
		VoucherNotValidIsoCurrencyCode = 721,
		VoucherNotValidVisibility = 722,
		VoucherLotNotPendingActivation = 730
	}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
    // IB.Data.Model.BrandId matched IB.Data.Model.* so no code is being generated for it.
    // IB.Data.Model.SupportUrlAuthenticationTokenType matched IB.Data.Model.* so no code is being generated for it.
    // IB.Data.Model.SettingInputType matched IB.Data.Model.* so no code is being generated for it.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
    // IB.Core.StandardResultCode matched IB.Core.* so no code is being generated for it.
// This is generated code... DO NOT modify this file.
	export enum ReportTemplateRole {
		Report = 0,
		Chart = 1,
		DataOnly = 2
	}
	export enum ReportTemplateType {
		MRT = 0,
		XLSX = 100,
		DOCX = 200
	}
	export enum QueryOutputSetting {
		Prompt = 0,
		PromptDefaultOn = 1,
		PromptDefaultOff = 2,
		Always = 10,
		Never = 20,
		Smart = 90
	}
	export enum QueryOutputCompressionType {
		None = 0,
		Zip = 1
	}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System {
    // System.TypeCode matched System.* so no code is being generated for it.
}
// This is generated code... DO NOT modify this file.
	export enum VoucherStatus {
		NotFound = 0,
		Hold = 1,
		Pending = 2,
		Reserved = 3,
		Active = 4,
		Inactive = 5,
		Expired = 6,
		Lapsed = 7,
		Suspended = 8,
		Used = 9,
		Unknown = 10
	}

/// <reference path="ngCoreModels.ts" />

// This is generated code... DO NOT modify this file.

    // This is generated code... DO NOT modify this file.
	export class ApiReportId {
	}

    // IB.Web.App.Models.PropertyMetaDataViewModel matched IB.Web.App.Models.PropertyMetaDataViewModel so no code is being generated for it.
    // IB.Web.App.Models.PickListSelectionViewModel matched IB.Web.App.Models.PickListSelectionViewModel so no code is being generated for it.
    // IB.Web.App.Models.AuthenticationData matched IB.Web.App.Models.AuthenticationData so no code is being generated for it.

    // This is generated code... DO NOT modify this file.
	export class KeyValuePairModel<TKey, TValue> {
		Key: TKey = null;
		Value: TValue = null;
	}

// This is generated code... DO NOT modify this file.

    // This is generated code... DO NOT modify this file.
	export class ActivityEditViewModel {
		ActivityId: number = null;
		ActivityDateTime: Date = null;
		SourceNumber: string = "";
		AniIi: string = "";
		OtherNumber: string = "";
		OtherNumber2: string = "";
		DnisNumber: string = "";
		TargetNumber: string = "";
		Description: string = "";
		Details: string = "";
		AccessCode: string = "";
		TotalUnits: number = 0;
		Connect: number = 0;
		HoldQueue: number = 0;
		ActualDuration: number = 0;
		SerialNumber: string = "";
		Source: ActivityEndpointEditViewModel = null;
		Target: ActivityEndpointEditViewModel = null;
		Extension: string = "";
		TransferredExtension: string = "";
		DirectoryId: number = null;
		ContactId: number = null;
		SwitchCode: string = "";
		SwitchName: string = "";
		SwitchId: number = null;
		Incoming: string = "";
		Completed: boolean = false;
		AccountCode: string = "";
		AuthorizationCode: string = "";
		ClassificationType: string = "";
		OtherCode: string = "";
		Phase: string = "";
		Flags: string = "";
		ServiceType: string = "";
		ServiceId: string = null;
		NetworkId: string = null;
		SubscriberId: string = null;
		GroupId: string = null;
		AccountingId: string = null;
		ClassOfService: string = "";
		Quality: string = "";
		VocodingType: string = "";
		Disconnect: string = "";
		CostCenters: ActivityCostCenterEditViewModel[] = [];
		FaxPages: number = 0;
		PacketsSentSource: number = 0;
		PacketsReceivedSource: number = 0;
		PacketsSentTarget: number = 0;
		PacketsReceivedTarget: number = 0;
		PacketsLostSource: number = 0;
		PacketsLostTarget: number = 0;
		JitterSource: number = 0;
		JitterTarget: number = 0;
		LatencySource: number = 0;
		LatencyTarget: number = 0;
		OctetsSentSource: number = 0;
		OctetsReceivedSource: number = 0;
		OctetsSentTarget: number = 0;
		OctetsReceivedTarget: number = 0;
		Stat01SentSource: number = 0;
		Stat01ReceivedSource: number = 0;
		Stat01SentTarget: number = 0;
		Stat01ReceivedTarget: number = 0;
		Stat02: number = 0;
		Stat03: number = 0;
		Stat04: number = 0;
		Stat05: number = 0;
		Stat06: number = 0;
		Stat07: number = 0;
		Stat08: number = 0;
		Stat09: number = 0;
		Stat10: number = 0;
		Bandwidth: number = 0;
		RecordStatus: number = 0;
		RecordFlag: number = 0;
		Attributes: any = null;
		UtcOffset: number = null;
		TimeZoneDescription: string = "";
		DataSourceTableName: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class ActivityEndpointEditViewModel {
		SwitchDomainName: string = "";
		SwitchIpAddress: string = "";
		GatewayId: string = null;
		SwitchCode: string = "";
		SwitchName: string = "";
		PortGroupIn: string = "";
		PortIn: string = "";
		PortGroupOut: string = "";
		PortOut: string = "";
		Info: string = "";
		SwitchId: number = null;
		PortGroupInId: number = null;
		PortInId: number = null;
		PortGroupOutId: number = null;
		PortOutId: number = null;
		ConnectionType: string = "";
		Endpoint: string = "";
		Location: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class ActivityCostCenterEditViewModel {
		CostCenterType: string = "";
		CostCenterLevel: number = 0;
		ContactId: number = null;
		IsoCurrencyCode: string = "";
		BaseCost: number = 0;
		FullCost: number = 0;
		Duration: number = 0;
		RateResult: number = 0;
		RateCode: string = "";
		Type: string = "";
		PackageOccurrenceId: number = null;
		BillingResult: number = 0;
		BillingTransactionId: number = null;
		ExceptionBillingTransactionId: number = null;
	}


    // This is generated code... DO NOT modify this file.
	export class AuthorizationCodeListViewModel {
		AssociationId: number = null;
		ExternalAssociationId: string = null;
		Description: string = "";
		Independent: boolean = false;
		ParentAssociationId: number = null;
		PackageOccurrenceId: number = null;
		BillingAccountId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		SwitchId: number = null;
		VoucherId: number = null;
		InventoryHistoryId: number = null;
		EntityType: string = "";
		AuthorizationCodeGroup: string = "";
		AuthorizationCode: string = "";
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		Attributes: any = null;
		UtcOffset: number = null;
		TimeZone: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class AuthorizationCodeEditViewModel {
		AssociationId: number = null;
		ExternalAssociationId: string = null;
		Description: string = "";
		Independent: boolean = false;
		ParentAssociationId: number = null;
		PackageOccurrenceId: number = null;
		BillingAccountId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		SwitchId: number = null;
		VoucherId: number = null;
		InventoryHistoryId: number = null;
		EntityType: string = "";
		AuthorizationCodeGroup: string = "";
		AuthorizationCode: string = "";
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		Password: string = "";
		AuthenticationCount: number = 0;
		FailedAuthenticationCount: number = 0;
		LastAuthenticationDateTime: Date = null;
		LastAuthenticationInformation: string = "";
		Status: string = "";
		InUse: boolean = false;
		InUseInformation: string = "";
		Comments: string = "";
		Attributes: any = null;
		Cargo: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class AssociationListViewModel {
		AssociationId: number = null;
		ExternalAssociationId: string = null;
		Description: string = "";
		Unassigned: boolean = false;
		Independent: boolean = false;
		ParentAssociationId: number = null;
		PackageOccurrenceId: number = null;
		BillingAccountId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		NetworkElementId: number = null;
		VoucherId: number = null;
		InventoryHistoryId: number = null;
		EntityType: string = "";
		AssociationGroup: string = "";
		AssociationType: string = "";
		AssociationValue: string = "";
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		SharedAssociation: boolean = false;
		UseWorkSchedule: boolean = false;
		Days: string = "";
		StartTime: string = "";
		EndTime: string = "";
		Status: string = "";
		InUse: boolean = false;
		InUseInformation: string = "";
		TransferredIn: boolean = false;
		TransferredOut: boolean = false;
		TimeZone: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class AssociationEditViewModel {
		AssociationId: number = null;
		ExternalAssociationId: string = null;
		Description: string = "";
		Unassigned: boolean = false;
		Independent: boolean = false;
		ParentAssociationId: number = null;
		PackageOccurrenceId: number = null;
		BillingAccountId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		NetworkElementId: number = null;
		VoucherId: number = null;
		InventoryHistoryId: number = null;
		EntityType: string = "";
		AssociationGroup: string = "";
		AssociationType: string = "";
		AssociationValue: string = "";
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		SharedAssociation: boolean = false;
		UseWorkSchedule: boolean = false;
		Days: string = "";
		StartTime: string = "";
		EndTime: string = "";
		Password: string = "";
		AuthenticationCount: number = 0;
		FailedAuthenticationCount: number = 0;
		LastAuthenticationDateTime: Date = null;
		LastAuthenticationInformation: string = "";
		Status: string = "";
		InUse: boolean = false;
		InUseInformation: string = "";
		TransferredIn: boolean = false;
		TransferredOut: boolean = false;
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class BillingAccountListViewModel {
		BillingAccountId: number = null;
		ParentBillingAccountId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		BillingAccountType: string = "";
		BillingProfileId: number = null;
		AccountNumber: string = "";
		ExternalAccountNumber: string = "";
		AccountName: string = "";
		AccountType: string = "";
		AccountClass: string = "";
		AccountGroup: string = "";
		MarketType: string = "";
		ServiceType: string = "";
		Status: string = "";
		CreatedDateTime: Date = null;
		SuspendedDateTime: Date = null;
		ClosedDateTime: Date = null;
		ReactivatedDateTime: Date = null;
		IsoCurrencyCode: string = "";
		Balance: number = 0;
		PendingInvoiceAmount: number = 0;
		CreditHold: boolean = false;
		BillingCycle: string = "";
		BillingCycleInterval: number = 0;
		LastInvoiceNumber: string = "";
		LastInvoiceDate: Date = null;
		LastInvoiceAmount: number = 0;
		LastPaymentMethod: string = "";
		LastPaymentNumber: string = "";
		LastPaymentDate: Date = null;
		LastPaymentAmount: number = 0;
		LastAdjustmentNumber: string = "";
		LastAdjustmentDate: Date = null;
		LastAdjustmentAmount: number = 0;
		TimeZone: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class BillingAccountEditViewModel {
		BillingAccountId: number = null;
		ParentBillingAccountId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		BillingAccountType: string = "";
		BillingProfileId: number = null;
		AccountNumber: string = "";
		ExternalAccountNumber: string = "";
		AccountName: string = "";
		AccountType: string = "";
		AccountClass: string = "";
		AccountGroup: string = "";
		MarketType: string = "";
		ServiceType: string = "";
		AgentContactId: number = null;
		AgentContactName: string = "";
		AgentPlanId: number = null;
		TelecomLocationProfileId: number = null;
		TelecomHomeCountryCode: string = "";
		TelecomHomeNpa: string = "";
		TelecomHomeNxx: string = "";
		TelecomHomeLata: number = 0;
		TelecomHomeState: string = "";
		Status: string = "";
		StatusLocked: boolean = false;
		CreatedDateTime: Date = null;
		SuspendedDateTime: Date = null;
		ClosedDateTime: Date = null;
		ReactivatedDateTime: Date = null;
		IsoCurrencyCode: string = "";
		Deposit: number = 0;
		DepositBillingTransactionId: number = null;
		Balance: number = 0;
		PendingInvoiceAmount: number = 0;
		AutoPay: boolean = false;
		AutoPayMinimumThreshold: number = 0;
		AutoPayMaximumThreshold: number = 0;
		AutoPayRechargeAmount: number = 0;
		AlarmCreditLimitThreshold: number = 0;
		AlarmBalanceThreshold: number = 0;
		TermsDescription: string = "";
		TermsNetDays: number = 0;
		TermsDiscountPercent: number = 0;
		TermsDiscountDays: number = 0;
		CreditLimit: number = 0;
		CreditHold: boolean = false;
		BillingCycle: string = "";
		BillingCycleInterval: number = 0;
		LagDays: number = 0;
		MaximumPastBillingPeriodsToScan: number = 0;
		MaximumBillingPeriodsPerInvoice: number = 0;
		MinimumInvoiceThreshold: number = 0;
		AlwaysInvoiceFlag: string = "";
		ZeroAmountInvoiceWhenBelowThreshold: boolean = false;
		PrintZeroAmountInvoice: string = "";
		IncludeEmptyLineItems: boolean = false;
		IncludeIncomingActivity: boolean = false;
		IncludeInternalActivity: boolean = false;
		IncludeUncompletedActivity: boolean = false;
		IncludeFreeActivity: boolean = false;
		IncludeZeroCostActivity: boolean = false;
		Geocode: string = "";
		InOutCity: string = "";
		InOutLocal: string = "";
		Taxable: string = "";
		TaxCustomerType: string = "";
		TaxServiceProviderType: string = "";
		BillingReportProfileId: number = null;
		AddressType: string = "";
		LastInvoiceNumber: string = "";
		LastInvoiceDate: Date = null;
		LastInvoiceAmount: number = 0;
		LastPaymentMethod: string = "";
		LastPaymentNumber: string = "";
		LastPaymentDate: Date = null;
		LastPaymentAmount: number = 0;
		LastAdjustmentNumber: string = "";
		LastAdjustmentDate: Date = null;
		LastAdjustmentAmount: number = 0;
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class BillingEventEditViewModel {
		BillingEventId: string = null;
		ExternalBillingEventId: string = null;
		BillingEventType: string = "";
		ContactId: number = null;
		ContactName: string = "";
		BillingAccountId: number = null;
		PackageOccurrenceId: number = null;
		PaymentTransactionId: string = null;
		InventoryId: number = null;
		InventoryHistoryId: number = null;
		CaseId: number = null;
		RegardingResourceType: string = "";
		RegardingResourceId: number = null;
		RegardingResourceId2: string = "";
		ItemDateTime: Date = null;
		RequestedItemType: string = "";
		RequestedItemId: string = null;
		ItemId: number = null;
		RateId: string = null;
		Description: string = "";
		Quantity: number = 0;
		RequestedIsoCurrencyCode: string = "";
		RequestedPriceEach: number = 0;
		RequestedAmount: number = 0;
		RequestedTaxAmount: number = 0;
		IsoCurrencyCode: string = "";
		PriceEach: number = 0;
		Amount: number = 0;
		ShowAmountOnly: boolean = false;
		ExpenseAmount: number = 0;
		TaxAmount: number = 0;
		RefundedAmount: number = 0;
		BillingTransactionId: number = null;
		BillingTransactionDetailId: number = null;
		ExceptionBillingTransactionId: number = null;
		BillingResultCode: number = 0;
		BillingResultMessage: string = "";
		IncludeTrace: boolean = false;
		Trace: string = "";
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class BillingProfileListViewModel {
		BillingProfileId: number = null;
		ExternalBillingProfileId: string = null;
		Description: string = "";
		Enabled: boolean = false;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		DefaultProfile: boolean = false;
		TimeZone: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class BillingProfileEditViewModel {
		BillingProfileId: number = null;
		ExternalBillingProfileId: string = null;
		Description: string = "";
		Enabled: boolean = false;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		VisibilityCostCenterType: string[] = [];
		VisibilityAccountType: string = "";
		VisibilityAccountClass: string = "";
		VisibilityMarketType: string = "";
		VisibilityContactId: number = null;
		VisibilityContactName: string = "";
		DefaultProfile: boolean = false;
		AccountType: string = "";
		AccountClass: string = "";
		AccountGroup: string = "";
		MarketType: string = "";
		ServiceType: string = "";
		AgentContactId: number = null;
		AgentContactName: string = "";
		AgentPlanId: number = null;
		IsoCurrencyCode: string = "";
		AutoPay: boolean = false;
		AutoPayMinimumThreshold: number = 0;
		AutoPayMaximumThreshold: number = 0;
		AutoPayRechargeAmount: number = 0;
		AlarmCreditLimitThreshold: number = 0;
		AlarmBalanceThreshold: number = 0;
		TermsDescription: string = "";
		TermsNetDays: number = 0;
		TermsDiscountPercent: number = 0;
		TermsDiscountDays: number = 0;
		CreditLimit: number = 0;
		CreditHold: boolean = false;
		BillingCycle: string = "";
		BillingCycleInterval: number = 0;
		LagDays: number = 0;
		MaximumPastBillingPeriodsToScan: number = 0;
		MaximumBillingPeriodsPerInvoice: number = 0;
		MinimumInvoiceThreshold: number = 0;
		AlwaysInvoiceFlag: string = "";
		ZeroAmountInvoiceWhenBelowThreshold: boolean = false;
		PrintZeroAmountInvoice: string = "";
		IncludeEmptyLineItems: boolean = false;
		IncludeIncomingActivity: boolean = false;
		IncludeInternalActivity: boolean = false;
		IncludeUncompletedActivity: boolean = false;
		IncludeFreeActivity: boolean = false;
		IncludeZeroCostActivity: boolean = false;
		Taxable: string = "";
		TaxCustomerType: string = "";
		TaxServiceProviderType: string = "";
		BillingReportProfileId: number = null;
		AddressType: string = "";
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class BillingReportEditViewModel {
		BillingReportId: number = null;
		BillingReportProfileId: number = null;
		Description: string = "";
		PrintBatch: number = 0;
		SequenceNumber: number = 0;
		QueryId: number = null;
		FilterId: number = null;
		ReportId: number = null;
		SystemReportId: string = null;
		ReportTemplateId: number = null;
		SystemReportTemplateId: string = null;
		ReportInstructions: string = "";
		ReferenceCopy: boolean = false;
		Printed: boolean = false;
		PrintServer: string = "";
		Printer: string = "";
		Export: boolean = false;
		Compress: boolean = false;
		FileFormat: string = "";
		FileName: string = "";
		ExportToDocumentsFolder: boolean = false;
		ExportToFolderName: string = "";
		NotificationGroupId: number = null;
		AttachToNotification: boolean = false;
		DeleteFileAfterNotification: boolean = false;
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class BillingReportProfileListViewModel {
		BillingReportProfileId: number = null;
		Description: string = "";
		Enabled: boolean = false;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		DefaultProfile: boolean = false;
		InvoiceLanguage: string = "";
		RemitToName: string = "";
		TimeZone: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class BillingReportProfileEditViewModel {
		BillingReportProfileId: number = null;
		Description: string = "";
		Enabled: boolean = false;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		VisibilityCostCenterType: string[] = [];
		VisibilityAccountType: string = "";
		VisibilityAccountClass: string = "";
		VisibilityMarketType: string = "";
		VisibilityContactId: number = null;
		VisibilityContactName: string = "";
		DefaultProfile: boolean = false;
		InvoiceLanguage: string = "";
		PrimaryInvoiceMessageAssetId: number = null;
		SecondaryInvoiceMessageAssetId: number = null;
		DefaultAddressType: string = "";
		BillToAddressBlock: string = "";
		RemitToName: string = "";
		RemitToAddressBlock: string = "";
		RemitToPostalCode: string = "";
		RemitToLogoImage: string = "";
		RemitToLogoAssetId: number = null;
		RemitToMessageAssetId: number = null;
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class BillingTransactionBatchEditViewModel {
		BillingTransactionBatchId: number = null;
		TransactionType: string = "";
		BatchType: string = "";
		TransactionDescription: string = "";
		TransactionComments: string = "";
		AsOfDateTime: Date = null;
		BillingPeriodBeginningDateTime: Date = null;
		BillingPeriodEndingDateTime: Date = null;
		BillingCycle: string = "";
		AccountType: string = "";
		AccountClass: string = "";
		MarketType: string = "";
		AgentContactId: number = null;
		AgentContactName: string = "";
		IsoCurrencyCode: string = "";
		ContactFilterId: number = null;
		BillingAccountFilterId: number = null;
		TransactionCount: number = 0;
		PendingCount: number = 0;
		RunningCount: number = 0;
		SuccessCount: number = 0;
		WarningCount: number = 0;
		ErrorCount: number = 0;
		TraceOnlyCount: number = 0;
		ReviewPendingCount: number = 0;
		ReviewCompletedCount: number = 0;
		BatchAmount: number = 0;
		ApprovedDateTime: Date = null;
		ApprovedByContactId: number = null;
		ApprovedByContactName: string = "";
		ApprovalComments: string = "";
		IncludeTrace: boolean = false;
		Status: string = "";
		ProcessingServer: string = "";
		ProcessingDateTime: Date = null;
		Flags: string = "";
		Properties: string = "";
		Trace: string = "";
		Comments: string = "";
		Attributes: any = null;
		BillingTransactions: BillingTransactionEditViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class BillingTransactionEditViewModel {
		BillingTransactionId: number = null;
		BillingAccountId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		TransactionType: string = "";
		TransactionCount: number = 0;
		TransactionDate: Date = null;
		TransactionNumber: string = "";
		ExternalTransactionNumber: string = "";
		TransactionMethod: string = "";
		Description: string = "";
		IsoCurrencyCode: string = "";
		Amount: number = 0;
		AmountPaid: number = null;
		Tax: number = 0;
		TaxInformation: {[key: string]:  string} = null;
		BeginningDate: Date = null;
		EndingDate: Date = null;
		DueDate: Date = null;
		AddressType: string = "";
		Address: string = "";
		AddressPostalCode: string = "";
		ActivityCount: number = 0;
		EarliestActivityDate: Date = null;
		ActivityIncluded: string = "";
		BillingTransactionBatchId: number = null;
		LinkedBillingTransactionId: number = null;
		PrintBatch: number = 0;
		Status: string = "";
		ExportStatus: string = "";
		PrintStatus: string = "";
		ProcessingServer: string = "";
		ProcessingDateTime: Date = null;
		Flags: string = "";
		Properties: string = "";
		Trace: string = "";
		Comments: string = "";
		Attributes: any = null;
		Details: BillingTransactionDetailEditViewModel[] = [];
		CommissionDetails: BillingTransactionCommissionDetailEditViewModel[] = [];
		PaymentDetails: BillingTransactionPaymentDetailEditViewModel[] = [];
		Payments: BillingTransactionPaymentDetailEditViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class BillingTransactionDetailEditViewModel {
		BillingTransactionDetailId: number = null;
		BillingTransactionId: number = null;
		PackageOccurrenceId: number = null;
		PackageOccurrenceItemId: number = null;
		PackageItemId: number = null;
		SequenceNumber: number = 0;
		Quantity: number = 0;
		ItemId: number = null;
		ItemRateId: number = null;
		Description: string = "";
		PriceEach: number = 0;
		Amount: number = 0;
		ShowAmountOnly: boolean = false;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		AccountingBeginningDateTime: Date = null;
		AccountingEndingDateTime: Date = null;
		BillingBeginningDateTime: Date = null;
		BillingEndingDateTime: Date = null;
		Status: string = "";
		Trace: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class BillingTransactionCommissionDetailEditViewModel {
		BillingTransactionCommissionDetailId: number = null;
		BillingTransactionId: number = null;
		AgentContactId: number = null;
		AgentContactName: string = "";
		AgentPlanId: number = null;
		ActualAgentPlanId: number = null;
		AgentPlanDetailId: number = null;
		AgentPlanMethod: string = "";
		BaseBillingTransactionId: number = null;
		BaseBillingTransactionDetailId: number = null;
		PaymentBillingTransactionId: number = null;
		PaymentPercent: number = 0;
		CommissionAmount: number = 0;
		AccountingBeginningDateTime: Date = null;
		AccountingEndingDateTime: Date = null;
		BillingBeginningDateTime: Date = null;
		BillingEndingDateTime: Date = null;
		Status: string = "";
		Trace: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class BillingTransactionPaymentDetailEditViewModel {
		BillingTransactionPaymentDetailId: number = null;
		BillingTransactionId: number = null;
		PaidBillingTransactionId: number = null;
		Amount: number = 0;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class BillingTransactionListViewModel {
		BillingTransactionId: number = null;
		BillingAccountId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		TransactionType: string = "";
		TransactionDate: Date = null;
		TransactionNumber: string = "";
		ExternalTransactionNumber: string = "";
		TransactionMethod: string = "";
		Amount: number = 0;
		AmountPaid: number = null;
		BeginningDate: Date = null;
		EndingDate: Date = null;
		DueDate: Date = null;
		Status: string = "";
		ExportStatus: string = "";
		PrintStatus: string = "";
		TimeZone: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class ItemListEditViewModel {
		ItemListId: number = null;
		ItemId: number = null;
		LinkedItemId: number = null;
		LinkedItemNumber: string = "";
		LinkedTaxType: string = "";
		LinkedTaxCategory: string = "";
		LinkedTaxItemCode: string = "";
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ItemRateEditViewModel {
		ItemRateId: number = null;
		ItemId: number = null;
		RateId: string = null;
		GeneralLedgerCode: string = "";
		CommissionMaximumCheck: string = "";
		CommissionMaximumValue: number = 0;
		BillingPeriodsWhenAutoPay: number = 0;
		BillingPeriodsWhenNotAutoPay: number = 0;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		IsoCurrencyCode: string = "";
		RateCalculationFlag: string = "";
		RateStep1Quantity: number = 0;
		RateStep1Rate: number = 0;
		RateStep1MaximumQuantity: number = 0;
		RateStep2Quantity: number = 0;
		RateStep2Rate: number = 0;
		RateStep2MaximumQuantity: number = 0;
		RateStep3Quantity: number = 0;
		RateStep3Rate: number = 0;
		RateStep3MaximumQuantity: number = 0;
		RateStep4Quantity: number = 0;
		RateStep4Rate: number = 0;
		RateStep4MaximumQuantity: number = 0;
		SetupRate: number = 0;
		MinimumRate: number = 0;
		MaximumRate: number = 0;
		SurchargeCalculationFlag: string = "";
		SurchargeStep1Quantity: number = 0;
		SurchargeStep1Rate: number = 0;
		SurchargeStep1Percent: number = 0;
		SurchargeStep1MaximumQuantity: number = 0;
		SurchargeStep2Quantity: number = 0;
		SurchargeStep2Rate: number = 0;
		SurchargeStep2Percent: number = 0;
		SurchargeStep2MaximumQuantity: number = 0;
		SurchargeStep3Quantity: number = 0;
		SurchargeStep3Rate: number = 0;
		SurchargeStep3Percent: number = 0;
		SurchargeStep3MaximumQuantity: number = 0;
		SurchargeStep4Quantity: number = 0;
		SurchargeStep4Rate: number = 0;
		SurchargeStep4Percent: number = 0;
		SurchargeStep4MaximumQuantity: number = 0;
		SurchargeSetupRate: number = 0;
		SurchargePercent: number = 0;
		SurchargeMinimumRate: number = 0;
		SurchargeMaximumRate: number = 0;
		TaxCalculationMethod: string = "";
		Flags: string = "";
		Rules: string = "";
		Properties: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ItemEditViewModel {
		ItemId: number = null;
		ItemNumber: string = "";
		ExternalItemNumber: string = "";
		Description: string = "";
		ItemCategory: string = "";
		ItemType: string = "";
		ItemClass: string = "";
		ItemGroup: string = "";
		VisibilityAccountType: string = "";
		VisibilityAccountClass: string = "";
		VisibilityMarketType: string = "";
		VisibilityContactId: number = null;
		VisibilityContactName: string = "";
		ServiceType: string = "";
		GeneralLedgerCode: string = "";
		TaxItemCode: string = "";
		TaxCalculationMethod: string = "";
		InventoryItem: boolean = false;
		InventoryId: number = null;
		InventoryTypeId: number = null;
		CommissionMaximumCheck: string = "";
		CommissionMaximumValue: number = 0;
		CalculationType: string = "";
		CalculationFixedBillingPeriod: string = "";
		CalculationFixedProrate: string = "";
		CalculationFixedConsolidate: string = "";
		CalculationPercentFlag: string = "";
		CalculationPercentConsolidate: string = "";
		CalculationTaxConsolidate: string = "";
		CalculationActivityTypeList: string[] = [];
		CalculationActivityTypeQuantityProperty: string = "";
		CalculationActivityTypeRateFlag: string = "";
		CalculationActivityFilterId: number = null;
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PackageListViewModel {
		PackageId: number = null;
		ExternalPackageId: string = null;
		Description: string = "";
		FullDescription: string = "";
		PackageType: string = "";
		PackageCode: string = "";
		PackageClass: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class PackageEditViewModel {
		PackageId: number = null;
		ExternalPackageId: string = null;
		Description: string = "";
		FullDescription: string = "";
		PackageOccurrenceDescription: string = "";
		PackageType: string = "";
		PackageCode: string = "";
		PackageClass: string = "";
		Enabled: boolean = false;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		IsoCurrencyCode: string = "";
		VisibilityCostCenterType: string[] = [];
		VisibilityAccountType: string = "";
		VisibilityAccountClass: string = "";
		VisibilityMarketType: string = "";
		VisibilityServiceType: string = "";
		VisibilityBillingCycle: string = "";
		VisibilityContactId: number = null;
		VisibilityContactName: string = "";
		PackageSelectable: boolean = false;
		QuantitySelectable: boolean = false;
		CaseTemplateIdStart: number = 0;
		CaseTemplateIdModify: number = 0;
		CaseTemplateIdClose: number = 0;
		CaseTemplateIdConvert: number = 0;
		CaseBasedActivation: boolean = false;
		CaseBasedCancellation: boolean = false;
		ConversionTriggerCaseStart: boolean = false;
		ConversionTriggerCaseClose: boolean = false;
		NotificationGroupIdStart: number = 0;
		NotificationGroupIdActivate: number = 0;
		NotificationGroupIdModify: number = 0;
		NotificationGroupIdCancel: number = 0;
		NotificationGroupIdClose: number = 0;
		NotificationGroupIdConvert: number = 0;
		ContractRequired: boolean = false;
		ContractDurationMonths: number = 0;
		ContractAutoRenew: boolean = false;
		ContractRenewDurationMonths: number = 0;
		ContractCanCancel: boolean = false;
		ContractCanConvert: string = "";
		TaxItemCode: string = "";
		TaxCalculationMethod: string = "";
		LifeTimeMinutes: number = 0;
		InvoiceOnActivation: boolean = false;
		PackageConversionFlag: string = "";
		AgentTrackingFlag: string = "";
		AgentPlanId: number = null;
		QuantityMinimum: number = 0;
		QuantityMaximum: number = 0;
		QuantityCalculationFlag: string = "";
		ValidBillingPeriodFlag: string = "";
		RateAdjustmentFlag: string = "";
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PackageAssociationEditViewModel {
		PackageAssociationId: number = null;
		PackageId: number = null;
		AssociationType: string = "";
		Description: string = "";
		FullDescription: string = "";
		QuantitySelectable: boolean = false;
		QuantityMinimum: number = 0;
		QuantityMaximum: number = 0;
		ActivateActionFlag: string = "";
		CloseActionFlag: string = "";
		LagDaysClose: number = 0;
		LagDaysUnassigned: number = 0;
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PackageInventoryEditViewModel {
		PackageInventoryId: number = null;
		PackageId: number = null;
		InventoryTypeId: number = null;
		Description: string = "";
		FullDescription: string = "";
		QuantitySelectable: boolean = false;
		QuantityMinimum: number = 0;
		QuantityMaximum: number = 0;
		ActivateActionFlag: string = "";
		UnassignedContactId: number = null;
		UnassignedContactName: string = "";
		CloseActionFlag: string = "";
		MoveContactId: number = null;
		MoveContactName: string = "";
		LagDaysClose: number = 0;
		LagDaysUnassigned: number = 0;
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PackageItemEditViewModel {
		PackageItemId: number = null;
		PackageId: number = null;
		ItemTrigger: string = "";
		ItemTriggerBillingPeriod: number = 0;
		Enabled: boolean = false;
		SequenceNumber: number = 0;
		QuantitySelectable: boolean = false;
		Quantity: number = 0;
		ItemId: number = null;
		Description: string = "";
		RateId: string = null;
		ActivityFilterId: number = null;
		ValidStartBillingPeriod: number = 0;
		ValidEndBillingPeriod: number = 0;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PackageOccurrenceListViewModel {
		PackageOccurrenceId: number = null;
		PackageId: number = null;
		ExternalPackageOccurrenceId: string = null;
		BillingAccountId: number = null;
		SequenceNumber: number = 0;
		Description: string = "";
		ActivationFlag: string = "";
		CancellationFlag: string = "";
		Status: string = "";
		OrderedDateTime: Date = null;
		RequestedStartDateTime: Date = null;
		ActivatedDateTime: Date = null;
		ContractEndDateTime: Date = null;
		SuspendedDateTime: Date = null;
		ReactivatedDateTime: Date = null;
		CancelledDateTime: Date = null;
		ClosedDateTime: Date = null;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		TimeZone: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class PackageOccurrenceEditViewModel {
		PackageOccurrenceId: number = null;
		PackageId: number = null;
		ExternalPackageOccurrenceId: string = null;
		BillingAccountId: number = null;
		SequenceNumber: number = 0;
		Description: string = "";
		AgentContactId: number = null;
		AgentContactName: string = "";
		AgentPlanId: number = null;
		CaseIdStart: number = 0;
		CaseIdClose: number = 0;
		ActivationFlag: string = "";
		CancellationFlag: string = "";
		ContractCancelled: boolean = false;
		ConvertedFromPackageOccurrenceId: number = null;
		ConvertedToPackageOccurrenceId: number = null;
		Status: string = "";
		StatusLocked: boolean = false;
		OrderedDateTime: Date = null;
		RequestedStartDateTime: Date = null;
		ActivatedDateTime: Date = null;
		ContractEndDateTime: Date = null;
		SuspendedDateTime: Date = null;
		ReactivatedDateTime: Date = null;
		CancelledDateTime: Date = null;
		ClosedDateTime: Date = null;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		Properties: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PackageOccurrenceItemEditViewModel {
		PackageOccurrenceItemId: number = null;
		PackageOccurrenceId: number = null;
		PackageId: number = null;
		PackageItemId: number = null;
		ItemTrigger: string = "";
		ItemTriggerBillingPeriod: number = 0;
		Enabled: boolean = false;
		SequenceNumber: number = 0;
		Quantity: number = 0;
		ItemId: number = null;
		Description: string = "";
		RateId: string = null;
		ActivityFilterId: number = null;
		ValidStartBillingPeriod: number = 0;
		ValidEndBillingPeriod: number = 0;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PackageOccurrenceRateAdjustmentEditViewModel {
		PackageOccurrenceRateAdjustmentId: number = null;
		PackageOccurrenceId: number = null;
		PackageId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		ParentBillingAccountId: number = null;
		BillingAccountId: number = null;
		TypeList: string[] = [];
		TargetLocationList: string[] = [];
		BillingPeriodStartDateTime: Date = null;
		BillingPeriodEndDateTime: Date = null;
		UnitBasis: string = "";
		UnitType: string = "";
		UnitsFullValue: number = 0;
		UnitsCurrentValue: number = 0;
		UnitsPushForwardValue: number = 0;
		UnitsPullForwardValue: number = 0;
		SurchargeOrDiscount: number = 0;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PackageRateAdjustmentEditViewModel {
		PackageRateAdjustmentId: number = null;
		PackageId: number = null;
		TypeList: string[] = [];
		TargetLocationList: string[] = [];
		UnitBasis: string = "";
		UnitType: string = "";
		UnitsFullValue: number = 0;
		SurchargeOrDiscount: number = 0;
		ValidStartBillingPeriod: number = 0;
		ValidEndBillingPeriod: number = 0;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PayPalAuthorizationRequestModel {
		PaymentProviderId: string = null;
		ReturnUrl: string = "";
		CancelUrl: string = "";
		Packages: PayPalAuthorizationRequestPackageModel[] = [];
		Attributes: any = null;
		Cargo: any = null;
		Options: {[key: string]:  string} = null;
		UtcOffset: number = null;
		TimeZone: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class PayPalAuthorizationRequestPackageModel {
		PackageId: number = null;
		PackageType: string = "";
		PackageCode: string = "";
		PackageClass: string = "";
		Description: string = "";
		IsoCurrencyCode: string = "";
		RetailAmount: number = 0;
		BillingAmount: number = 0;
		AuthorizationCode: string = "";
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		Attributes: any = null;
		Options: {[key: string]:  string} = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PurchaseRequestModel {
		TransactionId: string = null;
		CustomerId: number = null;
		Customer: CustomerEditViewModel = null;
		BillingAccountId: number = null;
		BillingAccount: BillingAccountEditViewModel = null;
		Packages: PurchaseRequestPackageModel[] = [];
		Payment: PurchaseRequestPaymentModel = null;
		LinkedPurchaseCheckoutId: string = null;
		WorkflowCaseTemplateId: number = null;
		WorkflowAlwaysCreatesCase: boolean = false;
		WorkflowTimeoutSeconds: number = 0;
		Attributes: any = null;
		Cargo: any = null;
		Options: {[key: string]:  string} = null;
		UtcOffset: number = null;
		TimeZone: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class CustomerEditViewModel {
		CustomerId: number = null;
		ExternalCustomerId: string = null;
		CustomerName: string = "";
		FirstName: string = "";
		MiddleName: string = "";
		LastName: string = "";
		OtherName: string = "";
		CourtesyTitle: string = "";
		PostNominalTitles: string = "";
		Title: string = "";
		Address1: string = "";
		Address2: string = "";
		Address3: string = "";
		City: string = "";
		State: string = "";
		PostalCode: string = "";
		County: string = "";
		Country: string = "";
		Phone: string = "";
		Fax: string = "";
		HomePhone: string = "";
		Cellular: string = "";
		AlternatePhone: string = "";
		Extension: string = "";
		Email: string = "";
		AlternateEmail: string = "";
		ImAddress: string = "";
		ImNetwork: string = "";
		AlternateImAddress: string = "";
		AlternateImNetwork: string = "";
		WebSite: string = "";
		FtpSite: string = "";
		TaxId: string = null;
		TickerSymbol: string = "";
		SicCode: string = "";
		Industry: string = "";
		ReferredBy: string = "";
		RepresentativeDirectoryId: number = null;
		RepresentativeDirectoryName: string = "";
		AgentId: number = null;
		AgentName: string = "";
		WebLanguage: string = "";
		IvrLanguage: string = "";
		TimeZoneId: string = null;
		Salutation: string = "";
		AssistantName: string = "";
		ManagerName: string = "";
		SpouseName: string = "";
		ChildrenNames: string = "";
		DepartmentName: string = "";
		Office: string = "";
		Profession: string = "";
		Hobbies: string = "";
		Gender: string = "";
		BirthDate: Date = null;
		AnniversaryDate: Date = null;
		NotifyAlert: boolean = false;
		NotifyIssueChange: boolean = false;
		NotifyIssueChangeGlobal: boolean = false;
		NotifyProductInfo: boolean = false;
		NotifyVendorInfo: boolean = false;
		NotifyThirdPartyInfo: boolean = false;
		NotifyFormat: string = "";
		NotifyStandardStartTime: string = "";
		NotifyStandardEndTime: string = "";
		NotifyUrgentStartTime: string = "";
		NotifyUrgentEndTime: string = "";
		Status: string = "";
		StatusLocked: boolean = false;
		CreateDateTime: Date = null;
		SuspendDateTime: Date = null;
		CloseDateTime: Date = null;
		ReactivateDateTime: Date = null;
		IsoCurrencyCode: string = "";
		CurrencyFormat: string = "";
		Login: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PurchaseRequestPackageModel {
		PackageId: number = null;
		PackageType: string = "";
		PackageCode: string = "";
		PackageClass: string = "";
		Description: string = "";
		IsoCurrencyCode: string = "";
		RetailAmount: number = 0;
		BillingAmount: number = 0;
		TaxAmount: number = 0;
		TaxScenarioId: string = null;
		ExternalAssociationId: string = null;
		AuthorizationCode: string = "";
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		Attributes: any = null;
		Payment: PurchaseRequestPaymentModel = null;
		Options: {[key: string]:  string} = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PurchaseRequestPaymentModel {
		Complimentary: boolean = false;
		ComplimentaryReason: string = "";
		PaymentMethodId: number = null;
		CreditCard: m5pay.PaymentMethodCreditCardEditViewModel = null;
		ExternalPayment: PurchaseRequestExternalPaymentModel = null;
		Transaction: m5pay.PaymentTransactionEditViewModel = null;
		VoucherCode: string = "";
		VoucherPreapproved: boolean = false;
		PaymentProviderId: string = null;
		PaymentProviderSelector: string = "";
		PaymentTransactionGroupingId: string = null;
		EmailReceipt: boolean = false;
		Options: {[key: string]:  string} = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PurchaseRequestExternalPaymentModel {
		PaymentProvider: string = "";
		TransactionId: string = null;
		PayerId: string = null;
		Token: string = "";
		PaymentType: string = "";
		Options: {[key: string]:  string} = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PayPalAuthorizationResponseModel {
		PayPalPaymentId: string = null;
		PayPalApprovalUrl: string = "";
		ReturnUrl: string = "";
		CancelUrl: string = "";
		PaymentProviderId: string = null;
		Cargo: any = null;
		UtcOffset: number = null;
		TimeZone: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class PurchaseResponseModel {
		ResponseCode: PurchaseResponseCode = null;
		ResponseMessage: string = "";
		TransactionId: string = null;
		PurchaseCheckoutId: string = null;
		CustomerId: number = null;
		BillingAccountId: number = null;
		PaymentMethodId: number = null;
		PaymentMethod: string = "";
		PaymentMethodDetail: string = "";
		PaymentTransactionId: string = null;
		PaymentTransactionOrderReference: string = "";
		Packages: PurchaseResponsePackageModel[] = [];
		ContactInformation: PurchaseResponseContactInformationModel = null;
		Attributes: any = null;
		Cargo: any = null;
		UtcOffset: number = null;
		TimeZone: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class PurchaseResponsePackageModel {
		ResponseCode: PurchaseResponseCode = null;
		ResponseMessage: string = "";
		PackageOccurrenceId: number = null;
		PackageId: number = null;
		PackageType: string = "";
		PackageCode: string = "";
		PackageClass: string = "";
		PaymentMethodId: number = null;
		PaymentMethod: string = "";
		PaymentMethodDetail: string = "";
		PaymentTransactionId: string = null;
		PaymentTransactionOrderReference: string = "";
		IsoCurrencyCode: string = "";
		RetailAmount: number = 0;
		BillingAmount: number = 0;
		TaxAmount: number = 0;
		TaxScenarioId: string = null;
		AuthorizationCode: string = "";
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		Attributes: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PurchaseResponseContactInformationModel {
		Source: string = "";
		Type: string = "";
		Id: string = null;
		ExternalId: string = null;
		FirstName: string = "";
		LastName: string = "";
		Name: string = "";
		OtherName: string = "";
		Address1: string = "";
		Address2: string = "";
		Address3: string = "";
		City: string = "";
		State: string = "";
		PostalCode: string = "";
		Country: string = "";
		Phone: string = "";
		Email: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class CachePackageSignupEditViewModel {
		PackageOccurrenceId: number = null;
		BillingAccountId: number = null;
		ContactId: string = null;
		ContactId2: number = 0;
		ExternalContactId: string = null;
		ContactName: string = "";
		FirstName: string = "";
		LastName: string = "";
		State: string = "";
		Country: string = "";
		Phone: string = "";
		Email: string = "";
		PackageId: number = null;
		PackageType: string = "";
		PackageClass: string = "";
		PackageCode: string = "";
		ProductId: string = null;
		ProductName: string = "";
		EventDateTime: Date = null;
		ActivatedDateTime: Date = null;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		AssociationId: number = null;
		AssociationType: string = "";
		AssociationValue: string = "";
		PaymentMethod: string = "";
		PaymentMethodDetail: string = "";
		PaymentMethodInformation: string = "";
		VoucherCode: string = "";
		VoucherId: number = null;
		VoucherType: string = "";
		PaymentMethodId: number = null;
		PaymentMethodType: string = "";
		PaymentMethodCardTypeId: string = null;
		PaymentMethodTypeId: number = null;
		AccountNumberReferenceSubset: string = "";
		PaymentRequestDateTime: Date = null;
		PaymentTransactionId: number = null;
		PaymentTransactionOrderReference: string = "";
		PaymentTransactionPaymentId: string = null;
		IsoCurrencyCode: string = "";
		PaymentAmount: number = 0;
		RetailAmount: number = 0;
		TaxAmount: number = 0;
		TaxInformation: string = "";
		Status: number = 0;
		LastUpdatedDateTime: Date = null;
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class CacheSummaryDataEditViewModel {
		CacheSummaryDataId: number = null;
		CacheSummaryConfigurationId: number = null;
		DateRangeType: string = "";
		BeginningDateTime: Date = null;
		EndingDateTime: Date = null;
		SegmentValue01: string = "";
		SegmentValue01Description: string = "";
		SegmentValue02: string = "";
		SegmentValue02Description: string = "";
		SegmentValue03: string = "";
		SegmentValue03Description: string = "";
		SegmentValue04: string = "";
		SegmentValue04Description: string = "";
		SegmentValue05: string = "";
		SegmentValue05Description: string = "";
		SegmentValue06: string = "";
		SegmentValue06Description: string = "";
		SegmentValue07: string = "";
		SegmentValue07Description: string = "";
		SegmentValue08: string = "";
		SegmentValue08Description: string = "";
		Value001: string = "";
		Value002: string = "";
		Value003: string = "";
		Value004: string = "";
		Value005: string = "";
		Value006: string = "";
		Value007: string = "";
		Value008: string = "";
		Value009: string = "";
		Value010: string = "";
		Value011: string = "";
		Value012: string = "";
		Value013: string = "";
		Value014: string = "";
		Value015: string = "";
		Value016: string = "";
		Value017: string = "";
		Value018: string = "";
		Value019: string = "";
		Value020: string = "";
		Value021: number = 0;
		Value022: number = 0;
		Value023: number = 0;
		Value024: number = 0;
		Value025: number = 0;
		Value026: number = 0;
		Value027: number = 0;
		Value028: number = 0;
		Value029: number = 0;
		Value030: number = 0;
		Value031: number = 0;
		Value032: number = 0;
		Value033: number = 0;
		Value034: number = 0;
		Value035: number = 0;
		Value036: number = 0;
		Value037: number = 0;
		Value038: number = 0;
		Value039: number = 0;
		Value040: number = 0;
		Value041: number = 0;
		Value042: number = 0;
		Value043: number = 0;
		Value044: number = 0;
		Value045: number = 0;
		Value046: number = 0;
		Value047: number = 0;
		Value048: number = 0;
		Value049: number = 0;
		Value050: number = 0;
		Value051: number = 0;
		Value052: number = 0;
		Value053: number = 0;
		Value054: number = 0;
		Value055: number = 0;
		Value056: number = 0;
		Value057: number = 0;
		Value058: number = 0;
		Value059: number = 0;
		Value060: number = 0;
		Value061: number = 0;
		Value062: number = 0;
		Value063: number = 0;
		Value064: number = 0;
		Value065: number = 0;
		Value066: number = 0;
		Value067: number = 0;
		Value068: number = 0;
		Value069: number = 0;
		Value070: number = 0;
		Value071: number = 0;
		Value072: number = 0;
		Value073: number = 0;
		Value074: number = 0;
		Value075: number = 0;
		Value076: number = 0;
		Value077: number = 0;
		Value078: number = 0;
		Value079: number = 0;
		Value080: number = 0;
		Value081: number = 0;
		Value082: number = 0;
		Value083: number = 0;
		Value084: number = 0;
		Value085: number = 0;
		Value086: number = 0;
		Value087: number = 0;
		Value088: number = 0;
		Value089: number = 0;
		Value090: number = 0;
		Value091: number = 0;
		Value092: number = 0;
		Value093: number = 0;
		Value094: number = 0;
		Value095: number = 0;
		Value096: number = 0;
		Value097: number = 0;
		Value098: number = 0;
		Value099: number = 0;
		Value100: number = 0;
		AsOfDateTime: Date = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class CaseAccessEditViewModel {
		CaseAccessId: number = null;
		CaseId: number = null;
		CaseTaskListId: number = null;
		CaseAccessRole: string = "";
		ExternalCaseNumber: string = "";
		ContactId: number = null;
		ContactName: string = "";
		BillingRate: number = 0;
		Budget: number = 0;
		NotificationEntity: string = "";
		NotificationChanges: boolean = false;
		NotificationCorrespondence: boolean = false;
		ReminderAll: boolean = false;
		ScopeTaskAll: boolean = false;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class CaseCorrespondenceRecipientEditViewModel {
		CaseCorrespondenceRecipientId: number = null;
		CaseCorrespondenceId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		HiddenRecipient: boolean = false;
		ReadDateTime: Date = null;
		RepliedTo: boolean = false;
		RequireReadConfirmation: boolean = false;
		ReadConfirmationDateTime: Date = null;
		LastReadReminderDateTime: Date = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class CaseCorrespondenceEditViewModel {
		CaseCorrespondenceId: number = null;
		CaseId: number = null;
		Method: string = "";
		MethodType: string = "";
		MethodCorrespondenceId: string = null;
		MethodReplyToCorrespondenceId: string = null;
		CorrespondenceFromContactId: number = null;
		CorrespondenceFromContactName: string = "";
		CorrespondenceFrom: string = "";
		CorrespondenceTo: string = "";
		CorrespondenceCc: string = "";
		Subject: string = "";
		Correspondence: string = "";
		CorrespondenceAlternateFormat: string = "";
		HasAttachments: boolean = false;
		SuppressNotification: boolean = false;
		PublicCorrespondence: boolean = false;
		ReplyToCaseCorrespondenceId: number = null;
		CorrespondenceDateTime: Date = null;
		ReadDateTime: Date = null;
		ReplyNeeded: boolean = false;
		Attachments: AssetListViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class AssetListViewModel {
		AssetId: number = null;
		ExternalAssetId: string = null;
		Title: string = "";
		ShortDescription: string = "";
		ExternalUrl: string = "";
		FriendlyName: string = "";
		SystemAssetId: string = null;
		SystemAssetGroup: string = "";
		AssetType: string = "";
		FileType: string = "";
		ContentType: string = "";
		SizeBytes: number = 0;
		SizeOther: number = 0;
		SizeInformation: string = "";
		Width: number = 0;
		Height: number = 0;
		DisplayOrder: number = 0;
		Priority: number = 0;
		AssetCategory: string = "";
		AssetClass: string = "";
		AssetGroup: string = "";
		AssetScope: string = "";
		Visibility: string = "";
		Language: string = "";
		VersionId: string = null;
		VersionNumber: number = 0;
		VersionCurrent: boolean = false;
		VersionFinal: boolean = false;
		VersionInventoryTypeId: number = null;
		VersionStarting: string = "";
		VersionEnding: string = "";
		NeedsReview: boolean = false;
		ReviewedDateTime: Date = null;
		ReviewComments: string = "";
		NextReviewDateTime: Date = null;
		NextReviewContactId: number = null;
		NextReviewContactName: string = "";
		ApprovedDateTime: Date = null;
		HitCount: number = 0;
		LastHitDateTime: Date = null;
		PromptHelped: boolean = false;
		PromptRating: boolean = false;
		PromptFeedback: boolean = false;
		AllowAnonymousFeedback: boolean = false;
		HelpedYes: number = 0;
		HelpedYesPercent: number = 0;
		HelpedNo: number = 0;
		HelpedNoPercent: number = 0;
		HelpedNa: number = 0;
		HelpedNaPercent: number = 0;
		HelpedTotal: number = 0;
		Rating01: number = 0;
		Rating01Percent: number = 0;
		Rating02: number = 0;
		Rating02Percent: number = 0;
		Rating03: number = 0;
		Rating03Percent: number = 0;
		Rating04: number = 0;
		Rating04Percent: number = 0;
		Rating05: number = 0;
		Rating05Percent: number = 0;
		RatingAverage: number = 0;
		RatingTotal: number = 0;
		OwnerResourceType: string = "";
		OwnerResourceId: number = null;
		OwnerResourceId2: string = "";
		Value001: string = "";
		Value002: string = "";
		Value003: string = "";
		Value004: string = "";
		Value005: string = "";
		Value006: string = "";
		Value007: string = "";
		Value008: string = "";
		Value009: string = "";
		Value010: string = "";
		AddedDateTime: Date = null;
		AddedByContactId: number = null;
		AddedByContactName: string = "";
		LastEditedDateTime: Date = null;
	}


    // This is generated code... DO NOT modify this file.
	export class CaseContactSummaryViewModel {
		ContactId: number = null;
		ContactName: string = "";
		ContactType: string = "";
		Grouping1: string = "";
		Grouping2: string = "";
		Grouping3: string = "";
		Total: number = 0;
		Active: number = 0;
		Closed: number = 0;
		ReviewInProgress: number = 0;
		Other: number = 0;
		PastDue: number = 0;
	}


    // This is generated code... DO NOT modify this file.
	export class AssetArticleGroupEditViewModel {
		Title: string = "";
		Icon: string = "";
		RootContents: AssetArticleGroupContentEditViewModel[] = [];
		Structure: AssetArticleGroupStructureEditViewModel[] = [];
		StructureContents: AssetArticleGroupContentEditViewModel[] = [];
		TableOfContentsCodeSnippetAssetId: number = null;
		TableOfContentsCodeSnippetAssetTitle: string = "";
		ChildContentLinkCodeSnippetAssetId: number = null;
		ChildContentLinkCodeSnippetAssetTitle: string = "";
		IncludeArticleSeeAlsoItems: boolean = false;
		IncludeArticleSeeAlsoItemsNotIncludedInArticleGroup: boolean = false;
		IncludeIndex: boolean = false;
		IncludeBreadcrumbs: boolean = false;
		TableOfContentsPicker: boolean = false;
		VersionPicker: boolean = false;
		VersionInventoryTypeId: number = null;
		VersionMaximumAgeDays: number = 0;
		LanguagePicker: boolean = false;
		LanguageDefault: string = "";
		LanguageOptions: string[] = [];
		NavHome: AssetArticleGroupNavEditViewModel = null;
		NavTableOfContents: AssetArticleGroupNavEditViewModel = null;
		NavIndex: AssetArticleGroupNavEditViewModel = null;
		NavPrevious: AssetArticleGroupNavEditViewModel = null;
		NavNext: AssetArticleGroupNavEditViewModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class AssetArticleGroupContentEditViewModel {
		Title: string = "";
		Icon: string = "";
		Slug: string = "";
		ContentAssetId: number = null;
		ContentAssetTitle: string = "";
		ContentAssetIsArticleGroup: boolean = false;
		ContentType: string = "";
		ContentText: string = "";
		Contents: AssetArticleGroupContentEditViewModel[] = [];
		Structure: AssetArticleGroupStructureEditViewModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class AssetArticleGroupStructureEditViewModel {
		StructureLevel: number = 0;
		StructureName: string = "";
		StructureIcon: string = "";
		StructureTitleTemplate: string = "";
		Numbering: string = "";
		ChildContentLinkLocation: string = "";
		ChildContentLinkCodeSnippetAssetId: number = null;
		ChildContentLinkCodeSnippetAssetTitle: string = "";
		ShowOnMenu: boolean = false;
		ShowInTableOfContents: boolean = false;
		ShowInChildContentLinks: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class AssetArticleGroupNavEditViewModel {
		Enabled: boolean = false;
		Icon: string = "";
		Label: string = "";
		Description: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class AssetOptionsArticleEditViewModel {
		SeeAlsoCodeSnippetAssetId: number = null;
		SeeAlsoCodeSnippetAssetTitle: string = "";
		SeeAlsoUseArticleDisplayOrderWhenOrderNotExplicitlySet: boolean = false;
		SeeAlsoItems: AssetOptionsSeeAlsoItemEditViewModel[] = [];
		VersionStartingAllowedClosestCodeSnippetAssetId: number = null;
		VersionStartingAllowedClosestCodeSnippetAssetTitle: string = "";
		VersionEndingAllowedClosestCodeSnippetAssetId: number = null;
		VersionEndingAllowedClosestCodeSnippetAssetTitle: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class AssetOptionsSeeAlsoItemEditViewModel {
		SeeAlsoGroupName: string = "";
		SeeAlsoAssetId: number = null;
		SeeAlsoAssetTitle: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class AssetOptionsSeeAlsoResultViewModel {
		Id: number = null;
		Description: string = "";
		Slug: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class InventoryTypeVersionBatchAddModel {
		InventoryTypeIds: number[] = [];
		NumberOfVersionsToCreate: number = 0;
		ReleaseType: string = "";
		CreateBasedOnLastReleaseDate: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class ObjectStatusEditViewModel {
		ObjectStatusId: number = null;
		Description: string = "";
		StatusType: string = "";
		StatusCode: string = "";
		StatusAsOfDateTime: Date = null;
		Information: string = "";
		Detail: string = "";
		Property01: string = "";
		Value01: string = "";
		Property02: string = "";
		Value02: string = "";
		Property03: string = "";
		Value03: string = "";
		Property04: string = "";
		Value04: string = "";
		Property05: string = "";
		Value05: string = "";
		Property06: string = "";
		Value06: string = "";
		Property07: string = "";
		Value07: string = "";
		Property08: string = "";
		Value08: string = "";
		Property09: string = "";
		Value09: string = "";
		Property10: string = "";
		Value10: string = "";
		RegardingResourceType: string = "";
		RegardingResourceId: number = null;
		RegardingResourceId2: string = "";
		RegardingResourceScope: string = "";
		RegardingResourceDescription: string = "";
		ResolvedDateTime: Date = null;
		ResolvedByContactId: number = null;
		ResolvedByContactName: string = "";
		ResolvedComments: string = "";
		IgnoredDateTime: Date = null;
		IgnoredByContactId: number = null;
		IgnoredByContactName: string = "";
		IgnoredComments: string = "";
		ReviewedDateTime: Date = null;
		ReviewedByContactId: number = null;
		ReviewedByContactName: string = "";
		ReviewedComments: string = "";
		Flags: string[] = [];
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TaskSystemTaskActionEditViewModel {
		SystemTaskId: string = null;
		Type: string = "";
		Version: number = 0;
		Value: string = "";
		Properties: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TaskSystemTaskParametersEditViewModel {
		SystemTaskId: string = null;
		Type: string = "";
		Version: number = 0;
		CanReassign: boolean = false;
		CanEdit: boolean = false;
		CanCopy: boolean = false;
		CanChooseCompletionDate: boolean = false;
		CanDelete: boolean = false;
		Properties: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TaskSystemTaskResultsEditViewModel {
		SystemTaskId: string = null;
		Type: string = "";
		Version: number = 0;
		ResultCode: number = 0;
		ResultMessage: string = "";
		Properties: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TaskListStatusViewModel {
		TotalCount: number = 0;
		TotalCountHighPriority: number = 0;
		TotalCountNotHighPriority: number = 0;
		PersonalCount: number = 0;
		SharedCount: number = 0;
		LastUpdate: Date = null;
	}


    // This is generated code... DO NOT modify this file.
	export class CaseTemplateLayoutEditViewModel {
		WhenCustomAttributeForm: CaseTemplateLayoutWhenCustomAttributeFormEditViewModel = null;
		Properties: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class CaseTemplateLayoutWhenCustomAttributeFormEditViewModel {
		PositionTitle: string = "";
		PositionDescription: string = "";
		PositionNotes: string = "";
		PositionForm: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class CaseListViewModel {
		CaseId: number = null;
		CaseNumber: number = 0;
		ExternalCaseNumber: string = "";
		ContactCaseNumber: string = "";
		ParentCaseId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		SiteContactId: number = null;
		SiteContactName: string = "";
		OpenedByContactId: number = null;
		OpenedByContactName: string = "";
		OwnedByContactId: number = null;
		OwnedByContactName: string = "";
		AssignedToContactId: number = null;
		AssignedToContactName: string = "";
		SupervisorContactId: number = null;
		SupervisorContactName: string = "";
		SystemStatus: string = "";
		Type: string = "";
		Category: string = "";
		CaseGroup: string = "";
		Priority: string = "";
		PriorityRanking: number = 0;
		Status: string = "";
		Progress: string = "";
		PercentDone: number = 0;
		Outcome: string = "";
		RiskLevel: string = "";
		EventDateTime: Date = null;
		OpenedDateTime: Date = null;
		StartDateTime: Date = null;
		CheckDateTime: Date = null;
		DueDateTime: Date = null;
		EstimatedCloseDateTime: Date = null;
		ActualCloseDateTime: Date = null;
		ReportDateTime: Date = null;
		EstimatedTime: number = 0;
		ActualTime: number = 0;
		Title: string = "";
		Description: string = "";
		PromptHelped: boolean = false;
		PromptRating: boolean = false;
		PromptFeedback: boolean = false;
		HelpedYes: number = 0;
		HelpedYesPercent: number = 0;
		HelpedNo: number = 0;
		HelpedNoPercent: number = 0;
		HelpedNa: number = 0;
		HelpedNaPercent: number = 0;
		HelpedTotal: number = 0;
		Rating01: number = 0;
		Rating01Percent: number = 0;
		Rating02: number = 0;
		Rating02Percent: number = 0;
		Rating03: number = 0;
		Rating03Percent: number = 0;
		Rating04: number = 0;
		Rating04Percent: number = 0;
		Rating05: number = 0;
		Rating05Percent: number = 0;
		RatingAverage: number = 0;
		RatingTotal: number = 0;
		Publish: boolean = false;
		Faq: boolean = false;
		PublishCategory: string = "";
		ReadCount: number = 0;
		LastReadDateTime: Date = null;
		CorrespondenceReplyNeeded: boolean = false;
		CaseOpenItemsApplied: boolean = false;
		CaseCloseItemsApplied: boolean = false;
		ContactIdNotified: boolean = false;
		Sanitized: boolean = false;
		TimeZone: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class CaseEditViewModel {
		CaseId: number = null;
		CaseNumber: number = 0;
		ExternalCaseNumber: string = "";
		ContactCaseNumber: string = "";
		CaseTemplateId: number = null;
		ParentCaseId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		SiteContactId: number = null;
		SiteContactName: string = "";
		OpenedByContactId: number = null;
		OpenedByContactName: string = "";
		OwnedByContactId: number = null;
		OwnedByContactName: string = "";
		AssignedToContactId: number = null;
		AssignedToContactName: string = "";
		SupervisorContactId: number = null;
		SupervisorContactName: string = "";
		SystemStatus: string = "";
		Type: string = "";
		Category: string = "";
		CaseGroup: string = "";
		Priority: string = "";
		PriorityRanking: number = 0;
		Status: string = "";
		Progress: string = "";
		PercentDone: number = 0;
		Outcome: string = "";
		RiskLevel: string = "";
		RegardingResourceType: string = "";
		RegardingResourceId: number = null;
		RegardingResourceId2: string = "";
		RegardingResourceAssignedByContactId: number = null;
		RegardingResourceAssignedByContactName: string = "";
		RegardingResourceAssignedDateTime: Date = null;
		ReportedVersion: string = "";
		IdentifiedStartingVersion: string = "";
		IdentifiedEndingVersion: string = "";
		ResolvedVersion: string = "";
		EventDateTime: Date = null;
		OpenedDateTime: Date = null;
		StartDateTime: Date = null;
		CheckDateTime: Date = null;
		DueDateTime: Date = null;
		EstimatedCloseDateTime: Date = null;
		ActualCloseDateTime: Date = null;
		ReportDateTime: Date = null;
		EstimatedTime: number = 0;
		ActualTime: number = 0;
		Title: string = "";
		Description: string = "";
		Summary: string = "";
		Resolution: string = "";
		AppliesToList: string = "";
		DependsOnList: number[] = [];
		SeeAlsoList: number[] = [];
		Tags: string[] = [];
		PromptHelped: boolean = false;
		PromptRating: boolean = false;
		PromptFeedback: boolean = false;
		HelpedYes: number = 0;
		HelpedYesPercent: number = 0;
		HelpedNo: number = 0;
		HelpedNoPercent: number = 0;
		HelpedNa: number = 0;
		HelpedNaPercent: number = 0;
		HelpedTotal: number = 0;
		Rating01: number = 0;
		Rating01Percent: number = 0;
		Rating02: number = 0;
		Rating02Percent: number = 0;
		Rating03: number = 0;
		Rating03Percent: number = 0;
		Rating04: number = 0;
		Rating04Percent: number = 0;
		Rating05: number = 0;
		Rating05Percent: number = 0;
		RatingAverage: number = 0;
		RatingTotal: number = 0;
		Publish: boolean = false;
		Faq: boolean = false;
		PublishCategory: string = "";
		ReadOnlyShareId: string = null;
		FirstPublishedDateTime: Date = null;
		RevisionDateTime: Date = null;
		RevisionNumber: string = "";
		NeedsReview: boolean = false;
		ReviewedDateTime: Date = null;
		ReviewedByContactId: number = null;
		ReviewedByContactName: string = "";
		ReviewComments: string = "";
		NextReviewDateTime: Date = null;
		NextReviewContactId: number = null;
		NextReviewContactName: string = "";
		Billable: boolean = false;
		BillingRate: number = 0;
		BillingType: string = "";
		BillingMinuteMinimum: number = 0;
		BillingMinuteIncrement: number = 0;
		BudgetType: string = "";
		Budget: number = 0;
		BudgetWarningPercent: number = 0;
		ReopenedCount: number = 0;
		ReadCount: number = 0;
		LastReadDateTime: Date = null;
		CorrespondenceReplyNeeded: boolean = false;
		CaseOpenItemsApplied: boolean = false;
		CaseCloseItemsApplied: boolean = false;
		ContactIdNotified: boolean = false;
		Sanitized: boolean = false;
		Comments: string = "";
		Attributes: any = null;
		Correspondence: CaseCorrespondenceEditViewModel[] = [];
		Access: CaseAccessEditViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class CaseTemplateListViewModel {
		CaseTemplateId: number = null;
		ExternalCaseTemplateId: string = null;
		Description: string = "";
		Enabled: boolean = false;
		Classification: string = "";
		Presentation: string = "";
		Type: string = "";
		Priority: string = "";
		Status: string = "";
		Progress: string = "";
		DefaultTitle: string = "";
		LastUsedDateTime: Date = null;
		TimeZone: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class CaseTemplateEditViewModel {
		CaseTemplateId: number = null;
		ExternalCaseTemplateId: string = null;
		Description: string = "";
		Enabled: boolean = false;
		Classification: string = "";
		Presentation: string = "";
		Layout: CaseTemplateLayoutEditViewModel = null;
		LayoutFeatures: string = "";
		LayoutAssetId: number = null;
		LayoutFormId: number = null;
		CustomAttributeFormId: number = null;
		Type: string = "";
		Category: string = "";
		CaseGroup: string = "";
		Priority: string = "";
		Status: string = "";
		StatusWhenDone: string = "";
		Progress: string = "";
		ProgressWhenDone: string = "";
		RiskLevel: string = "";
		DefaultDescription: string = "";
		DefaultTitle: string = "";
		DefaultSummary: string = "";
		DefaultTags: string[] = [];
		RegardingPrompt: string = "";
		PromptVersion: string = "";
		CaseTimeFromTasks: boolean = false;
		ShowExternalCaseNumber: boolean = false;
		RequireUniqueExternalCaseNumber: boolean = false;
		CaseNumberAssignment: string = "";
		CanAddItems: boolean = false;
		PublicCaseTemplate: boolean = false;
		PublicCaseNotes: boolean = false;
		AllowReopen: string = "";
		DueMinutes: number = 0;
		DueTrigger: string = "";
		Publish: boolean = false;
		PublishCategory: string = "";
		NeedsReview: boolean = false;
		PromptHelped: boolean = false;
		PromptRating: boolean = false;
		PromptFeedback: boolean = false;
		ViewRequiresRoleId: number = null;
		CreateRequiresRoleId: number = null;
		ViewRequiresGroupId: number = null;
		CreateRequiresGroupId: number = null;
		Billable: boolean = false;
		BillingRate: number = 0;
		BillingType: string = "";
		BillingMinuteMinimum: number = 0;
		BillingMinuteIncrement: number = 0;
		BudgetType: string = "";
		Budget: number = 0;
		BudgetWarningPercent: number = 0;
		NotificationGroupIdNewCase: number = 0;
		NotificationGroupIdModifiedCase: number = 0;
		NotificationGroupIdAssignedCase: number = 0;
		NotificationGroupIdClosedCase: number = 0;
		NotificationGroupIdCaseCorrespondence: number = 0;
		NotificationGroupIdAnnounce: number = 0;
		NotificationGroupIdReminder: number = 0;
		AllowThirdPartiesToWatchCase: boolean = false;
		AutoCloseWhenAllTasksCompleted: boolean = false;
		CloseRuleTitleRequired: boolean = false;
		CloseRuleSummaryRequired: boolean = false;
		CloseRuleResolutionRequired: boolean = false;
		CloseRuleTagsRequired: boolean = false;
		CloseRuleAppliesToListRequired: boolean = false;
		CloseRuleSummaryNotDescription: boolean = false;
		CloseRuleAllTasksCompleted: boolean = false;
		CloseRuleSanitizeCase: boolean = false;
		CloseRuleReviewRequired: boolean = false;
		CloseRuleOutcomeRequired: boolean = false;
		CloseAsSuccessOutcomes: string[] = [];
		CloseAsFailureOutcomes: string[] = [];
		SupportChildCase: boolean = false;
		SupportTask: boolean = false;
		SupportTimeLog: boolean = false;
		SupportInternalCaseNumber: boolean = false;
		SupportExternalCaseNumber: boolean = false;
		SupportContactCaseNumber: boolean = false;
		SupportType: boolean = false;
		SupportTypeStatic: boolean = false;
		SupportCategory: boolean = false;
		SupportCategoryStatic: boolean = false;
		SupportGroup: boolean = false;
		SupportGroupStatic: boolean = false;
		SupportPriority: boolean = false;
		SupportPriorityRanking: boolean = false;
		SupportStatus: boolean = false;
		SupportProgress: boolean = false;
		SupportPercentDone: boolean = false;
		SupportRiskLevel: boolean = false;
		SupportEstimatedTime: boolean = false;
		SupportCustomer: boolean = false;
		SupportCustomerSite: boolean = false;
		SupportResolution: boolean = false;
		SupportDependsOn: boolean = false;
		SupportSeeAlso: boolean = false;
		SupportComments: boolean = false;
		SupportChangeLogging: boolean = false;
		SupportCorrespondence: boolean = false;
		SupportAddingTaskLists: boolean = false;
		SupportSystemTasks: boolean = false;
		SupportTriggers: boolean = false;
		SupportAdvancedSettings: boolean = false;
		SupportExtendedAttributes: boolean = false;
		SupportOtherFeatureList: string[] = [];
		SanitizeRemoveNotes: boolean = false;
		SanitizeRemoveCorrespondence: boolean = false;
		SanitizeRemoveChangeLog: boolean = false;
		SanitizeRemoveTimeLog: boolean = false;
		SanitizeRemoveTasks: boolean = false;
		SanitizeRemoveAllAttachments: boolean = false;
		SanitizeRemoveDraftAttachments: boolean = false;
		SanitizeCaseOtherSteps: string[] = [];
		DisplayColor: string = "";
		OwnedByContactId: number = null;
		OwnedByContactName: string = "";
		CaseOwnership: string = "";
		AssignedToContactId: number = null;
		AssignedToContactName: string = "";
		CaseAssignment: string = "";
		SupervisorContactId: number = null;
		SupervisorContactName: string = "";
		SupervisorAssignment: string = "";
		NextReviewToPreviousReviewer: boolean = false;
		NextReviewContactId: number = null;
		NextReviewContactName: string = "";
		ReviewAssignment: string = "";
		StatusWhenReviewSubmitted: string = "";
		StatusWhenReviewSuccess: string = "";
		StatusWhenReviewFail: string = "";
		ProgressWhenReviewSubmitted: string = "";
		ProgressWhenReviewSuccess: string = "";
		ProgressWhenReviewFail: string = "";
		LastUsedDateTime: Date = null;
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ChildContactListViewModel {
		ContactId: number = null;
		ParentContactId: number = null;
		ExternalContactId: string = null;
		AddressType: string = "";
		ContactType: string = "";
		ContactName: string = "";
		FirstName: string = "";
		LastName: string = "";
		Phone: string = "";
		Email: string = "";
		Login: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class ChildContactEditViewModel {
		ContactId: number = null;
		ParentContactId: number = null;
		ExternalContactId: string = null;
		ContactType: string = "";
		AddressType: string = "";
		ContactName: string = "";
		FirstName: string = "";
		MiddleName: string = "";
		LastName: string = "";
		OtherName: string = "";
		CourtesyTitle: string = "";
		PostNominalTitles: string = "";
		Title: string = "";
		Address1: string = "";
		Address2: string = "";
		Address3: string = "";
		City: string = "";
		State: string = "";
		PostalCode: string = "";
		County: string = "";
		Country: string = "";
		Phone: string = "";
		Fax: string = "";
		HomePhone: string = "";
		Cellular: string = "";
		AlternatePhone: string = "";
		Extension: string = "";
		Email: string = "";
		AlternateEmail: string = "";
		ImAddress: string = "";
		ImNetwork: string = "";
		AlternateImAddress: string = "";
		AlternateImNetwork: string = "";
		WebSite: string = "";
		FtpSite: string = "";
		TaxId: string = null;
		WebLanguage: string = "";
		IvrLanguage: string = "";
		TimeZoneId: string = null;
		Salutation: string = "";
		AssistantName: string = "";
		ManagerName: string = "";
		SpouseName: string = "";
		ChildrenNames: string = "";
		DepartmentName: string = "";
		Office: string = "";
		Profession: string = "";
		Hobbies: string = "";
		Gender: string = "";
		BirthDate: Date = null;
		AnniversaryDate: Date = null;
		NotifyAlert: boolean = false;
		NotifyIssueChange: boolean = false;
		NotifyIssueChangeGlobal: boolean = false;
		NotifyProductInfo: boolean = false;
		NotifyVendorInfo: boolean = false;
		NotifyThirdPartyInfo: boolean = false;
		NotifyFormat: string = "";
		NotifyStandardStartTime: string = "";
		NotifyStandardEndTime: string = "";
		NotifyUrgentStartTime: string = "";
		NotifyUrgentEndTime: string = "";
		Status: string = "";
		StatusLocked: boolean = false;
		CreateDateTime: Date = null;
		SuspendDateTime: Date = null;
		CloseDateTime: Date = null;
		ReactivateDateTime: Date = null;
		IsoCurrencyCode: string = "";
		CurrencyFormat: string = "";
		Login: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ContactReferenceViewModel {
		ContactId: number = null;
		ContactName: string = "";
		ContactType: string = "";
		ContactEmail: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class ContactAccessEditViewModel {
		ContactAccessId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		AccessIdentifierType: string = "";
		AccessIdentifier: string = "";
		AccessLocation: string = "";
		AccessDetails: string = "";
		AccessCount: number = 0;
		LastAccessDateTime: Date = null;
		ActionLinkDateTime: Date = null;
		ActionLinkId: number = null;
		NotificationDateTime: Date = null;
		NotificationEventId: number = null;
		Suspect: boolean = false;
		BlockedAutomatically: boolean = false;
		BlockedManually: boolean = false;
		Whitelisted: boolean = false;
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ContactConsentEditViewModel {
		ContactConsentId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		Consent: string = "";
		Enabled: boolean = false;
		FirstRequestDateTime: Date = null;
		LastRequestDateTime: Date = null;
		GrantDateTime: Date = null;
		RevokeDateTime: Date = null;
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ContactExternalAuthenticationEditViewModel {
		ContactExternalAuthenticationId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		AuthenticationService: string = "";
		UserId: string = null;
		UserName: string = "";
		DisplayName: string = "";
		FirstName: string = "";
		LastName: string = "";
		Email: string = "";
		AlternateEmail: string = "";
		Language: string = "";
		ProfileUrl: string = "";
		ImageUrl: string = "";
		ThumbnailUrl: string = "";
		AuthenticationCount: number = 0;
		LastAuthenticationDateTime: Date = null;
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ContactPropertiesContactTypeGroup {
		GroupMembershipAllowedContactTypes: string[] = [];
		MembersInheritRolesFromChildGroups: boolean = false;
		MembersInheritScopeFromChildGroups: boolean = false;
		JoiningGroup: GroupMembershipApproval = null;
		LeavingGroup: GroupMembershipApproval = null;
		MemberStatusDaysBeforeConsideredInactive: number = 0;
		GroupMembershipProperties: m.PropertyMetaDataViewModel[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class ContactGroupMembershipEditViewModel {
		ContactGroupMembershipId: number = null;
		GroupContactId: number = null;
		GroupContactName: string = "";
		MemberContactId: number = null;
		MemberContactName: string = "";
		Description: string = "";
		Status: string = "";
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		ValidDateRangeTriggersExecuted: boolean = false;
		GroupOwner: boolean = false;
		CanImpersonateGroup: boolean = false;
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ContactRoleEditViewModel {
		ContactRoleId: number = null;
		ContactId: number = null;
		ContactType: string = "";
		ContactName: string = "";
		RoleId: number = null;
		RoleDescription: string = "";
		Enabled: boolean = false;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		RoleRatio: number = 0;
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ContactListViewModel {
		ContactId: number = null;
		ExternalContactId: string = null;
		ParentContactId: number = null;
		ContactType: string = "";
		AddressType: string = "";
		ContactName: string = "";
		FirstName: string = "";
		LastName: string = "";
		Title: string = "";
		Address1: string = "";
		Address2: string = "";
		Address3: string = "";
		City: string = "";
		State: string = "";
		PostalCode: string = "";
		Phone: string = "";
		Email: string = "";
		Login: string = "";
		Flags: string[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class ContactEditViewModel {
		ContactId: number = null;
		ExternalContactId: string = null;
		ParentContactId: number = null;
		ContactType: string = "";
		AddressType: string = "";
		ContactName: string = "";
		FirstName: string = "";
		MiddleName: string = "";
		LastName: string = "";
		OtherName: string = "";
		CourtesyTitle: string = "";
		PostNominalTitles: string = "";
		Title: string = "";
		Address1: string = "";
		Address2: string = "";
		Address3: string = "";
		City: string = "";
		State: string = "";
		PostalCode: string = "";
		County: string = "";
		Country: string = "";
		AddressBlock: string = "";
		AddressSentence: string = "";
		Phone: string = "";
		Fax: string = "";
		HomePhone: string = "";
		Cellular: string = "";
		AlternatePhone: string = "";
		Extension: string = "";
		Email: string = "";
		AlternateEmail: string = "";
		ImAddress: string = "";
		ImNetwork: string = "";
		AlternateImAddress: string = "";
		AlternateImNetwork: string = "";
		WebSite: string = "";
		FtpSite: string = "";
		TaxId: string = null;
		TickerSymbol: string = "";
		SicCode: string = "";
		Industry: string = "";
		ReferredBy: string = "";
		RepresentativeDirectoryId: number = null;
		RepresentativeDirectoryName: string = "";
		AgentId: number = null;
		AgentName: string = "";
		WebLanguage: string = "";
		IvrLanguage: string = "";
		TimeZoneId: string = null;
		Salutation: string = "";
		AssistantName: string = "";
		ManagerName: string = "";
		SpouseName: string = "";
		ChildrenNames: string = "";
		DepartmentName: string = "";
		Office: string = "";
		Profession: string = "";
		Hobbies: string = "";
		Gender: string = "";
		BirthDate: Date = null;
		AnniversaryDate: Date = null;
		NotifyAlert: boolean = false;
		NotifyIssueChange: boolean = false;
		NotifyIssueChangeGlobal: boolean = false;
		NotifyProductInfo: boolean = false;
		NotifyVendorInfo: boolean = false;
		NotifyThirdPartyInfo: boolean = false;
		NotifyFormat: string = "";
		NotifyStandardStartTime: string = "";
		NotifyStandardEndTime: string = "";
		NotifyUrgentStartTime: string = "";
		NotifyUrgentEndTime: string = "";
		Status: string = "";
		StatusLocked: boolean = false;
		CreateDateTime: Date = null;
		SuspendDateTime: Date = null;
		CloseDateTime: Date = null;
		ReactivateDateTime: Date = null;
		IsoCurrencyCode: string = "";
		CurrencyFormat: string = "";
		Login: string = "";
		Flags: string[] = [];
		Comments: string = "";
		Properties: any = null;
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ContactBulkAddRequestViewModel {
		ContactType: string = "";
		ContactLevel: string = "";
		Groups: ContactGroupMembershipEditViewModel[] = [];
		EmailAddresses: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class ContactBulkAddResultViewModel {
		ContactType: string = "";
		ContactLevel: string = "";
		Groups: ContactGroupMembershipEditViewModel[] = [];
		EmailAddressesAdded: string[] = [];
		EmailAddressesRejected: string[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class ContactWorkScheduleExceptionEditViewModel {
		ContactWorkScheduleExceptionId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		Description: string = "";
		ExceptionStartDateTime: Date = null;
		ExceptionEndDateTime: Date = null;
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ContactWorkScheduleEditViewModel {
		ContactWorkScheduleId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		Enabled: boolean = false;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		Days: string = "";
		StartTime: string = "";
		EndTime: string = "";
		NumberOfMinutes: number = 0;
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class CustomerListViewModel {
		CustomerId: number = null;
		ExternalCustomerId: string = null;
		CustomerName: string = "";
		FirstName: string = "";
		LastName: string = "";
		Title: string = "";
		Address1: string = "";
		Address2: string = "";
		Address3: string = "";
		City: string = "";
		State: string = "";
		PostalCode: string = "";
		Phone: string = "";
		Email: string = "";
		Login: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class GroupListViewModel {
		GroupId: number = null;
		ExternalGroupId: string = null;
		GroupName: string = "";
		Email: string = "";
		Scope: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class GroupEditViewModel {
		GroupId: number = null;
		ExternalGroupId: string = null;
		ParentGroupId: number = null;
		GroupName: string = "";
		Email: string = "";
		AlternateEmail: string = "";
		WebSite: string = "";
		GroupMembershipAllowedContactTypes: string[] = [];
		MembersInheritRolesFromChildGroups: boolean = false;
		MembersInheritScopeFromChildGroups: boolean = false;
		JoiningGroup: GroupMembershipApproval = null;
		LeavingGroup: GroupMembershipApproval = null;
		MemberStatusDaysBeforeConsideredInactive: number = 0;
		GroupMembershipProperties: m.PropertyMetaDataViewModel[] = [];
		Scope: string = "";
		Comments: string = "";
		Attributes: any = null;
		Members: ContactGroupMembershipEditViewModel[] = [];
		Roles: ContactRoleEditViewModel[] = [];
		Children: GroupEditViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class DirectoryListViewModel {
		DirectoryId: number = null;
		ExternalDirectoryId: string = null;
		DirectoryName: string = "";
		FirstName: string = "";
		LastName: string = "";
		Title: string = "";
		Address1: string = "";
		Address2: string = "";
		Address3: string = "";
		City: string = "";
		State: string = "";
		PostalCode: string = "";
		Phone: string = "";
		Email: string = "";
		Login: string = "";
		Flags: string[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class DirectoryEditViewModel {
		DirectoryId: number = null;
		ExternalDirectoryId: string = null;
		DirectoryName: string = "";
		FirstName: string = "";
		MiddleName: string = "";
		LastName: string = "";
		OtherName: string = "";
		CourtesyTitle: string = "";
		PostNominalTitles: string = "";
		Title: string = "";
		Address1: string = "";
		Address2: string = "";
		Address3: string = "";
		City: string = "";
		State: string = "";
		PostalCode: string = "";
		County: string = "";
		Country: string = "";
		Phone: string = "";
		Fax: string = "";
		HomePhone: string = "";
		Cellular: string = "";
		AlternatePhone: string = "";
		Extension: string = "";
		Email: string = "";
		AlternateEmail: string = "";
		ImAddress: string = "";
		ImNetwork: string = "";
		AlternateImAddress: string = "";
		AlternateImNetwork: string = "";
		WebSite: string = "";
		FtpSite: string = "";
		TaxId: string = null;
		TickerSymbol: string = "";
		SicCode: string = "";
		Industry: string = "";
		ReferredBy: string = "";
		RepresentativeDirectoryId: number = null;
		RepresentativeDirectoryName: string = "";
		WebLanguage: string = "";
		IvrLanguage: string = "";
		TimeZoneId: string = null;
		Salutation: string = "";
		AssistantName: string = "";
		ManagerName: string = "";
		SpouseName: string = "";
		ChildrenNames: string = "";
		DepartmentName: string = "";
		Office: string = "";
		Profession: string = "";
		Hobbies: string = "";
		Gender: string = "";
		BirthDate: Date = null;
		AnniversaryDate: Date = null;
		NotifyAlert: boolean = false;
		NotifyIssueChange: boolean = false;
		NotifyIssueChangeGlobal: boolean = false;
		NotifyProductInfo: boolean = false;
		NotifyVendorInfo: boolean = false;
		NotifyThirdPartyInfo: boolean = false;
		NotifyFormat: string = "";
		NotifyStandardStartTime: string = "";
		NotifyStandardEndTime: string = "";
		NotifyUrgentStartTime: string = "";
		NotifyUrgentEndTime: string = "";
		Status: string = "";
		StatusLocked: boolean = false;
		CreateDateTime: Date = null;
		SuspendDateTime: Date = null;
		CloseDateTime: Date = null;
		ReactivateDateTime: Date = null;
		IsoCurrencyCode: string = "";
		CurrencyFormat: string = "";
		Login: string = "";
		Flags: string[] = [];
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class LocationListViewModel {
		LocationId: number = null;
		ExternalLocationId: string = null;
		LocationName: string = "";
		FirstName: string = "";
		LastName: string = "";
		Title: string = "";
		Address1: string = "";
		Address2: string = "";
		Address3: string = "";
		City: string = "";
		State: string = "";
		PostalCode: string = "";
		Phone: string = "";
		Email: string = "";
		Login: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class LocationEditViewModel {
		LocationId: number = null;
		ExternalLocationId: string = null;
		LocationName: string = "";
		FirstName: string = "";
		MiddleName: string = "";
		LastName: string = "";
		OtherName: string = "";
		CourtesyTitle: string = "";
		PostNominalTitles: string = "";
		Title: string = "";
		Address1: string = "";
		Address2: string = "";
		Address3: string = "";
		City: string = "";
		State: string = "";
		PostalCode: string = "";
		County: string = "";
		Country: string = "";
		Phone: string = "";
		Fax: string = "";
		HomePhone: string = "";
		Cellular: string = "";
		AlternatePhone: string = "";
		Extension: string = "";
		Email: string = "";
		AlternateEmail: string = "";
		ImAddress: string = "";
		ImNetwork: string = "";
		AlternateImAddress: string = "";
		AlternateImNetwork: string = "";
		WebSite: string = "";
		FtpSite: string = "";
		TaxId: string = null;
		TickerSymbol: string = "";
		SicCode: string = "";
		Industry: string = "";
		ReferredBy: string = "";
		RepresentativeDirectoryId: number = null;
		RepresentativeDirectoryName: string = "";
		WebLanguage: string = "";
		IvrLanguage: string = "";
		TimeZoneId: string = null;
		Salutation: string = "";
		AssistantName: string = "";
		ManagerName: string = "";
		SpouseName: string = "";
		ChildrenNames: string = "";
		DepartmentName: string = "";
		Office: string = "";
		Profession: string = "";
		Hobbies: string = "";
		Gender: string = "";
		BirthDate: Date = null;
		AnniversaryDate: Date = null;
		NotifyAlert: boolean = false;
		NotifyIssueChange: boolean = false;
		NotifyIssueChangeGlobal: boolean = false;
		NotifyProductInfo: boolean = false;
		NotifyVendorInfo: boolean = false;
		NotifyThirdPartyInfo: boolean = false;
		NotifyFormat: string = "";
		NotifyStandardStartTime: string = "";
		NotifyStandardEndTime: string = "";
		NotifyUrgentStartTime: string = "";
		NotifyUrgentEndTime: string = "";
		Status: string = "";
		StatusLocked: boolean = false;
		CreateDateTime: Date = null;
		SuspendDateTime: Date = null;
		CloseDateTime: Date = null;
		ReactivateDateTime: Date = null;
		IsoCurrencyCode: string = "";
		CurrencyFormat: string = "";
		Login: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class SalesOpportunityEditViewModel {
		SalesOpportunityId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		Description: string = "";
		IsoCurrencyCode: string = "";
		Amount: number = 0;
		CreatedDateTime: Date = null;
		ExpiresDateTime: Date = null;
		ForecastedCloseDateTime: Date = null;
		Probability: number = 0;
		SalesStage: string = "";
		Competitors: string[] = [];
		RepresentativeContactId: number = null;
		RepresentativeContactName: string = "";
		RegardingResourceType: string = "";
		RegardingResourceId: number = null;
		RegardingResourceId2: string = "";
		Result: string = "";
		ResultReason: string = "";
		Tags: string[] = [];
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class VendorListViewModel {
		VendorId: number = null;
		ExternalVendorId: string = null;
		VendorName: string = "";
		FirstName: string = "";
		LastName: string = "";
		Title: string = "";
		Address1: string = "";
		Address2: string = "";
		Address3: string = "";
		City: string = "";
		State: string = "";
		PostalCode: string = "";
		Phone: string = "";
		Email: string = "";
		Login: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class VendorEditViewModel {
		VendorId: number = null;
		ExternalVendorId: string = null;
		VendorName: string = "";
		FirstName: string = "";
		MiddleName: string = "";
		LastName: string = "";
		OtherName: string = "";
		CourtesyTitle: string = "";
		PostNominalTitles: string = "";
		Title: string = "";
		Address1: string = "";
		Address2: string = "";
		Address3: string = "";
		City: string = "";
		State: string = "";
		PostalCode: string = "";
		County: string = "";
		Country: string = "";
		Phone: string = "";
		Fax: string = "";
		HomePhone: string = "";
		Cellular: string = "";
		AlternatePhone: string = "";
		Extension: string = "";
		Email: string = "";
		AlternateEmail: string = "";
		ImAddress: string = "";
		ImNetwork: string = "";
		AlternateImAddress: string = "";
		AlternateImNetwork: string = "";
		WebSite: string = "";
		FtpSite: string = "";
		TaxId: string = null;
		TickerSymbol: string = "";
		SicCode: string = "";
		Industry: string = "";
		ReferredBy: string = "";
		RepresentativeDirectoryId: number = null;
		RepresentativeDirectoryName: string = "";
		WebLanguage: string = "";
		IvrLanguage: string = "";
		TimeZoneId: string = null;
		Salutation: string = "";
		AssistantName: string = "";
		ManagerName: string = "";
		SpouseName: string = "";
		ChildrenNames: string = "";
		DepartmentName: string = "";
		Office: string = "";
		Profession: string = "";
		Hobbies: string = "";
		Gender: string = "";
		BirthDate: Date = null;
		AnniversaryDate: Date = null;
		NotifyAlert: boolean = false;
		NotifyIssueChange: boolean = false;
		NotifyIssueChangeGlobal: boolean = false;
		NotifyProductInfo: boolean = false;
		NotifyVendorInfo: boolean = false;
		NotifyThirdPartyInfo: boolean = false;
		NotifyFormat: string = "";
		NotifyStandardStartTime: string = "";
		NotifyStandardEndTime: string = "";
		NotifyUrgentStartTime: string = "";
		NotifyUrgentEndTime: string = "";
		Status: string = "";
		StatusLocked: boolean = false;
		CreateDateTime: Date = null;
		SuspendDateTime: Date = null;
		CloseDateTime: Date = null;
		ReactivateDateTime: Date = null;
		IsoCurrencyCode: string = "";
		CurrencyFormat: string = "";
		Login: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class WarehouseListViewModel {
		WarehouseId: number = null;
		ExternalWarehouseId: string = null;
		WarehouseName: string = "";
		FirstName: string = "";
		LastName: string = "";
		Title: string = "";
		Address1: string = "";
		Address2: string = "";
		Address3: string = "";
		City: string = "";
		State: string = "";
		PostalCode: string = "";
		Phone: string = "";
		Email: string = "";
		Login: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class WarehouseEditViewModel {
		WarehouseId: number = null;
		ExternalWarehouseId: string = null;
		WarehouseName: string = "";
		FirstName: string = "";
		MiddleName: string = "";
		LastName: string = "";
		OtherName: string = "";
		CourtesyTitle: string = "";
		PostNominalTitles: string = "";
		Title: string = "";
		Address1: string = "";
		Address2: string = "";
		Address3: string = "";
		City: string = "";
		State: string = "";
		PostalCode: string = "";
		County: string = "";
		Country: string = "";
		Phone: string = "";
		Fax: string = "";
		HomePhone: string = "";
		Cellular: string = "";
		AlternatePhone: string = "";
		Extension: string = "";
		Email: string = "";
		AlternateEmail: string = "";
		ImAddress: string = "";
		ImNetwork: string = "";
		AlternateImAddress: string = "";
		AlternateImNetwork: string = "";
		WebSite: string = "";
		FtpSite: string = "";
		TaxId: string = null;
		TickerSymbol: string = "";
		SicCode: string = "";
		Industry: string = "";
		ReferredBy: string = "";
		RepresentativeDirectoryId: number = null;
		RepresentativeDirectoryName: string = "";
		WebLanguage: string = "";
		IvrLanguage: string = "";
		TimeZoneId: string = null;
		Salutation: string = "";
		AssistantName: string = "";
		ManagerName: string = "";
		SpouseName: string = "";
		ChildrenNames: string = "";
		DepartmentName: string = "";
		Office: string = "";
		Profession: string = "";
		Hobbies: string = "";
		Gender: string = "";
		BirthDate: Date = null;
		AnniversaryDate: Date = null;
		NotifyAlert: boolean = false;
		NotifyIssueChange: boolean = false;
		NotifyIssueChangeGlobal: boolean = false;
		NotifyProductInfo: boolean = false;
		NotifyVendorInfo: boolean = false;
		NotifyThirdPartyInfo: boolean = false;
		NotifyFormat: string = "";
		NotifyStandardStartTime: string = "";
		NotifyStandardEndTime: string = "";
		NotifyUrgentStartTime: string = "";
		NotifyUrgentEndTime: string = "";
		Status: string = "";
		StatusLocked: boolean = false;
		CreateDateTime: Date = null;
		SuspendDateTime: Date = null;
		CloseDateTime: Date = null;
		ReactivateDateTime: Date = null;
		IsoCurrencyCode: string = "";
		CurrencyFormat: string = "";
		Login: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ApiAccessClientEditViewModel {
		ApiAccessClientId: number = null;
		ApiAccessId: number = null;
		Description: string = "";
		ClientIdentifierType: string = "";
		ClientIdentifier: string = "";
		ClientType: string = "";
		DebugUntilDateTime: Date = null;
		Flags: string = "";
		SuspendedOnDateTime: Date = null;
		SuspendedByContactId: number = null;
		SuspendedByContactName: string = "";
		SuspendedUntilDateTime: Date = null;
		SuspendedReason: string = "";
		Enabled: boolean = false;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ApiAccessEditViewModel {
		ApiAccessId: number = null;
		ApiKey: string = "";
		Description: string = "";
		ContactId: number = null;
		ContactName: string = "";
		RoleId: number = null;
		Anonymous: boolean = false;
		ShortTermMinutes: number = 0;
		EncryptionType: string = "";
		EncryptionKey: string = "";
		Flags: string = "";
		RateLimitPerMinute: number = 0;
		RateLimitPerHour: number = 0;
		SuspendedOnDateTime: Date = null;
		SuspendedByContactId: number = null;
		SuspendedByContactName: string = "";
		SuspendedUntilDateTime: Date = null;
		SuspendedReason: string = "";
		Enabled: boolean = false;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		Properties: string = "";
		Comments: string = "";
		Clients: ApiAccessClientEditViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class EncryptionEditViewModel {
		Tag: string = "";
		Description: string = "";
		KeyType: string = "";
		KeyLength: number = 0;
		PublicKey: string = "";
		PrivateKey: string = "";
		Comments: string = "";
		AddedDateTime: Date = null;
		AddedByContactId: number = null;
		AddedByContactName: string = "";
		LastEditedDateTime: Date = null;
		LastEditedByContactId: number = null;
		LastEditedByContactName: string = "";
		MarkedForDeletionDateTime: Date = null;
		MarkedForDeletionByContactId: number = null;
		MarkedForDeletionByContactName: string = "";
		CurrentRowVersion: string = "";
		UtcOffset: number = null;
		TimeZoneDescription: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class EncryptionEchoEditViewModel {
		Tag: string = "";
		PlainText: string = "";
		EncryptedText: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class ReviewViewModel {
		ReviewDateTime: Date = null;
		ReviewedByContactId: number = null;
		Successful: boolean = false;
		Note: string = "";
		UtcOffset: number = null;
	}


    // This is generated code... DO NOT modify this file.
	export class MessageExchangeEditViewModel {
		MessageExchangeId: number = null;
		Tag: string = "";
		Role: string = "";
		Description: string = "";
		Protocol: string = "";
		Configuration: string = "";
		KeyType: string = "";
		KeyLength: number = 0;
		PublicKey: string = "";
		PrivateKey: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class MetaDataModel {
		AddedDateTime: Date = null;
		AddedByContactId: number = null;
		AddedByContactName: string = "";
		LastEditedDateTime: Date = null;
		LastEditedByContactId: number = null;
		LastEditedByContactName: string = "";
		MarkedForDeletionDateTime: Date = null;
		MarkedForDeletionByContactId: number = null;
		MarkedForDeletionByContactName: string = "";
		CurrentRowVersion: string = "";
		ObjectHashCode: string = "";
		DataSourceTableName: string = "";
		IncludedDataObjectTypes: string[] = [];
		DeletedDataObjects: m.IDeletedDataObject[] = [];
		PartitionId: number = null;
		ReadOnly: boolean = false;
		Properties: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class MetaDataLightModel {
		CurrentRowVersion: string = "";
		ObjectHashCode: string = "";
		DataSourceTableName: string = "";
		PartitionId: number = null;
		ReadOnly: boolean = false;
		Properties: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class DeletedDataObject {
		ObjectType: string = "";
		ObjectId: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class NoteEditViewModel {
		NoteId: number = null;
		NoteDateTime: Date = null;
		EnteredByContactId: number = null;
		EnteredByContactName: string = "";
		EnteredByContactEmail: string = "";
		NoteType: string = "";
		Note: string = "";
		Tags: string[] = [];
		OwnerResourceType: string = "";
		OwnerResourceId: number = null;
		OwnerResourceId2: string = "";
		Attributes: any = null;
		Changes: ChangedItemViewModel[] = [];
		ChangesOnly: boolean = false;
		AuditTrailId: number = null;
		Attachments: AssetListViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ChangedItemViewModel {
		Label: string = "";
		Property: string = "";
		OldValue: string = "";
		NewValue: string = "";
		Message: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class PossibleDuplicateObjectEditViewModel {
		ReasonSuspectedDuplicateRule: string = "";
		ReasonSuspectedDuplicateDescription: string = "";
		ObjectType: string = "";
		ObjectValue01Label: string = "";
		ObjectValue02Label: string = "";
		ObjectValue03Label: string = "";
		ObjectValue04Label: string = "";
		ObjectValue05Label: string = "";
		ObjectValue06Label: string = "";
		ObjectValue07Label: string = "";
		ObjectValue08Label: string = "";
		ObjectValue09Label: string = "";
		ObjectValue10Label: string = "";
		ObjectValue11Label: string = "";
		ObjectValue12Label: string = "";
		ObjectValue13Label: string = "";
		ObjectValue14Label: string = "";
		ObjectValue15Label: string = "";
		ObjectValue16Label: string = "";
		ObjectValue17Label: string = "";
		ObjectValue18Label: string = "";
		ObjectValue19Label: string = "";
		ObjectValue20Label: string = "";
		ObjectOneId: string = null;
		ObjectOneValue01: string = "";
		ObjectOneValue02: string = "";
		ObjectOneValue03: string = "";
		ObjectOneValue04: string = "";
		ObjectOneValue05: string = "";
		ObjectOneValue06: string = "";
		ObjectOneValue07: string = "";
		ObjectOneValue08: string = "";
		ObjectOneValue09: string = "";
		ObjectOneValue10: string = "";
		ObjectOneValue11: string = "";
		ObjectOneValue12: string = "";
		ObjectOneValue13: string = "";
		ObjectOneValue14: string = "";
		ObjectOneValue15: string = "";
		ObjectOneValue16: string = "";
		ObjectOneValue17: string = "";
		ObjectOneValue18: string = "";
		ObjectOneValue19: string = "";
		ObjectOneValue20: string = "";
		ObjectTwoId: string = null;
		ObjectTwoValue01: string = "";
		ObjectTwoValue02: string = "";
		ObjectTwoValue03: string = "";
		ObjectTwoValue04: string = "";
		ObjectTwoValue05: string = "";
		ObjectTwoValue06: string = "";
		ObjectTwoValue07: string = "";
		ObjectTwoValue08: string = "";
		ObjectTwoValue09: string = "";
		ObjectTwoValue10: string = "";
		ObjectTwoValue11: string = "";
		ObjectTwoValue12: string = "";
		ObjectTwoValue13: string = "";
		ObjectTwoValue14: string = "";
		ObjectTwoValue15: string = "";
		ObjectTwoValue16: string = "";
		ObjectTwoValue17: string = "";
		ObjectTwoValue18: string = "";
		ObjectTwoValue19: string = "";
		ObjectTwoValue20: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class ServerResourceAlarmRuleViewModel {
		ServerResourceAlarmRuleId: number = null;
		Description: string = "";
		Enabled: boolean = false;
		Type: string = "";
		ServerName: string = "";
		Resource: string = "";
		AlarmRule: string = "";
		AlarmMessage: string = "";
		AlarmDescription: string = "";
		AlarmNotificationGroupId: number = null;
		AlarmNotificationEmailAddresses: string[] = [];
		AlarmIntervalMinutes: number = 0;
		Comments: string = "";
		Private: boolean = false;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ServerResourceEditViewModel {
		ServerResourceId: number = null;
		ServerName: string = "";
		Description: string = "";
		Type: string = "";
		Resource: string = "";
		Available: number = 0;
		Used: number = 0;
		Total: number = 0;
		Measurement: string = "";
		PercentAvailable: number = 0;
		PercentUsed: number = 0;
		AlarmEnabled: boolean = false;
		AlarmState: boolean = false;
		AlarmMessage: string = "";
		AlarmIntervalMinutes: number = 0;
		LastAlarmDeliveryDateTime: Date = null;
		LastAlarmStateStartDateTime: Date = null;
		LastAlarmStateEndDateTime: Date = null;
		Properties: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class SettingEditViewModel {
		SettingId: number = null;
		Category: string = "";
		Attribute: string = "";
		Value: string = "";
		Protected: boolean = false;
		Hidden: boolean = false;
		ReadOnly: boolean = false;
		PartitionZeroOnly: boolean = false;
		Encrypted: boolean = false;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class SynopsisObjectEditViewModel {
		ObjectType: string = "";
		ObjectId: string = null;
		ObjectTypeLabel: string = "";
		ObjectValue01Label: string = "";
		ObjectValue02Label: string = "";
		ObjectValue03Label: string = "";
		ObjectValue04Label: string = "";
		ObjectValue05Label: string = "";
		ObjectValue06Label: string = "";
		ObjectValue07Label: string = "";
		ObjectValue08Label: string = "";
		ObjectValue09Label: string = "";
		ObjectValue10Label: string = "";
		ObjectValue11Label: string = "";
		ObjectValue12Label: string = "";
		ObjectValue13Label: string = "";
		ObjectValue14Label: string = "";
		ObjectValue15Label: string = "";
		ObjectValue16Label: string = "";
		ObjectValue17Label: string = "";
		ObjectValue18Label: string = "";
		ObjectValue19Label: string = "";
		ObjectValue20Label: string = "";
		ObjectValue01: string = "";
		ObjectValue02: string = "";
		ObjectValue03: string = "";
		ObjectValue04: string = "";
		ObjectValue05: string = "";
		ObjectValue06: string = "";
		ObjectValue07: string = "";
		ObjectValue08: string = "";
		ObjectValue09: string = "";
		ObjectValue10: string = "";
		ObjectValue11: string = "";
		ObjectValue12: string = "";
		ObjectValue13: string = "";
		ObjectValue14: string = "";
		ObjectValue15: string = "";
		ObjectValue16: string = "";
		ObjectValue17: string = "";
		ObjectValue18: string = "";
		ObjectValue19: string = "";
		ObjectValue20: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class SystemSettings {
		AsOf: Date = null;
		TimeZoneName: string = "";
		Groups: SettingGroupModel<SettingEditViewModel>[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class SettingGroupModel<TSettingModel> {
		Group: string = "";
		NativeDescription: string = "";
		Language: string = "";
		Description: string = "";
		Visible: boolean = false;
		Categories: SettingCategoryModel<TSettingModel>[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class SettingCategoryModel<TSettingModel> {
		Category: string = "";
		NativeDescription: string = "";
		Language: string = "";
		Description: string = "";
		Visible: boolean = false;
		Settings: SettingInformationModel<TSettingModel>[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class SettingInformationModel<TSettingModel> {
		Attribute: string = "";
		NativeDescription: string = "";
		Language: string = "";
		Description: string = "";
		DefaultSetting: string = "";
		UserInputType: m.SettingInputType = null;
		UserInputTypeString: string = "";
		InputPickList: m.ConstantInformation[] = [];
		Required: boolean = false;
		Protected: boolean = false;
		SettingId: number = null;
		Value: string = "";
		Visible: boolean = false;
		Setting: TSettingModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TagEditViewModel {
		TagId: number = null;
		Tag: string = "";
		OwnerResourceType: string = "";
		OwnerResourceId: number = null;
		OwnerResourceId2: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TimeZoneListViewModel {
		TimeZoneId: string = null;
		Description: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class TimeZoneEditViewModel {
		TimeZoneId: string = null;
		Description: string = "";
		Name: string = "";
		UtcOffset: string = null;
		UtcOffsetHours: number = 0;
		AdjustForDst: boolean = false;
		DstStartDateTime: Date = null;
		DstStartDayOfWeek: number = 0;
		DstStartWeek: number = 0;
		DstStartConstraint: number = 0;
		DstEndDateTime: Date = null;
		DstEndDayOfWeek: number = 0;
		DstEndWeek: number = 0;
		DstEndConstraint: number = 0;
		DstUtcOffset: string = "";
		DstUtcOffsetHours: number = 0;
		SystemTimeZoneId: string = null;
		SystemTimeZoneInformation: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class AssetLicenseViewModel {
		AssetLicenseId: number = null;
		ExternalAssetLicenseId: string = null;
		Description: string = "";
		LongDescription: string = "";
		Icons: string[] = [];
		Type: string = "";
		OverviewUrl: string = "";
		LicenseUrl: string = "";
		Urls: AssetLicenseUrlViewModel[] = [];
		Private: boolean = false;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class AssetAccessLogEditViewModel {
		AssetAccessLogId: number = null;
		AssetId: number = null;
		AccessDateTime: Date = null;
		AccessedByContactId: number = null;
		AccessedByContactName: string = "";
		AccessedByIpAddress: string = "";
		SignatureType: string = "";
		Signature: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class AssetFileEditViewModel {
		AssetId: number = null;
		FriendlyName: string = "";
		FileType: string = "";
		ContentType: string = "";
		FileName: string = "";
		FileContentsBase64: string = "";
		SizeBytes: number = 0;
		Height: number = 0;
		Width: number = 0;
		IsPublic: boolean = false;
		Created: Date = null;
		LastModified: Date = null;
		FileRole: string = "";
		ReadOnlyShareId: string = null;
		ReadOnlySharedResource: boolean = false;
		TimeZone: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class AssetOptionsButtonEditViewModel {
		Enabled: boolean = false;
		Text: string = "";
		Icon: string = "";
		Color: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class AssetOptionsNoticeEditViewModel {
		Required: boolean = false;
		MaxPostponeDays: number = 0;
		SignatureType: string = "";
		DownloadFormat: string = "";
		OkButton: AssetOptionsButtonEditViewModel = null;
		CancelButton: AssetOptionsButtonEditViewModel = null;
		DownloadButton: AssetOptionsButtonEditViewModel = null;
		PrintButton: AssetOptionsButtonEditViewModel = null;
		LaterButton: AssetOptionsButtonEditViewModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class AssetOptionsWidgetEditViewModel {
		Category: string = "";
		Icon: string = "";
		Properties: any = null;
		ValidForDirectory: boolean = false;
		ValidForCustomer: boolean = false;
		ValidForVendor: boolean = false;
		ValidForAgent: boolean = false;
		ValidForServiceProvider: boolean = false;
		ValidForRoles: number[] = [];
		Width: number = 0;
		Height: number = 0;
		MinWidth: number = 0;
		MinHeight: number = 0;
		MaxWidth: number = 0;
		MaxHeight: number = 0;
		Actions: m5web.DashboardWidgetActionEditViewModel[] = [];
		Dependencies: string[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class AssetReferenceEditViewModel {
		AssetReferenceId: number = null;
		AssetId: number = null;
		ReferencedAssetId: number = null;
		ReferencedAssetTitle: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class AssetSelectionEditViewModel {
		AssetSelectionId: number = null;
		AssetId: number = null;
		Description: string = "";
		Property01: string = "";
		Value01: string = "";
		AllRequired01: boolean = false;
		Property02: string = "";
		Value02: string = "";
		AllRequired02: boolean = false;
		Property03: string = "";
		Value03: string = "";
		AllRequired03: boolean = false;
		Property04: string = "";
		Value04: string = "";
		AllRequired04: boolean = false;
		Property05: string = "";
		Value05: string = "";
		AllRequired05: boolean = false;
		Property06: string = "";
		Value06: string = "";
		AllRequired06: boolean = false;
		Property07: string = "";
		Value07: string = "";
		AllRequired07: boolean = false;
		Property08: string = "";
		Value08: string = "";
		AllRequired08: boolean = false;
		Property09: string = "";
		Value09: string = "";
		AllRequired09: boolean = false;
		Property10: string = "";
		Value10: string = "";
		AllRequired10: boolean = false;
		SelectionType: string = "";
		Inferred: boolean = false;
		InferredFirstDateTime: Date = null;
		InferredLastDateTime: Date = null;
		InferredCount: number = 0;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class AssetEditViewModel {
		AssetId: number = null;
		ParentAssetId: number = null;
		ParentAssetTitle: string = "";
		ExternalAssetId: string = null;
		SystemAssetId: string = null;
		SystemAssetGroup: string = "";
		Title: string = "";
		ShortDescription: string = "";
		FullDescription: string = "";
		Tags: string[] = [];
		AssetText: string = "";
		AssetTextFingerprint: string = "";
		AlternateAssetText: string = "";
		AlternateAssetTextFingerprint: string = "";
		AssetOptions: string = "";
		AssetOptionsFingerprint: string = "";
		ExternalUrl: string = "";
		FriendlyName: string = "";
		PhysicalOrganization: string = "";
		VirtualOrganization: string = "";
		AssetType: string = "";
		FileType: string = "";
		ContentType: string = "";
		ContentCategory: string = "";
		ActionType: string = "";
		SizeBytes: number = 0;
		SizeOther: number = 0;
		SizeInformation: string = "";
		Height: number = 0;
		Width: number = 0;
		DisplayOrder: number = 0;
		Priority: number = 0;
		AssetCategory: string = "";
		AssetClass: string = "";
		AssetClassInferred: string = "";
		AssetClassInferredDateTime: Date = null;
		AssetClassInferredProbability: number = 0;
		AssetGroup: string = "";
		AssetScope: string = "";
		LastObjectStatusAnalysisDateTime: Date = null;
		Comments: string = "";
		Searchable: boolean = false;
		ReadOnly: boolean = false;
		LogAccess: boolean = false;
		MaximumAccessCount: number = 0;
		RequiresRegistration: boolean = false;
		RequiresSupportPlan: boolean = false;
		RequiresContactType: string[] = [];
		Visibility: string = "";
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		OwnerResourceType: string = "";
		OwnerResourceId: number = null;
		OwnerResourceId2: string = "";
		SecondaryOwnerResourceType: string = "";
		SecondaryOwnerResourceId: number = null;
		SecondaryOwnerResourceId2: string = "";
		ShowAddedByInformation: string[] = [];
		AuthorLongName: string = "";
		AuthorShortName: string = "";
		AuthorContactInformation: string = "";
		Copyright: string = "";
		License: string = "";
		LicenseUrl: string = "";
		LicenseAnonymous: boolean = false;
		LicenseInformation: AssetLicenseInformationViewModel[] = [];
		ReferenceUrl: string = "";
		ReadOnlyShareId: string = null;
		HitCount: number = 0;
		LastHitDateTime: Date = null;
		PromptHelped: boolean = false;
		PromptRating: boolean = false;
		PromptFeedback: boolean = false;
		AllowAnonymousFeedback: boolean = false;
		HelpedYes: number = 0;
		HelpedYesPercent: number = 0;
		HelpedNo: number = 0;
		HelpedNoPercent: number = 0;
		HelpedNa: number = 0;
		HelpedNaPercent: number = 0;
		HelpedTotal: number = 0;
		Rating01: number = 0;
		Rating01Percent: number = 0;
		Rating02: number = 0;
		Rating02Percent: number = 0;
		Rating03: number = 0;
		Rating03Percent: number = 0;
		Rating04: number = 0;
		Rating04Percent: number = 0;
		Rating05: number = 0;
		Rating05Percent: number = 0;
		RatingAverage: number = 0;
		RatingTotal: number = 0;
		NeedsReview: boolean = false;
		ReviewedDateTime: Date = null;
		ReviewedByContactId: number = null;
		ReviewedByContactName: string = "";
		ReviewComments: string = "";
		NextReviewDateTime: Date = null;
		NextReviewContactId: number = null;
		NextReviewContactName: string = "";
		ApprovalPending: boolean = false;
		ApprovedDateTime: Date = null;
		ApprovedByContactId: number = null;
		ApprovedByContactName: string = "";
		ApprovalComments: string = "";
		AccessPassword: string = "";
		AssetPassword: string = "";
		SourceAssetId: number = null;
		Language: string = "";
		VersionId: string = null;
		VersionNumber: number = 0;
		VersionCurrent: boolean = false;
		VersionFinal: boolean = false;
		VersionInventoryTypeId: number = null;
		VersionStarting: string = "";
		VersionEnding: string = "";
		VersionStartingAllowClosest: boolean = false;
		VersionEndingAllowClosest: boolean = false;
		Attributes: any = null;
		Selections: AssetSelectionEditViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class AssetVisibilityEditViewModel {
		AssetVisibilityId: number = null;
		AssetId: number = null;
		Enabled: boolean = false;
		VisibilityType: string = "";
		VisibilityTypeId: number = null;
		VisibilityTypeDescription: string = "";
		RequireValidMaintenance: boolean = false;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class AttributeConfigurationEditViewModel {
		AttributeConfigurationId: number = null;
		AttributeSetId: number = null;
		AttributeNumber: number = 0;
		AttributeName: string = "";
		Description: string = "";
		DataType: string = "";
		SharedPickListId: string = null;
		DefaultValue: string = "";
		Mask: string = "";
		Required: boolean = false;
		Minimum: number = 0;
		Maximum: number = 0;
		Validation: string = "";
		ValidationFailedMessage: string = "";
		Encrypt: boolean = false;
		MessageExchangeId: number = null;
		DescriptionAttribute: boolean = false;
		PublicAttribute: boolean = false;
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class AttributeSetEditViewModel {
		AttributeSetId: number = null;
		Name: string = "";
		Description: string = "";
		AttributeSetType: string = "";
		AttributeSetTarget: string = "";
		AttributeSetScope: string = "";
		RoleId: number = null;
		SecurityAreaType: string = "";
		SecurityArea: string = "";
		Enabled: boolean = false;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		Properties: string = "";
		Comments: string = "";
		Details: AttributeConfigurationEditViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class AttributeEditViewModel {
		AttributeId: number = null;
		AttributeSetId: number = null;
		AttributeSetTarget: string = "";
		AttributeSetTargetId: number = null;
		AttributeSetTargetExternalId: string = null;
		AttributeSetTargetDescription: string = "";
		AttributeSetTargetName: string = "";
		Value001: string = "";
		Value002: string = "";
		Value003: string = "";
		Value004: string = "";
		Value005: string = "";
		Value006: string = "";
		Value007: string = "";
		Value008: string = "";
		Value009: string = "";
		Value010: string = "";
		Value011: string = "";
		Value012: string = "";
		Value013: string = "";
		Value014: string = "";
		Value015: string = "";
		Value016: string = "";
		Value017: string = "";
		Value018: string = "";
		Value019: string = "";
		Value020: string = "";
		Value021: string = "";
		Value022: string = "";
		Value023: string = "";
		Value024: string = "";
		Value025: string = "";
		Value026: string = "";
		Value027: string = "";
		Value028: string = "";
		Value029: string = "";
		Value030: string = "";
		Value031: string = "";
		Value032: string = "";
		Value033: string = "";
		Value034: string = "";
		Value035: string = "";
		Value036: string = "";
		Value037: string = "";
		Value038: string = "";
		Value039: string = "";
		Value040: string = "";
		Value041: string = "";
		Value042: string = "";
		Value043: string = "";
		Value044: string = "";
		Value045: string = "";
		Value046: string = "";
		Value047: string = "";
		Value048: string = "";
		Value049: string = "";
		Value050: string = "";
		Value051: string = "";
		Value052: string = "";
		Value053: string = "";
		Value054: string = "";
		Value055: string = "";
		Value056: string = "";
		Value057: string = "";
		Value058: string = "";
		Value059: string = "";
		Value060: string = "";
		Value061: string = "";
		Value062: string = "";
		Value063: string = "";
		Value064: string = "";
		Value065: string = "";
		Value066: string = "";
		Value067: string = "";
		Value068: string = "";
		Value069: string = "";
		Value070: string = "";
		Value071: string = "";
		Value072: string = "";
		Value073: string = "";
		Value074: string = "";
		Value075: string = "";
		Value076: string = "";
		Value077: string = "";
		Value078: string = "";
		Value079: string = "";
		Value080: string = "";
		Value081: string = "";
		Value082: string = "";
		Value083: string = "";
		Value084: string = "";
		Value085: string = "";
		Value086: string = "";
		Value087: string = "";
		Value088: string = "";
		Value089: string = "";
		Value090: string = "";
		Value091: string = "";
		Value092: string = "";
		Value093: string = "";
		Value094: string = "";
		Value095: string = "";
		Value096: string = "";
		Value097: string = "";
		Value098: string = "";
		Value099: string = "";
		Value100: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class AssetFeedbackEditViewModel {
		AssetFeedbackId: number = null;
		AssetId: number = null;
		FeedbackDateTime: Date = null;
		FeedbackByContactId: number = null;
		FeedbackByContactName: string = "";
		FeedbackIpAddress: string = "";
		Helped: string = "";
		Rating: number = 0;
		Feedback: string = "";
		NeedsReview: boolean = false;
		ReviewedByContactId: number = null;
		ReviewedByContactName: string = "";
		ReviewedDateTime: Date = null;
		Tags: string[] = [];
		HelpedInferred: string = "";
		HelpedInferredProbability: number = 0;
		RatingInferred: number = 0;
		RatingInferredProbability: number = 0;
		InferredDateTime: Date = null;
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class FilterConditionGroupViewModel {
		ConditionBooleanOperator: string = "";
		GroupBooleanOperator: string = "";
		Conditions: FilterConditionViewModel[] = [];
		Groups: FilterConditionGroupViewModel[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class FilterConditionViewModel {
		PropertyName: string = "";
		DataType: m.System.TypeCode = null;
		ComparisonOperator: string = "";
		Value: any = null;
		ValueDescription: string = "";
		ValueMetaData: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class FreeFormTextParserViewModel {
		FreeFormTextParserId: number = null;
		Description: string = "";
		Rules: FreeFormTextParserRuleViewModel[] = [];
		Comments: string = "";
		Private: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class FreeFormTextParserRuleViewModel {
		Id: string = null;
		DataType: string = "";
		Icon: string = "";
		Name: string = "";
		Description: string = "";
		MultipleLinesAllowed: boolean = false;
		Mode: string = "";
		AbsolutePositionStart: number = 0;
		AbsolutePositionEnd: number = 0;
		LineStart: number = 0;
		LineEnd: number = 0;
		LinePositionStart: number = 0;
		LinePositionEnd: number = 0;
		ExtractRegExMatchPattern: string = "";
		ExtractRegExFlags: string = "";
		ExtractRegExMatchInstance: number = 0;
		ExtractAfterText: string = "";
		ExtractAfterTextInstance: number = 0;
		ExtractAfterTextUntilText: string = "";
		ExtractAfterTextUntilEndOfLine: boolean = false;
		ExtractAfterTextUntilCharacterCount: number = 0;
		ExtractMaximumCharacterCount: number = 0;
	}


    // This is generated code... DO NOT modify this file.
	export class FilterEditViewModel {
		FilterId: number = null;
		ExternalFilterId: string = null;
		Description: string = "";
		LongDescription: string = "";
		Tags: string[] = [];
		Category: string = "";
		ObjectName: string = "";
		DataStoreFilterExpression: string = "";
		FilterConditions: FilterConditionGroupViewModel = null;
		Enabled: boolean = false;
		Shared: boolean = false;
		ValidForReport: boolean = false;
		ValidForAdHocQuery: boolean = false;
		ValidForDataExport: boolean = false;
		ValidForVisualComponent: boolean = false;
		FilterLocked: boolean = false;
		ContactId: number = null;
		ContactName: string = "";
		ApprovalPending: boolean = false;
		ApprovedDateTime: Date = null;
		ApprovedByContactId: number = null;
		ApprovedByContactName: string = "";
		ApprovedFilterFingerprint: string = "";
		ApprovalComments: string = "";
		FilterUseCount: number = 0;
		LastUsedDateTime: Date = null;
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class MergeEditViewModel {
		ObjectType: string = "";
		ObjectOneId: string = null;
		ObjectOnePropertiesToKeep: string[] = [];
		ObjectOneChildrenToDiscard: MergeChildEditViewModel[] = [];
		ObjectTwoId: string = null;
		ObjectTwoPropertiesToKeep: string[] = [];
		ObjectTwoChildrenToDiscard: MergeChildEditViewModel[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class MergeChildEditViewModel {
		ObjectType: string = "";
		ObjectId: string = null;
	}


    // This is generated code... DO NOT modify this file.
	export class NotDuplicateObjectEditViewModel {
		ObjectType: string = "";
		ObjectOneId: string = null;
		ObjectTwoId: string = null;
	}


    // This is generated code... DO NOT modify this file.
	export class QueryExecutionRequestOptionsViewModel {
		Description: string = "";
		QueryId: number = null;
		FilterId: number = null;
		FilterExpression: string = "";
		FilterConditions: FilterConditionGroupViewModel = null;
		DataSourceId: number = null;
		OutputConfigurationId: number = null;
		ReportTemplateId: number = null;
		JobId: number = null;
		RequestedByContactId: number = null;
		FileTypes: string[] = [];
		DateRange: string = "";
		BeginningDateTime: Date = null;
		EndingDateTime: Date = null;
		AsOfDateTime: Date = null;
		TimeZoneId: string = null;
		TimeZoneName: string = "";
		UserSystemTimeZoneId: string = null;
		ServerSystemTimeZoneId: string = null;
		HasTimeZoneDifference: boolean = false;
		Variables: any = null;
		DuplicatePreventionType: DuplicatePreventionTypeOption = null;
		DuplicatePreventionThresholdMinutes: number = 0;
		Test: boolean = false;
		SuppressQueryEventRecord: boolean = false;
		RealTime: boolean = false;
		Trace: boolean = false;
		QueryEventExpirationMinutes: number = 0;
		IsDateRangeBasedDataFeed: boolean = false;
		IncludeCoverPage: boolean = false;
		IncludeRowShading: boolean = false;
		IncludeDailySubtotal: boolean = false;
		IncludeCharts: boolean = false;
		ReinitializeSummaryData: boolean = false;
		NotificationContactId: number = null;
		NotificationContactType: string = "";
		UploadTargets: QueryExecutionRequestUploadTargetViewModel[] = [];
		EmailRecipients: QueryExecutionRequestEmailRecipientViewModel[] = [];
		EmailMessageAssetId: number = null;
		EmailSubject: string = "";
		EmailBody: string = "";
		UtcOffset: number = null;
	}


    // This is generated code... DO NOT modify this file.
	export class QueryExecutionRequestUploadTargetViewModel {
		FileServerId: number = null;
		FileServerFolder: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class QueryExecutionRequestEmailRecipientViewModel {
		Name: string = "";
		Email: string = "";
		Type: QueryExecutionRequestEmailRecipientType = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportTemplateEditViewModel {
		ReportTemplateId: number = null;
		SystemReportTemplateId: string = null;
		Description: string = "";
		Role: ReportTemplateRole = null;
		Type: ReportTemplateType = null;
		FileName: string = "";
		Template: string = "";
		Default: boolean = false;
		Language: string = "";
		Variables: ReportTemplateVariable[] = [];
		Comments: string = "";
		Properties: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class QueryVariableEditViewModel {
		Description: string = "";
		InputFormId: number = null;
		SystemInputFormId: string = null;
		InputFormSplit: boolean = false;
		InputFormStacked: boolean = false;
		InputFormSize: string = "";
		Collapsed: boolean = false;
		Hidden: boolean = false;
		DateType: QueryDateType = null;
		Variables: m.PropertyMetaDataViewModel[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class QueryOutputEditViewModel {
		Configurations: QueryOutputConfigurationEditViewModel[] = [];
		Templates: ReportTemplateEditViewModel[] = [];
		Properties: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class QueryOutputConfigurationEditViewModel {
		QueryOutputConfigurationId: number = null;
		Description: string = "";
		Default: boolean = false;
		QueryEventExpirationMinutes: number = 0;
		IncludeCoverPage: QueryOutputSetting = null;
		IncludeRowShading: QueryOutputSetting = null;
		IncludeDailySubtotal: QueryOutputSetting = null;
		IncludeCharts: QueryOutputSetting = null;
		ReinitializeSummaryData: QueryOutputSetting = null;
		SavePageViewImages: boolean = false;
		SaveRawData: boolean = false;
		PromptForReportTemplate: boolean = false;
		ReportTemplateId: number = null;
		SystemReportTemplateId: string = null;
		PromptForFileFormat: boolean = false;
		SupportedFileFormats: string[] = [];
		AlwaysGenerateFileFormats: string[] = [];
		PropertyDelimiter: string = "";
		StringQuoteDelimiter: string = "";
		RowDelimiter: string = "";
		RowTemplate: string = "";
		DateTimeFormat: string = "";
		PropertyNames: string[] = [];
		Headers: string[] = [];
		FormatPropertyNameInHeader: boolean = false;
		PropertyFormatting: QueryOutputPropertyFormatEditViewModel[] = [];
		ObjectName: string = "";
		ObjectKeyLabel: string = "";
		ObjectKeyPropertyName: string = "";
		Title: string = "";
		Subtitle: string = "";
		Details: string = "";
		IncludeAsOfTime: boolean = false;
		ExportFileName: string = "";
		ControlFileName: string = "";
		ControlFileFormat: string = "";
		ControlFileContents: string = "";
		Compression: QueryOutputCompressionType = null;
		CompressionPassword: string = "";
		EncryptionPublicKey: string = "";
		EncryptionKeyServerId: number = null;
		EncryptionKeyTag: string = "";
		Trace: boolean = false;
		Comments: string = "";
		Properties: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class QueryOutputPropertyFormatEditViewModel {
		PropertyName: string = "";
		FormatSelectionExpression: string = "";
		Description: string = "";
		DataType: m.System.TypeCode = null;
		IsHiddenAlways: boolean = false;
		IsHiddenIfRepeatedAbove: boolean = false;
		IsHiddenIfRepeatedAboveWhenTrueForAllRepeatedProperties: boolean = false;
		Template: string = "";
		Filter: string = "";
		IsGroupBy: boolean = false;
		IsGroupByCollapsed: boolean = false;
		StandardFormat: string = "";
		ExcelFormat: string = "";
		FontSize: number = 0;
		FontBold: boolean = false;
		FontItalic: boolean = false;
		FontUnderline: boolean = false;
		Color: string = "";
		BackgroundColor: string = "";
		Border: string = "";
		StyleAppliesTo: string = "";
		FooterType: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class QueryListViewModel {
		QueryId: number = null;
		ExternalQueryId: string = null;
		Category: string = "";
		Description: string = "";
		LongDescription: string = "";
		ContactId: number = null;
		ContactName: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class QueryEditViewModel {
		QueryId: number = null;
		ParentQueryId: number = null;
		ExternalQueryId: string = null;
		SystemQueryId: string = null;
		ParentSystemQueryId: string = null;
		Description: string = "";
		LongDescription: string = "";
		Tags: string[] = [];
		ContactId: number = null;
		ContactName: string = "";
		DataSourceId: number = null;
		DataSources: DataSourceEditViewModel[] = [];
		Entities: string = "";
		Columns: string = "";
		Conditions: string = "";
		Sorting: string = "";
		Variables: QueryVariableEditViewModel = null;
		Output: QueryOutputEditViewModel = null;
		CompiledQuery: string = "";
		AdvancedQuery: string = "";
		SensitiveInformationCategories: string[] = [];
		FilterObjectName: string = "";
		QueryLocked: boolean = false;
		Visibility: string = "";
		ValidForReport: boolean = false;
		ValidForAdHocQuery: boolean = false;
		ValidForDataExport: boolean = false;
		ValidForVisualComponent: boolean = false;
		ValidForDrillDownTarget: boolean = false;
		Restrictions: m5sec.RestrictionsEditViewModel = null;
		Category: string = "";
		ReadOnlyShareId: string = null;
		HitCount: number = 0;
		LastHitDateTime: Date = null;
		LastRowCount: number = 0;
		LastRunSeconds: number = 0;
		LastResultMessage: string = "";
		LastExecutionPlan: string = "";
		ApprovalPending: boolean = false;
		ApprovedDateTime: Date = null;
		ApprovedByContactId: number = null;
		ApprovedByContactName: string = "";
		ApprovedQueryFingerprint: string = "";
		ApprovalComments: string = "";
		TimeoutSeconds: number = 0;
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class DataSourceEditViewModel {
		DataSourceId: number = null;
		Description: string = "";
		DataFolder: string = "";
		FailoverDataFolder: string = "";
		ConnectionString: string = "";
		Users: DataSourceUserEditViewModel[] = [];
		Private: boolean = false;
		Type: DataSourceType = null;
	}


    // This is generated code... DO NOT modify this file.
	export class DataSourceUserEditViewModel {
		Description: string = "";
		Login: string = "";
		Password: string = "";
		Token: string = "";
		ApiKey: string = "";
		ContactId: number = null;
		ContactName: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class QueryExecutionRequestEditViewModel {
		QueryId: number = null;
		DataSourceId: number = null;
		Variables: {[key: string]:  any} = null;
		Test: boolean = false;
		ReturnResults: boolean = false;
		ExportFormats: string[] = [];
		ExportPropertyNames: string[] = [];
		ExportHeaders: string[] = [];
		ExportFileName: string = "";
		ExportTitle: string = "";
		ExportSubtitle: string = "";
		IncludeAsOfTime: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class QueryExecutionStatusViewModel {
		Request: QueryExecutionRequestEditViewModel = null;
		ExportFilePickUpId: string = null;
		ExportDownloadFileName: string = "";
		Status: string = "";
		Messages: string[] = [];
		RequestReceivedDateTime: Date = null;
		StartedDateTime: Date = null;
		FinishedDateTime: Date = null;
		AvailableUntilDateTime: Date = null;
		TimeZone: string = "";
		TimeZoneDescription: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class InventoryEventEditViewModel {
		InventoryEventId: string = null;
		ExternalInventoryEventId: string = null;
		InventoryEventType: string = "";
		EventDateTime: Date = null;
		Description: string = "";
		InventoryEventBatchId: string = null;
		TrackingNumber: string = "";
		InventoryId: number = null;
		InventoryLocationId: number = null;
		LocationContactId: number = null;
		LocationContactName: string = "";
		Quantity: number = 0;
		QuantityType: string = "";
		PostedQuantity: number = 0;
		PostedQuantityType: string = "";
		PurchaseReferenceNumber: string = "";
		PurchasePrice: number = 0;
		PurchaseIsoCurrencyCode: string = "";
		ContactId: number = null;
		ContactName: string = "";
		BillingAccountId: number = null;
		PackageOccurrenceId: number = null;
		CaseId: number = null;
		ProcessId: number = null;
		ProcessDateTime: Date = null;
		BillingTransactionId: number = null;
		BillingTransactionDetailId: number = null;
		RegardingResourceType: string = "";
		RegardingResourceId: number = null;
		RegardingResourceId2: string = "";
		Status: string = "";
		ResultCode: number = 0;
		ResultMessage: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class InventoryExpiringViewModel {
		ContactId: number = null;
		ContactType: string = "";
		ContactName: string = "";
		FirstName: string = "";
		LastName: string = "";
		Phone: string = "";
		Email: string = "";
		InventoryId: number = null;
		TrackingNumber: string = "";
		Description: string = "";
		InventoryTypeId: number = null;
		InventoryType: string = "";
		ExpirationDate: Date = null;
	}


    // This is generated code... DO NOT modify this file.
	export class InventoryHistoryEditViewModel {
		InventoryHistoryId: number = null;
		InventoryId: number = null;
		ParentInventoryId: number = null;
		PreviousParentInventoryId: number = null;
		NextParentInventoryId: number = null;
		InventoryTypeVersionId: number = null;
		PreviousInventoryTypeVersionId: number = null;
		NextInventoryTypeVersionId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		PreviousContactId: number = null;
		PreviousContactName: string = "";
		NextContactId: number = null;
		NextContactName: string = "";
		PackageOccurrenceId: number = null;
		PreviousPackageOccurrenceId: number = null;
		NextPackageOccurrenceId: number = null;
		BeginningDateTime: Date = null;
		EndingDateTime: Date = null;
		ExpectedEndingDateTime: Date = null;
		BeginningDateTimeCondition: string = "";
		EndingDateTimeCondition: string = "";
		AssociationType: string = "";
		AssociationValue: string = "";
		AssociationSwitchId: string = null;
		AssociationDescription: string = "";
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		ExpectedValidStartDateTime: Date = null;
		ExpectedValidEndDateTime: Date = null;
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class InventoryLocationEditViewModel {
		InventoryLocationId: number = null;
		InventoryId: number = null;
		Description: string = "";
		LocationContactId: number = null;
		LocationContactName: string = "";
		Quantity: number = 0;
		QuantityType: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class InventoryQuantityTypeEditViewModel {
		InventoryQuantityTypeId: number = null;
		QuantityType: string = "";
		Description: string = "";
		UnitOfMeasureType: string = "";
		Enabled: boolean = false;
		DisplayOrder: number = 0;
		Icon: string = "";
		Conversion: InventoryQuantityTypeConversionViewModel[] = [];
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class InventoryQuantityTypeConversionViewModel {
		TargetQuantityType: string = "";
		Multiplier: number = 0;
	}


    // This is generated code... DO NOT modify this file.
	export class InventoryTypeVersionReleaseNoteCreateRequestModel {
		Title: string = "";
		InventoryTypeVersionIds: number[] = [];
		Groups: string[] = [];
		Sort: string[] = [];
		UseCaseDescriptionWhenResolutionIsEmpty: boolean = false;
		OutputArticle: boolean = false;
		OutputDocx: boolean = false;
		OutputPdf: boolean = false;
		ReleaseNotesTemplateAssetId: number = null;
		OwnerInventoryTypeVersionId: number = null;
	}


    // This is generated code... DO NOT modify this file.
	export class InventoryTypeVersionEditViewModel {
		InventoryTypeVersionId: number = null;
		InventoryTypeId: number = null;
		Version: string = "";
		Description: string = "";
		ReleaseType: string = "";
		ReleaseDate: Date = null;
		DiscontinueDate: Date = null;
		SupportEndDate: Date = null;
		ExtendedSupportEndDate: Date = null;
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class InventoryTypeListViewModel {
		InventoryTypeId: number = null;
		Type: string = "";
		Class: string = "";
		Category: string = "";
		Description: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class InventoryTypeEditViewModel {
		InventoryTypeId: number = null;
		Type: string = "";
		Class: string = "";
		Category: string = "";
		Description: string = "";
		QuantityBasedInventory: boolean = false;
		QuantityLocationTracking: boolean = false;
		QuantityTypes: string[] = [];
		QuantityTypeDefault: string = "";
		ManufacturerContactId: number = null;
		ManufacturerContactName: string = "";
		ManagementVendorContactId: number = null;
		ManagementVendorContactName: string = "";
		PurchaseVendorContactId: number = null;
		PurchaseVendorContactName: string = "";
		PurchasePrice: number = 0;
		PurchaseIsoCurrencyCode: string = "";
		LeaseItem: boolean = false;
		LeaseDays: number = 0;
		LeaseStart: string = "";
		InstalledByContactId: number = null;
		InstalledByContactName: string = "";
		WarrantyDays: number = 0;
		WarrantyStart: string = "";
		MaintenanceVendorContactId: number = null;
		MaintenanceVendorContactName: string = "";
		MaintenanceDays: number = 0;
		MaintenanceStart: string = "";
		MaintenanceType: string = "";
		MaintenancePrice: number = 0;
		MaintenanceIsoCurrencyCode: string = "";
		Condition: string = "";
		AssociationType: string = "";
		SupportVersions: boolean = false;
		VersionNumberTemplate: string = "";
		VersionDescriptionTemplate: string = "";
		VersionReleaseFrequency: string = "";
		VersionReleaseFrequencyInterval: string = "";
		InventoryTypeVersionInstructions: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class InventoryListViewModel {
		InventoryId: number = null;
		TrackingNumber: string = "";
		SerialNumber: string = "";
		Description: string = "";
		ContactId: number = null;
		ContactName: string = "";
		ContactType: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class InventoryEditViewModel {
		InventoryId: number = null;
		InventoryTypeId: number = null;
		ParentInventoryId: number = null;
		TrackingNumber: string = "";
		Description: string = "";
		Quantity: number = 0;
		QuantityType: string = "";
		ContactId: number = null;
		ContactName: string = "";
		ContactType: string = "";
		PackageOccurrenceId: number = null;
		BillingContract: string = "";
		ManufacturerContactId: number = null;
		ManufacturerContactName: string = "";
		SerialNumber: string = "";
		InventoryTypeVersionId: number = null;
		ManagementVendorContactId: number = null;
		ManagementVendorContactName: string = "";
		PurchaseVendorContactId: number = null;
		PurchaseVendorContactName: string = "";
		PurchaseDate: Date = null;
		PurchaseReferenceNumber: string = "";
		PurchasePrice: number = 0;
		PurchaseIsoCurrencyCode: string = "";
		LeaseItem: boolean = false;
		LeaseExpirationDate: Date = null;
		InstalledDate: Date = null;
		InstalledByContactId: number = null;
		InstalledByContactName: string = "";
		WarrantyExpirationDate: Date = null;
		MaintenanceVendorContactId: number = null;
		MaintenanceVendorContactName: string = "";
		MaintenanceExpirationDate: Date = null;
		MaintenanceReferenceNumber: string = "";
		MaintenanceType: string = "";
		MaintenancePrice: number = 0;
		MaintenanceIsoCurrencyCode: string = "";
		Condition: string = "";
		ConditionDescription: string = "";
		ConditionUpdatedByContactId: number = null;
		ConditionUpdatedByContactName: string = "";
		ConditionUpdatedDateTime: Date = null;
		AssociationType: string = "";
		AssociationValue: string = "";
		AssociationSwitchId: string = null;
		AssociationDescription: string = "";
		AvailableDateTime: Date = null;
		ProcessingStatus: string = "";
		ProcessingServer: string = "";
		ProcessingDateTime: Date = null;
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class JobTypeViewModel {
		Type: string = "";
		Description: string = "";
		LongDescription: string = "";
		Category: string = "";
		CategoryDescription: string = "";
		DefaultProperties: string = "";
		SampleProperties: string = "";
		SystemAdministratorOnly: boolean = false;
		UnmanagedServiceOnly: boolean = false;
		SystemRequestedOnly: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class JobEditViewModel {
		JobId: number = null;
		Description: string = "";
		Tags: string[] = [];
		Type: string = "";
		TypeDescription: string = "";
		Priority: number = 0;
		Scope: string = "";
		Flags: string = "";
		ScheduleType: string = "";
		RealTimeInterval: number = 0;
		TriggeredByJobId: number = null;
		TriggeredByJobWhen: string = "";
		Status: string = "";
		Properties: any = null;
		RunAsApiKey: string = "";
		RunAsContactId: number = null;
		RunAsDomain: string = "";
		RunAsUserName: string = "";
		RunAsPassword: string = "";
		TargetDatabaseServer: string = "";
		TargetApplicationServer: string = "";
		RunningApplicationServer: string = "";
		RunningPingDateTime: Date = null;
		WaitingApplicationServer: string = "";
		WaitingPingDateTime: Date = null;
		WatchDogInterval: number = 0;
		Frequency: string = "";
		FrequencyDescription: string = "";
		LastRunDateTime: Date = null;
		NextRunDateTime: Date = null;
		NotBeforeTime: string = "";
		NotAfterTime: string = "";
		RunNow: boolean = false;
		CancelNow: boolean = false;
		TriggeredByResourceType: string = "";
		TriggeredByResourceId: number = null;
		TriggeredByResourceId2: string = "";
		LastResultCode: number = 0;
		LastResultMessage: string = "";
		Locked: boolean = false;
		Disabled: boolean = false;
		AllowLateExecution: boolean = false;
		AutoDelete: boolean = false;
		LogType: string = "";
		StatusIntervalMinutes: number = 0;
		RetryMinutes: number = 0;
		RetryMaximumCount: number = 0;
		RetryCount: number = 0;
		MinimumMemoryAvailableMb: number = 0;
		MinimumCpuAvailable: number = 0;
		RecycleMinutes: number = 0;
		RecycleMemoryMb: number = 0;
		RecycleTimeOfDay: string = "";
		RecyclePauseSeconds: number = 0;
		RecycleLastDateTime: Date = null;
		LoadBalanceNumber: number = 0;
		LoadBalanceTotal: number = 0;
		DatabaseLoadBalanceNumber: number = 0;
		DatabaseLoadBalanceTotal: number = 0;
		CountStart: number = 0;
		CountSuccess: number = 0;
		CountFailure: number = 0;
		NotificationGroupIdStart: number = 0;
		NotificationStartFlags: string = "";
		NotificationGroupIdProgress: number = 0;
		NotificationProgressFlags: string = "";
		NotificationProgressMinutes: number = 0;
		NotificationProgressDateTime: Date = null;
		NotificationProgressEvents: number = 0;
		NotificationProgressCounter: number = 0;
		NotificationGroupIdNoProgress: number = 0;
		NotificationNoProgressFlags: string = "";
		NotificationNoProgressDays1: string = "";
		NotificationNoProgressStartTime1: string = "";
		NotificationNoProgressEndTime1: string = "";
		NotificationNoProgressMinutes1: number = 0;
		NotificationNoProgressDays2: string = "";
		NotificationNoProgressStartTime2: string = "";
		NotificationNoProgressEndTime2: string = "";
		NotificationNoProgressMinutes2: number = 0;
		NotificationNoProgressDays3: string = "";
		NotificationNoProgressStartTime3: string = "";
		NotificationNoProgressEndTime3: string = "";
		NotificationNoProgressMinutes3: number = 0;
		NotificationNoProgressDays4: string = "";
		NotificationNoProgressStartTime4: string = "";
		NotificationNoProgressEndTime4: string = "";
		NotificationNoProgressMinutes4: number = 0;
		NotificationNoProgressDateTime: Date = null;
		NotificationGroupIdLogEvent: number = 0;
		NotificationLogEventKeywords: string = "";
		NotificationGroupIdError: number = 0;
		NotificationGroupIdFatalError: number = 0;
		NotificationGroupIdSuccess: number = 0;
		NotificationGroupIdFailure: number = 0;
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class AuditTrailEditViewModel {
		AuditTrailId: string = null;
		TransactionDateTime: Date = null;
		TransactionType: string = "";
		ContactId: number = null;
		ContactName: string = "";
		DatabaseObject: string = "";
		PrimaryKey: string = "";
		OwnerDatabaseObject: string = "";
		OwnerForeignKey: string = "";
		ApplicationName: string = "";
		MachineName: string = "";
		SessionId: number = null;
		AuditInformation: string = "";
		Alarm: boolean = false;
		AlarmAcknowledged: boolean = false;
		AutomatedFlag: boolean = false;
		ManualFlag: boolean = false;
		Reviewed: boolean = false;
		Details: AuditTrailDetailViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class AuditTrailDetailViewModel {
		PropertyName: string = "";
		DisplayName: string = "";
		OldValue: string = "";
		NewValue: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class LifeCycleEditViewModel {
		LifeCycleId: number = null;
		LifeCycleEventDateTime: Date = null;
		OwnerResourceType: string = "";
		OwnerResourceId: number = null;
		OwnerResourceId2: string = "";
		LifeCycleType: string = "";
		OldValue: string = "";
		NewValue: string = "";
		Cascading: boolean = false;
		TriggeredByContactId: number = null;
		TriggeredByContactName: string = "";
		TriggeredByResourceType: string = "";
		TriggeredByResourceId: number = null;
		TriggeredByResourceId2: string = "";
		TriggeredByDescription: string = "";
		Properties: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class LogListViewModel {
		LogId: string = null;
		SourceType: string = "";
		SourceId: number = null;
		SourceId2: string = "";
		SessionId: string = null;
		ContactId: number = null;
		ContactName: string = "";
		LogDateTime: Date = null;
		LogType: string = "";
		Category: string = "";
		Process: string = "";
		Subprocess: string = "";
		LogInformation: string = "";
		ResultCode: number = 0;
		Reviewed: boolean = false;
		Alarm: boolean = false;
		AlarmAcknowledged: boolean = false;
		TimeZone: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class LogEditViewModel {
		LogId: string = null;
		SourceType: string = "";
		SourceId: number = null;
		SourceId2: string = "";
		SessionId: string = null;
		ContactId: number = null;
		ContactName: string = "";
		LogDateTime: Date = null;
		LogType: string = "";
		Category: string = "";
		Process: string = "";
		Subprocess: string = "";
		LogInformation: string = "";
		LogDetail: string = "";
		ResultCode: number = 0;
		Reviewed: boolean = false;
		Alarm: boolean = false;
		AlarmAcknowledged: boolean = false;
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessStatusDetailEditViewModel {
		ProcessStatusDetailId: number = null;
		ProcessStatusId: number = null;
		StartDateTime: Date = null;
		Description: string = "";
		Value: number = 0;
		Information: string = "";
		DisplayOrder: number = 0;
		DetailGroup: string = "";
		DetailGroupDisplayOrder: number = 0;
		ValueIsDenominator: boolean = false;
		LastUpdateDateTime: Date = null;
		TimerCount: number = 0;
		TimerTotalMilliseconds: number = 0;
		TimerMaximumMilliseconds: number = 0;
		TimerMinimumMilliseconds: number = 0;
		TimerAverageMilliseconds: number = 0;
		TimerMedianMilliseconds: number = 0;
		TimerPercentile90Milliseconds: number = 0;
		TimerStandardDeviationMilliseconds: number = 0;
		TimerEventsMilliseconds: string = "";
		TimerStatsAsOfDateTime: Date = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessStatusEditViewModel {
		ProcessStatusId: number = null;
		Description: string = "";
		ProcessType: string = "";
		ProcessTypeId: number = null;
		ProcessTypeId2: string = "";
		SessionId: string = null;
		StatusIntervalMinutes: number = 0;
		ServerName: string = "";
		ServerProcessId: number = null;
		ProcessCount: number = 0;
		ProcessTotal: number = 0;
		ProcessTime: number = 0;
		StartDateTime: Date = null;
		LastUpdateDateTime: Date = null;
		DataProcessedDateTime: Date = null;
		FinishDateTime: Date = null;
		CancelNow: boolean = false;
		Status: string = "";
		ProcessMemoryUsedKb: number = 0;
		ServerMemoryFreeKb: number = 0;
		ServerMemoryTotalKb: number = 0;
		ErrorCount: number = 0;
		ErrorMessage: string = "";
		WarningCount: number = 0;
		WarningMessage: string = "";
		OmittedCount: number = 0;
		OmittedMessage: string = "";
		AlarmCount: number = 0;
		AlarmMessage: string = "";
		Feedback: string = "";
		ResultCode: number = 0;
		ResultMessage: string = "";
		TimerCount: number = 0;
		TimerTotalMilliseconds: number = 0;
		TimerMaximumMilliseconds: number = 0;
		TimerMinimumMilliseconds: number = 0;
		TimerAverageMilliseconds: number = 0;
		TimerMedianMilliseconds: number = 0;
		TimerPercentile90Milliseconds: number = 0;
		TimerStandardDeviationMilliseconds: number = 0;
		TimerEventsMilliseconds: string = "";
		TimerStatsAsOfDateTime: Date = null;
		Details: m.IProcessStatusDetailModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class QueryEventFileDeliveryViewModel {
		QueryEventFileDeliveryId: string = null;
		QueryEventFileId: string = null;
		QueryEventId: string = null;
		EventDateTime: Date = null;
		ContactId: number = null;
		ContactName: string = "";
		FileServerId: number = null;
		DeliveryDateTime: Date = null;
		DeliveryType: string = "";
		DeliveryDetail: string = "";
		Properties: string = "";
		ResultCode: number = 0;
		ResultMessage: string = "";
		Trace: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class QueryEventFileViewModel {
		QueryEventFileId: string = null;
		QueryEventId: string = null;
		EventDateTime: Date = null;
		Tag: string = "";
		QueryOutputConfigurationId: number = null;
		FileRole: string = "";
		HasFilePerPage: boolean = false;
		FriendlyName: string = "";
		FileType: string = "";
		ContentType: string = "";
		SizeBytes: number = 0;
		SizePages: number = 0;
		SizeRows: number = 0;
		HitCount: number = 0;
		LastHitDateTime: Date = null;
		Properties: string = "";
		ResultCode: number = 0;
		ResultMessage: string = "";
		Trace: string = "";
		Deliveries: QueryEventFileDeliveryViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class QueryEventViewModel {
		QueryEventId: string = null;
		EventDateTime: Date = null;
		Tag: string = "";
		Description: string = "";
		RequestedByContactId: number = null;
		RequestedByContactName: string = "";
		DataSourceId: number = null;
		QueryId: number = null;
		ReportId: number = null;
		ReportTemplateId: number = null;
		JobId: number = null;
		ExpirationDateTime: Date = null;
		CanDownloadAuthenticated: boolean = false;
		CanDownloadAnonymous: boolean = false;
		LogAccess: boolean = false;
		IsDateRangeBasedDataFeed: boolean = false;
		AsOfDateTime: Date = null;
		BeginningDateTime: Date = null;
		EndingDateTime: Date = null;
		TimeZoneId: string = null;
		TimeZoneName: string = "";
		ServerAsOfDateTime: Date = null;
		ServerBeginningDateTime: Date = null;
		ServerEndingDateTime: Date = null;
		Properties: QueryEventPropertiesViewModel = null;
		PercentDone: number = 0;
		Phase: string = "";
		RunningApplicationServer: string = "";
		RunningPingDateTime: Date = null;
		ResultCode: number = 0;
		ResultMessage: string = "";
		Trace: string = "";
		Files: QueryEventFileViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class QueryEventPropertiesViewModel {
		Request: QueryExecutionRequestOptionsViewModel = null;
		Information: QueryEventInformationViewModel = null;
		Variables: any = null;
		Results: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class QueryEventInformationViewModel {
		QueryDescription: string = "";
		FilterDescription: string = "";
		DataSourceDescription: string = "";
		OutputConfigurationDescription: string = "";
		ReportTemplateDescription: string = "";
		JobDescription: string = "";
		IncludesRawData: boolean = false;
		IncludesPageViewImages: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class WatchEventListViewModel {
		WatchEventId: string = null;
		EventDateTime: Date = null;
		ContactId: number = null;
		ContactName: string = "";
		WatchId: number = null;
		AuditTrailId: string = null;
		NotificationEventId: string = null;
		NotificationId: string = null;
		WatchedResourceType: string = "";
		WatchedResourceId: number = null;
		WatchedResourceId2: string = "";
		Message: string = "";
		PayloadId: string = null;
		PayloadVersion: number = 0;
		Status: string = "";
		DeliveredDateTime: Date = null;
		ResponseProgressPercentDone: number = 0;
		ResponseProgressMessage: string = "";
		ResponsePayloadId: string = null;
		ResponsePayloadVersion: number = 0;
		Flagged: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class WatchEventEditViewModel {
		WatchEventId: string = null;
		EventDateTime: Date = null;
		ContactId: number = null;
		ContactName: string = "";
		WatchId: number = null;
		AuditTrailId: string = null;
		NotificationEventId: string = null;
		NotificationId: string = null;
		WatchedResourceType: string = "";
		WatchedResourceId: number = null;
		WatchedResourceId2: string = "";
		Message: string = "";
		Properties: any = null;
		PayloadId: string = null;
		PayloadVersion: number = 0;
		Payload: string = "";
		Status: string = "";
		DeliveredServiceList: string[] = [];
		DeliveredDateTime: Date = null;
		ResponseProgressPercentDone: number = 0;
		ResponseProgressMessage: string = "";
		ResponsePayloadId: string = null;
		ResponsePayloadVersion: number = 0;
		ResponsePayload: string = "";
		Flagged: boolean = false;
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class WatchEventSystemAddViewModel {
		WatchedResourceType: string = "";
		PayloadId: string = null;
		PayloadVersion: number = 0;
		Payload: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ActionLinkEditViewModel {
		ActionLinkId: string = null;
		TagType: string = "";
		TagLength: number = 0;
		Tag: string = "";
		Role: string = "";
		Url: string = "";
		LinkType: string = "";
		Value001: string = "";
		Value002: string = "";
		Value003: string = "";
		Value004: string = "";
		Value005: string = "";
		Properties: string = "";
		NotificationTriggeredDateTime: Date = null;
		NotificationId: number = null;
		DeliveryMethod: string = "";
		ContactId: number = null;
		ContactName: string = "";
		AuthenticationRequired: boolean = false;
		AnonymousAccessApiKey: string = "";
		RegardingResourceType: string = "";
		RegardingResourceId: number = null;
		RegardingResourceId2: string = "";
		NotificationGroupId: number = null;
		CaseTemplateId: number = null;
		TriggerAssetId: number = null;
		FormId: number = null;
		ValidMinutes: number = 0;
		ValidCount: number = 0;
		ActionCount: number = 0;
		ActionDoneDateTime: Date = null;
		ActionDoneInformation: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class AlarmRuleNotificationEditViewModel {
		AlarmRuleId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		FirstNotifiedDateTime: Date = null;
		LastNotifiedDateTime: Date = null;
		ExpirationDateTime: Date = null;
		NotificationCounter: number = 0;
		ActiveNotification: boolean = false;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class AlarmRuleEditViewModel {
		AlarmRuleId: number = null;
		Description: string = "";
		RuleType: string = "";
		NotificationResetEventType: string = "";
		NotificationResetMinutes: number = 0;
		NotificationGroupId: number = null;
		RuleFormat: string = "";
		RuleText: string = "";
		OwnerResourceType: string = "";
		OwnerResourceId: number = null;
		OwnerResourceId2: string = "";
		Enabled: boolean = false;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		Trace: boolean = false;
		AlarmExecutionCount: number = 0;
		AlarmViolationCount: number = 0;
		LastUsedDateTime: Date = null;
		Comments: string = "";
		Notifications: AlarmRuleNotificationEditViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class NotificationContactEditViewModel {
		NotificationContactId: number = null;
		ContactId: number = null;
		OwnerContactName: string = "";
		OwnerContactType: string = "";
		ContactName: string = "";
		FirstName: string = "";
		MiddleName: string = "";
		LastName: string = "";
		OtherName: string = "";
		Title: string = "";
		Address1: string = "";
		Address2: string = "";
		Address3: string = "";
		City: string = "";
		State: string = "";
		PostalCode: string = "";
		Country: string = "";
		Phone: string = "";
		AlternatePhone: string = "";
		Fax: string = "";
		Cellular: string = "";
		Email: string = "";
		AlternateEmail: string = "";
		ImAddress: string = "";
		ImNetwork: string = "";
		AlternateImAddress: string = "";
		AlternateImNetwork: string = "";
		WebSite: string = "";
		FtpSite: string = "";
		NotificationContactType: string = "";
		DefaultNotificationContact: boolean = false;
		SmtpServer: string = "";
		SmtpServerPort: number = 0;
		SmtpTimeoutSeconds: number = 0;
		SmtpUseSsl: boolean = false;
		SmtpAuthenticate: boolean = false;
		SmtpUserName: string = "";
		SmtpPassword: string = "";
		SmtpReplyToName: string = "";
		SmtpReplyToAddress: string = "";
		SmtpServer2: string = "";
		SmtpServerPort2: number = 0;
		SmtpTimeoutSeconds2: number = 0;
		SmtpUseSsl2: boolean = false;
		SmtpAuthenticate2: boolean = false;
		SmtpUserName2: string = "";
		SmtpPassword2: string = "";
		SmtpReplyToName2: string = "";
		SmtpReplyToAddress2: string = "";
		SmsGateway: string = "";
		SmsTimeoutSeconds: number = 0;
		SmsInterface: string = "";
		SmsParameters: string = "";
		SmsUserName: string = "";
		SmsPassword: string = "";
		SmsFromName: string = "";
		SmsFromAddress: string = "";
		SmsGateway2: string = "";
		SmsTimeoutSeconds2: number = 0;
		SmsInterface2: string = "";
		SmsParameters2: string = "";
		SmsUserName2: string = "";
		SmsPassword2: string = "";
		SmsFromName2: string = "";
		SmsFromAddress2: string = "";
		FaxGateway: string = "";
		FaxTimeoutSeconds: number = 0;
		FaxInterface: string = "";
		FaxParameters: string = "";
		FaxUserName: string = "";
		FaxPassword: string = "";
		FaxFromName: string = "";
		FaxFromAddress: string = "";
		FaxGateway2: string = "";
		FaxTimeoutSeconds2: number = 0;
		FaxInterface2: string = "";
		FaxParameters2: string = "";
		FaxUserName2: string = "";
		FaxPassword2: string = "";
		FaxFromName2: string = "";
		FaxFromAddress2: string = "";
		VoiceGateway: string = "";
		VoiceTimeoutSeconds: number = 0;
		VoiceInterface: string = "";
		VoiceParameters: string = "";
		VoiceUserName: string = "";
		VoicePassword: string = "";
		VoiceFromName: string = "";
		VoiceFromAddress: string = "";
		VoiceGateway2: string = "";
		VoiceTimeoutSeconds2: number = 0;
		VoiceInterface2: string = "";
		VoiceParameters2: string = "";
		VoiceUserName2: string = "";
		VoicePassword2: string = "";
		VoiceFromName2: string = "";
		VoiceFromAddress2: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class NotificationEventEditViewModel {
		NotificationEventId: string = null;
		Description: string = "";
		Type: string = "";
		EventTag: string = "";
		Tags: string[] = [];
		NotificationGroupId: number = null;
		FilterId: number = null;
		ContactType: string = "";
		SeedListContactInformation: string = "";
		AssetId: number = null;
		ExternalAssetId: string = null;
		NotificationContactId: number = null;
		NotificationContactName: string = "";
		TriggeredForContactId: number = null;
		TriggeredForContactName: string = "";
		TriggeredByDataSource: string = "";
		TriggeredByDataKey: string = "";
		TriggeredByDescription: string = "";
		Attachments: NotificationEventAttachmentViewModel[] = [];
		NotificationData: NotificationEventDataViewModel = null;
		HoldDelivery: boolean = false;
		Status: string = "";
		ProcessingServer: string = "";
		ProcessingDateTime: Date = null;
		TestRun: boolean = false;
		TestRunCount: number = 0;
		TestRunContactInformation: string = "";
		SplitTestingId: string = null;
		SplitNumber: number = 0;
		SplitDescription: string = "";
		SplitPercent: number = 0;
		SetupSuccessCount: number = 0;
		SetupErrorCount: number = 0;
		EmailSuccessCount: number = 0;
		EmailErrorCount: number = 0;
		EmailOpenedCount: number = 0;
		EmailClickedCount: number = 0;
		EmailCausedOptOutCount: number = 0;
		SmsSuccessCount: number = 0;
		SmsErrorCount: number = 0;
		SmsCausedOptOutCount: number = 0;
		PhoneSuccessCount: number = 0;
		PhoneErrorCount: number = 0;
		PhoneCausedOptOutCount: number = 0;
		FaxSuccessCount: number = 0;
		FaxErrorCount: number = 0;
		FaxCausedOptOutCount: number = 0;
		Comments: string = "";
		Trace: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class NotificationEventAttachmentViewModel {
		AssetId: number = null;
		FileName: string = "";
		FriendlyName: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class NotificationEventDataViewModel {
		Data: string = "";
		Context: any = null;
		TriggeredByDataSource: string = "";
		TriggeredByDataKey: string = "";
		TriggeredByDescription: string = "";
		MessageId: number = null;
		ExternalMessageId: string = null;
		MessageSubject: string = "";
		MessageBody: string = "";
		Method: string = "";
		To: string[] = [];
		Cc: string[] = [];
		Bcc: string[] = [];
		ConsolidateNotifications: boolean = false;
		Priority: number = 0;
	}


    // This is generated code... DO NOT modify this file.
	export class NotificationGroupDetailEditViewModel {
		NotificationGroupDetailId: number = null;
		NotificationGroupId: number = null;
		NotificationOrder: number = 0;
		Description: string = "";
		Method: string = "";
		Type: string = "";
		NotificationRecipientType: string = "";
		SourceNotificationGroupId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		ContactType: string = "";
		ContactInformation: string = "";
		ContactInformationCopied: string = "";
		ContactInformationBlindCopied: string = "";
		ConsolidateNotifications: boolean = false;
		BroadcastGroup: string = "";
		ContactAddressType: string = "";
		IncludeAllAddressTypes: boolean = false;
		IncludeAlternateAddressesForMethod: boolean = false;
		FilterId: number = null;
		Comments: string = "";
		ConfirmationRequired: boolean = false;
		ConfirmSingleNotificationOnly: boolean = false;
		ConfirmationOptions: string = "";
		Enabled: boolean = false;
		NotificationMaximumCount: number = 0;
		InitialDelay: number = 0;
		LoopDelay: number = 0;
		StartTime: string = "";
		EndTime: string = "";
		AssetId: number = null;
		ExternalAssetId: string = null;
		NotificationContactId: number = null;
		NotificationContactName: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class NotificationGroupEditViewModel {
		NotificationGroupId: number = null;
		Description: string = "";
		NotificationContactType: string = "";
		NotificationContactMethod: string = "";
		Category: string = "";
		DisplayOrder: number = 0;
		Title: string = "";
		Summary: string = "";
		SourceNotificationGroupId: number = null;
		ManualBroadcast: boolean = false;
		Enabled: boolean = false;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		Trace: boolean = false;
		NotificationCount: number = 0;
		LastUsedDateTime: Date = null;
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class NotificationOptOutEditViewModel {
		NotificationOptOutId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		ContactInformation: string = "";
		Category: string = "";
		NotificationGroupId: number = null;
		MethodList: string = "";
		Method: string = "";
		Source: string = "";
		ReasonCode: string = "";
		Reason: string = "";
		NotificationEventDateTime: Date = null;
		NotificationEventId: number = null;
		NotificationId: number = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class NotificationEditViewModel {
		NotificationId: string = null;
		NotificationEventId: string = null;
		Description: string = "";
		ContactId: number = null;
		ContactName: string = "";
		ContactType: string = "";
		Method: string = "";
		Type: string = "";
		ContactInformation: string = "";
		ContactInformationCopied: string = "";
		ContactInformationBlindCopied: string = "";
		ConsolidateNotifications: boolean = false;
		NotificationGroupId: number = null;
		NotificationGroupDetailId: number = null;
		AssetId: number = null;
		ExternalAssetId: string = null;
		NotificationContactId: number = null;
		NotificationContactName: string = "";
		NotificationMaximumCount: number = 0;
		InitialDelay: number = 0;
		LoopDelay: number = 0;
		StartTime: string = "";
		EndTime: string = "";
		QueueDateTime: Date = null;
		Status: string = "";
		ProcessingServer: string = "";
		ProcessingDateTime: Date = null;
		SetupResultCode: number = 0;
		DeliveryResultCode: number = 0;
		NotificationCount: number = 0;
		FinishedDateTime: Date = null;
		ConfirmationRequired: boolean = false;
		ConfirmSingleNotificationOnly: boolean = false;
		ConfirmationOptions: string = "";
		ConfirmedDateTime: Date = null;
		ConfirmationOption: string = "";
		Comments: string = "";
		Opened: boolean = false;
		Clicked: boolean = false;
		CausedOptOut: boolean = false;
		NotDelivered: boolean = false;
		NotDeliveredCode: string = "";
		NotDeliveredMessage: string = "";
		NotDeliveredType: string = "";
		Trace: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class VoucherBatchEditViewModel {
		VoucherBatchId: number = null;
		ExternalVoucherBatchId: string = null;
		Description: string = "";
		VoucherCount: number = 0;
		VoucherTemplate: string = "";
		VoucherSerialNumberTemplate: string = "";
		StartingSerialNumber: number = 0;
		EndingSerialNumber: number = 0;
		VoucherUseCount: number = 0;
		AllowPreapprovedVoucherOveruse: boolean = false;
		PromoteVoucherReservations: boolean = false;
		SuppressBillingTransaction: boolean = false;
		LinkToAuthorizationCode: boolean = false;
		IsoCurrencyCode: string = "";
		VoucherAmount: number = 0;
		VoucherPercent: number = 0;
		AverageDiscountApplied: number = 0;
		VoucherMinimum: number = 0;
		VoucherMaximum: number = 0;
		VoucherType: string = "";
		CreatedByContactId: number = null;
		CreatedByContactName: string = "";
		CreatedDateTime: Date = null;
		OwnerContactId: number = null;
		OwnerContactName: string = "";
		NotificationGroupIdApproved: number = 0;
		NotificationGroupIdDenied: number = 0;
		DaysBeforeInactive: number = 0;
		DaysBeforeLapse: number = 0;
		LapseDateTime: Date = null;
		DaysBeforeExpire: number = 0;
		ExpireDateTime: Date = null;
		Status: string = "";
		MarketingMessageStatusHold: string = "";
		MarketingMessageStatusPending: string = "";
		MarketingMessageStatusReserved: string = "";
		MarketingMessageStatusActive: string = "";
		MarketingMessageStatusInactive: string = "";
		MarketingMessageStatusExpired: string = "";
		MarketingMessageStatusLapsed: string = "";
		MarketingMessageStatusSuspended: string = "";
		MarketingMessageStatusUsed: string = "";
		StatAsOfDateTime: Date = null;
		StatHoldCount: number = 0;
		StatHoldBalance: number = 0;
		StatPendingCount: number = 0;
		StatPendingBalance: number = 0;
		StatReservedCount: number = 0;
		StatReservedBalance: number = 0;
		StatActiveCount: number = 0;
		StatActiveBalance: number = 0;
		StatInactiveCount: number = 0;
		StatInactiveBalance: number = 0;
		StatExpiredCount: number = 0;
		StatExpiredBalance: number = 0;
		StatLapsedCount: number = 0;
		StatLapsedBalance: number = 0;
		StatSuspendedCount: number = 0;
		StatSuspendedBalance: number = 0;
		StatUsedCount: number = 0;
		StatUsedBalance: number = 0;
		Attributes: any = null;
		Lots: VoucherLotEditViewModel[] = [];
		Visibility: VoucherBatchVisibilityEditViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class VoucherLotEditViewModel {
		VoucherLotId: number = null;
		VoucherBatchId: number = null;
		ExternalVoucherLotId: string = null;
		Description: string = "";
		ManufacturerContactId: number = null;
		ManufacturerContactName: string = "";
		Manufacturer: string = "";
		ManufacturerOrder: string = "";
		ManufacturerOrderDateTime: Date = null;
		DistributorContactId: number = null;
		DistributorContactName: string = "";
		Distributor: string = "";
		DistributorOrder: string = "";
		DistributorOrderDateTime: Date = null;
		VoucherCount: number = 0;
		StartingSerialNumber: number = 0;
		EndingSerialNumber: number = 0;
		CreatedByContactId: number = null;
		CreatedByContactName: string = "";
		CreatedDateTime: Date = null;
		ManufacturingDateTime: Date = null;
		DistributionDateTime: Date = null;
		ActivatedDateTime: Date = null;
		ActivationStatus: string = "";
		StatAsOfDateTime: Date = null;
		StatHoldCount: number = 0;
		StatHoldBalance: number = 0;
		StatPendingCount: number = 0;
		StatPendingBalance: number = 0;
		StatReservedCount: number = 0;
		StatReservedBalance: number = 0;
		StatActiveCount: number = 0;
		StatActiveBalance: number = 0;
		StatInactiveCount: number = 0;
		StatInactiveBalance: number = 0;
		StatExpiredCount: number = 0;
		StatExpiredBalance: number = 0;
		StatLapsedCount: number = 0;
		StatLapsedBalance: number = 0;
		StatSuspendedCount: number = 0;
		StatSuspendedBalance: number = 0;
		StatUsedCount: number = 0;
		StatUsedBalance: number = 0;
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class VoucherBatchVisibilityEditViewModel {
		VisibilityId: number = null;
		VoucherBatchId: number = null;
		Enabled: boolean = false;
		VisibilityType: string = "";
		VisibilityTypeId: number = null;
		VisibilityTypeId2: string = "";
		VisibilityTypeId2EndRange: string = "";
		VisibilityTypeValueList: string[] = [];
		VisibilityTypeScope: string = "";
		IncludeSubvalues: boolean = false;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class VoucherBatchRestrictionRuleEditViewModel {
		RestrictionRuleId: number = null;
		VoucherBatchId: number = null;
		Enabled: boolean = false;
		RestrictionRuleTemplate: string = "";
		LimitUseCount: number = 0;
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class VoucherCheckRequestViewModel {
		VoucherCode: string = "";
		IsoCurrencyCode: string = "";
		Amount: number = 0;
		Visibility: {[key: string]:  string} = null;
	}


    // This is generated code... DO NOT modify this file.
	export class VoucherAddFromListAddViewModel {
		VoucherBatchId: number = null;
		VoucherLotId: number = null;
		VoucherCodes: string[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class VoucherEditViewModel {
		VoucherId: number = null;
		VoucherLotId: number = null;
		VoucherBatchId: number = null;
		VoucherCode: string = "";
		VoucherSerialNumber: number = 0;
		Status: string = "";
		ActivatedDateTime: Date = null;
		LapseDateTime: Date = null;
		ExpireDateTime: Date = null;
		LastUseDateTime: Date = null;
		Balance: number = 0;
		DiscountApplied: number = 0;
		VoucherUseCount: number = 0;
		ContactId: number = null;
		ContactName: string = "";
		BillingAccountId: number = null;
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class AliasEditViewModel {
		AliasId: number = null;
		OwnerResourceType: string = "";
		OwnerResourceId: number = null;
		OwnerResourceId2: string = "";
		AliasType: string = "";
		Value: string = "";
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class FeedbackEditViewModel {
		FeedbackId: number = null;
		FeedbackDateTime: Date = null;
		FeedbackByContactId: number = null;
		FeedbackByContactName: string = "";
		FeedbackIpAddress: string = "";
		Helped: string = "";
		Rating: number = 0;
		Feedback: string = "";
		NeedsReview: boolean = false;
		ReviewedByContactId: number = null;
		ReviewedByContactName: string = "";
		ReviewedDateTime: Date = null;
		Tags: string[] = [];
		HelpedInferred: string = "";
		HelpedInferredProbability: number = 0;
		RatingInferred: number = 0;
		RatingInferredProbability: number = 0;
		InferredDateTime: Date = null;
		OwnerResourceType: string = "";
		OwnerResourceId: number = null;
		OwnerResourceId2: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ReferenceEditViewModel {
		ReferenceId: number = null;
		OwnerResourceType: string = "";
		OwnerResourceId: number = null;
		OwnerResourceId2: string = "";
		ReferencedResourceType: string = "";
		ReferencedResourceId: number = null;
		ReferencedResourceId2: string = "";
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class RestrictionCheckEditViewModel {
		RestrictionCheckId: number = null;
		RestrictionRuleId: number = null;
		RestrictedResourceType: string = "";
		RestrictedResourceId: number = null;
		RestrictedResourceId2: string = "";
		RestrictedCheck: string = "";
		UseCount: number = 0;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class RestrictionRuleEditViewModel {
		RestrictionRuleId: number = null;
		OwnerResourceType: string = "";
		OwnerResourceId: number = null;
		OwnerResourceId2: string = "";
		Enabled: boolean = false;
		RestrictionRuleTemplate: string = "";
		LimitUseCount: number = 0;
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TaskListTemplateEditViewModel {
		TaskListTemplateId: number = null;
		AlternateTaskListTemplateId: string = null;
		DisplayOrder: number = 0;
		Description: string = "";
		Comments: string = "";
		Enabled: string = "";
		EnabledWhen: string = "";
		EnabledReason: string = "";
		Required: string = "";
		RequiredReason: string = "";
		RequiredWaivedForContacts: ContactReferenceViewModel[] = [];
		DependsOnTaskLists: string = "";
		MilestoneCompletion: boolean = false;
		CompletedTaskMode: string = "";
		DefaultTaskList: boolean = false;
		OwnerCanEstimate: boolean = false;
		OwnerCanReassign: boolean = false;
		OwnerCanEdit: boolean = false;
		SupportWorkFlow: boolean = false;
		SupportSubtasks: boolean = false;
		SupportNotes: boolean = false;
		SupportTimeLogs: boolean = false;
		SupportFreeForm: boolean = false;
		SupportAddingTasks: boolean = false;
		SupportSystemTasks: boolean = false;
		SupportTriggers: boolean = false;
		SupportAdvancedSettings: boolean = false;
		SupportExtendedAttributes: boolean = false;
		HasExtendedAttributes: boolean = false;
		CloseRuleNoteRequired: boolean = false;
		CloseRuleTimeLogRequired: boolean = false;
		CloseRuleTimeLogsAllClosed: boolean = false;
		CloseRuleAllSubtasksCompleted: boolean = false;
		Private: boolean = false;
		Icon: string = "";
		DisplayColor: string = "";
		OwnerResourceType: string = "";
		OwnerResourceId: number = null;
		OwnerResourceId2: string = "";
		Tasks: TaskTemplateEditViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TaskTemplateEditViewModel {
		TaskTemplateId: number = null;
		AlternateTaskTemplateId: string = null;
		TaskListTemplateId: number = null;
		TaskType: string = "";
		Category: string = "";
		DisplayOrder: number = 0;
		Description: string = "";
		Comments: string = "";
		Priority: number = 0;
		Status: string = "";
		Required: string = "";
		RequiredReason: string = "";
		RequiredWaivedForContacts: ContactReferenceViewModel[] = [];
		StartLagTimeMinutes: number = 0;
		EstimatedTimeMinutes: number = 0;
		EstimatedCloseMinutes: number = 0;
		DependsOnTasks: string = "";
		EstimatedStartAlternateTaskId: string = null;
		EstimatedStartAlternateTaskIdTrigger: string = "";
		EstimatedCloseAlternateTaskId: string = null;
		EstimatedCloseAlternateTaskIdTrigger: string = "";
		EndDateTreatment: string = "";
		AutoClose: boolean = false;
		Announce: boolean = false;
		ReminderStartMinutes: number[] = [];
		ReminderEndMinutes: number[] = [];
		StartedProgress: string = "";
		CompletedPercentDone: number = 0;
		Billable: boolean = false;
		BillingRate: number = 0;
		Budget: number = 0;
		MilestoneCompletion: boolean = false;
		OwnerCanEstimate: boolean = false;
		OwnerCanReassign: boolean = false;
		OwnerCanEdit: boolean = false;
		CloseRuleNoteRequired: boolean = false;
		CloseRuleTimeLogRequired: boolean = false;
		CloseRuleTimeLogsAllClosed: boolean = false;
		CloseRuleAllSubtasksCompleted: boolean = false;
		AssignmentFlag: string = "";
		AssignToContactId: number = null;
		AssignToContactName: string = "";
		AssignedByFlag: string = "";
		AssignedByContactId: number = null;
		AssignedByContactName: string = "";
		SupervisorFlag: string = "";
		SupervisorContactId: number = null;
		SupervisorContactName: string = "";
		Participants: ContactReferenceViewModel[] = [];
		CanCompleteFlags: string[] = [];
		TaskStartNotificationGroupId: number = null;
		TaskStartProcessTemplateId: number = null;
		TaskStartProcessPeerId: string = null;
		TaskStartTriggerAssetId: number = null;
		TaskCompleteNotificationGroupId: number = null;
		TaskCompleteProcessTemplateId: number = null;
		TaskCompleteProcessPeerId: string = null;
		TaskCompleteTriggerAssetId: number = null;
		RelatedToAlternateTaskId: string = null;
		HasExtendedAttributes: boolean = false;
		Private: boolean = false;
		Icon: string = "";
		DisplayColor: string = "";
		SystemTaskProcessTemplateId: number = null;
		SystemTaskProcessPeerId: string = null;
		SystemTaskProcessWait: boolean = false;
		SystemTaskId: string = null;
		SystemTaskAction: TaskSystemTaskActionEditViewModel = null;
		SystemTaskCompleteFlag: string = "";
		SystemTaskCompleteAction: string[] = [];
		Subtasks: TaskSubtaskTemplateEditViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TaskSubtaskTemplateEditViewModel {
		TaskSubtaskTemplateId: number = null;
		TaskTemplateId: number = null;
		DisplayOrder: number = 0;
		Description: string = "";
		Comments: string = "";
		TaskStatusWhenComplete: string = "";
		TaskPercentDoneWhenComplete: number = 0;
		AutoComplete: boolean = false;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TaskListEditViewModel {
		TaskListId: number = null;
		TaskListTemplateId: number = null;
		DisplayOrder: number = 0;
		Description: string = "";
		Comments: string = "";
		EndDateTime: Date = null;
		EstimatedCloseDateTime: Date = null;
		ActualCloseDateTime: Date = null;
		OwnerResourceType: string = "";
		OwnerResourceId: number = null;
		OwnerResourceId2: string = "";
		Tasks: TaskEditViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TaskEditViewModel {
		TaskId: number = null;
		TaskListId: number = null;
		TaskType: string = "";
		Category: string = "";
		DisplayOrder: number = 0;
		Description: string = "";
		Comments: string = "";
		Priority: number = 0;
		Status: string = "";
		PercentDone: number = 0;
		NextReminderDateTime: Date = null;
		EstimatedStartDateTime: Date = null;
		StartDateTime: Date = null;
		EndDateTime: Date = null;
		EstimatedCloseDateTime: Date = null;
		ActualCloseDateTime: Date = null;
		ActualTimeMinutes: number = 0;
		RelatedToTaskId: number = null;
		AssignedToContactId: number = null;
		AssignedToContactName: string = "";
		AssignedByContactId: number = null;
		AssignedByContactName: string = "";
		SupervisorContactId: number = null;
		SupervisorContactName: string = "";
		Participants: ContactReferenceViewModel[] = [];
		Billable: boolean = false;
		BillingRate: number = 0;
		Budget: number = 0;
		TaskTemplateId: number = null;
		SystemTaskProcessDateTime: Date = null;
		SystemTaskProcessId: number = null;
		SystemTaskProcessStatus: string = "";
		SystemTaskId: string = null;
		SystemTaskAction: TaskSystemTaskActionEditViewModel = null;
		SystemTaskCompleteFlag: string = "";
		SystemTaskCompleteAction: string = "";
		SystemTaskCompleteDate: Date = null;
		SystemTaskParameters: TaskSystemTaskParametersEditViewModel = null;
		SystemTaskResults: TaskSystemTaskResultsEditViewModel = null;
		Subtasks: TaskSubtaskEditViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TaskSubtaskEditViewModel {
		TaskSubtaskId: number = null;
		TaskId: number = null;
		DisplayOrder: number = 0;
		Description: string = "";
		Comments: string = "";
		ClosedDateTime: Date = null;
		ClosedByContactId: number = null;
		ClosedByContactName: string = "";
		TaskSubtaskTemplateId: number = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TimeLogEditViewModel {
		TimeLogId: number = null;
		EnteredByContactId: number = null;
		EnteredByContactName: string = "";
		Type: string = "";
		Code: string = "";
		Class: string = "";
		Category: string = "";
		StartDateTime: Date = null;
		EndDateTime: Date = null;
		Minutes: number = 0;
		Description: string = "";
		Tags: string[] = [];
		Billable: boolean = false;
		ItemId: number = null;
		RateId: string = null;
		AdjustedMinutes: number = 0;
		BillingTransactionId: number = null;
		InvoiceNumber: string = "";
		InvoiceDate: Date = null;
		OwnerResourceType: string = "";
		OwnerResourceId: number = null;
		OwnerResourceId2: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class VisibilityEditViewModel {
		VisibilityId: number = null;
		OwnerResourceType: string = "";
		OwnerResourceId: number = null;
		OwnerResourceId2: string = "";
		Enabled: boolean = false;
		VisibilityType: string = "";
		VisibilityTypeId: number = null;
		VisibilityTypeId2: string = "";
		VisibilityTypeId2EndRange: string = "";
		VisibilityTypeValueList: string[] = [];
		VisibilityTypeScope: string = "";
		IncludeSubvalues: boolean = false;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class SyncArticleDetailEditViewModel {
		SyncArticleDetailId: number = null;
		SyncArticleId: number = null;
		PublishedDateTime: Date = null;
		Property: string = "";
		Value: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class SyncArticleSubscriptionEditViewModel {
		SyncArticleSubscriptionId: number = null;
		SyncArticleId: number = null;
		PublishedDateTime: Date = null;
		SyncSubscriptionId: number = null;
		SyncDataStoreId: number = null;
		Status: string = "";
		Result: string = "";
		AutoApproved: boolean = false;
		ApprovedDateTime: Date = null;
		ApprovedBy: string = "";
		ApprovedProperties: string = "";
		ExcludedProperties: string = "";
		LastUpdateDateTime: Date = null;
		FinishedDateTime: Date = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class SyncArticleEditViewModel {
		SyncArticleId: number = null;
		SyncPublisherArticleTypeId: number = null;
		ArticleKeyId: number = null;
		ArticleKeyId2: string = "";
		EventType: string = "";
		PublishedDateTime: Date = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class SyncDataStoreEditViewModel {
		SyncDataStoreId: number = null;
		Description: string = "";
		DatabaseId: string = null;
		PartitionId: number = null;
		Region: string = "";
		DataFolder: string = "";
		Login: string = "";
		ApiKey: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class SyncPublisherArticleTypeEditViewModel {
		SyncPublisherArticleTypeId: number = null;
		SyncDataStoreId: number = null;
		Description: string = "";
		Enabled: boolean = false;
		ArticleType: string = "";
		ArticleFilter: string = "";
		IdentityRangeStart: number = 0;
		IdentityRangeEnd: number = 0;
		SyncMode: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class SyncSubscriptionEditViewModel {
		SyncSubscriptionId: number = null;
		SyncPublisherArticleTypeId: number = null;
		SyncDataStoreId: number = null;
		Description: string = "";
		Enabled: boolean = false;
		SubscribeToAddEvents: boolean = false;
		SubscribeToEditEvents: boolean = false;
		SubscribeToDeleteEvents: boolean = false;
		RealTimeDelivery: boolean = false;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class DataSourceListViewModel {
		DataSourceId: number = null;
		Description: string = "";
		DataFolder: string = "";
		FailoverDataFolder: string = "";
		ConnectionString: string = "";
		Private: boolean = false;
		Type: DataSourceType = null;
	}


    // This is generated code... DO NOT modify this file.
	export class CurrencyListViewModel {
		IsoCurrencyCode: string = "";
		Description: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class CurrencyEditViewModel {
		CurrencyId: number = null;
		IsoCurrencyCode: string = "";
		Description: string = "";
		DecimalSeparator: string = "";
		Decimals: number = 0;
		DetailDecimals: number = 0;
		GroupSeparator: string = "";
		DigitsInGroup: number = 0;
		CurrencyMarker: string = "";
		CurrencyMarkerAfterAmount: boolean = false;
		CurrencyMarkerSpaceSeparated: boolean = false;
		NegativeMarkerFlag: string = "";
		NegativeMarkerSpaceSeparated: boolean = false;
		NegativeMarkerOutsideCurrencyMarker: boolean = false;
		Enabled: boolean = false;
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class DataImportDefinitionViewModelBase {
		DataImportDefinitionId: number = null;
		Description: string = "";
		Type: string = "";
		Version: number = 0;
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class DataImportDefinitionViewModel extends DataImportDefinitionViewModelBase {
		Properties: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class DataImportDefinitionDataUpdatePropertiesViewModel {
		DataSource: string = "";
		DataTargets: DataImportDefinitionDataUpdateTargetPropertiesViewModel[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class DataImportDefinitionDataUpdateTargetPropertiesViewModel {
		DataTarget: string = "";
		IgnoreDataUpdateSafeguards: boolean = false;
		Properties: m.PropertyMetaDataViewModel[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class DataTableSupportViewModel {
		DataTableSupportId: number = null;
		Description: string = "";
		TableName: string = "";
		TableScope: string = "";
		FilterExpression: string = "";
		SensitiveInformationHandlerEnabled: boolean = false;
		SensitiveInformationHandlers: DataTableSupportSensitiveInformationHandlerViewModel[] = [];
		AuditTrailEnabled: boolean = false;
		AuditTrailCustomization: m5core.ScriptViewModel = null;
		TriggersEnabled: boolean = false;
		TriggerOnAddBefore: DataTableSupportTriggerViewModel = null;
		TriggerOnAddSuccess: DataTableSupportTriggerViewModel = null;
		TriggerOnAddFail: DataTableSupportTriggerViewModel = null;
		TriggerOnEditBefore: DataTableSupportTriggerViewModel = null;
		TriggerOnEditSuccess: DataTableSupportTriggerViewModel = null;
		TriggerOnEditFail: DataTableSupportTriggerViewModel = null;
		TriggerOnDeleteBefore: DataTableSupportTriggerViewModel = null;
		TriggerOnDeleteSuccess: DataTableSupportTriggerViewModel = null;
		TriggerOnDeleteFail: DataTableSupportTriggerViewModel = null;
		TriggerOnReadBefore: DataTableSupportTriggerViewModel = null;
		TriggerOnReadSuccess: DataTableSupportTriggerViewModel = null;
		TriggerOnReadFail: DataTableSupportTriggerViewModel = null;
		Triggers: m5core.ScriptViewModel = null;
		Comments: string = "";
		PartitionId: number = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class DataTableSupportSensitiveInformationHandlerViewModel {
		PropertyName: string = "";
		Category: string = "";
		MaskType: string = "";
		MaskReplacementValue: string = "";
		MaskCharactersToShowPrefix: number = 0;
		MaskCharactersToShowSuffix: number = 0;
		MaskRemovalRequestAudited: boolean = false;
		SanitizeType: string = "";
		SanitizeReplacementValue: string = "";
		SanitizeCharactersToKeepPrefix: number = 0;
		SanitizeCharactersToKeepSuffix: number = 0;
		SanitizeRequestAudited: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class DataTableSupportTriggerViewModel {
		Description: string = "";
		NotificationGroupId: number = null;
		ProcessTemplateId: number = null;
		ProcessPeerId: string = null;
		CaseTemplateId: number = null;
		WebhookId: number = null;
		TriggerAssetId: number = null;
		Triggers: m5core.ScriptViewModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class FileServerEditViewModel {
		FileServerId: number = null;
		Description: string = "";
		Server: string = "";
		Port: number = 0;
		Login: string = "";
		Password: string = "";
		ProxyServerAutoDetect: boolean = false;
		ProxyServerId: number = null;
		InitialDirectory: string = "";
		Ssl: SslSettingsEditViewModel = null;
		Ssh: SshSettingsEditViewModel = null;
		Ftp: FtpSettingsEditViewModel = null;
		InactivityTimeoutSeconds: number = 0;
		AbsoluteTimeoutSeconds: number = 0;
		Encryption: EncryptionAlgorithm = null;
		EncryptionPassword: string = "";
		Private: boolean = false;
		ServerType: FileServerType = null;
	}


    // This is generated code... DO NOT modify this file.
	export class SslSettingsEditViewModel {
		Enabled: boolean = false;
		AcceptServerCertificate: boolean = false;
		StartMode: SslStartMode = null;
		CertificateId: number = null;
	}


    // This is generated code... DO NOT modify this file.
	export class SshSettingsEditViewModel {
		AcceptServerKey: boolean = false;
		AcceptServerCertificatesFromIssuerPublicKey: string = "";
		AcceptedServerKeys: string[] = [];
		AcceptedServerFingerprints: string[] = [];
		AcceptedServerCertificates: number[] = [];
		AuthorizationMode: SshAuthorizationMode = null;
		CertificateId: number = null;
		CompressionAlgorithms: string[] = [];
		EncryptionAlgorithms: string[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class FtpSettingsEditViewModel {
		PassiveMode: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class MailAddressActionViewModel {
		MailAddressActionId: number = null;
		Description: string = "";
		MailAddress: string = "";
		MailAddressAliases: string[] = [];
		Enabled: boolean = false;
		LogType: string = "";
		IgnoredSenders: string[] = [];
		IgnoreWhenSubjectContains: string[] = [];
		IgnoreWhenBodyContains: string[] = [];
		CaseTemplateId: number = null;
		CaseCreateWhenSenderUnknown: boolean = false;
		CaseOwnedByContactId: number = null;
		CaseOwnedByContactName: string = "";
		CaseOwnedByContactType: string = "";
		CaseAssignedToContactId: number = null;
		CaseAssignedToContactName: string = "";
		CaseAssignedToContactType: string = "";
		CaseSupervisorContactId: number = null;
		CaseSupervisorContactName: string = "";
		CaseSupervisorContactType: string = "";
		CasePriority: string = "";
		CaseTags: string[] = [];
		CaseMatchUsingHeaderMessageId: boolean = null;
		CaseMatchUsingHeaderMessageIdTemplate: string = "";
		CaseMatchUsingSubjectLine: boolean = false;
		CaseMatchUsingSubjectLineTemplate: string = "";
		CaseMatchUsingBody: boolean = false;
		CaseMatchUsingBodyTemplate: string = "";
		NoReplyNeededSenders: string[] = [];
		SuppressNotification: boolean = false;
		PublicCorrespondence: boolean = false;
		ProcessTemplateId: number = null;
		ProcessCreateWhenSenderUnknown: boolean = false;
		Comments: string = "";
		Private: boolean = false;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class MailServerViewModel {
		MailServerId: number = null;
		Description: string = "";
		Enabled: boolean = false;
		IncomingMailProtocol: string = "";
		PopServer: string = "";
		PopServerPort: number = 0;
		PopTimeoutSeconds: number = 0;
		PopUseSsl: boolean = false;
		PopUserName: string = "";
		PopPassword: string = "";
		ImapServer: string = "";
		ImapServerPort: number = 0;
		ImapTimeoutSeconds: number = 0;
		ImapUseSsl: boolean = false;
		ImapUserName: string = "";
		ImapPassword: string = "";
		ImapMailboxName: string = "";
		SmtpServer: string = "";
		SmtpServerPort: number = 0;
		SmtpTimeoutSeconds: number = 0;
		SmtpUseSsl: boolean = false;
		SmtpAuthenticate: boolean = false;
		SmtpUserName: string = "";
		SmtpPassword: string = "";
		SmtpReplyToName: string = "";
		SmtpReplyToAddress: string = "";
		Comments: string = "";
		Private: boolean = false;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessStepListViewModel {
		ProcessStepId: string = null;
		ProcessId: string = null;
		ProcessTemplateId: number = null;
		ProcessTemplateStepId: number = null;
		ProcessStepTag: string = "";
		ProcessDateTime: Date = null;
		StepNumber: number = 0;
		Description: string = "";
		Priority: number = 0;
		StepActionId: string = null;
		Status: string = "";
		ResultCode: number = 0;
		ResultMessage: string = "";
		PercentDone: number = 0;
		FirstAttemptDateTime: Date = null;
		StartDateTime: Date = null;
		EnteredWaitStateDateTime: Date = null;
		FinishDateTime: Date = null;
		QueueDateTime: Date = null;
		AttemptCount: number = 0;
		LastAttemptDateTime: Date = null;
		TimeZone: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessStepEditViewModel {
		ProcessStepId: string = null;
		ProcessId: string = null;
		ProcessTemplateId: number = null;
		ProcessTemplateStepId: number = null;
		ProcessStepTag: string = "";
		ProcessDateTime: Date = null;
		StepNumber: number = 0;
		Description: string = "";
		Priority: number = 0;
		StepActionId: string = null;
		InputProperties: string = "";
		OutputProperties: string = "";
		Status: string = "";
		ResultCode: number = 0;
		ResultMessage: string = "";
		PercentDone: number = 0;
		FirstAttemptDateTime: Date = null;
		StartDateTime: Date = null;
		EnteredWaitStateDateTime: Date = null;
		FinishDateTime: Date = null;
		QueueDateTime: Date = null;
		AttemptCount: number = 0;
		LastAttemptDateTime: Date = null;
		TargetServers: string[] = [];
		ProcessingServer: string = "";
		ProcessingDateTime: Date = null;
		NotificationRetryDateTime: Date = null;
		NotificationWaitDateTime: Date = null;
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessTemplateStepActionPropertiesEditViewModel<T> {
		StepActionType: string = "";
		Version: number = 0;
		Properties: T = null;
		Attributes: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessTemplateStepActionChildProcessPropertiesModel {
		ProcessTemplateId: number = null;
		ProcessPeerId: string = null;
		Wait: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessTemplateStepActionCasePropertiesEditViewModel {
		CaseTemplateId: number = null;
		CaseDescription: string = "";
		Wait: boolean = false;
		CaseSuccessOutcome: string = "";
		CaseFailureOutcome: string = "";
		CaseIndeterminateOutcome: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessTemplateStepActionScriptPropertiesEditViewModel {
		ScriptAssetId: number = null;
		ScriptSuccessResultCodes: number[] = [];
		ScriptWaitingResultCodes: number[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessTemplateStepActionJobPropertiesEditViewModel {
		JobType: string = "";
		JobProperties: any = null;
		Wait: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessTemplateStepActionPluginPropertiesEditViewModel {
		Plugin: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessTemplateStepActionNotificationPropertiesEditViewModel {
		NotificationGroupId: number = null;
		Wait: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessTemplateStepActionStepPropertiesEditViewModel {
		StartStepActionId: string = null;
		StartStepProcessTemplateId: number = null;
		StartStepNumbers: number[] = [];
		MaximumSteps: number = 0;
		Chronological: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessTemplateStepActionDatabaseActionPropertiesEditViewModel {
		DataFolder: string = "";
		DataFolderFailover: string = "";
		Family: string = "";
		Region: string = "";
		Server: string = "";
		ServerFailover: string = "";
		Database: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessTemplateStepActionSqlPropertiesEditViewModel extends ProcessTemplateStepActionDatabaseActionPropertiesEditViewModel {
		SqlStatement: string = "";
		SqlWriteOperation: boolean = false;
		SqlStatementRowsAffected: string = "";
		SqlStatementNoRowsAffected: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessTemplateStepActionDbBackupPropertiesEditViewModel extends ProcessTemplateStepActionDatabaseActionPropertiesEditViewModel {
		BackupType: string = "";
		CompressionType: string = "";
		OverwriteExistingBackups: boolean = false;
		FileGroup: string = "";
		BackupName: string = "";
		BackupDescription: string = "";
		Password: string = "";
		CopyOnly: boolean = false;
		FileName: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessTemplateStepActionDbRestorePropertiesEditViewModel {
		RestoreType: string = "";
		Server: string = "";
		Database: string = "";
		FileGroup: string = "";
		FileName: string = "";
		Folder: string = "";
		SearchPattern: string = "";
		Sort: string = "";
		OnSuccessfulRestore: string = "";
		OnSuccessfulRestoreTarget: string = "";
		MaximumFileCount: number = 0;
		MaximumSessionSeconds: number = 0;
		NoRecovery: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessTemplateStepActionCopyFilesPropertiesEditViewModel {
		SourceFileServerId: number = null;
		SourceFolder: string = "";
		SourceFiles: string = "";
		IncludeSubfolders: boolean = false;
		SourceAssetIdMap: string = "";
		SourceAssetId: number = null;
		SourceAssetDescription: string = "";
		TargetFileServerId: number = null;
		TargetFolder: string = "";
		TargetFileName: string = "";
		Overwrite: boolean = false;
		ExtractArchive: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessTemplateStepActionDeleteFilesPropertiesEditViewModel {
		FileServerId: number = null;
		Folder: string = "";
		Files: string = "";
		IncludeSubfolders: boolean = false;
		Exclude: string[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessTemplateStepActionRunExecutablePropertiesModel {
		FileName: string = "";
		Arguments: string = "";
		Script: string = "";
		ScriptAssetIdMap: string = "";
		ScriptAssetId: number = null;
		ScriptAssetDescription: string = "";
		WorkingDirectory: string = "";
		WindowStyle: string = "";
		MaximumRunMinutes: number = 0;
		ShellExecute: boolean = false;
		WaitForExit: boolean = false;
		TreatErrorOutputAsFailure: boolean = false;
		ExecutableSuccessResultCodes: number[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessTemplateStepActionConvertFilePropertiesModel {
		SourceFileName: string = "";
		TargetFileName: string = "";
		Overwrite: boolean = false;
		PaperKind: string = "";
		Landscape: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessTemplateStepActionAttachFilePropertiesModel {
		FileName: string = "";
		FriendlyFileName: string = "";
		FileType: string = "";
		OwnerResourceType: string = "";
		OwnerResourceId: number = null;
		OwnerResourceId2: string = "";
		SecondaryOwnerResourceType: string = "";
		SecondaryOwnerResourceId: number = null;
		SecondaryOwnerResourceId2: string = "";
		MoveFile: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessTemplateStepActionSystemWatchEventPropertiesModel {
		WatchedResourceType: string = "";
		Wait: boolean = false;
		PayloadId: string = null;
		PayloadVersion: number = 0;
		Payload: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessTemplateStepEditViewModel {
		ProcessTemplateStepId: number = null;
		ProcessTemplateId: number = null;
		StepNumber: number = 0;
		Description: string = "";
		Priority: number = 0;
		State: string = "";
		ProcessStepDescriptionTemplate: string = "";
		InputPropertiesDocumentation: m.PropertyMetaDataViewModel[] = [];
		OutputPropertiesDocumentation: m.PropertyMetaDataViewModel[] = [];
		InputPropertiesTemplate: string = "";
		OutputPropertiesTemplate: string = "";
		DependsOnSteps: string = "";
		SkipWhen: string = "";
		RepeatStepForEach: string[] = [];
		OnStepSuccess: string = "";
		OnStepFailure: string = "";
		StepActionType: string = "";
		StepActionId: string = null;
		StepActionProperties: ProcessTemplateStepActionPropertiesEditViewModel<any> = null;
		TimeoutSeconds: number = 0;
		OrphanedSeconds: number = 0;
		ApprovalPending: boolean = false;
		ApprovedDateTime: Date = null;
		ApprovedByContactId: number = null;
		ApprovedByContactName: string = "";
		ApprovedFingerprint: string = "";
		ApprovalComments: string = "";
		NotificationGroupIdStepStart: number = 0;
		NotificationGroupIdStepSuccess: number = 0;
		NotificationGroupIdStepFailure: number = 0;
		NotificationGroupIdStepSkipped: number = 0;
		NotificationGroupIdStepRetry: number = 0;
		NotificationStepRetryMinutes: number = 0;
		NotificationGroupIdStepWait: number = 0;
		NotificationStepWaitMinutes: number = 0;
		NotificationGroupIdStepWarning: number = 0;
		NotificationGroupIdStepError: number = 0;
		NotificationStepErrorAfterAttemptCount: number = 0;
		WaitStateDequeueMinutes: number = 0;
		WaitStateLimitMinutes: number = 0;
		WaitStateAction: string = "";
		RetryIntervalMinutes: number[] = [];
		RetryLimitMinutes: number = 0;
		RetryLimitCount: number = 0;
		RetryAction: string = "";
		LogType: string = "";
		TargetServers: string[] = [];
		CountUsed: number = 0;
		CountSuccess: number = 0;
		CountFailure: number = 0;
		CountSkipped: number = 0;
		LastUsedDateTime: Date = null;
		LastSuccessDateTime: Date = null;
		LastFailureDateTime: Date = null;
		LastSkippedDateTime: Date = null;
		Properties: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessTemplateEditViewModel {
		ProcessTemplateId: number = null;
		ExternalProcessTemplateId: string = null;
		Description: string = "";
		Tags: string[] = [];
		Enabled: boolean = false;
		Type: string = "";
		Category: string = "";
		Priority: number = 0;
		ProcessDescriptionTemplate: string = "";
		InputPropertiesDocumentation: m.PropertyMetaDataViewModel[] = [];
		OutputPropertiesDocumentation: m.PropertyMetaDataViewModel[] = [];
		InputPropertiesTemplate: string = "";
		OutputPropertiesTemplate: string = "";
		ValidProcessTriggers: string[] = [];
		ViewRequiresRoleId: number = null;
		CreateRequiresRoleId: number = null;
		ViewRequiresGroupId: number = null;
		CreateRequiresGroupId: number = null;
		ApprovalRequiredFromContactIdList: number[] = [];
		ApprovalRequiredFromContactList: ContactReferenceViewModel[] = [];
		NotificationGroupIdProcessStart: number = 0;
		NotificationGroupIdProcessSuccess: number = 0;
		NotificationGroupIdProcessFailure: number = 0;
		NotificationGroupIdStepStart: number = 0;
		NotificationGroupIdStepSuccess: number = 0;
		NotificationGroupIdStepFailure: number = 0;
		NotificationGroupIdStepSkipped: number = 0;
		NotificationGroupIdStepRetry: number = 0;
		NotificationStepRetryMinutes: number = 0;
		NotificationGroupIdStepWait: number = 0;
		NotificationStepWaitMinutes: number = 0;
		NotificationGroupIdStepWarning: number = 0;
		NotificationGroupIdStepError: number = 0;
		NotificationStepErrorAfterAttemptCount: number = 0;
		ChronologicalPeerProcessing: boolean = false;
		LogType: string = "";
		TargetServers: string[] = [];
		CountUsed: number = 0;
		CountSuccess: number = 0;
		CountFailure: number = 0;
		LastUsedDateTime: Date = null;
		LastSuccessDateTime: Date = null;
		LastFailureDateTime: Date = null;
		Properties: string = "";
		Comments: string = "";
		Attributes: any = null;
		Steps: ProcessTemplateStepEditViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessEditViewModel {
		ProcessId: string = null;
		ProcessTemplateId: number = null;
		ProcessTag: string = "";
		ProcessDateTime: Date = null;
		Description: string = "";
		Priority: number = 0;
		ChronologicalPeerProcessing: boolean = false;
		ProcessPeerId: string = null;
		InputProperties: string = "";
		OutputProperties: string = "";
		TriggeredByContactId: number = null;
		TriggeredByContactName: string = "";
		TriggeredByResourceType: string = "";
		TriggeredByResourceId: number = null;
		TriggeredByResourceId2: string = "";
		Approved: boolean = false;
		ApprovedDateTime: Date = null;
		ApprovedByContactId: number = null;
		ApprovedByContactName: string = "";
		ApprovalComments: string = "";
		ReferencedResourceType: string = "";
		ReferencedResourceId: number = null;
		ReferencedResourceId2: string = "";
		Status: string = "";
		ResultCode: number = 0;
		ResultMessage: string = "";
		RequestedStartEventType: string = "";
		RequestedStartEventVersion: number = 0;
		RequestedStartEventProperties: string = "";
		RequestedStartDateTime: Date = null;
		StartDateTime: Date = null;
		FinishDateTime: Date = null;
		TargetServers: string[] = [];
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TaxConfigurationEditViewModel {
		TaxConfigurationId: number = null;
		Description: string = "";
		Enabled: boolean = false;
		DefaultConfiguration: boolean = false;
		Priority: number = 0;
		CompanyHeadquartersLocationId: number = null;
		CompanyHeadquartersLocationName: string = "";
		GeocodeLookupLimitedToOneGeocodePerJurisdictionLevel: boolean = false;
		TaxableEventDetermination: string = "";
		TaxableEventSources: string[] = [];
		GeocodeSources: string[] = [];
		GeocodeSourcesWildcardSupportForSource: boolean = false;
		GeocodeSourcesWildcardSupportForTarget: boolean = false;
		GeocodeSourcesWildcardSupportForPair: boolean = false;
		GeocodeSourcesFallbackSearchRaisesWarning: boolean = false;
		GeocodeSourcesWildcardSearchRaisesWarning: boolean = false;
		GeocodeJurisdictionMinimumMatchCount: number = 0;
		RoamingTaxes: string = "";
		PaymentTransactionIndependentTaxation: string = "";
		IncludedTaxAmountDecimalCount: number = 0;
		LogType: string = "";
		AllowTaxConfigurationChangeFromTaxableEventDeterminationTaxable: boolean = false;
		AllowTaxConfigurationChangeFromTaxableEventDeterminationNotTaxable: boolean = false;
		AllowTaxConfigurationChangeFromGeocodeSourcesSearch: boolean = false;
		DefaultTaxCalculationMethod: string = "";
		DefaultTaxCalculationMethodRaisesWarning: boolean = false;
		DefaultTaxItemCode: string = "";
		DefaultTaxItemCodeRaisesWarning: boolean = false;
		DefaultTaxCustomerType: string = "";
		DefaultTaxCustomerTypeRaisesWarning: boolean = false;
		DefaultTaxServiceProviderType: string = "";
		DefaultTaxServiceProviderTypeRaisesWarning: boolean = false;
		DefaultTaxVendorType: string = "";
		DefaultTaxVendorTypeRaisesWarning: boolean = false;
		DefaultIsoCurrencyCode: string = "";
		DefaultIsoCurrencyCodeRaisesWarning: boolean = false;
		PaymentTransactionAttributePropertyNamePackageCode: string = "";
		PaymentTransactionAttributePropertyNameSourceIata: string = "";
		PaymentTransactionAttributePropertyNameTargetIata: string = "";
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TaxGeocodeLookupEditViewModel {
		TaxGeocodeLookupId: number = null;
		Type: string = "";
		Value: string = "";
		ValueEndRange: string = "";
		DefaultGeocode: boolean = false;
		Geocode: string = "";
		InOutCity: string = "";
		InOutLocal: string = "";
		TaxConfigurationId: number = null;
		DataStatus: string = "";
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TaxGeocodeEditViewModel {
		TaxGeocodeId: number = null;
		Geocode: string = "";
		Description: string = "";
		Country: string = "";
		State: string = "";
		County: string = "";
		CountyCode: string = "";
		City: string = "";
		Locality: string = "";
		Other: string = "";
		TaxConfigurationId: number = null;
		DataStatus: string = "";
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TaxJurisdictionEditViewModel {
		TaxJurisdictionId: number = null;
		Geocode: string = "";
		InOutCity: string = "";
		InOutLocal: string = "";
		OwnerResourceType: string = "";
		OwnerResourceId: number = null;
		OwnerResourceId2: string = "";
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TaxCalculationViewModel {
		ResultCode: number = 0;
		ResultCodeType: string = "";
		ResultCodeDescription: string = "";
		TaxScenarioId: string = null;
		TaxAmount: number = 0;
		Context: any = null;
		Result: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TaxMatrixEditViewModel {
		TaxMatrixId: number = null;
		Description: string = "";
		Geocode: string = "";
		InOutCity: string = "";
		InOutLocal: string = "";
		TaxItemCode: string = "";
		TaxCustomerType: string = "";
		TaxServiceProviderType: string = "";
		TaxVendorType: string = "";
		IsoCurrencyCode: string = "";
		TaxAuthorityContactId: number = null;
		TaxAuthorityContactName: string = "";
		TaxAuthorityExternalContactId: string = null;
		Taxable: boolean = false;
		TaxOnTax: boolean = false;
		TaxCategory: string = "";
		TaxType: string = "";
		TaxAuthorityType: string = "";
		TaxLocation: string = "";
		MaximumTaxMethod: string = "";
		DisplayRequirement: string = "";
		DisplayMethod: string = "";
		BasisType: string = "";
		TaxRateSelection: string = "";
		TaxStep1BeginningAmount: number = 0;
		TaxStep1EndingAmount: number = 0;
		TaxStep1FlatAmount: number = 0;
		TaxStep1Rate: number = 0;
		TaxStep2BeginningAmount: number = 0;
		TaxStep2EndingAmount: number = 0;
		TaxStep2FlatAmount: number = 0;
		TaxStep2Rate: number = 0;
		TaxStep3BeginningAmount: number = 0;
		TaxStep3EndingAmount: number = 0;
		TaxStep3FlatAmount: number = 0;
		TaxStep3Rate: number = 0;
		TaxStep4BeginningAmount: number = 0;
		TaxStep4EndingAmount: number = 0;
		TaxStep4FlatAmount: number = 0;
		TaxStep4Rate: number = 0;
		TaxFlatAmount: number = 0;
		TaxRate: number = 0;
		TaxMinimum: number = 0;
		TaxMaximum: number = 0;
		TaxPerUnitRateSelection: string = "";
		TaxPerUnitStep1BeginningQuantity: number = 0;
		TaxPerUnitStep1EndingQuantity: number = 0;
		TaxPerUnitStep1FlatAmount: number = 0;
		TaxPerUnitStep2BeginningQuantity: number = 0;
		TaxPerUnitStep2EndingQuantity: number = 0;
		TaxPerUnitStep2FlatAmount: number = 0;
		TaxPerUnitStep3BeginningQuantity: number = 0;
		TaxPerUnitStep3EndingQuantity: number = 0;
		TaxPerUnitStep3FlatAmount: number = 0;
		TaxPerUnitStep4BeginningQuantity: number = 0;
		TaxPerUnitStep4EndingQuantity: number = 0;
		TaxPerUnitStep4FlatAmount: number = 0;
		TaxPerUnitMinimum: number = 0;
		TaxPerUnitMaximum: number = 0;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		DataStatus: string = "";
		Properties: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TaxTransactionDetailEditViewModel {
		TaxTransactionDetailId: string = null;
		OwnerResourceType: string = "";
		OwnerResourceId: number = null;
		OwnerResourceDetailId: number = null;
		TransactionDateTime: Date = null;
		TaxConfigurationId: number = null;
		TaxMatrixId: number = null;
		Quantity: number = 0;
		IsoCurrencyCode: string = "";
		TaxableAmount: number = 0;
		TaxAmount: number = 0;
		TaxOnTax: boolean = false;
		TaxCalculationMethod: string = "";
		TaxInformation: {[key: string]:  string} = null;
		TaxItemId: number = null;
		ResultCode: number = 0;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class VoucherCheckResponseModel {
		ResponseCode: VoucherCheckResponseCode = null;
		ResponseMessage: string = "";
		VoucherCode: string = "";
		Status: VoucherStatus = null;
		StatusMessage: string = "";
		MarketingMessage: string = "";
		VoucherAmount: number = 0;
		VoucherPercent: number = 0;
		VoucherMinimum: number = 0;
		VoucherMaximum: number = 0;
		IsoCurrencyCode: string = "";
		Amount: number = 0;
		DiscountAmount: number = 0;
		NetAmount: number = 0;
	}

// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
    // IB.Data.Model.IMetaDataModel matched IB.Data.Model.* so no code is being generated for it.
    // IB.Data.Model.IDeletedDataObject matched IB.Data.Model.* so no code is being generated for it.
    // IB.Data.Model.IMetaDataLightModel matched IB.Data.Model.* so no code is being generated for it.
    // IB.Data.Model.PartitionBrandModel matched IB.Data.Model.* so no code is being generated for it.
    // IB.Data.Model.IProcessStatusDetailModel matched IB.Data.Model.* so no code is being generated for it.
// This is generated code... DO NOT modify this file.
export module System.Collections.Generic {
    // System.Collections.Generic.KeyValuePair<TKey, TValue> matched System.* so no code is being generated for it.
}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
    // IB.Data.Model.System.MenuItem matched IB.Data.Model.* so no code is being generated for it.
    // IB.Data.Model.System.MenuInputParameter matched IB.Data.Model.* so no code is being generated for it.
    // IB.Data.Model.System.Modules matched IB.Data.Model.* so no code is being generated for it.
    // IB.Data.Model.System.Module matched IB.Data.Model.* so no code is being generated for it.
    // IB.Data.Model.System.Permissions matched IB.Data.Model.* so no code is being generated for it.
    // IB.Data.Model.System.Permission matched IB.Data.Model.* so no code is being generated for it.
    // IB.Data.Model.System.SecurityScope matched IB.Data.Model.* so no code is being generated for it.
    // IB.Data.Model.System.SecurityScopePermission matched IB.Data.Model.* so no code is being generated for it.
// This is generated code... DO NOT modify this file.
    // IB.Core.ConstantInformation matched IB.Core.* so no code is being generated for it.
// This is generated code... DO NOT modify this file.

    // This is generated code... DO NOT modify this file.
	export class AssetLicenseUrlViewModel {
		Description: string = "";
		Url: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class AssetLicenseInformationViewModel {
		DatabaseId: string = null;
		PartitionId: number = null;
		AssetId: number = null;
		Title: string = "";
		AttributedToName: string = "";
		AttributedToUrl: string = "";
		LicenseUrls: AssetLicenseUrlViewModel[] = [];
		LicenseText: string = "";
		Modifications: string = "";
		AsOfDate: Date = null;
		Anonymous: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportTemplateVariable {
		Name: string = "";
		Value: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class IDataImportResultModel {
		OriginalFileName: string = "";
		MimeFileName: string = "";
		FinalFileName: string = "";
		FinalFileFullPath: string = "";
		Extension: string = "";
		ContentType: string = "";
		DryRun: boolean = false;
		ExcelMetaData: IDataImportExportMetaDataModel = null;
		DataEntryCountParsed: number = 0;
		DataEntryCountAdded: number = 0;
		DataEntryCountAlreadyAdded: number = 0;
		DataEntryCountUpdated: number = 0;
		DataEntryCountDeleted: number = 0;
		DataEntryCountAlreadyDeleted: number = 0;
		DataEntryCountNotChanged: number = 0;
		DataEntryCountWarning: number = 0;
		DataEntryCountError: number = 0;
		DataEntriesAdded: any[] = [];
		DataEntriesAlreadyAdded: any[] = [];
		DataEntriesUpdated: any[] = [];
		DataEntriesUpdatedReferences: any[] = [];
		DataEntriesDeleted: any[] = [];
		DataEntriesAlreadyDeleted: any[] = [];
		DataEntriesNotChanged: any[] = [];
		DataEntriesWarnings: any[] = [];
		DataEntriesErrors: any[] = [];
		Notices: IDataImportNoticeModel[] = [];
		Warnings: IDataImportNoticeModel[] = [];
		Errors: IDataImportNoticeModel[] = [];
		Trace: string[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class IDataImportExportMetaDataModel {
		TableName: string = "";
		DateBasedTableBeginningDate: Date = null;
		DateBasedTableEndingDate: Date = null;
		FilterId: number = null;
		Filter: string = "";
		FreeFormQueryText: string = "";
		ComputedFilterExpression: string = "";
		Sort: string = "";
		TimeZone: string = "";
		PartitionIdPropertyName: string = "";
		AddedDateTimePropertyName: string = "";
		AddedByContactIdPropertyName: string = "";
		LastEditedDateTimePropertyName: string = "";
		LastEditedByContactIdPropertyName: string = "";
		DeletedDateTimePropertyName: string = "";
		DeletedByContactIdPropertyName: string = "";
		PrimaryKey: string = "";
		PrimaryKeyDataType: string = "";
		PrimaryKeyLabel: string = "";
		DeleteFlagLabel: string = "";
		ColumnLabelMap: any[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class IDataImportNoticeModel {
		Id: any = null;
		OperationType: string = "";
		ResultCode: m.StandardResultCode = null;
		Message: string = "";
		CurrentValues: {[key: string]:  any} = null;
		RequestedValues: {[key: string]:  any} = null;
		Details: string = "";
	}

// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.

// This is generated code... DO NOT modify this file.
	export module ApiReportId {
		export var InventoryExpiringMaintenance: string = "expiring-maintenance";
		export var InventoryExpiringWarranty: string = "expiring-warranty";
		export var InventoryExpiringLease: string = "expiring-lease";
	}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
	export module ReportCompilerConstants {
		export var SanitizeCaseRemoveSubjectMatter: string = "SanitizeCaseRemoveSubjectMatter";
		export var SanitizeCaseRemoveReportSections: string = "SanitizeCaseRemoveReportSections";
		export var SanitizeCaseRemoveReportAttachments: string = "SanitizeCaseRemoveReportAttachments";
		export var SanitizeCaseLearnFavorites: string = "SanitizeCaseLearnFavorites";
		export var SupportCaseFeatureRespectCaseReviewResultPermissions: string = "RespectCaseReviewResultPermissions";
		export var SubjectMatterTypeLibrarySelectionRules: string = "LibrarySelectionRules";
		export var SubjectMatterTypeStructuralProperty: string = "StructuralProperty";
		export var LibraryTypeOther: string = "Other";
		export var LibraryTypeStructural: string = "Structural";
		export var ModuleLibraryPersonal: string = "rc-library-personal";
		export var ModuleLibraryIndustry: string = "rc-library-industry";
		export var ModuleLibraryOrganization: string = "rc-library-organization";
		export var ModuleLibraryInsights: string = "rc-library-insights";
		export var ModuleLibraryExport: string = "rc-library-export";
		export var ModuleManagementInsights: string = "rc-management-insights";
		export var ModuleReportParser: string = "rc-report-parser";
		export var ModuleReportFinalPdf: string = "rc-report-final-pdf";
		export var ModuleWorkflow: string = "rc-workflow";
		export var ScoreMatchExact: number = 100;
		export var ScoreMatchPartial: number = 50;
		export var ScoreMatchAlways: number = 25;
		export var ScoreMatchSearchFoundInTag: number = 10;
		export var ScoreMatchSearchFoundInLibrary: number = 5;
		export var ScoreFavLove: number = 100;
		export var ScoreFavLike: number = 50;
		export var ScoreFavDislike: number = 0;
		export var ScoreFavBlock: number = -100;
		export var ScoreFavNaturalDefault: number = 5;
		export var ScoreFavNaturalMax: number = 50;
	}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System.Collections.Generic {
}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System {
}
// This is generated code... DO NOT modify this file.

