<div>

  <h3>Request Methods</h3>

  <p>
    Except for the overview section of the documentation, the remaining API documentation is centered around the
    resources exposed in the API and how to access and modify those resources.
  </p>

  <p>
    Most resources support the following methods:
  </p>
  <ul>
    <li>List (HTTP GET)</li>
    <li>Get (HTTP GET)</li>
    <li>Add (HTTP POST)</li>
    <li>Edit (HTTP PUT)</li>
    <li>Delete (HTTP DELETE)</li>
  </ul>

  <p>
    Additionally, some endpoints support the following methods:
  </p>
  <ul>
    <li>Patch (HTTP PATCH) (see <a href="https://tools.ietf.org/html/rfc6902"
         target="_blank">RFC 6902 regarding JSON Patch</a>)</li>
    <li>Merge (HTTP custom verb MERGE)</li>
    <li>Copy (HTTP POST with empty body and source in query string)</li>
  </ul>

  <p>
    Some API resources support other methods for retrieving reports, downloading resources, executing functions, etc.
    as documented in the APIs for those resources.
  </p>

  <br />
  <h4>PUT Implementation Considerations</h4>
  <p>
    Per <a href="https://tools.ietf.org/html/rfc7231#section-4.3.4"
       target="_blank">RFC 2731 Section 4.3.4</a>:
    “The PUT method requests that the state of the target resource be created or replaced with the state
    defined by the representation enclosed in the request message payload.” This is the pattern followed by the API
    and, therefore, care must be taken that any PUT operation contains the entire object to be saved in the
    message payload.
  </p>
  <p>
    Attempts to update a few properties via a PUT operation that only contains the properties that are to be
    updated will not result in the other properties not defined in the payload being left as-is.
    The properties excluded from the PUT message payload are replaced
    with blank or null values since the target of the PUT is “replaced with the state defined by the representation
    enclosed in the request message payload”. This will result in data loss. To avoid data loss the
    current representation of the object should be retrieved with a GET operation and then updated with new values
    for any properties that changed and then that complete object can be updated via a PUT.
  </p>
  <p>
    Certain APIs may have endpoints for MERGE and/or <a href="https://tools.ietf.org/html/rfc6902"
       target="_blank">PATCH</a>
    functionality which can be used to modify a partial resource.
  </p>


  <br />
  <h4>MERGE Implementation Considerations</h4>
  <p>
    The API uses a custom http verb MERGE. For updating a resource based on a partial resource object.
    If custom http verbs are not supported by your http client use a POST with the X-HTTP-Method-Override
    http header set to MERGE in order to instruct the API that you are doing a merge.
  </p>
  <p>
    Note that it is not possible to merge deep object properties individually. This means, for example, that
    it is not possible to set InvoiceLineItem.Invoice.DueDate as the entire Invoice object would be replaced.
    If deep value changes are needed then either GET the current object, update the needed properties, and
    do a PUT of the whole object or use the PATCH method.
  </p>


</div>
