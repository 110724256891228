<ib-input-text [name]="label"
               [label]="label"
               [labelPrefix]="labelPrefix"
               [labelSuffix]="labelSuffix"
               [placeholder]="placeholder"
               [tooltip]="tooltip"
               [width]="width"
               [outerClass]="outerClass"
               [labelClass]="labelClass"
               [controlClass]="controlClass"
               [bold]="bold"
               [tight]="tight"
               [vertical]="vertical"
               [size]="size"
               [required]="required"
               [includeRequiredIcon]="includeRequiredIcon"
               [readonly]="true"
               [disabled]="disabled"
               [autofocus]="autofocus"
               [standalone]="standalone"
               [errorRequiredMessage]="errorRequiredMessage"
               [errorMinimumLengthMessage]="errorMinimumLengthMessage"
               [errorMaximumLengthMessage]="errorMaximumLengthMessage"
               [errorInvalidFormatMessage]="errorInvalidFormatMessage"
               [errorOtherMessage]="errorOtherMessage"
               suffixIcon="search"
               (suffixClick)="openContactPicker($event)"
               suffixIcon2="share"
               [suffixShow2]="allowRedirect"
               (suffixClick2)="openContact($event)"
               [ngModel]="contact.ContactName">
</ib-input-text>