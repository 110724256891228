import * as m from "./ngCoreModels";
import * as m5core from "./ngModelsCore5";
import * as m5web from "./ngModelsWeb5";
import * as m5sec from "./ngModelsSecurity5";
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
	export enum WidgetType {
		AssetFilteredList = 0,
		InventoryExpiringMaintenance = 1,
		InventoryExpiringWarranty = 2,
		InventoryExpiringLease = 3,
		CasesAssignedToMe = 4,
		CasesOwnedByMeButAssignedToOthers = 5,
		CasesAssignedToMyGroups = 6,
		PossibleDuplicates = 7,
		QueryResultsAsTiles = 8,
		QueryResultsAsTable = 9,
		QueryResultsAsChart = 10,
		QueryResultsAsTableAndChart = 11,
		DownloadButtons = 12,
		Bookmarks = 13
	}
// This is generated code... DO NOT modify this file.
export module System {
}
// This is generated code... DO NOT modify this file.

/// <reference path="ngCoreModels.ts" />

// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System.Collections.Generic {
}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.

    // This is generated code... DO NOT modify this file.
	export class DashboardWidgetActionEditViewModel {
		Id: string = null;
		Enabled: boolean = false;
		DefaultAction: boolean = false;
		ShowAsButton: boolean = false;
		ShowAsButtonIconOnly: boolean = false;
		ShowAsMenuItem: boolean = false;
		ShowAsContextMenuItem: boolean = false;
		Icon: string = "";
		Title: string = "";
		Description: string = "";
		Style: string = "";
		SelectedStyle: string = "";
		SetWidgetTitle: string = "";
		Action: string = "";
		CustomAction: boolean = false;
		Download: boolean = false;
		OpenInNewWindow: boolean = false;
		QueryId: number = null;
		QueryName: string = "";
		QueryResultsFormat: string = "";
		QueryResultsFileName: string = "";
		QueryResultsTitle: string = "";
		QueryResultsIncludeAsOfTime: boolean = false;
		AssetId: number = null;
		AssetName: string = "";
		Url: string = "";
		Configuration: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class DashboardListViewModel {
		Id: string = null;
		Title: string = "";
		Description: string = "";
		ValidContactTypes: string[] = [];
		ValidGroupIds: number[] = [];
		InScopeReason: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class DashboardEditViewModel {
		Id: string = null;
		OwnerContactId: number = null;
		ValidContactTypes: string[] = [];
		ValidGroupIds: number[] = [];
		Title: string = "";
		Description: string = "";
		Configuration: any = null;
		Pages: m5web.DashboardPageEditViewModel[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class DashboardPageEditViewModel {
		Id: string = null;
		Title: string = "";
		Description: string = "";
		Configuration: any = null;
		Widgets: m5web.DashboardPageWidgetEditViewModel[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class DashboardPageWidgetEditViewModel {
		Id: string = null;
		PageId: string = null;
		WidgetId: string = null;
		Icon: string = "";
		Title: string = "";
		Description: string = "";
		RefreshShowIcon: boolean = false;
		RefreshIntervalSeconds: number = 0;
		Properties: any = null;
		Width: number = 0;
		Height: number = 0;
		MinWidth: number = 0;
		MinHeight: number = 0;
		MaxWidth: number = 0;
		MaxHeight: number = 0;
		Row: number = 0;
		Column: number = 0;
		RowSm: number = 0;
		ColumnSm: number = 0;
		RowMd: number = 0;
		ColumnMd: number = 0;
		RowLg: number = 0;
		ColumnLg: number = 0;
		RowXl: number = 0;
		ColumnXl: number = 0;
		Actions: m5web.DashboardWidgetActionEditViewModel[] = [];
		SelectedAction: string = "";
		Contents: string = "";
		Configuration: any = null;
		Widget: m5web.DashboardWidgetEditViewModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class DashboardWidgetEditViewModel {
		Id: string = null;
		Category: string = "";
		Icon: string = "";
		Title: string = "";
		Description: string = "";
		RefreshShowIcon: boolean = false;
		RefreshIntervalSeconds: number = 0;
		Properties: any = null;
		PropertyList: m5web.DashboardWidgetPropertyEditViewModel[] = [];
		WidgetTemplate: string = "";
		WidgetTemplateUrl: string = "";
		WidgetTemplateAssetId: number = null;
		WidgetPropertiesEditorTemplateUrl: string = "";
		ValidForDirectory: boolean = false;
		ValidForCustomer: boolean = false;
		ValidForVendor: boolean = false;
		ValidForAgent: boolean = false;
		ValidForServiceProvider: boolean = false;
		ValidForPermissions: m5sec.PermissionEditViewModel[] = [];
		Width: number = 0;
		Height: number = 0;
		MinWidth: number = 0;
		MinHeight: number = 0;
		MaxWidth: number = 0;
		MaxHeight: number = 0;
		NewWidgetAutoShowSettings: boolean = false;
		Actions: m5web.DashboardWidgetActionEditViewModel[] = [];
		ModulesAny: string[] = [];
		ModulesAll: string[] = [];
		Configuration: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class DashboardWidgetPropertyEditViewModel {
		Id: string = null;
		PageLabel: string = "";
		Label: string = "";
		Property: string = "";
		Width: string = "";
		Type: string = "";
		DescriptionProperty: string = "";
		Options: m5core.PickListSelectionViewModel[] = [];
		Children: m5web.DashboardWidgetPropertyEditViewModel[] = [];
		Configuration: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class DashboardWidgetPropertyBookmarkEditViewModel {
		Title: string = "";
		Url: string = "";
		Icon: string = "";
		Background: string = "";
		OpenUrlInNewWindow: boolean = false;
		GroupHeading: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class FavoriteEditViewModel {
		FavoriteId: number = null;
		FavoriteResourceType: string = "";
		FavoriteResourceId: number = null;
		FavoriteResourceId2: string = "";
		ContactId: number = null;
		ContactName: string = "";
		FavoriteRank: number = 0;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class FormControlGroupEditViewModel {
		FormControlGroupId: number = null;
		FormId: number = null;
		ParentFormControlGroupId: number = null;
		Description: string = "";
		GroupOrder: number = 0;
		Enabled: boolean = false;
		Label: string = "";
		Icon: string = "";
		HelpTitle: string = "";
		HelpText: string = "";
		GroupScope: string = "";
		GroupType: string = "";
		VisibleInDesktopMode: boolean = false;
		VisibleInTabletMode: boolean = false;
		VisibleInMobileMode: boolean = false;
		PresentationStyle: string = "";
		ColumnWidth: string = "";
		LabelClasses: string = "";
		LabelStyles: string = "";
		GroupClasses: string = "";
		GroupStyles: string = "";
		InheritLayout: boolean = false;
		LabelLayout: string = "";
		LabelBold: boolean = false;
		ControlLayoutTight: boolean = false;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		DisplayWhenExpression: string = "";
		LazyLoad: boolean = false;
		SecurityScope: m.SecurityScope = null;
		Flags: string[] = [];
		Properties: any = null;
		Comments: string = "";
		Groups: m5web.FormControlGroupEditViewModel[] = [];
		Controls: m5web.FormControlEditViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class FormControlEditViewModel {
		FormControlId: number = null;
		FormControlGroupId: number = null;
		FormId: number = null;
		Description: string = "";
		Enabled: boolean = false;
		ObjectName: string = "";
		ObjectChildName: string = "";
		ObjectGrandchildName: string = "";
		ObjectFullName: string = "";
		PropertyName: string = "";
		PropertyDescriptionName: string = "";
		PropertyDataType: string = "";
		ControlType: string = "";
		ControlOption: string = "";
		ControlProperties: string = "";
		ControlScope: string = "";
		DisplayWhenExpression: string = "";
		OptionsPickList: m5core.PickListSelectionViewModel[] = [];
		OptionsPickListId: string = null;
		OptionsPickListFilter: string = "";
		OptionsValueIsInteger: boolean = false;
		OptionsIncludeNone: boolean = false;
		OptionsNoneLabel: string = "";
		Contents: string = "";
		AssetUrl: string = "";
		AssetType: string = "";
		AssetId: number = null;
		AssetTitle: string = "";
		EmbeddedFormId: number = null;
		InheritLayout: boolean = false;
		Label: string = "";
		LabelPrefix: string = "";
		LabelSuffix: string = "";
		Icon: string = "";
		Watermark: string = "";
		HelpTitle: string = "";
		HelpText: string = "";
		Alignment: string = "";
		Width: string = "";
		Height: number = 0;
		LabelLayout: string = "";
		LabelBold: boolean = false;
		CharacterCountTemplate: string = "";
		ContainerClasses: string = "";
		LabelClasses: string = "";
		ControlClasses: string = "";
		ContainerStyles: string = "";
		LabelStyles: string = "";
		ControlStyles: string = "";
		Mask: string = "";
		ReadOnly: boolean = false;
		Disabled: boolean = false;
		AutoFocus: boolean = false;
		Standalone: boolean = false;
		Sortable: boolean = false;
		Resizable: boolean = false;
		Wrap: boolean = false;
		AllowLineBreaks: boolean = false;
		AllowMarkUp: boolean = false;
		IncludeInGlobalFilter: boolean = false;
		FilterType: string = "";
		FilterMatchMode: string = "";
		FilterPickListId: string = null;
		PrefixIcon: string = "";
		PrefixText: string = "";
		PrefixHelpText: string = "";
		PrefixClickEvent: string = "";
		SuffixIcon: string = "";
		SuffixText: string = "";
		SuffixHelpText: string = "";
		SuffixClickEvent: string = "";
		SuffixIcon2: string = "";
		SuffixText2: string = "";
		SuffixHelpText2: string = "";
		SuffixClickEvent2: string = "";
		Required: boolean = false;
		IncludeRequiredIcon: boolean = false;
		ErrorMessageRequired: string = "";
		Minimum: number = 0;
		ErrorMessageMinimum: string = "";
		Maximum: number = 0;
		ErrorMessageMaximum: string = "";
		Format: string = "";
		ErrorMessageInvalidFormat: string = "";
		ErrorMessageOther: string = "";
		ControlOrder: number = 0;
		ChangeEvent: string = "";
		ClickEvent: string = "";
		CacheMinutes: number = 0;
		SecurityScope: m.SecurityScope = null;
		Flags: string[] = [];
		Properties: any = null;
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class FormEditViewModel {
		FormId: number = null;
		ExternalFormId: string = null;
		SystemFormId: string = null;
		Description: string = "";
		Tags: string[] = [];
		Enabled: boolean = false;
		RoleId: number = null;
		TableNameReference: string = "";
		ApiNameReference: string = "";
		Embedded: boolean = false;
		UseBlankBasePage: boolean = false;
		FormClasses: string = "";
		FormStyles: string = "";
		FormCss: string = "";
		ListModeUseInLineEditing: boolean = false;
		ListModeDefaultSearchText: string = "";
		ListModeDefaultFilter: string = "";
		AddModeRegistration: string = "";
		AddMode: string = "";
		AddModeWarnDataLoss: boolean = false;
		AddModeShowErrors: boolean = false;
		AddSaveButtonLabel: string = "";
		AddSaveButtonIcon: string = "";
		AddSaveButtonIconAnimation: string = "";
		AddSaveButtonColor: string = "";
		AddSaveButtonOutline: boolean = false;
		AddSaveButtonHelpText: string = "";
		AddSaveButtonDisableWhenPristine: boolean = false;
		AddSaveButtonDisableWhenInvalid: boolean = null;
		AddSaveButtonDisableWhenWorking: boolean = false;
		AddSaveButtonLabelWhenWorking: string = "";
		AddSaveButtonIconWhenWorking: string = "";
		AddSaveButtonIconAnimationWhenWorking: string = "";
		AddSaveAction: string = "";
		AddSaveInformation: string = "";
		AddCancelButtonLabel: string = "";
		AddCancelButtonIcon: string = "";
		AddCancelButtonColor: string = "";
		AddCancelButtonOutline: boolean = false;
		AddCancelButtonHelpText: string = "";
		AddCancelButtonDisableWhenWorking: boolean = false;
		AddCancelAction: string = "";
		AddCancelInformation: string = "";
		EditMode: string = "";
		EditModeWarnDataLoss: boolean = false;
		EditModeShowErrors: boolean = false;
		EditSaveButtonLabel: string = "";
		EditSaveButtonIcon: string = "";
		EditSaveButtonIconAnimation: string = "";
		EditSaveButtonColor: string = "";
		EditSaveButtonOutline: boolean = false;
		EditSaveButtonHelpText: string = "";
		EditSaveButtonDisableWhenPristine: boolean = false;
		EditSaveButtonDisableWhenInvalid: boolean = null;
		EditSaveButtonDisableWhenWorking: boolean = false;
		EditSaveButtonLabelWhenWorking: string = "";
		EditSaveButtonIconWhenWorking: string = "";
		EditSaveButtonIconAnimationWhenWorking: string = "";
		EditSaveAction: string = "";
		EditSaveInformation: string = "";
		EditCancelButtonLabel: string = "";
		EditCancelButtonIcon: string = "";
		EditCancelButtonColor: string = "";
		EditCancelButtonOutline: boolean = false;
		EditCancelButtonHelpText: string = "";
		EditCancelButtonDisableWhenWorking: boolean = false;
		EditCancelAction: string = "";
		EditCancelInformation: string = "";
		LabelLayout: string = "";
		LabelBold: boolean = false;
		ControlLayoutTight: boolean = false;
		FormErrorsHandledInternally: boolean = false;
		ErrorMessageRequiredSingle: string = "";
		ErrorMessageRequiredMultiple: string = "";
		ErrorMessageMinimumLengthSingle: string = "";
		ErrorMessageMinimumLengthMultiple: string = "";
		ErrorMessageMaximumLengthSingle: string = "";
		ErrorMessageMaximumLengthMultiple: string = "";
		ErrorMessageInvalidFormatSingle: string = "";
		ErrorMessageInvalidFormatMultiple: string = "";
		ErrorMessageOtherSingle: string = "";
		ErrorMessageOtherMultiple: string = "";
		ScriptList: string = "";
		ScriptAdd: string = "";
		ScriptEdit: string = "";
		EventListSearchButtonClick: string = "";
		EventListPageChange: string = "";
		EventListPageSizeChange: string = "";
		EventListLoad: string = "";
		EventListRowClick: string = "";
		EventListAdd: string = "";
		EventListEdit: string = "";
		EventListDelete: string = "";
		EventAddLoad: string = "";
		EventAddSave: string = "";
		EventEditLoad: string = "";
		EventEditSave: string = "";
		Flags: string[] = [];
		Properties: any = null;
		Comments: string = "";
		Groups: m5web.FormControlGroupEditViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TableConfigurationViewModel {
		TableConfigurationId: number = null;
		Description: string = "";
		Enabled: boolean = false;
		Title: string = "";
		RowsPerPage: number = 0;
		RowsPerPageOptions: number[] = [];
		RowsPerPageSelector: boolean = false;
		RowCountMessage: boolean = false;
		Paging: boolean = false;
		GlobalSearch: boolean = false;
		FilterAllowSaved: boolean = false;
		FilterAllowBuilder: boolean = false;
		FilterAllowAdvanced: boolean = false;
		DateRange: string = "";
		SortMode: string = "";
		Sort: string = "";
		Expand: string = "";
		ShowRefreshOption: boolean = false;
		HeaderClickUrl: string = "";
		HeaderClickUrlNewWindow: boolean = false;
		FilterColumns: boolean = false;
		ColumnResizeMode: string = "";
		Responsive: boolean = false;
		Theme: string = "";
		RowStyle: string = "";
		GetRowStyle: string = "";
		RowClickUrl: string = "";
		RowClickUrlNewWindow: boolean = false;
		ShowLoader: boolean = false;
		Columns: m5web.TableColumnConfigurationViewModel[] = [];
		RowActionIds: string[] = [];
		RowActionDisplayMode: string = "";
		RowActionsForIconDisplay: string[] = [];
		RememberLayout: boolean = false;
		RememberSort: boolean = false;
		RememberFilter: boolean = false;
		RememberPaging: boolean = false;
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TableColumnConfigurationViewModel {
		Id: string = null;
		TableColumnId: string = null;
		PropertyName: string = "";
		SupportingPropertyName: string = "";
		HeaderIcon: string = "";
		HeaderLabel: string = "";
		HeaderDescription: string = "";
		Visible: boolean = false;
		Sortable: boolean = false;
		Resizable: boolean = false;
		WordWrap: boolean = false;
		AllowLineBreaks: boolean = false;
		AllowHtml: boolean = false;
		DataType: string = "";
		Decimals: number = 0;
		MaxCharacterLength: number = 0;
		ToolTipWhenMaxCharacterLengthExceeded: boolean = false;
		Align: string = "";
		ToolTipType: string = "";
		ToolTipTitle: string = "";
		ToolTipText: string = "";
		ToolTipTitleFunction: string = "";
		ToolTipTextFunction: string = "";
		ToolTipAppendToBody: boolean = false;
		ToolTipPlacement: string = "";
		Width: string = "";
		FooterHtml: string = "";
		HeaderClickUrl: string = "";
		HeaderClickUrlNewWindow: boolean = false;
		RowClickUrl: string = "";
		RowClickUrlNewWindow: boolean = false;
		Render: string = "";
		SupportLateRender: boolean = false;
		Style: string = "";
		GetStyle: string = "";
		OptionsPickListId: string = null;
		OptionsPickList: {[key: string]:  string} = null;
		OptionsIncludeNone: boolean = false;
		OptionsNoneLabel: string = "";
		IncludeInGlobalFilter: boolean = false;
		FilterType: string = "";
		FilterMatchMode: string = "";
		FilterPickListId: string = null;
		FilterPickListValues: {[key: string]:  string} = null;
	}


    // This is generated code... DO NOT modify this file.
	export class SearchConfigurationViewModel {
		SearchConfigurationId: number = null;
		ExternalSearchConfigurationId: string = null;
		Description: string = "";
		LinkSearchToAppTable: string = "";
		LinkSearchToAppTableStyle: string = "";
		LinkSearchToAppTableDisablePrefetch: boolean = false;
		LinkSearchToAppGlobalSearch: boolean = false;
		LinkSearchToAppDisplayOrder: number = 0;
		SearchFormId: number = null;
		SearchFilterBuilderTriggerAssetId: number = null;
		SearchFilterBuilderTriggerAssetTitle: string = "";
		SearchFilterBuilderScript: m5core.ScriptViewModel = null;
		SearchQueryId: number = null;
		SearchQueryApiProperties: string = "";
		SearchQueryColumns: string = "";
		SearchQueryEntities: string = "";
		SearchQueryFilter: string = "";
		SearchQuerySort: string = "";
		SearchResultTitle: string = "";
		SearchResultIncludeNewSearchOption: boolean = false;
		SearchResultSessionSeconds: number = 0;
		SearchResultColumns: m.PropertyMetaDataViewModel[] = [];
		SearchRowClickUrl: string = "";
		SearchRowClickNewPage: boolean = false;
		ApprovalPending: boolean = false;
		ApprovedDateTime: Date = null;
		ApprovedByContactId: number = null;
		ApprovedByContactName: string = "";
		ApprovedFingerprint: string = "";
		ApprovalComments: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class WatchEditViewModel {
		WatchId: number = null;
		WatchedResourceType: string = "";
		WatchedResourceId: number = null;
		WatchedResourceId2: string = "";
		WatchedResourceScope: string = "";
		ContactId: number = null;
		ContactName: string = "";
		NotificationMethods: string = "";
		ContactInformation: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class WebApiRequestViewModel {
		Url: string = "";
		Method: string = "";
		MethodCustom: string = "";
		Headers: m.KeyValuePairModel<string, string>[] = [];
		Body: string = "";
		ContentType: string = "";
		Accept: string = "";
		UserAgent: string = "";
		UserName: string = "";
		Password: string = "";
		SourceIpAddress: string = "";
		Protocol: string = "";
		AlwaysTrustCertificate: boolean = false;
		TimeoutMilliseconds: number = 0;
		ExceptionHandling: string = "";
		ErrorResponseWhenBodyContains: string = "";
		ErrorResponseWhenBodyMatchesRegex: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class WebhookEventListViewModel {
		WebhookEventId: string = null;
		EventTag: string = "";
		EventDateTime: Date = null;
		ResourceType: string = "";
		ResourceId: number = null;
		ResourceId2: string = "";
		EventType: string = "";
		Description: string = "";
		WebhookId: number = null;
		NotificationEventDateTime: Date = null;
		NotificationEventId: number = null;
		CaseId: number = null;
		ProcessDateTime: Date = null;
		ProcessId: number = null;
		JobId: number = null;
		PluginResultCode: number = 0;
		AssetResultCode: number = 0;
		Status: string = "";
		QueueDateTime: Date = null;
		DeliveryAttemptCount: number = 0;
		LastDeliveryAttemptDateTime: Date = null;
		TimeZone: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class WebhookEventEditViewModel {
		WebhookEventId: string = null;
		EventTag: string = "";
		EventDateTime: Date = null;
		ResourceType: string = "";
		ResourceId: number = null;
		ResourceId2: string = "";
		EventType: string = "";
		Description: string = "";
		WebhookId: number = null;
		EventData: string = "";
		ResponseData: string = "";
		NotificationEventDateTime: Date = null;
		NotificationEventId: number = null;
		CaseId: number = null;
		ProcessDateTime: Date = null;
		ProcessId: number = null;
		JobId: number = null;
		PluginResultCode: number = 0;
		AssetResultCode: number = 0;
		Status: string = "";
		QueueDateTime: Date = null;
		DeliveryAttemptCount: number = 0;
		LastDeliveryAttemptDateTime: Date = null;
		DeliveryHistory: m5web.WebhookEventDeliveryEditViewModel[] = [];
		ProcessingServer: string = "";
		ProcessingDateTime: Date = null;
		Trace: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class WebhookEventDeliveryEditViewModel {
		Id: string = null;
		Time: Date = null;
		ResponseCode: number = 0;
		ResponseMessage: string = "";
		DurationMilliseconds: number = 0;
		Server: string = "";
		Url: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class HelpLinkEditViewModel {
		HelpLinkId: number = null;
		Description: string = "";
		Enabled: boolean = false;
		Context: string = "";
		Brands: string[] = [];
		Tags: string[] = [];
		StartingCoreApiVersion: string = "";
		EndingCoreApiVersion: string = "";
		StartingAppVersion: string = "";
		EndingAppVersion: string = "";
		EmbeddedContexts: string[] = [];
		IncludeOnScreenHelpTips: boolean = false;
		IncludeHelpIndex: boolean = false;
		IncludeSystemHelpLinks: boolean = false;
		IncludeContactSupport: boolean = false;
		Links: m5web.HelpLinkItemEditViewModel[] = [];
		Comments: string = "";
		Properties: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class HelpLinkItemEditViewModel {
		HelpLinkItemId: number = null;
		Enabled: boolean = false;
		Description: string = "";
		Icon: string = "";
		HelpLinkItemType: string = "";
		Id: string = null;
		Url: string = "";
		Message: m5web.HelpLinkItemMessageEditViewModel = null;
		Tour: m5web.HelpLinkItemTourEditViewModel = null;
		BadgeText: string = "";
		BadgeIcon: string = "";
		BadgeColor: string = "";
		BadgeUntil: Date = null;
		HelpMenuSpotlightUntil: Date = null;
		AutoOpen: boolean = false;
		AutoOpenWhenUrlContains: string = "";
		AutoOpenCanBeDisabled: boolean = false;
		Language: string = "";
		Properties: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class HelpLinkItemMessageEditViewModel {
		Title: string = "";
		TitleIcon: string = "";
		TitleColor: string = "";
		Text: string = "";
		TextType: string = "";
		WindowSize: string = "";
		Movable: boolean = false;
		Properties: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class HelpLinkItemTourEditViewModel {
		Title: string = "";
		Description: string = "";
		Steps: m5web.HelpLinkItemTourStepEditViewModel[] = [];
		Properties: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class HelpLinkItemTourStepEditViewModel {
		SequenceNumber: number = 0;
		Selector: string = "";
		Title: string = "";
		Text: string = "";
		Image: string = "";
		TextType: string = "";
		AllowTargetClickEvent: boolean = false;
		OnStepClickTarget: boolean = false;
		OnTargetClickMoveToNextStep: boolean = false;
		Actions: any = null;
		Properties: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class WebhookListViewModel {
		WebhookId: number = null;
		ExternalWebhookId: string = null;
		Description: string = "";
		Type: string = "";
		Enabled: boolean = false;
		Incoming: boolean = false;
		Url: string[] = [];
		EventTypes: string[] = [];
		RealTimeDelivery: boolean = false;
		RetryIntervalMinutes: number = 0;
		RetryLimitMinutes: number = 0;
		Trace: boolean = false;
		TimeZone: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class WebhookEditViewModel {
		WebhookId: number = null;
		ExternalWebhookId: string = null;
		Description: string = "";
		Type: string = "";
		Enabled: boolean = false;
		Incoming: boolean = false;
		Url: string[] = [];
		RoundRobin: boolean = false;
		SmartRouting: boolean = false;
		TimeoutSeconds: number = 0;
		EventTypes: string[] = [];
		EventFilters: string = "";
		ContentType: string = "";
		Secret: string = "";
		Login: string = "";
		Password: string = "";
		Bearer: string = "";
		SignatureEncryptionKeyServerId: number = null;
		SignatureEncryptionKeyTag: string = "";
		Configuration: {[key: string]:  any} = null;
		Headers: {[key: string]:  string} = null;
		Format: string = "";
		FormatDataSource: string = "";
		ResponseFormat: string = "";
		ResponseFormatDataSource: string = "";
		SignatureData: string = "";
		SignatureAlgorithm: string = "";
		NotificationGroupId: number = null;
		CaseTemplateId: number = null;
		ProcessTemplateId: number = null;
		ProcessPeerId: string = null;
		TriggerAssetId: number = null;
		TriggerJobType: string = "";
		TriggerJobId: number = null;
		TriggerPlugin: string = "";
		RealTimeDelivery: boolean = false;
		RetryIntervalMinutes: number = 0;
		RetryIntervalMinutesList: number[] = [];
		RetryLimitMinutes: number = 0;
		Trace: boolean = false;
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}

// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.

// This is generated code... DO NOT modify this file.
	export module ApiReportId {
		export var InventoryExpiringMaintenance: string = "expiring-maintenance";
		export var InventoryExpiringWarranty: string = "expiring-warranty";
		export var InventoryExpiringLease: string = "expiring-lease";
	}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
	export module ReportCompilerConstants {
		export var SanitizeCaseRemoveSubjectMatter: string = "SanitizeCaseRemoveSubjectMatter";
		export var SanitizeCaseRemoveReportSections: string = "SanitizeCaseRemoveReportSections";
		export var SanitizeCaseRemoveReportAttachments: string = "SanitizeCaseRemoveReportAttachments";
		export var SanitizeCaseLearnFavorites: string = "SanitizeCaseLearnFavorites";
		export var SupportCaseFeatureRespectCaseReviewResultPermissions: string = "RespectCaseReviewResultPermissions";
		export var SubjectMatterTypeLibrarySelectionRules: string = "LibrarySelectionRules";
		export var SubjectMatterTypeStructuralProperty: string = "StructuralProperty";
		export var LibraryTypeOther: string = "Other";
		export var LibraryTypeStructural: string = "Structural";
		export var ModuleLibraryPersonal: string = "rc-library-personal";
		export var ModuleLibraryIndustry: string = "rc-library-industry";
		export var ModuleLibraryOrganization: string = "rc-library-organization";
		export var ModuleLibraryInsights: string = "rc-library-insights";
		export var ModuleLibraryExport: string = "rc-library-export";
		export var ModuleManagementInsights: string = "rc-management-insights";
		export var ModuleReportParser: string = "rc-report-parser";
		export var ModuleReportFinalPdf: string = "rc-report-final-pdf";
		export var ModuleWorkflow: string = "rc-workflow";
		export var ScoreMatchExact: number = 100;
		export var ScoreMatchPartial: number = 50;
		export var ScoreMatchAlways: number = 25;
		export var ScoreMatchSearchFoundInTag: number = 10;
		export var ScoreMatchSearchFoundInLibrary: number = 5;
		export var ScoreFavLove: number = 100;
		export var ScoreFavLike: number = 50;
		export var ScoreFavDislike: number = 0;
		export var ScoreFavBlock: number = -100;
		export var ScoreFavNaturalDefault: number = 5;
		export var ScoreFavNaturalMax: number = 50;
	}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System.Collections.Generic {
}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System {
}
// This is generated code... DO NOT modify this file.

