import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER, PLATFORM_ID } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { BrowserTransferStateModule } from '@angular/platform-browser';
// Note that we reference lib via source not dist 'core-lib/lib/core-lib.module' since acting as feature module instead of actual lib
import { CoreLibModule } from 'projects/core-lib/src/lib/core-lib.module';
import { CommonLibModule } from 'projects/common-lib/src/lib/common-lib.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { HttpLoaderFactory, ApiMissingTranslationHandler } from 'projects/core-lib/src/lib/helpers/i18n-helpers';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { GlobalErrorHandler } from 'projects/core-lib/src/lib/services/global-error-handler';
import { AppInitService } from 'projects/core-lib/src/lib/services/app-init.service';
import { InstructionsModule } from './instructions/instructions.module';
import { EndpointModule } from './endpoint/endpoint.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app.routing.module';

/**
 * We need to execute the appInitService.Init(). We cannot do it directly from the provider.
 * We need to create a function which invokes the appInitService.Init() and returns a Promise.
 * We do that in initializeApp1 function.
 * See https://www.tektutorialshub.com/angular/angular-how-to-use-app-initializer/
 * @param appInitService
 */
export function initializeApp1(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.Init();
  }
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    //CacheModule.forRoot(),
    //NgForageModule, //.forRoot(),
    //BrowserCacheModule.forRoot([
    //{
    //provide: CACHE,
    //useClass: MemoryCacheService, // or LocalStorageCacheService
    //deps: [PLATFORM_ID]
    //}
    //]),
    BrowserTransferStateModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: ApiMissingTranslationHandler, deps: [ApiService] },
      useDefaultLang: false
    }),
    NgProgressModule.withConfig({
      //trickleSpeed: 50,
      //speed: 50,
      debounceTime: 100,
      color: "red",
      thick: true,
      meteor: true
    }), // See https://github.com/MurhafSousli/ngx-progressbar
    NgProgressRouterModule.withConfig({
      delay: 0,
    }), // See https://github.com/MurhafSousli/ngx-progressbar/wiki/Router-events
    CoreLibModule,
    CommonLibModule,
    AppRoutingModule,
    InstructionsModule,
    EndpointModule,
    SharedModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    AppInitService,
    { provide: APP_INITIALIZER, useFactory: initializeApp1, deps: [AppInitService], multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
