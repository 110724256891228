<div>

  <h3>{{appName}} API and Data Use Policy</h3>

  <h5><em>Last Updated: October 20, 2015</em></h5>
  <br />

  <p>
    Users of the {{appNameLong}} ("{{appName}}") application programming interface ("API") and {{appName}} Data (as defined below) are required to comply
    with {{appName}}'s <a [routerLink]="['/', 'overview', 'terms-of-use']">Terms of Use</a>,
    <a [routerLink]="['/', 'overview', 'api-license-agreement']">API License Agreement</a>
    and the terms and conditions of this Policy.
  </p>

  <br />
  <h4>
    I. General
  </h4>

  <ul>
    <li>
      Your use of the API and {{appName}} Data, including the collection, use, maintenance and disclosure of user data shall comply
      with all applicable laws, rules and regulations and the terms of this Policy, and any advertising, marketing, privacy, or
      other self-regulatory code(s) applicable to your industry.
    </li>
    <li>
      You must not take any action that constitutes unauthorized or unsolicited advertising, junk or bulk e-mail.
    </li>
  </ul>

  <br />
  <h4>
    II. Authorization
  </h4>

  <ul>
    <li>
      You must take commercially reasonable steps, compliant with applicable laws, rules and regulations, to protect all data you
      collect, obtain or access through the API or that users submit to the {{appName}} service through your application
      and/or website ("{{appName}} Data") and your authentication key from unauthorized use, disclosure or access.
    </li>
    <li>
      You must not give your authentication key to another party and you take responsibility for any and all use of
      the API using your authentication key.
    </li>
    <li>
      {{appName}} may revoke your authentication credentials at any time, for any reason or no reason, with or
      without notice, and without liability to you or any other person.
    </li>
    <li>
      You must notify us immediately in the event of any unauthorized access to {{appName}} Data, your authentication key,
      and/or your application and/or website.
    </li>
    <li>
      If your application and/or website authenticates {{appName}} service users:
      <ul>
        <li>
          You must not include functionality in your application and/or website that requests or collects
          {{appName}} username or passwords, and you may only permit users to log in via the login API.
        </li>
        <li>
          Your application and/or website must provide a link to the {{appName}} service settings page
          where the user can disconnect your application and/or website from the {{appName}} service.
        </li>
      </ul>
    </li>
  </ul>

  <br />
  <h4>
    III. Access/Use
  </h4>

  <ul>
    <li>
      You will only request the {{appName}} Data you need to operate your application and/or website and must
      make no further use of it and delete it when your application and/or website has no such further use for it,
      including, without limitation, when a user has withdrawn consent.
    </li>
    <li>
      {{appName}} expressly reserves the right to limit the number and/or frequency of API requests in its sole
      discretion. If {{appName}} reasonably believes that you have attempted to exceed or circumvent the rate
      limits (including, without limitation, through mass-registering of applications), your ability to use the
      API may be temporarily or permanently blocked, with or without notice, and without {{appName}}
      liability to you or any other person.
    </li>
    <li>
      If your application and/or website requires or permits the transfer of user information outside of the user's
      home country (including transfers to {{appName}}'s servers, which are in the U.S.), you must ensure that
      such transfer complies with applicable laws.
    </li>
    <li>
      If your application and/or website collects, obtains or accesses any information about {{appName}} service
      users or submits any user information to {{appName}}, whether acting as a user of your application
      and/or website or indirectly:
      <ul>
        <li>
          You must respect a user's {{appName}} user settings.
        </li>
        <li>
          You must obtain affirmative consent from the user who provided the data before using it for any
          purpose other than displaying it back to that user on your application and/or website.
        </li>
        <li>
          You must obtain affirmative consent from a user before you post any information on their behalf on
          your application and/or website, the {{appName}} service or through any third party service.
        </li>
        <li>
          You may not publicly display any personal information of a user (e.g., name or profile photos)
          in any advertisement or promotions without {{appName}}'s prior written consent.
        </li>
      </ul>
    </li>
    <li>
      If you receive any de-identified {{appName}} Data, you will not use the {{appName}} Data in combination
      with any other content, documentation, code, data and related materials made available through any
      other {{appName}} product, service or application programming interface, or any product, service or
      application of any third party, to deanonymize, or attempt to deanonymize by any manner or means,
      any {{appName}} Data or to otherwise identify any individual.
    </li>
    <li>
      You shall not use any automated means (for example scraping or robots) other than the Merchant API to access, query or
      otherwise collect {{appName}}-related information {{appName}} or any website owned or operated by {{appName}} or
      a {{appName}} partner site that displays {{appName}} information.
    </li>
  </ul>

  <br />
  <h4>
    IV. Retention
  </h4>

  <ul>
    <li>
      Your application and/or website must offer a simple means for users to disable your application and/or website,
      upon which disabling, you shall permanently delete all data of such user.
    </li>
    <li>
      You may cache data you receive through use of the API in order to improve your application's and/or website's
      user experience, but you should try to keep the data up to date and must delete all old data. This permission
      does not give you any rights to cached data.
    <li>
      If you stop using the API or we disable your application and/or website, you must delete all {{appName}} Data.
    </li>
    <li>
      You must delete all data concerning a user that you receive from the API if the user asks you to do so,
      and you must provide a simple and easily accessible mechanism for users to make such a request.
      We may require you to delete data you receive from the API if you violate our terms.
      In the event that we do, we will have no liability to you or any other person.
    </li>
    <li>
      If payment information is being collected, you must provide the ability for users to remove payment
      information (i.e. credit cards) and you must call the appropriate {{appName}} API to request that {{appName}} removes
      that same data.
    </li>
  </ul>

  <br />
  <h4>
    V. No Transfer
  </h4>

  <ul>
    <li>
      You will not directly or indirectly transfer any {{appName}} Data, including user data or {{appName}} user ids,
      to (or use such data in connection with) any ad network, ad exchange, data broker, or other advertising or monetization
      related toolset, even if a user consents to such transfer or use. By "indirectly," we mean you cannot, for example,
      transfer data to a third party who then transfers the data to an ad network. By "any {{appName}} Data," we mean all data
      obtained through use of the API, including aggregate, anonymous or derivative data.
    </li>
    <li>
      You will not sell, rent, or transfer any {{appName}} Data. If you are acquired by or merge with a third party, you may
      continue to use user data within your application and/or website, but you may not transfer data outside your
      application and/or website.
    </li>
  </ul>

  <br />
  <h4>
    VI. Privacy Policy
  </h4>

  <ul>
    <li>
      If you collect, use, store or disclose any {{appName}} Data:
      <ul>
        <li>
          You must provide a privacy policy to users of your application and/or website that clearly discloses how you collect, use, store and disclose user data, including that:
          <ul>
            <li>
              you receive and use {{appName}} service user data, including a description of the categories of user data received and how you use and disclose it;
            </li>
            <li>
              you share user data with {{appName}}, for use by {{appName}} for its own purposes; and
            </li>
            <li>
              the user may read the {{appName}} privacy policy
            </li>
          </ul>
        </li>
        <li>
          You must clearly and conspicuously present your privacy policy to each user at the point of application and/or download,
          installation, authorization or first-run, and you must also make the policy available from the application user interface
          or from the home page of your website.
        </li>
        <li>
          Your application and/or website must not collect personally identifiable information of any user unless it first informs
          the user about the types of information being collected and how that information may be used and then obtains the user's
          express permission for those uses.
        </li>
      </ul>
    </li>
  </ul>

  <br />
  <h4>
    VII. Attribution/Trademarks
  </h4>

  <ul>
    <li>
      Your application and/or website must not:
      <ul>
        <li>
          Use business names and/or logos in a manner that can mislead, confuse or deceive users (e.g., do not copy the look and feel of the {{appName}} service);
        </li>
        <li>
          Confuse or mislead users about the source or purpose of your application and/or website;
        </li>
        <li>
          Impersonate or facilitate impersonation of others in a manner that can mislead, confuse or deceive users.
        </li>
      </ul>
    </li>
    <li>
      You must not express or imply any affiliation or relationship with or endorsement by us.
    </li>
    <li>
      If your application and/or website displays any {{appName}} logos or trademarks, you must prominently include the following statement:
      "All of the {{appName}}® logos and trademarks displayed on this [application/service] are the property of {{appName}}."
    </li>
    <li>
      You must read and comply with the {{appName}} Trademark and Brand Feature Guidelines.
    </li>
  </ul>

  <br />
  <h4>
    VIII. Permissions/Feedback
  </h4>

  <ul>
    <li>
      By incorporating the API into your website or service, you agree that {{appName}} may collect and use information
      from you and your users as described in our Privacy Policy. You also agree that {{appName}} may use automated
      methods to analyze your website or service where the API and/or {{appName}} Materials have been incorporated.
      Any information you or your users submit, upload, post, create, or add to the {{appName}} services through your
      use of the API (including, without limitation, the use by users of your service and/or website) shall be deemed
      a User Submission (as defined in the Terms of Use).
    </li>
    <li>
      {{appName}} shall have the right to use, reproduce, transfer, sublicense and otherwise exploit perpetually
      any information that you or your users submit, upload, post, create or add to the {{appName}} services
      through your use of the API (including, without limitation, the use by users of your application and/or website).
    </li>
    <li>
      If you choose to submit comments, ideas or feedback, you agree that {{appName}} is free to use them without
      any restriction or compensation to you. By accepting your submission, {{appName}} doesn't waive any rights
      to use similar or related feedback previously known to {{appName}}, or developed by its employees,
      or obtained from sources other than you.
    </li>
  </ul>


</div>
