<ib-modal-common-title [options]="options"
                       [modal]="modal"
                       [cancelData]="null"
                       (cancel)="options.cancel">
</ib-modal-common-title>
<div class="modal-body">
  <div class="row">
    <div class="{{Constants.Layout.fullWidth}}">
      <ib-filter-builder [objectName]="objectName"
                         [initialize]="true"
                         [(data)]="data.FilterConditions">
      </ib-filter-builder>
    </div>
  </div>
  <div class="row mt-3 ms-1">
    <div class="{{Constants.Layout.split2column}}">
      <ib-input-radio *ngIf="data.FilterId"
                      name="Save Option"
                      label=""
                      format="pull-left"
                      [tight]="true"
                      size="small"
                      [inline]="true"
                      [(ngModel)]="data.SaveOption"
                      [options]="[{value:'no',label:'Do not save'}, {value:'save',label:'Save'}, {value:'save-as',label:'Save As'}, {value:'delete',label:'Delete'}]">
      </ib-input-radio>
      <ib-input-radio *ngIf="!data.FilterId"
                      name="Save Option"
                      label=""
                      format="pull-left"
                      [tight]="true"
                      size="small"
                      [inline]="true"
                      [(ngModel)]="data.SaveOption"
                      [options]="[{value:'no',label:'Do not save'}, {value:'save-as',label:'Save As'}]">
      </ib-input-radio>
    </div>
    <div class="{{Constants.Layout.split2column}}">
    </div>
  </div>
  <div class="row ms-1"
       *ngIf="data.SaveOption === 'save' || data.SaveOption === 'save-as'">
    <div class="{{Constants.Layout.split2column}}">
      <ib-input-text name="Filter Description"
                     label=""
                     placeholder="Saved Filter Name"
                     format="pull-left"
                     size="small"
                     [tight]="true"
                     [(ngModel)]="data.Description"></ib-input-text>
    </div>
    <div class="{{Constants.Layout.split2column}}">
      <ib-input-checkbox name="Shared"
                         label="Share This Filter"
                         format="pull-left"
                         size="small"
                         labelClass="mt-2"
                         [tight]="true"
                         [(ngModel)]="data.Shared">
      </ib-input-checkbox>
      <ib-input-multiselect name="Valid For"
                            label="Valid For"
                            [vertical]="true"
                            format="pull-left"
                            size="small"
                            [(ngModel)]="data.ValidFor"
                            [optionsPickList]="validForPickList">
      </ib-input-multiselect>
    </div>
  </div>
  <!--<ngb-tabset type="{{isMobile ? 'pills' : 'tabs'}}" [destroyOnHide]="false" (navChange)="onNavChange($event)">
    <ngb-tab title="Builder" id="builder">
      <ng-template ngbNavContent>
        <div class="row">
          <div class="{{Constants.Layout.fullWidth}}">
            <ib-filter-builder [objectName]="objectName"
                               [(data)]="data.FilterConditions">
            </ib-filter-builder>
          </div>
        </div>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="Filters" id="filters">
      <ng-template ngbNavContent>
        <div class="row">
          <div class="{{Constants.Layout.fullWidth}}">
            <ib-standard-table [options]="tableOptions"
                               [reloadCount]="tableReloadCount"
                               [headerData]="selectedFilters"
                               (rowSelect)="onRowSelected($event)">
            </ib-standard-table>
          </div>
        </div>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>-->
</div>
<ib-modal-common-buttons [options]="options"
                         [modal]="modal"
                         [okData]="data"
                         [cancelData]="null"
                         (ok)="options.ok"
                         (cancel)="options.cancel">
</ib-modal-common-buttons>
