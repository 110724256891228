import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as m from "projects/core-lib/src/lib/api/ApiModels";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { ApiDocsService } from 'projects/core-lib/src/lib/services/api-docs.service';

@Component({
  selector: 'app-data-model-property-table',
  templateUrl: './data-model-property-table.component.html',
  styleUrls: ['./data-model-property-table.component.scss']
})
export class DataModelPropertyTableComponent implements OnInit, OnChanges {

  @Input() properties: m.DataModelProperty[] | m.ApiDocDataModelColumn[] = [];
  @Input() showPartitionId: boolean = false;
  @Input() sort: "natural" | "asc" | "desc" = "natural";

  sortedProperties: Array<m.DataModelProperty | m.ApiDocDataModelColumn> = [];

  /**
  Make constants available in html view.
  */
  public Constants = Constants;

  constructor(protected docsService: ApiDocsService) { }

  ngOnInit() {
    this.setupSort();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setupSort();
  }

  setupSort() {
    if (!this.properties || this.properties.length === 0) {
      return;
    }

    if (!this.sortedProperties || this.sortedProperties.length === 0) {
      this.sortedProperties = [];
    }

    if (Helper.equals(this.sort, "asc", true)) {
      this.sortedProperties = Helper.arraySort(this.properties as any[], "Name", false);
    } else if (Helper.equals(this.sort, "desc", true)) {
      this.sortedProperties = Helper.arraySort(this.properties as any[], "Name", true);
    } else {
      this.sortedProperties = [...(this.properties as any[])];
    }
  }

  typeDescription(col: m.DataModelProperty | m.ApiDocDataModelColumn) {
    let description: string = col.DataType;
    if (col.IsInteger) {
      description = "Integer";
    } else if (col.IsFloat) {
      description = "Float";
    } else if (col.IsDateTime) {
      description = "DateTime";
    } else if (col.IsBoolean) {
      description = "Boolean";
    } else if (col.IsChar || col.IsText || col.IsRowVersion) {
      description = "String";
    } else if (col.IsObject) {
      if (col.SubDataType) {
        if (Helper.equals(col.SubDataType, "object", true)) {
          description = "dynamic object";
        } else {
          description = col.SubDataType + " Object";
        }
      } else {
        description = "Object";
      }
    } else if (col.IsCollection) {
      if (col.SubDataType) {
        if (Helper.equals(col.SubDataType, "object", true)) {
          description = "Collection of dynamic objects";
        } else if (Helper.equals(col.SubDataType, "string", true)) {
          description = "Collection of strings";
        } else if (Helper.contains(col.SubDataType, "int", true)) {
          description = "Collection of integers";
        } else if (Helper.contains(col.SubDataType, "pairs", true)) {
          description = "Collection of " + col.SubDataType;
        } else {
          description = "Collection of " + col.SubDataType + " Objects";
        }
      } else {
        description = "Collection";
      }
    }
    return description;
  }


  collapseProperties(col: m.DataModelProperty | m.ApiDocDataModelColumn) {
    // Toggle collapse
    col.PropertiesCollapsed = !col.PropertiesCollapsed;
    // See if we need to lazy load these properties
    if (col.PropertiesLazyLoaded && (!col.Properties || col.Properties.length === 0)) {
      this.docsService.getDataModel(`_${col.SubDataType}~${col.SubDataType}`).subscribe(model => {
        if (model) {
          col.Properties = model.Columns;
        }
      });
    }
  }


  trackByIndex(index, item) {
    return index; // or item.id
  }

}
