<div>

  <h3>Response Formats</h3>

  <br />

  <p>
    Responses are always in
    <a href="https://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf"
       target="_blank">JSON format</a>.
  </p>


  <br />
  <h4>Response Headers</h4>
  <p>
    Responses may include some of the following response headers:
  </p>

  <app-response-headers></app-response-headers>




  <br />
  <h4>Response Body Format</h4>
  <p>
    All API responses are in
    <a href="https://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf"
       target="_blank">JSON format</a>
    and share some common response format elements as shown in this example:
  </p>

  <app-response-body></app-response-body>


</div>
