import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, Directive } from '@angular/core';
import { ApiProperties, ApiEndpoint, ApiCall } from 'projects/core-lib/src/lib/api/ApiModels';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m from "projects/core-lib/src/lib/api/ApiModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { EventModel, ButtonItem, Action } from 'projects/common-lib/src/lib/ux-models';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { StaticPickList } from 'projects/core-lib/src/lib/models/model-helpers';
import { ApiDocsService } from 'projects/core-lib/src/lib/services/api-docs.service';

@Directive()
export abstract class EndpointTestFormBaseClass implements OnInit, OnChanges {

  @Input() apiProperties: ApiProperties = null;
  @Input() apiEndpoint: ApiEndpoint = null;
  @Input() apiCall: ApiCall = null;
  @Input() modelDocumentation: m.ApiDocDataModel = null; // no default so we can use this to know when our component has been initialized
  @Input() requestData: any = null;
  @Input() working: boolean = false;

  /**
  When prompting for path properties in test forms those properties are most often part of the request data and, therefore,
  mapped to the requestData property.  In some cases the path properties are not to be included as part of the request
  data object and, therefore, need to be mapped to a separate object used for path properties in the url but not part of the payload.
  Some child components that inherit from this base class have this object passed into it so it can be shared.
  */
  @Input() requestPathProperties: any = {};


  @Output() submit: EventEmitter<EventModel> = new EventEmitter();
  @Output() submit2: EventEmitter<EventModel> = new EventEmitter();


  public modelDocumentationParsed: boolean = false;
  public modelDocumentationIncludesOwnerKeys: boolean = false;
  public modelDocumentationOwnerKeys: m.ApiDocDataModelColumn[] = [];
  public requestDataModelReady: boolean = false;
  public httpMethod: string = "";

  public objectPrimaryKey: string | string[] = "";
  public testFormSuppressPromptForOwnerKey: boolean = false;
  public testFormUseAnonymousObjectForPathModelProperties: boolean = false;
  public testFormPathModelPropertiesExcludedFromPayload: boolean = false;
  public testFormAllowFreeFormQueryString: boolean = false;
  public testFormAllowFreeFormQueryStringTooltip: string = "";
  public pathModelProperties: string[] = [];
  public pathModelPropertiesMappedInUrl: string[] = [];
  public testFormProperties: m.ApiDocTestFormProperty[] = null;
  public testFormPropertyTypes: any = m.ApiDocTestFormPropertyType;
  public testFormPropertyWidths: any = m.ApiDocTestFormPropertyWidth;
  public testFormJsonOnly: boolean = false;
  public testFormNoJson: boolean = false;
  //public jsonEditor: any = null;
  //public jsonOptions: any = {};
  public jsonActions: ButtonItem = null;

  public testButtonIcon: string = "check";
  public testButtonText: string = "Execute";
  public testButtonContextColor: string = "success";
  public testButton2Icon: string = "";
  public testButton2Text: string = "";
  public testButton2Action: string = "";
  public testButton2ContextColor: string = "success";

  public jsonError: string = "";

  /**
  Make constants available in html view.
  */
  public Constants = Constants;

  constructor(protected docsService: ApiDocsService) { }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.init();
  }

  protected init(): void {

    // See if we have the objects that will tell us we're ready
    if (!this.apiEndpoint || !this.apiCall) {
      return;
    }

    this.httpMethod = m.ApiMethodType[this.apiCall.method]; // Method name
    this.objectPrimaryKey = this.apiProperties.documentation.objectPrimaryKey;

    // Default button stuff
    this.testButtonText = null;
    if (this.apiEndpoint.type === m.ApiOperationType.List) {
      this.testButtonIcon = "list";
      this.testButtonText = "Get List";
      this.testButtonContextColor = "success";
      this.testButton2ContextColor = "success";
    } else if (this.apiEndpoint.type === m.ApiOperationType.Report) {
      this.testButtonIcon = "print";
      this.testButtonText = "Get Report";
      this.testButtonContextColor = "success";
      this.testButton2ContextColor = "success";
    } else if (this.apiEndpoint.type === m.ApiOperationType.Get) {
      this.testButtonIcon = "arrow-down";
      this.testButtonText = "Get";
      this.testButtonContextColor = "success";
      this.testButton2ContextColor = "success";
    } else if (this.apiEndpoint.type === m.ApiOperationType.Add) {
      this.testButtonIcon = "plus";
      this.testButtonText = "Add";
      this.testButtonContextColor = "success";
      this.testButton2ContextColor = "success";
    } else if (this.apiEndpoint.type === m.ApiOperationType.Edit) {
      this.testButtonIcon = "edit";
      this.testButtonText = "Edit";
      this.testButtonContextColor = "success";
      this.testButton2ContextColor = "success";
    } else if (this.apiEndpoint.type === m.ApiOperationType.Patch) {
      this.testButtonIcon = "level-up";
      this.testButtonText = "Patch";
      this.testButtonContextColor = "success";
      this.testButton2ContextColor = "success";
    } else if (this.apiEndpoint.type === m.ApiOperationType.Merge) {
      this.testButtonIcon = "exchange";
      this.testButtonText = "Merge";
      this.testButtonContextColor = "success";
      this.testButton2ContextColor = "success";
    } else if (this.apiEndpoint.type === m.ApiOperationType.Delete) {
      this.testButtonIcon = "times";
      this.testButtonText = "Delete";
      this.testButtonContextColor = "danger";
      this.testButton2ContextColor = "danger";
    } else if (this.apiEndpoint.type === m.ApiOperationType.Copy) {
      this.testButtonIcon = "copy";
      this.testButtonText = "Copy";
      this.testButtonContextColor = "success";
      this.testButton2ContextColor = "success";
    } else if (this.apiEndpoint.type === m.ApiOperationType.Call) {
      this.testButtonIcon = "arrow-up";
      this.testButtonText = "Call";
      this.testButtonContextColor = "success";
      this.testButton2ContextColor = "success";
    }

    // debug console.error(this.apiEndpoint);

    if (this.apiEndpoint.documentation) {
      this.testFormSuppressPromptForOwnerKey = this.apiEndpoint.documentation.testFormSuppressPromptForOwnerKey;
      this.testFormUseAnonymousObjectForPathModelProperties = this.apiEndpoint.documentation.testFormUseAnonymousObjectForPathModelProperties;
      this.testFormPathModelPropertiesExcludedFromPayload = this.apiEndpoint.documentation.testFormPathModelPropertiesExcludedFromPayload;
      this.testFormAllowFreeFormQueryString = this.apiEndpoint.documentation.testFormAllowFreeFormQueryString;
      this.testFormAllowFreeFormQueryStringTooltip = this.apiEndpoint.documentation.testFormAllowFreeFormQueryStringTooltip;
      if (this.apiEndpoint.documentation.testFormProperties && this.apiEndpoint.documentation.testFormProperties.length > 0) {
        this.testFormProperties = this.apiEndpoint.documentation.testFormProperties;
      } else {
        this.testFormProperties = null;
      }
      this.testFormJsonOnly = this.apiEndpoint.documentation.testFormJsonOnly;
      this.testFormNoJson = this.apiEndpoint.documentation.testFormNoJson;
      if (this.apiEndpoint.documentation.objectPrimaryKey) {
        this.objectPrimaryKey = this.apiEndpoint.documentation.objectPrimaryKey;
      }
      if (this.apiEndpoint.documentation.testButtonIcon) {
        this.testButtonIcon = this.apiEndpoint.documentation.testButtonIcon;
      }
      if (this.apiEndpoint.documentation.testButtonText) {
        this.testButtonText = this.apiEndpoint.documentation.testButtonText;
      }
      if (this.apiEndpoint.documentation.testButtonContextColor) {
        this.testButtonContextColor = this.apiEndpoint.documentation.testButtonContextColor;
      }
      if (this.apiEndpoint.documentation.testButton2Icon) {
        this.testButton2Icon = this.apiEndpoint.documentation.testButton2Icon;
      }
      if (this.apiEndpoint.documentation.testButton2Text) {
        this.testButton2Text = this.apiEndpoint.documentation.testButton2Text;
      }
      if (this.apiEndpoint.documentation.testButton2ContextColor) {
        this.testButton2ContextColor = this.apiEndpoint.documentation.testButton2ContextColor;
      }
      if (this.apiEndpoint.documentation.testButton2Action) {
        this.testButton2Action = this.apiEndpoint.documentation.testButton2Action;
      }
    }

    this.pathModelProperties = ApiHelper.getApiPathProperties(this.apiProperties, this.apiEndpoint);
    this.pathModelPropertiesMappedInUrl = ApiHelper.getApiPathProperties(this.apiProperties, this.apiEndpoint, true);

    // Let's be reasonable here... how are we going to build a test form if we don't have one of these?
    // 1. testFormProperties
    // 2. pathModelProperties
    // 3. modelDocumentation
    // 4. testFormJsonOnly
    if ((!this.testFormProperties || this.testFormProperties.length === 0) &&
      (!this.pathModelProperties || this.pathModelProperties.length === 0) &&
      (!this.modelDocumentation) &&
      (!this.testFormJsonOnly)) {
      if (this.apiEndpoint.type === m.ApiOperationType.Call) {
        this.testFormProperties = ApiHelper.getApiDocTestFormPropertiesFromModel(this.requestData);
      }
    }

    if (!this.modelDocumentationParsed && this.modelDocumentation) {
      // For some test forms we want to automatically include any properties that are in our url if not already covered by an owner key in our data model docs.
      this.modelDocumentationOwnerKeys = this.modelDocumentation.Columns.filter(x => x.IsOwnerKey);
      this.modelDocumentationIncludesOwnerKeys = (this.modelDocumentationOwnerKeys.length > 0);
      this.modelDocumentationParsed = true;
    }

    this.jsonEditorActionSetup();

  }


  protected jsonEditorActionSetup() {
    if (!this.modelDocumentation) {
      //console.error("no model docs yet");
      return;
    }
    this.jsonActions = new ButtonItem("", "cog", "secondary");
    this.jsonActions.options = [];
    this.modelDocumentation.Columns.forEach((col: m.ApiDocDataModelColumn, index: number, array: m.ApiDocDataModelColumn[]) => {
      if (col.IsObject && !this.requestData[col.Name]) {
        this.jsonActions.options.push(new Action(`init`, `Initialize ${col.Name}`, "cog", "", () => {
          this.jsonEditorActionInitObject(col);
        }));
        //this.jsonOptions.actions.push({
        //  text: `Initialize ${col.Name}`, title: `Initialize ${col.Name} to new ${ApiHelper.getApiModelDocSubDataTypeDescription(col)}`,
        //  action: col.Name, click: this.jsonEditorActionInitObject
        //});
      } else if (col.IsCollection) {
        this.jsonActions.options.push(new Action(`add`, `Add ${ApiHelper.getApiModelDocSubDataTypeDescription(col)} to ${col.Name}`, "cog", "", () => {
          this.jsonEditorActionAddToCollection(col);
        }));
        //this.jsonOptions.actions.push({
        //  text: `Add ${ApiHelper.getApiModelDocSubDataTypeDescription(col)} to ${col.Name}`, title: `Add new ${ApiHelper.getApiModelDocSubDataTypeDescriptionWithRawName(col)} to ${col.Name}`, action: col.Name, click: this.jsonEditorActionAddToCollection
        //});
      }
    });
    // If we don't have any actions then set the button to null so it doesn't get rendered
    if (this.jsonActions.options.length === 0) {
      this.jsonActions = null;
    }
  }

  // fat arrow function so we can get called out of scope (i.e. from action button event) and still know "this"
  jsonEditorActionInitObject = (col: m.ApiDocDataModelColumn) => {
    if (col && col.IsObject) {
      if (col.SubDataType === "object" && !col.RawName) {
        this.requestData[col.Name] = {};
      } else {
        try {
          // TODO use api version to pick namespace m5, m6, etc.
          this.requestData[col.Name] = this.docsService.getModelObject(col.RawName, 5);
        } catch (err) {
          Log.errorMessage(err);
        }
      }
    }
  }

  // fat arrow function so we can get called out of scope (i.e. from action button event) and still know "this"
  jsonEditorActionAddToCollection = (col: m.ApiDocDataModelColumn) => {
    if (col && col.IsCollection) {
      if (!this.requestData[col.Name]) {
        this.requestData[col.Name] = [];
      }
      if (Helper.equals(col.SubDataType, "string", true)) {
        this.requestData[col.Name].push("");
      } else if (Helper.contains(col.SubDataType, "int", true)) {
        this.requestData[col.Name].push(0);
      } else if (col.SubDataType === "object" && !col.RawName) {
        this.requestData[col.Name].push({});
      } else {
        try {
          // TODO different namespace per version... m5 is for api version 5
          this.requestData[col.Name].push(this.docsService.getModelObject(col.RawName, 5));
        } catch (err) {
          Log.errorMessage(err);
        }
      }
    }
  }

  inputWidth = (width: m.ApiDocTestFormPropertyWidth): string => {
    //console.error(this.Constants.Layout[""]);
    if (width === m.ApiDocTestFormPropertyWidth.Normal) {
      //console.error(this.Constants.Layout["split2input"]);
      return "";
    }
    //console.error(this.Constants.Layout["split2input" + m.ApiDocTestFormPropertyWidth[width]]);
    return m.ApiDocTestFormPropertyWidth[width].toLowerCase(); // enum as string
  }

  protected inputType = (type: m.ApiDocTestFormPropertyType): string => {
    return m.ApiDocTestFormPropertyType[type].toLowerCase(); // enum as string
  }

  getPickList(col: m.ApiDocTestFormProperty): m5core.PickListSelectionViewModel[] {
    return StaticPickList.stringArrayToPickList(col.inputOptions);
  }

  trackByIndex(index, item) {
    return index; // or item.id
  }

  getPropertyAsJsonString(propertyName: string, isPathPropertyOnly: boolean) {
    if (isPathPropertyOnly && !this.requestPathProperties[propertyName]) {
      this.requestPathProperties[propertyName] = {};
    } else if (!isPathPropertyOnly && !this.requestData[propertyName]) {
      this.requestData[propertyName] = {};
    }
    if (isPathPropertyOnly) {
      return JSON.stringify(this.requestPathProperties[propertyName], null, 4);
    } else if (!isPathPropertyOnly) {
      return JSON.stringify(this.requestData[propertyName], null, 4);
    }
  }

  onPropertyJsonStringChange($event, propertyName: string, isPathPropertyOnly: boolean) {
    if ($event.data) {
      let value: any = {};
      let success: boolean = false;
      try {
        value = JSON.parse($event.data);
        success = true;
      } catch (err) {
        // poorly formed JSON is expected while typing happens
        success = false;
      }
      if (success) {
        if (isPathPropertyOnly) {
          this.requestPathProperties[propertyName] = value;
        } else if (!isPathPropertyOnly) {
          this.requestData[propertyName] = value;
        }
      }
    } else {
      if (isPathPropertyOnly) {
        this.requestPathProperties[propertyName] = {};
      } else if (!isPathPropertyOnly) {
        this.requestData[propertyName] = {};
      }
    }
  }

  getRequestDataAsJsonString() {
    if (!this.requestData) {
      this.requestData = {};
    }
    return JSON.stringify(this.requestData, null, 4);
  }

  onRequestDataJsonStringChange($event) {
    if ($event.data) {
      let value: any = {};
      let success: boolean = false;
      try {
        value = JSON.parse($event.data);
        success = true;
      } catch (err) {
        // poorly formed JSON is expected while typing happens
        success = false;
        this.jsonError = err;
        console.error('json err', err);
      }
      if (success) {
        this.requestData = value;
        this.jsonError = "";
      }
    } else {
      this.requestData = {};
    }
  }

  onSubmit($event: EventModel) {
    $event.data = this.requestData;
    $event.cargo = { buttonId: 1, action: "submit", pathProperties: this.requestPathProperties };
    this.submit.emit($event);
  }

  onSubmit2($event) {
    $event.data = this.requestData;
    $event.cargo = { buttonId: 2, action: this.testButton2Action, pathProperties: this.requestPathProperties };
    this.submit2.emit($event);
  }


}
