<div>

  <h3>List Request Format</h3>

  <br />

  <p>
    Most list API requests include several query string parameters that can be used to request certain subsets of data.
  </p>
  <ul>
    <li><strong>page</strong> - The page number for a list of data results. See section below on pagination for more information.</li>
    <li><strong>size</strong> - The number of items to include in a page of data results. See section below on pagination for more information.</li>
    <li><strong>sort</strong> - The sort to apply to the data returned.</li>
    <li><strong>filterId</strong> - If using a saved filter this is the filter id of the filter.</li>
    <li><strong>filter</strong> - A free form filter to apply to the data results. See section below on filters for more information.</li>
    <li><strong>q</strong> - free form text to use for content searching to filter the data results.</li>
    <li><strong>expand</strong> - A comma delimited list of instructions on any data expansion that is requested. See section below on expanded results for more information.</li>
  </ul>



  <br />
  <h3>Pagination</h3>
  <p>
    Lists of data support pagination via page and size query string parameters. The page query string parameter is the page
    number that is desired. The size query string parameter is the number of rows to include on the page. The maximum size
    allowed is 1000 rows. For example, to get page 3 of a customer list where the page size is 50 format the url like this:
  </p>
  <ib-code-block>
    {{apiService.selectedHost.url}}/customers?page=3&size=50&sort=CustomerName%2CLastName%2CFirstName
  </ib-code-block>


  <br />
  <h3>Filters</h3>
  <p>
    Filters are expressions that resolve to either true or false for any give resource object and can used to select which
    resources will be included in a list.
  </p>

  <br />
  <h4>Logical Operators</h4>
  <table class="table table-striped table-bordered table-sm table-hover">
    <thead>
      <tr>
        <th>Description</th>
        <th>Operator</th>
        <th>Comments</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Equal To</td>
        <td>==</td>
        <td></td>
      </tr>
      <tr>
        <td>Not Equal To</td>
        <td>!=</td>
        <td></td>
      </tr>
      <tr>
        <td>Less Than</td>
        <td>&lt;</td>
        <td></td>
      </tr>
      <tr>
        <td>Less Than or Equal To</td>
        <td>&lt;=</td>
        <td></td>
      </tr>
      <tr>
        <td>Greater Than</td>
        <td>&gt;</td>
        <td></td>
      </tr>
      <tr>
        <td>Greater Than or Equal To</td>
        <td>&gt;=</td>
        <td></td>
      </tr>
      <tr>
        <td>String Starts With</td>
        <td>.StartsWith()</td>
        <td>For example, customer names that start with John would be: CustomerName.StartsWith("John")</td>
      </tr>
      <tr>
        <td>String Doesn't Start With</td>
        <td>.NotStartsWith()</td>
        <td>For example, customer names that don't start with John would be: CustomerName.NotStartsWith("John")</td>
      </tr>
      <tr>
        <td>String Ends With</td>
        <td>.EndsWith()</td>
        <td>For example, customer names that end with Smith would be: CustomerName.EndsWith("Smith")</td>
      </tr>
      <tr>
        <td>String Doesn't End With</td>
        <td>.NotEndsWith()</td>
        <td>For example, customer names that don't end with Smith would be: CustomerName.NotEndsWith("Smith")</td>
      </tr>
      <tr>
        <td>String Contains</td>
        <td>.Contains()</td>
        <td>For example, customer names that contain Fred would be: CustomerName.Contains("Fred")</td>
      </tr>
      <tr>
        <td>String Doesn't Contain</td>
        <td>.NotContains()</td>
        <td>For example, customer names that don't contain Fred would be: CustomerName.NotContains("Fred")</td>
      </tr>
    </tbody>
  </table>


  <br />
  <h4>String Filters</h4>
  <p>
    Strings are enclosed in quotation marks when used as part of a filter.
    For example, to get a list of customers with a first name of John use a url escaped filter of FirstName == "John" like this:
  </p>
  <ib-code-block>
    {{apiService.selectedHost.url}}/customers?page=1&size=1000&sort=CustomerName%2CLastName%2CFirstName&filter=FirstName%20%3D%3D%20%22John%22
  </ib-code-block>

  <br />
  <h4>Date Filters</h4>
  <p>
    When filtering date values format the date and time as DateTime(year,month,day,hour,minute,second).
    For example, to get a list of payment transactions for August 1, 2015 use a url escaped filter of
    RequestDateTime >= DateTime(2015,8,1,0,0,0) && RequestDateTime <= DateTime(2015,8,1,23,59,59)
       like
       this:
       </p>
      <ib-code-block>
        {{apiService.selectedHost.url}}/payment-transactions?page=1&size=25&sort=RequestDateTime&filter=RequestDateTime%20%3E%3D%20DateTime(2015%2C8%2C1%2C0%2C0%2C0)%20%26%26%20RequestDateTime%20%3C%3D%20DateTime(2015%2C8%2C1%2C23%2C59%2C59)
      </ib-code-block>

      <br />
      <h4>Combining Logic</h4>
      <p>
        Multiple filter clauses can be combined using && for AND, || for OR, and (parenthesis) for grouping of logic.
        For example, to filter all payment transactions for August 1, 2015 for Contact Id 475 that are either VISA or
        MasterCard use a url escaped filter of
        ContactId == 476 && ( PaymentMethodCardTypeId == "VISA" || PaymentMethodCardTypeId == "MasterCard" ) && RequestDateTime >= DateTime(2015,8,1,0,0,0) && RequestDateTime <=
           DateTime(2015,8,1,23,59,59)
           like
           this:
           </p>
          <ib-code-block>
            {{apiService.selectedHost.url}}/payment-transactions?page=1&size=25&sort=RequestDateTime&filter=ContactId%20%3D%3D%20476%20%26%26%20(%20PaymentMethodCardTypeId%20%3D%3D%20%22VISA%22%20%7C%7C%20PaymentMethodCardTypeId%20%3D%3D%20%22MasterCard%22%20)%20%26%26%20RequestDateTime%20%3E%3D%20DateTime(2015%2C8%2C1%2C0%2C0%2C0)%20%26%26%20RequestDateTime%20%3C%3D%20DateTime(2015%2C8%2C1%2C23%2C59%2C59)
          </ib-code-block>




          <br />
          <h3>Expanded Results</h3>
          <p>
            The list <strong>expand</strong> query string parameter is an optional comma delimited list of instructions
            on any data expansion that is requested. Typical values may include:
          </p>
          <ul>
            <li>
              <strong>full</strong> - when present, data objects with all available properties will be returned.
              Lists frequently return abbreviated object formats that includes items most often desired when
              presenting a list of objects but specifying <strong>full</strong> in the expand parameter will
              trigger the full object to be returned.
            </li>
            <li>
              <strong>related</strong> - when present, data objects returned will include the object graph information
              that is typically included when a single object is requested. This can be a much larger object graph
              and, therefore, is typically not included in lists. Note that when this option is present the
              <strong>full</strong> option is automatically assumed since the abbreviated object formats typically
              do not include properties for related objects included in an object graph.
            </li>
            <li>
              <strong>x</strong> - where <strong>x</strong> is the name of a related object or property in the object graph.
              This allows a more fine tuned response than returned when <strong>related</strong> is specified.
              For example, when getting a list of billing transactions when <strong>related</strong> is present
              the object graph will include billing transaction item details, tax details, commission details,
              and payment details. If only item details is needed specifying <strong>BillingTransactionDetail</strong>
              (the object name for item details) or <strong>Details</strong> (the property name where billing transaction details
              are stored in the billing transaction object) will cause only item details to be included in the
              response with tax, commission, and payment detail properties left empty.
              Note that when this option is present the <strong>full</strong> option is automatically assumed
              since the abbreviated object formats typically do not include properties for related objects
              included in an object graph.
            </li>
          </ul>


</div>
