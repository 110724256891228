<ng-container *ngIf="!inline">
  <ng-container *ngFor="let option of pickList; let i = index; trackBy: trackByIndex">
    <div class="{{inputFormGroupClass}} {{outerClass}}">
      <div class="{{inputLabelClass}}" *ngIf="i === 0">
        <span class="{{inputLabelClass}}" ngbTooltip="{{tooltip | translate}}">
          <span *ngIf="required && includeRequiredIcon">
            <ib-icon icon="exclamation-circle (solid)" ngbTooltip="Required"></ib-icon>&nbsp;
          </span>
          {{labelPrefix}}{{( label | translate ) || label}}{{labelSuffix}}
        </span>
      </div>
      <div class="{{inputLabelClass}}" *ngIf="i !== 0 && !isMobilePortrait">
        <span class="{{inputLabelClass}}" ngbTooltip="{{tooltip | translate}}">&nbsp;</span>
      </div>
      <div class="{{inputWrapperClass}}" style="padding-left:2.25rem; padding-top:0.5rem;">
        <input class="form-check-input {{inputSize}} {{controlClass}}"
               type="checkbox"
               id="{{inputControlId}}_{{option.Value}}_{{i}}"
               ngbTooltip="{{tooltip | translate}}"
               [disabled]="disabled || readonly ? 'disabled' : null"
               [readonly]="readonly ? 'readonly' : null"
               (change)="onCheckboxClick($event, option.Value)"
               [checked]="data && data.indexOf(option.Value) > -1" />
        <label class="form-check-label" for="{{inputControlId}}_{{option.Value}}_{{i}}">
          <ng-container *ngIf="option.DisplayText && option.Value && option.DisplayText !== option.Value">{{option.DisplayText}} ({{option.Value}})</ng-container>
          <ng-container *ngIf="option.DisplayText && option.Value && option.DisplayText === option.Value">{{option.DisplayText}}</ng-container>
          <ng-container *ngIf="option.DisplayText && !option.Value">{{option.DisplayText}}</ng-container>
          <ng-container *ngIf="!option.DisplayText && option.Value">{{option.Value}}</ng-container>
        </label>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="inline">
  <ib-input-wrapper label="{{label}}">
    <div class="form-check form-check-inline pe-2" *ngFor="let option of pickList; let i = index; trackBy: trackByIndex">
      <input class="form-check-input" type="checkbox"
             id="{{inputControlId}}_{{option.Value}}_{{i}}" name="{{option.Value}} {{i}}"
             ngbTooltip="{{tooltip | translate}}"
             [disabled]="disabled || readonly ? 'disabled' : null"
             [readonly]="readonly ? 'readonly' : null"
             (change)="onCheckboxClick($event, option.Value)"
             [checked]="data && data.indexOf(option.Value) > -1" />
      <label class="form-check-label" for="{{inputControlId}}_{{option.Value}}_{{i}}">
        <ng-container *ngIf="option.DisplayText && option.Value && option.DisplayText !== option.Value">{{option.DisplayText}} ({{option.Value}})</ng-container>
        <ng-container *ngIf="option.DisplayText && option.Value && option.DisplayText === option.Value">{{option.DisplayText}}</ng-container>
        <ng-container *ngIf="option.DisplayText && !option.Value">{{option.DisplayText}}</ng-container>
        <ng-container *ngIf="!option.DisplayText && option.Value">{{option.Value}}</ng-container>
      </label>
    </div>
  </ib-input-wrapper>
</ng-container>
