<div>

  <h3>{{appName}} API and Data License Agreement</h3>

  <h5><em>Last Updated: October 20, 2015</em></h5>
  <br />

  <p>
    This API and Data License Agreement ("Agreement") is by and between {{appNameLong}} (“{{appName}}”) you (“Licensee”) and applies to Licensee access to,
    use of, the content, code, documentation, data, and any and all related materials made available by {{appName}} to Licensee. By using the {{appName}}
    API and Data, Licensee (i.e. you) agree to the terms and conditions of this Agreement.
  </p>

  <br />
  <h4>
    I. LICENSE
  </h4>

  <p>
    1.1 Grant. Subject to the <a [routerLink]="['/', 'overview', 'terms-of-use']">Terms of Use</a>,
    <a [routerLink]="['/', 'overview', 'api-use-policy']">API Use Policy</a>,
    and the terms and conditions
    provided herein, {{appName}} hereby grants to Licensee a limited, nonexclusive,
    nontransferable license to use the documentation included as part of or related to the {{appName}} API (the “API”) solely in accordance
    with the terms of this Agreement. All references in this Agreement to the "sale", "selling" or "purchase" of API shall mean the
    granting or purchase of a license to use such API. Unless otherwise required by {{appName}} and except as otherwise provided
    in this Agreement, the terms and conditions of this Agreement shall apply to any and all updates and/or upgrades to the API.
  </p>

  <p>
    1.2 Scope of License. Licensee shall have the right, pursuant to the terms of this Agreement, to obtain a copy of the
    API from {{appName}}. Licensee agrees not make copies of the API, provided, however, that archival copies may be made
    for purposes of protection against loss. Copies of {{appName}}’s copyright notice and other proprietary notices must be
    included on and in all copies. Licensee shall not attempt to distribute, modify, lease, sub-license, or prepare derivative
    works of the API. Licensee agrees that the API will be used solely for the purpose of integrating with a properly licensed
    installation of {{appName}} or with the {{appName}} service if provided as a service.
    Licensee shall have no other rights whatsoever in the API.
  </p>

  <p>
    1.3 Support. This Agreement does not entitle Licensee to any technical support for the {{appName}} API, unless Licensee makes
    separate arrangements with {{appName}} and pays all fees associated with such support. Any such support provided by {{appName}}
    shall be subject to the terms of this Agreement as well as the associated support agreement.
  </p>



  <br />
  <h4>
    II. PURCHASE TERMS
  </h4>

  <p>
    2.1 Prices. In consideration of the license granted hereunder, Licensee shall pay to {{appName}} the API license fee as
    stated in {{appName}} pricing for access to the API (the “Fee”). Prices for all updates, upgrades, maintenance agreements,
    professional services and other products and services not included in the license for the API shall be equal to
    {{appName}}'s then current listed price for such product and/or service.
  </p>

  <p>
    2.2 Payment. Licensee shall pay to {{appName}} the Fee before the API is delivered. Payment shall be paid to {{appName}},
    without deduction, discount, abatement, counter-claim, offset, or demand, in lawful money of the United States of America
    via {{appName}}’s then current standard accepted payment terms and methods.
  </p>

  <p>
    2.3 Taxes. Licensee’s payment obligation under this Agreement includes any federal, state, county, local or governmental
    sales, use, excise, ad valorem, property, withholding, value added or other taxes or duties now or hereafter imposed on the sale,
    transportation, export, licensing or use of the API (except taxes based on {{appName}}'s net income). Licensee shall pay all
    such amounts payable with respect to the sale and purchase of the API under this Agreement.
  </p>

  <p>
    2.4 Shipping. API delivered under this Agreement is provided electronically by access to the web site hosting the API and
    related documentation.
  </p>

  <p>
    2.5 Terms and Conditions. {{appName}} may accept or reject any order in whole or part, in its discretion. No terms on purchase orders,
    invoices or like documents by Licensee shall serve to alter or add to the terms of this Agreement.
  </p>


  <br />
  <h4>
    III. WARRANTY.
  </h4>

  <p>
    3.1 Disclaimer/Limitation of Liability. EXCEPT AS OTHERWISE PROVIDED HEREIN, THE API PROVIDED HEREUNDER AND ANY INSTALLATION SERVICES,
    TRAINING, MAINTENANCE, TECHNICAL SUPPORT, SERVICE BUREAU OPERATIONS, DATA PROCESSING SERVICES, PROFESSIONAL SERVICES, AND ANY OTHER GOODS,
    PRODUCTS, AND/OR SERVICES PROVIDED IN CONNECTION WITH THE API ARE PROVIDED TO LICENSEE AND DONE ON AN "AS IS" BASIS. ALL OTHER WARRANTIES
    OF ANY KIND WITH RESPECT TO THE API AND ANY OTHER GOODS, PRODUCTS AND/OR SERVICES, IF ANY, PROVIDED BY {{appName | uppercase}} HEREUNDER,
    WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTY OF MERCHANTABILITY AND FITNESS FOR
    A PARTICULAR PURPOSE, ARE EXPRESSLY DISCLAIMED. IN NO EVENT SHALL {{appName | uppercase}} OR ANY {{appName | uppercase}} RELATED PERSONS OR ENTITIES INCLUDING,
    WITHOUT LIMITATION, ANY DEALER OR REFERRER OF {{appName | uppercase}}, HAVE ANY LIABILITY FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL OR
    CONSEQUENTIAL DAMAGES, INCLUDING, WITHOUT LIMITATION, LOST REVENUE, DATA OR PROFITS, HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY AND
    WHETHER OR NOT SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. THE CUMULATIVE LIABILITY OF {{appName | uppercase}} FOR ANY CLAIM ARISING
    OUT OF, RELATED TO, OR IN CONNECTION WITH THIS AGREEMENT, WHETHER SUCH CLAIM IS IN CONTRACT, TORT, OR OTHERWISE, SHALL NOT EXCEED,
    IN THE AGGREGATE, FIVE HUNDRED U.S. DOLLARS ($500.00 USD), EVEN IF {{appName | uppercase}} HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGES
    AND WHETHER OR NOT SUCH LOSS OR DAMAGES ARE FORESEEABLE. ANY CLAIM ARISING OUT OF OR RELATING TO THIS AGREEMENT MUST BE BROUGHT WITHIN
    ONE (1) YEAR AFTER THE OCCURRENCE OF THE EVENT GIVING RISE TO SUCH CLAIM. IN ADDITION, {{appName | uppercase}} DISCLAIMS ALL LIABILITY
    OF ANY KIND OF {{appName | uppercase}}'S VENDORS.
  </p>

  <p>
    3.3 Licensee Responsibility.
  </p>

  <p>
    a. In addition to any other of Licensee’s obligations pursuant to this Agreement, Licensee is solely responsible for selecting and using the
    API and related goods, products, and services to meet Licensee’s needs including any and all services rendered by {{appName}} and {{appName}}
    subcontractors. Licensee shall, and is solely responsible to, institute regular audit procedures to verify proper results of the API, related
    goods, products and services, and any and all services rendered by {{appName}} and {{appName}} subcontractors. Licensee shall be solely
    responsible to provide suitable hardware, network and database configurations to meet API specifications and shall be solely responsible to
    verify and confirm that the API and accompanying components, if any, are working properly in connection with the same. Before installing or
    using any upgrade or enhancement to the API related software, Licensee is also responsible for obtaining updated API documentation for that
    upgrade and verifying that Licensee’s use of the API will be modified as required to perform properly with the upgrade.
  </p>

  <p>
    b. Depending on the type of API License, the API may include a function that performs credit card transactions. While {{appName}} and/or {{appName}}'s
    licensors make efforts to ensure proper data storage following Payment Card Industry (PCI) guidelines, most PCI guidelines detail computer and network
    security requirements that are the sole responsibility of the Licensee. LICENSEE SHALL BE SOLELY RESPONSIBLE TO VERIFY THAT PAYMENT CARD INDUSTRY
    REQUIREMENTS ARE BEING FOLLOWED.
  </p>

  <p>
    c. Licensee is to engage in good business computing practices including, but not limited to, maintenance of firewalls, operating system patches,
    database server patches, anti-virus software, anti-spyware software, testing of new releases before putting them into production, etc.
    LICENSEE SHALL BE SOLELY RESPONSIBLE TO IMPLEMENT SUCH PRACTICES AND TO ENSURE THE DESIRED RESULTS OF SUCH PRACTICES.
  </p>


  <br />
  <h4>
    IV. INTELLECTUAL PROPERTY INDEMNIFICATION
  </h4>
  <p>
    4.1 {{appName}} agrees to indemnify, defend and hold harmless Licensee from and against damages resulting from any claim that the API infringes
    any United States patent or copyright that arises out of Licensee’s use of the API in accordance with this Agreement; provided, however, that Licensee
    (i) promptly notifies {{appName}}, in writing, of any notice or claim of such alleged infringement involving the API of which it becomes aware and
    (ii) provides {{appName}} all authority to appoint counsel and to control the defense, settlement, adjustment or compromise of any such claim.
    In no event, shall {{appName}} be liable to any extent whatsoever for any consequential and/or incidental damages or losses, including, but not limited to,
    any loss of use, sales and/or profits, suffered or incurred by Licensee, its customers or any third party, directly or indirectly, as a result of any API
    licensed hereunder being held to infringe any patent or copyright or as a result of Licensee being enjoined from further sales or any such API or
    Licensee’s customers being enjoined from further use of any such API. THIS SECTION STATES {{appName}}'S ENTIRE LIABILITY WITH RESPECT TO ANY CLAIM
    FOR PATENT OR COPYRIGHT INFRINGEMENT.
  </p>

  <p>
    4.2 Notwithstanding the provisions of 4.1, {{appName}} shall have no liability for any infringement claim of any kind arising from (i) the use of
    the API other than as licensed herein; (ii) the modification of the API, unless each modification was made, licensed or authorized by {{appName}},
    when such infringement would not have occurred but for such modification; (iii) the combination or use of the API with other hardware, software, APIs,
    items or processes not approved by {{appName}} in advance if such infringement is caused by such combination and such infringement would have been
    avoided by the use of the API standing alone, or (iv) any trademark infringements involving any marking or branding not applied by or requested
    by {{appName}}, or involving any marking or branding applied by {{appName}} at the request of Licensee.
  </p>


  <br />
  <h4>
    V. TERM AND TERMINATION
  </h4>

  <p>
    5.1 Term. Unless otherwise canceled or terminated as provided herein, the term of this Agreement shall continue until terminated as set forth in this section.
  </p>

  <p>
    5.2 Termination for Cause. If any payment from Licensee to {{appName}} remains unpaid for more than fifteen (15) days after written notice to Licensee of
    non-payment, or if Licensee violates or defaults in the performance of any of its non-monetary obligations in this Agreement, and the non-monetary violation or
    default continues for a period of fifteen (15) days after written notice, then {{appName}} may, but is not required to, terminate this Agreement, or may exercise
    all other available legal or equitable remedies. In the event of termination of Agreement by {{appName}} the liability of Licensee for the payment provided
    herein will not be relinquished or extinguished. At no time will {{appName}} be responsible to repay Licensee for any funds paid by Licensee to {{appName}}.
    Licensee will pay, in addition to the payments agreed to be paid herein, reasonable attorneys’ fees, costs and expenses in any suit or action instituted by or
    involving {{appName}} to enforce the provisions of, or the collection of the payments due {{appName}} under this Agreement, including any provisions under
    the Federal Bankruptcy Code.
  </p>

  <p>
    5.3 Effect of Termination.
  </p>

  <p>
    a. Upon termination of this Agreement for any reason, {{appName}} shall not be liable to Licensee for, and Licensee hereby expressly waives all rights to
    compensation, indemnities or damages of any kind, whether on account of the loss by Licensee of present or prospective profits, anticipated business, expenditures,
    investments or commitments made in connection with this Agreement, goodwill created, or on account of any other reason.
  </p>

  <p>
    b. Upon termination of this Agreement for any reason, Licensee shall immediately pay {{appName}} all amounts owed to {{appName}}. Late payment fees shall apply
    from the effective date of termination.
  </p>

  <p>
    c. Upon termination of this Agreement for any reason, Licensee shall immediately return to {{appName}} or destroy the API and all copies of the API. Licensee
    shall deliver to {{appName}} a written statement signed by Licensee certifying that all materials have been returned and/or destroyed within five (5) days of receipt of the
    request.
  </p>

  <br />
  <h4>
    VI. PROPRIETARY RIGHTS
  </h4>

  <p>
    6.1 Proprietary Rights. Licensee acknowledges and agrees that {{appName}} and/or its licensors own the API and all rights, titles, and interests therein,
    including, but not limited to, any and all patents, trademarks, trade names, inventions, copyrights, know how and trade secrets ("Proprietary Rights") relating
    to the design, manufacture, operation, documentation or service of the API and all derivatives, modifications or translations thereof. The use by Licensee of any
    of these Proprietary Rights is authorized only for the purposes herein set forth, and upon termination of this Agreement for any reason such authorization shall cease.
    It is expressly understood that no title to or ownership of the API, or any part thereof, is hereby transferred to Licensee.
  </p>

  <p>
    6.2 Proprietary Notices and API Protection. Licensee shall not remove, alter, cover or obfuscate any Proprietary Rights notices placed or embedded by
    {{appName}} on or in the API. Licensee shall not under any circumstances attempt, or knowingly permit any third party to attempt, to modify or otherwise alter the API.
    The API and/or Software using the API may from time-to-time connect to {{appName}} servers in order to be activated, provide license usage information and validation,
    check for live updates, and/or provide quality feedback information.
  </p>

  <p>
    6.3 Injunctive Relief. Licensee acknowledges that any breach or violation of any of its obligations with respect to any of the proprietary rights set forth in this Section
    6 is likely to cause or threaten irreparable harm to {{appName}}, and, accordingly, Licensee agrees that in the event of such breach, {{appName}} shall be entitled to
    seek equitable relief to protect its interest therein, including but not limited to preliminary and permanent injunctive relief, as well as money damages.
  </p>

  <p>
    6.4 Publicity. Notwithstanding anything stated herein to the contrary, {{appName}} and its employees, agents and representatives shall have the right, for advertising
    or publicity purposes, to use the name of Licensee, to represent, directly or indirectly, that the API or other goods, products, and/or services provided by {{appName}}
    have been approved or endorsed by Licensee and to use or publish details regarding Licensee’s use of the API and/or related software and services including, without
    limitation, quotes or statements made by Licensee in relation to Licensee’s use of the API and/or related software and services, in marketing materials created by {{appName}}
    including, without limitation, brochures, press releases, case studies, white papers, and advertisements.
  </p>


  <br />
  <h4>
    VII. CONFIDENTIALITY
  </h4>

  <p>
    7.1 Scope. The parties agree that any proprietary and confidential information (“Confidential Information”) disclosed by or under the authorization of {{appName}}
    to Licensee or by or under the authorization of Licensee to {{appName}} shall be subject to the terms of this Agreement.
  </p>

  <p>
    7.2 Disclosing Party; Recipient. For purposes of this Section VII:
  </p>

  <p>
    A. "Disclosing Party" shall mean a party disclosing Confidential Information.
  </p>

  <p>
    B. "Recipient" shall mean a party or other person described herein receiving Confidential Information.
  </p>

  <p>
    7.3 Confidential Information. Confidential Information means any and all business, technical, or other information or material disclosed to or acquired by
    Recipient directly or indirectly from Disclosing Party, whether or not owned or developed by Disclosing Party, and whether disclosed in writing, orally, or
    in any other form or medium. Confidential Information includes, but is not limited to:
  </p>

  <p>
    A. information concerning concepts, ideas, techniques, processes, contracts, marketing plans, business records and plans, financial statements, customer lists,
    records and data, trade secrets, technical information, products, inventions, product design information, pricing lists and structures, discounts, costs,
    computer programs and listings, source codes and/or object codes, business procedures and business operations and all materials related thereto;
  </p>

  <p>
    B. know-how, copyrights, trademarks, patents and other intellectual property;
  </p>

  <p>
    C. unpublished copyrightable material;
  </p>

  <p>
    D. confidential information belonging to parties with business relations with Disclosing Party;
  </p>

  <p>
    E. any use, variation, application, discussion and any other communication derivative of any and all products and services;
  </p>

  <p>
    F. any other proprietary information designated as Confidential Information by Disclosing Party at the time of its disclosure to Recipient.
  </p>

  <p>
    7.4 Exceptions. Confidential Information does not include:
  </p>

  <p>
    A. matters that at the time of disclosure by Disclosing Party are matters of
    public knowledge or thereafter become matters of public knowledge through no violation of this Agreement; or
  </p>

  <p>
    B. information disclosed by Recipient with the prior written consent of
    Disclosing Party; or
  </p>

  <p>
    C. any other information that both parties agree in writing is not confidential.
  </p>

  <p>
    7.5 Limited Use. The parties shall use the Confidential Information only for purposes related to this Agreement.
  </p>

  <p>
    7.6 Non-Disclosure. Recipient shall hold the Confidential Information in strict confidence and shall use its best efforts to protect the Confidential
    Information, taking at least the same precautions it takes to protect its own valuable proprietary and confidential information, but not less than reasonable care.
    Except where disclosure is made by court order, subpoena, and/or in cooperation with law enforcement agencies, Recipient shall not at any time or in any manner,
    either directly or indirectly, divulge, disclose, or communicate any Confidential Information to any third party, including, but not limited to, any affiliate or
    subsidiary of Recipient, nor shall Recipient copy or modify any Confidential Information, without the prior written consent of Disclosing Party. This provision
    shall continue to be effective after the termination of this agreement.
  </p>

  <p>
    7.7 Agent Access and Control of Information. Recipient may grant its employees, contractors, sub-contractors, or agents (“Agents”) access to the Confidential Information,
    but only to those Agents who have a need to know; provided, however, that Recipient will advise each such Agent of the terms of this Agreement and shall cause each such
    Agent to agree to fully comply with the provisions herein. Recipient shall remain fully responsible for any Confidential Information provided to any such Agent and will
    be liable for any violation of the terms of this Agreement by any such Agent as though such violation was committed by Recipient, regardless of whether or not such
    violation was sanctioned by or known to Recipient.
  </p>

  <p>
    7.8 Injunctive Relief. Recipient understands and acknowledges that the Confidential Information has been developed or obtained by Disclosing Party through the
    investment of significant time, effort and expense, and that the Confidential Information is a valuable, special and unique asset of Disclosing Party which provides
    Disclosing Party with a significant competitive advantage, such that if Recipient breaches any covenant or restriction set forth herein, Disclosing Party may be
    irreparably harmed. Therefore, in addition to other appropriate relief, if it appears that Recipient has disclosed (or has threatened to disclose) any Confidential
    Information in violation of this Agreement, Disclosing Party shall be entitled to seek from any court of competent jurisdiction an injunction, judgment, and/or
    order to enforce this Agreement and restrain Recipient from disclosing, in whole or in part, the Confidential Information. Disclosing Party shall not be prohibited
    by this provision from pursuing other available legal or equitable remedies, including a claim for losses and damages.
  </p>

  <p>
    7.9 Return of Confidential Information. Upon termination of this Agreement, or at anytime upon Disclosing Party’s request, Recipient will immediately return all
    Confidential Information supplied to Recipient by Disclosing Party, including, but not limited to, any documents, records, notes or other materials and items,
    as well as any copies or other reproductions thereof. Recipient shall destroy all documents, notes, reports, memorandum or other materials and items,
    as well as any copies or other reproductions thereof, in any form generated by Recipient that contain Confidential Information. Recipient shall deliver to
    Disclosing Party a written statement signed by Recipient certifying that all materials have been returned and/or destroyed within five (5) days of receipt of the request.
  </p>

  <p>
    7.10 No Warranty. Recipient acknowledges and agrees that the Confidential Information is provided on an AS IS basis. DISCLOSING PARTY DISCLAIMS ALL
    WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO THE CONFIDENTIAL INFORMATION, INCLUDING ALL WARRANTIES WITH RESPECT TO INFRINGEMENT OF INTELLECTUAL PROPERTY
    RIGHTS AND ANY AND ALL IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. IN NO EVENT SHALL DISCLOSING PARTY BE LIABLE FOR ANY DIRECT,
    INDIRECT, SPECIAL, OR CONSEQUENTIAL DAMAGES IN CONNECTION WITH OR ARISING OUT OF THE PERFORMANCE OR USE OF ANY PORTION OF THE CONFIDENTIAL INFORMATION.
    Disclosing Party does not represent or warrant that any product or business plans disclosed to Recipient will be marketed or carried out as disclosed, or at all.
    Any actions taken by Recipient in response to the disclosure of the Confidential Information shall be solely at the risk of Recipient.
  </p>

  <p>
    7.11 Ownership of the Confidential Information. Recipient acknowledges that, as between Disclosing Party and Recipient, the Confidential Information,
    all information derived from it, and all related copyrights and other intellectual property rights, are and will remain the exclusive property of Disclosing Party,
    even if suggestions, comments, and/or ideas made by Recipient are incorporated into the Confidential Information or related materials during the period of this Agreement.
    This Agreement does not explicitly or implicitly transfer or grant to Recipient any intellectual property rights, any license under any intellectual property right,
    nor any license to use the Confidential Information other than for the limited use set out above.
  </p>

  <p>
    7.12 Binding Effect. The confidentiality provisions of this Agreement shall remain in full force and effect after the effective date of this Agreement.
  </p>


  <br />
  <h4>
    VIII. GENERAL PROVISIONS
  </h4>

  <p>
    8.1 Indemnity. Licensee shall be solely responsible for, and shall indemnify, defend and hold harmless {{appName}} from and against any claim, damages or
    lawsuits (including attorneys’ fees) arising out of, related to, or incurred in connection with any breach by Licensee of its obligations under this Agreement
    or with the acts or omissions of Licensee or its employees or agents, including, without limitation, a modification of the API by the Licensee.
  </p>

  <p>
    8.2 Export Assurance. Licensee agrees to comply with and be responsible for understanding any and all export regulations and rules now in effect or that
    may be issued from time to time by the Office of Export Administration of the United States Department of Commerce or any other governmental authority that
    has jurisdiction relating to export laws. Licensee agrees to comply fully and strictly with the export controls and laws of any country in which it does,
    or intends to do, business. Licensee hereby agrees that it does not intend to nor will it knowingly, without the prior written consent, if required, of the
    Office of Export Administration of the U.S. Department of Commerce, transmit, ship, distribute or otherwise send the API or any modifications or updates thereto,
    directly or indirectly, to any country, person, entity, end-user, nation or territory subject to U.S. export restrictions. Licensee warrants and represents that
    neither the U.S.A. Bureau of Export Administration nor any other federal agency has suspended, revoked or denied Licensee's export privileges.
  </p>

  <p>
    8.3 Governing Law.
  </p>

  <p>
    a. This Agreement shall be governed by and interpreted in accordance with the laws of the State of Michigan without regard to conflicts of laws provisions
    thereof. Provided, however, that the United Nations Convention on the International Sale of Goods shall not apply. The forum and venue for any action
    arising out of or relating in any way to this Agreement shall be the applicable state court for Oakland County, Michigan or the United States District Court
    for the Eastern District of Michigan, Southern Division.
  </p>

  <p>
    b. In the event of a dispute, {{appName}} and Licensee agree to engage the other in discussions to reach a mutually acceptable resolution. If this procedure
    fails to resolve the dispute, the parties may mutually elect to participate in non-binding arbitration, by a sole arbitrator, in Oakland County, Michigan in
    accordance with the rules of the American Arbitration Association. The arbitrator shall be mutually selected and approved by the parties. The parties will
    share in the cost of such arbitration, but the foregoing shall not prevent a party from seeking injunctive or equitable relief to enforce the terms hereof or
    prevent the parties to pursue legal and equitable action as each deems appropriate. Both parties agree to waive their right to trial by jury.
  </p>

  <p>
    8.4 Entire Agreement. This Agreement sets forth the entire agreement and understanding between the parties relating to the subject matter hereof. There are
    no oral Agreements between {{appName}} and Licensee affecting this Agreement, and this Agreement supersedes and cancels any and all previous negotiations,
    arrangements, brochures, agreements, and understandings, if any, between {{appName}} and Licensee with respect to the subject matter of this Agreement.
    This Agreement may not be altered, changed, or amended, except by an instrument in writing signed by both parties.
  </p>

  <p>
    8.5 Notices. All notices and demands which may or are required to be given by either party to the other hereunder shall be in writing and shall be deemed to
    have been fully given when deposited in the United States Mail, certified or registered, or Express Carrier (i.e. FedEx, UPS, etc.), postage prepaid, and
    addressed to such address as provided by the parties.
  </p>

  <p>
    Notices shall be deemed effective upon receipt or, if delivery is not effected by reason of some fault of the addressee, when tendered.
  </p>

  <p>
    8.6 Employees. Neither party will hire or contract or solicit to hire or contract any current employees or contractors of the other party or of
    other party’s dealers or subcontractors or any former employees or contractors employed or contracted by the other party, its dealers or
    subcontractors in the preceding twelve (12) months. In the event that any such hire or contract does occur the hiring or contracting party will
    reimburse the other party the sum of $150,000 USD to be paid within thirty (30) days of the date of such hire or contract. The parties agree that
    this payment represents a reasonable estimation of actual expenses incurred should either party hire or contract an employee or contractor or
    former employee or contractor of the other party.
  </p>

  <p>
    8.7 Force Majeure. Nonperformance of either party (except for payment obligations) shall be excused to the extent that performance is rendered
    impossible by strike, fire, flood, act of God, governmental acts, failure of suppliers or any other reason beyond the reasonable control of the
    non-performing party.
  </p>

  <p>
    8.8 No Waiver. The failure of either party to enforce at any time any of the provisions of this Agreement, or the failure to require at any time
    performance by the other party of any of the provisions of this Agreement, will in no way be construed to be a present or future waiver of such provisions,
    nor in any way affect the validity of such provision, or the ability of either party to enforce each and every such provision thereafter.
  </p>

  <p>
    8.9 Nonassignability and Binding Effect. Unless otherwise provided herein, Licensee shall not assign this Agreement (or any of its rights hereunder)
    or delegate its obligations hereunder without {{appName}}’s prior written consent. Subject to the foregoing sentence, this Agreement shall be
    binding upon and inure to the benefit of the parties hereto, their successors and assigns.
  </p>

  <p>
    8.10 Severability. If any provision of this Agreement shall be held to be invalid or unenforceable for any reason, the remaining provisions shall
    continue to be valid and enforceable. If a court finds that any provision of this Agreement is invalid or unenforceable, but that by limiting such
    provision it would become valid or enforceable, then such provision shall be deemed to be written, construed, and enforced as so limited.
  </p>

  <p>
    8.11 Translation/Understanding. The parties do hereby acknowledge and the individuals agreeing on behalf of each respective party do hereby
    warrant that they have reviewed an accurate translation of this agreement, and/or have a sufficient command of the language used herein as to
    understand the provisions, obligations, and rights as contained within this Agreement.
  </p>

  <p>
    8.12 Corporate Authority. If Licensee acts as a corporation, each of the persons using the API on behalf of Licensee does hereby warrant that
    (a) Licensee is a duly authorized and validly existing corporation, (b) the corporation has full right and authority to enter into this Agreement,
    and (c) each person using the API on behalf of the corporation is authorized to do so.
  </p>


</div>
