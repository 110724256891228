<table class="table table-striped table-bordered table-sm table-hover">
  <thead>
    <tr>
      <th>ResultCode</th>
      <th>ResultText</th>
      <th>Http Status Code</th>
      <th>Message</th>
      <th style="width: 25%; word-wrap:break-word;">Comments</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>0</td>
      <td>Success</td>
      <td>OK (200)</td>
      <td>OK</td>
      <td>Depending on the context this may also return http status code Created (201) or Accepted (202).</td>
    </tr>
    <tr>
      <td>1</td>
      <td>PartialSuccess</td>
      <td>Partial Content (206) or error status</td>
      <td>Partial Success. See errors for information on what failed.</td>
      <td>
        The Http Status Code may vary depending on the context and severity of the failure.
        For processes that determine the result should be treated as a success Partial Content
        (206) will be returned.
        For processes that determine the result should be treated as a failure the Http Status
        Code returned will be based on the result code of one of the failed parts of the process.
        Depending on the context this should be treated as a success with warnings about what
        failed or as a failure with information about what succeeded.
      </td>
    </tr>
    <tr>
      <td>90</td>
      <td>UnknownError</td>
      <td>Bad Request (400)</td>
      <td>Request triggered an error. </td>
      <td></td>
    </tr>
    <tr>
      <td>99</td>
      <td>DataStoreInformationMissing</td>
      <td>Internal Server Error (500)</td>
      <td>Missing a connection to the data store. </td>
      <td></td>
    </tr>
    <tr>
      <td>100</td>
      <td>SubsystemSpecificError</td>
      <td>Bad Request (400)</td>
      <td>Request triggered an error. </td>
      <td></td>
    </tr>
    <tr>
      <td>1001</td>
      <td>RequiredValueEmpty</td>
      <td>Bad Request (400)</td>
      <td>Request was missing a required value. </td>
      <td></td>
    </tr>
    <tr>
      <td>1011</td>
      <td>InvalidValue</td>
      <td>Bad Request (400)</td>
      <td>Request included an invalid value. </td>
      <td></td>
    </tr>
    <tr>
      <td>1012</td>
      <td>InvalidValueTooShort</td>
      <td>Bad Request (400)</td>
      <td>Request included a value that was too short. </td>
      <td></td>
    </tr>
    <tr>
      <td>1013</td>
      <td>InvalidValueTooLong</td>
      <td>Bad Request (400)</td>
      <td>Request included a value that was too long. </td>
      <td></td>
    </tr>
    <tr>
      <td>1014</td>
      <td>InvalidValueTooLow</td>
      <td>Bad Request (400)</td>
      <td>Request included a value that was too low. </td>
      <td></td>
    </tr>
    <tr>
      <td>1015</td>
      <td>InvalidValueTooHigh</td>
      <td>Bad Request (400)</td>
      <td>Request included a value that was too high. </td>
      <td></td>
    </tr>
    <tr>
      <td>1051</td>
      <td>InvalidFormat</td>
      <td>Bad Request (400)</td>
      <td>Request included a value that was in an invalid format. </td>
      <td></td>
    </tr>
    <tr>
      <td>1061</td>
      <td>ValidationFailure</td>
      <td>Bad Request (400)</td>
      <td>Request was not valid. </td>
      <td></td>
    </tr>
    <tr>
      <td>1071</td>
      <td>PrerequisiteFailure</td>
      <td>Precondition Failed (412)</td>
      <td>Request had a prerequisite that failed. </td>
      <td></td>
    </tr>
    <tr>
      <td>1101</td>
      <td>NotFound</td>
      <td>Not Found (404)</td>
      <td>Requested item was not found. </td>
      <td></td>
    </tr>
    <tr>
      <td>1151</td>
      <td>TooManyFound</td>
      <td>Not Found (404)</td>
      <td>Request found too many items. Only one was expected. </td>
      <td></td>
    </tr>
    <tr>
      <td>1201</td>
      <td>DuplicateFound</td>
      <td>Conflict (409)</td>
      <td>An item already exists with that value and duplicates are not allowed. </td>
      <td></td>
    </tr>
    <tr>
      <td>1211</td>
      <td>InvalidForeignKeyReference</td>
      <td>Not Found (404)</td>
      <td>Request referenced an object that was not found. </td>
      <td></td>
    </tr>
    <tr>
      <td>1301</td>
      <td>Dependency</td>
      <td>Conflict (409)</td>
      <td>Request can't be completed because other items depend on it. </td>
      <td></td>
    </tr>
    <tr>
      <td>1401</td>
      <td>OutOfIdentitySpace</td>
      <td>Internal Server Error (500)</td>
      <td>Request has exhausted the pool of available keys. </td>
      <td></td>
    </tr>
    <tr>
      <td>1501</td>
      <td>InsufficientPermission</td>
      <td>Forbidden (403)</td>
      <td>Insufficient permission for request. </td>
      <td></td>
    </tr>
    <tr>
      <td>1511</td>
      <td>InvalidUserNamePasswordCombination</td>
      <td>Unauthorized (401)</td>
      <td>Invalid authentication information.</td>
      <td></td>
    </tr>
    <tr>
      <td>1512</td>
      <td>InvalidAuthenticationToken</td>
      <td>Unauthorized (401)</td>
      <td>Invalid authentication information.</td>
      <td></td>
    </tr>
    <tr>
      <td>1513</td>
      <td>InvalidAuthenticationKey</td>
      <td>Unauthorized (401)</td>
      <td>Invalid authentication information.</td>
      <td></td>
    </tr>
    <tr>
      <td>1519</td>
      <td>UnknownThirdPartyAuthentication</td>
      <td>Unauthorized (401)</td>
      <td>Unknown third-party authentication.</td>
      <td>An unknown third-party authentication was presented. Either a new user account needs to be created or the authentication needs to be linked to an existing user account.
      </td>
    </tr>
    <tr>
      <td>1520</td>
      <td>PasswordExpired</td>
      <td>Unauthorized (401)</td>
      <td>The user password has expired and needs to be changed.</td>
      <td></td>
    </tr>
    <tr>
      <td>1521</td>
      <td>AccessBlocked</td>
      <td>Unauthorized (401)</td>
      <td>Access was blocked due to suspicious activity, bad login attempts, or other reason.</td>
      <td></td>
    </tr>
    <tr>
      <td>1522</td>
      <td>AccessRejected</td>
      <td>Unauthorized (401)</td>
      <td>Access was rejected due to user being terminated, account closed, or other reason.</td>
      <td></td>
    </tr>
    <tr>
      <td>1531</td>
      <td>AccessRequiresMultiFactorAuthentication</td>
      <td>Unauthorized (401)</td>
      <td>Access requires multi-factor authentication. Recipient of this response should prompt for MFA, collect the results, and submit those results for access.</td>
      <td></td>
    </tr>
    <tr>
      <td>1532</td>
      <td>AccessRequiresMultiFactorAuthenticationForNewPublicIpAddress</td>
      <td>Unauthorized (401)</td>
      <td>Access requires multi-factor authentication. Recipient of this response should prompt for MFA, collect the results, and submit those results for access.</td>
      <td></td>
    </tr>
    <tr>
      <td>1533</td>
      <td>AccessRequiresMultiFactorAuthenticationForNewDeviceId</td>
      <td>Unauthorized (401)</td>
      <td>Access requires multi-factor authentication. Recipient of this response should prompt for MFA, collect the results, and submit those results for access.</td>
      <td></td>
    </tr>
    <tr>
      <td>1601</td>
      <td>NoneAffected</td>
      <td>Not Found (404)</td>
      <td>No item(s) affected by request. </td>
      <td></td>
    </tr>
    <tr>
      <td>1611</td>
      <td>ChangedByAnotherUser</td>
      <td>Conflict (409)</td>
      <td>This item has been changed by another user since you started editing it. Changes were not saved. The item should be reloaded and changes made again.</td>
      <td></td>
    </tr>
    <tr>
      <td>1701</td>
      <td>RequestedActionNotSupported</td>
      <td>Not Implemented (501)</td>
      <td>Requested an action that is not supported. </td>
      <td></td>
    </tr>
    <tr>
      <td>1711</td>
      <td>RequestedActionAlreadyCompleted</td>
      <td>OK (200)</td>
      <td>Requested an action that had already been completed. </td>
      <td></td>
    </tr>
    <tr>
      <td>2001</td>
      <td>DatabaseNotConfiguredMissingDatabaseId</td>
      <td>Internal Server Error (500)</td>
      <td>Missing a key data store configuration. </td>
      <td></td>
    </tr>
    <tr>
      <td>2101</td>
      <td>EncryptionKeyNotFound</td>
      <td>Internal Server Error (500)</td>
      <td>Missing encryption information required by data store. </td>
      <td></td>
    </tr>
    <tr>
      <td>2201</td>
      <td>EncryptionKeyMissingValues</td>
      <td>Internal Server Error (500)</td>
      <td>Missing encryption information required by data store. </td>
      <td></td>
    </tr>
    <tr>
      <td>2301</td>
      <td>EncryptionNotConfigured</td>
      <td>Internal Server Error (500)</td>
      <td>Missing encryption information required by data store. </td>
      <td></td>
    </tr>
    <tr>
      <td>3101</td>
      <td>PartnerSystemOffline</td>
      <td>Service Unavailable (503)</td>
      <td>A partner system (e.g. web service, payment gateway, etc.) is off-line. </td>
      <td></td>
    </tr>
    <tr>
      <td>3111</td>
      <td>PartnerSystemTimeout</td>
      <td>Gateway Timeout (504)</td>
      <td>A partner system (e.g. web service, payment gateway, etc.) has timed out. </td>
      <td></td>
    </tr>
    <tr>
      <td>3121</td>
      <td>PartnerSystemError</td>
      <td>Service Unavailable (503)</td>
      <td>A partner system (e.g. web service, payment gateway, etc.) had an error. </td>
      <td></td>
    </tr>
    <tr>
      <td>4101</td>
      <td>Rejected</td>
      <td>Not Acceptable (406)</td>
      <td>Request had an unacceptable value and was rejected. </td>
      <td></td>
    </tr>
    <tr>
      <td>4201</td>
      <td>Timeout</td>
      <td>Gateway Timeout (504)</td>
      <td>The request resulted in a timeout. Try again later.</td>
      <td></td>
    </tr>
    <tr>
      <td>4301</td>
      <td>Cancelled</td>
      <td>Not Acceptable (406)</td>
      <td>The request was cancelled. </td>
      <td></td>
    </tr>
    <tr>
      <td>2147483645</td>
      <td>ExceptionTimeout</td>
      <td>Gateway Timeout (504)</td>
      <td>The request resulted in a timeout. Try again later.</td>
      <td></td>
    </tr>
    <tr>
      <td>2147483646</td>
      <td>ExceptionUpdatingData</td>
      <td>Bad Request (400)</td>
      <td>The request resulted in an error. </td>
      <td></td>
    </tr>
    <tr>
      <td>2147483647</td>
      <td>Exception</td>
      <td>Bad Request (400)</td>
      <td>The request resulted in an error. </td>
      <td></td>
    </tr>
    <tr>
      <td>-1</td>
      <td>DataStoreError</td>
      <td>Bad Request (400)</td>
      <td>Data store did not like the request. </td>
      <td></td>
    </tr>
  </tbody>
</table>
