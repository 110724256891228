<div>

  <h3>Data Formats</h3>
  <br />

  <p>
    GET, DELETE, and copy requests carry their data in the URL and never have a request body. All other requests
    and all responses are always in
    <a href="https://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf"
       target="_blank">JSON format</a>.
  </p>

  <br />

  <h4>Dates</h4>
  <br />

  <p>
    Dates are provided in and expected in a few supported <a href="https://en.wikipedia.org/wiki/ISO_8601"
       target="_blank">ISO 8601</a>
    extended formats generally with a date portion (yyyy-mm-dd) and time portion (hh:mm:ss) separated by the letter <em>T</em> as a delimiter.
    The time may optionally be followed by a UTC indicator <em>Z</em> or a UTC offset (±hh:mm).
  </p>

  <ul>
    <li>
      The date portion (yyyy-mm-dd) includes:
      <ul>
        <li>yyyy refers to a four-digit year (note that the earliest year supported in our system is 1754)</li>
        <li>mm refers to a zero-padded month between 00 and 12</li>
        <li>dd refers to a zero-padded day of the specified month 01 to 31 (but not to exceed the last day of the specified month)</li>
      </ul>
    </li>
    <li>
      The time portion (hh:mm:ss) includes:
      <ul>
        <li>hh refers to a zero-padded hour between 00 and 23</li>
        <li>mm refers to a zero-padded minute between 00 and 59</li>
        <li>ss refers to a zero-padded second between 00 and 59</li>
      </ul>
    </li>
    <li>
      The optional UTC indicator (Z or ±hh:mm) includes:
      <ul>
        <li>A <em>Z</em> indicator for UTC (i.e. zero UTC offset)</li>
        <li>± refers to a plus (+) or minus (-) symbol indicating if the offset is before (-) or after (+) UTC</li>
        <li>hh refers to a zero-padded hours offset from UTC</li>
        <li>mm refers to a zero-padded minutes offset from UTC</li>
      </ul>
    </li>
  </ul>

  <br />
  <p>
    Examples of date formats that may be encountered while using the API
  </p>
  <ul>
    <li>
      <strong>yyyy-mm-ddThh:mm:ss</strong> (e.g. 2016-05-15T18:29:34)
    </li>
    <li>
      <strong>yyyy-mm-ddThh:mm:ssZ</strong> (e.g. 2016-05-15T18:29:34Z)
    </li>
    <li>
      <strong>yyyy-mm-ddThh:mm:ss±hh:mm</strong> (e.g. 2016-05-15T18:29:34-04:00)
    </li>
  </ul>

  <br />
  <p>
    Time Zone Property
  </p>
  <ul>
    <li>
      Most payloads will include a TimeZone property that will specify a time zone for date time values in that payload.
    </li>
    <li>
      Requests that do not include a time zone or have the time zone set to null will assume the payload time zone is the
      time zone configured for the user making the request. Note that this is <em>not</em> the time zone of a user's local
      computer this is the time zone configured in our system for the user.
    </li>
    <li>
      Responses will have a time zone that matches the time zone configured for the user making the request. Note that this is
      <em>not</em> the time zone of a user's local computer this is the time zone configured in our system for the user.
    </li>
  </ul>

</div>
