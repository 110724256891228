import { ApiProperties, ApiEndpoint, ApiDocumentation, ApiOperationType, CacheLevel, ApiDocPage, ApiDocTestFormProperty, ApiDocTestFormPropertyType, ApiRelationship } from "projects/core-lib/src/lib/api/ApiModels";
import { Log } from "projects/core-lib/src/lib/helpers/helper";

declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";

import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as m5pay from "projects/core-lib/src/lib/models/ngModelsPayment5";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import * as wm5 from "projects/core-lib/src/lib/models/ngWalletModels";

import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as Enumerable from "linq";
import { ApiHelper } from "projects/core-lib/src/lib/api/ApiHelper";


export class ApiModuleWeb {


  //#region Helper Functions

  /**
  This method returns an array of all of the api properties methods available in this class.
  */
  public static GetListOfApiPropertiesMethods(): string[] {
    let list: string[] = [];
    list = Object.getOwnPropertyNames(ApiModuleWeb).filter(function (p) {
      return p !== "GetListOfApiPropertiesMethods" && p !== "GetApi" && p !== "getApiRelationships" && typeof ApiModuleWeb[p] === "function";
    });
    return list;
  }
  /**
  This method returns the ApiProperties object for the requested api name.  This can be used to
  get api properties object dynamically.
  */
  public static GetApi(apiName: string, version: number = AppConfig.apiVersion, suppressErrorReporting: boolean = false): ApiProperties {
    if (!version) {
      version = AppConfig.apiVersion;
    }
    try {
      const api: ApiProperties = ApiModuleWeb[apiName](version);
      // If we don't have an id we can populate it here since we accessed this by method name and
      // the method name is our default id name.
      if (!api.id) {
        api.id = apiName;
      }
      return api;
    } catch (err) {
      if (!suppressErrorReporting) {
        Log.errorMessage(`Exception getting api for ${apiName} with version ${version}`);
        Log.errorMessage(err);
      }
    }
  }


  public static getApiRelationships(): ApiRelationship[] {

    const relationships: ApiRelationship[] = [];

    // Start by stepping through our APIs and gathering the api name and any parent api reference
    const apiNames = ApiModuleWeb.GetListOfApiPropertiesMethods();
    for (const apiName of apiNames) {
      const api: ApiProperties = ApiModuleWeb.GetApi(apiName);
      if (!api) {
        console.error(`Unable to get relationships for unknown api ${apiName}`);
        break;
      }
      const relationship: ApiRelationship = new ApiRelationship();
      relationship.apiName = apiName;
      relationship.parent = api.parentApi;
      relationships.push(relationship);
    }

    // Now step through our relationship list and gather list of children for each api
    const linq = Enumerable.from(relationships);
    relationships.forEach((relationship: ApiRelationship, index: number, array: ApiRelationship[]) => {
      const children = linq.where(x => x.parent === relationship.apiName).toArray();
      relationship.children = Enumerable.from(children).select(x => x.apiName).toArray();
    });

    return relationships;

  }

  //#endregion Helper Functions


  //#region System APIs

  public static DashboardWidgets(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("DashboardWidgets");
    api.version = version;
    api.documentation.objectDescription = "Dashboard Widgets";
    api.documentation.objectPrimaryKey = "Id";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.DashboardWidget;
    api.documentation.requestAndResponseDataModelObject = new m5web.DashboardWidgetEditViewModel();
    api.documentation.documentationUrlBase = "/dashboard-widget/";
    api.pathVariables = "{widgetId}";
    api.pathModelProperties = "Id";
    api.cacheName = "dashboardCache";
    api.cacheLevel = CacheLevel.Static;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Dashboards}/${m.RouteSegment.Widgets}?page={page}&size={size}&sort={sort}&filterId={filterId}&filter={filter}&q={q}&expand={expand}`, ApiOperationType.List));
    return api;
  };

  public static DashboardContact(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("DashboardContact");
    api.version = version;
    api.documentation.objectDescription = "Dashboard for Contacts";
    api.documentation.objectPrimaryKey = "OwnerContactId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Dashboard;
    api.documentation.requestAndResponseDataModelObject = new m5web.DashboardEditViewModel();
    api.documentation.documentationUrlBase = "/dashboard/";
    api.pathVariables = "{contactId}";
    api.pathModelProperties = "OwnerContactId";
    api.cacheName = "dashboardCache";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.useStandardEndpoints(`/${m.RouteSegment.Dashboards}/${m.RouteSegment.Contacts}`, new m5web.DashboardListViewModel(), Constants.DataModelName.DashboardList);
    return api;
  };

  public static DashboardBookmark(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("DashboardBookmark");
    api.version = version;
    api.documentation.objectDescription = "Bookmarks";
    api.documentation.objectPrimaryKey = "";
    api.documentation.requestAndResponseDataModelDocumentationName = ""; //Constants.DataModelName.Dashboard;
    api.documentation.requestAndResponseDataModelObject = new m5web.DashboardWidgetPropertyBookmarkEditViewModel();
    api.documentation.documentationUrlBase = "/dashboard/bookmarks/";
    api.pathVariables = "{contactId}";
    api.pathModelProperties = "ContactId";
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Dashboards}/${m.RouteSegment.Widgets}/${m.RouteSegment.Bookmarks}/${m.RouteSegment.Contacts}/{contactId}`, ApiOperationType.Add));
    return api;
  };

  public static DashboardAppendTemplate(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("DashboardAppendTemplate");
    api.version = version;
    api.documentation.objectDescription = "Append Template To Dashboard";
    api.documentation.objectPrimaryKey = "";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Dashboard;
    api.documentation.requestAndResponseDataModelObject = new m5web.DashboardEditViewModel();
    api.documentation.documentationUrlBase = "/dashboard/append-template/";
    api.pathVariables = ["{contactId}", "{id}"];
    api.pathModelProperties = ["ContactId", "Id"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Dashboards}/${m.RouteSegment.Contacts}/{contactId}/${m.RouteSegment.AddFromTemplate}/{id}`, ApiOperationType.Add));
    return api;
  };

  public static DashboardTemplate(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("DashboardTemplate");
    api.version = version;
    api.documentation.objectDescription = "Dashboard Template";
    api.documentation.objectPrimaryKey = "Id";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Dashboard;
    api.documentation.requestAndResponseDataModelObject = new m5web.DashboardEditViewModel();
    api.documentation.documentationUrlBase = "/dashboard-template/";
    api.pathVariables = "{id}";
    api.pathModelProperties = "Id";
    api.cacheName = "dashboardCache";
    api.cacheLevel = CacheLevel.PseudoStatic;
    api.useStandardEndpoints(`/${m.RouteSegment.Dashboards}/${m.RouteSegment.Templates}`, new m5web.DashboardListViewModel(), Constants.DataModelName.DashboardList);
    return api;
  };


  //#region Web APIs

  public static HelpLink(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("HelpLink");
    api.version = version;
    api.documentation.objectDescription = "Help Link";
    api.documentation.objectPrimaryKey = "HelpLinkId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "Context"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.HelpLink;
    api.documentation.requestAndResponseDataModelObject = new m5web.HelpLinkEditViewModel();
    api.documentation.documentationUrlBase = "/help-links/";
    api.documentation.securityAccessArea = Constants.AccessArea.HelpLink;
    api.pathVariables = "{helpLinkId}";
    api.pathModelProperties = "HelpLinkId";
    api.cacheName = "helpLinkCache";
    api.cacheLevel = CacheLevel.PseudoStatic;
    api.useStandardEndpoints(`/${m.RouteSegment.HelpLinks}`, null, "", false);
    return api;
  };

  public static HelpLinkFind(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("HelpLinkFind");
    api.version = version;
    api.documentation.objectDescription = "Help Link";
    api.documentation.objectPrimaryKey = "HelpLinkId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "Context"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.HelpLink;
    api.documentation.requestAndResponseDataModelObject = new m5web.HelpLinkEditViewModel();
    api.documentation.documentationUrlBase = "/help-links/";
    api.documentation.securityAccessArea = Constants.AccessArea.HelpLink;
    api.pathVariables = "{helpLinkId}";
    api.pathModelProperties = "HelpLinkId";
    api.cacheName = "helpLinkObjectCache";
    api.cacheLevel = CacheLevel.PseudoStatic;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.HelpLinks}/${m.RouteSegment.Find}?context={context}&brand={brand}&appVersion={appVersion}&tag={tag}&language={language}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Find Help Link";
    api.endpoints.slice(-1)[0].documentation.menuText = "Find Help Link";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This api endpoint is used to find a help link object for a given context.";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static HelpLinkItem(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("HelpLinkItem");
    api.version = version;
    api.documentation.objectDescription = "Help Link Item";
    api.documentation.objectPrimaryKey = "HelpLinkItemId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = "";
    api.documentation.requestAndResponseDataModelObject = new m5web.HelpLinkItemEditViewModel();
    api.documentation.securityAccessArea = Constants.AccessArea.HelpLink;
    // Documentation only object with no api endpoints since it is lives inside another object
    return api;
  };

  public static SearchConfiguration(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("SearchConfiguration");
    api.version = version;
    api.documentation.objectDescription = "Search Configuration";
    api.documentation.objectDescriptionPlural = "Search Configurations";
    api.documentation.objectPrimaryKey = "SearchConfigurationId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalSearchConfigurationId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.SearchConfiguration;
    api.documentation.requestAndResponseDataModelObject = new m5web.SearchConfigurationViewModel();
    api.documentation.documentationUrlBase = "/search-configuration/";
    api.documentation.securityAccessArea = Constants.AccessArea.SearchConfiguration;
    api.pathVariables = "{searchConfigurationId}";
    api.pathModelProperties = "SearchConfigurationId";
    api.cacheName = "systemCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Search}/${m.RouteSegment.Configurations}`, null, "", true);
    return api;
  };

  public static SearchResult(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("SearchResult");
    api.version = version;
    api.documentation.objectDescription = "Search Result";
    api.documentation.objectPrimaryKey = "";
    api.documentation.requestAndResponseDataModelDocumentationName = "";
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/search/result/";
    api.documentation.securityAccessArea = "";
    api.pathVariables = "{searchConfigurationId}";
    api.pathModelProperties = "SearchConfigurationId";
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Search}/{searchConfigurationId}/${m.RouteSegment.Data}?page={page}&size={size}&sort={sort}&filter={filter}`, ApiOperationType.List));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API retrieves a list of search results based on the specified search configuration and supplied parameters.";
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = [];
    return api;
  };

  public static TableConfiguration(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("TableConfiguration");
    api.version = version;
    api.documentation.objectDescription = "Table Configuration";
    api.documentation.objectDescriptionPlural = "Table Configurations";
    api.documentation.objectPrimaryKey = "TableConfigurationId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.TableConfiguration;
    api.documentation.requestAndResponseDataModelObject = new m5web.TableConfigurationViewModel();
    api.documentation.documentationUrlBase = "/table-configuration/";
    api.documentation.securityAccessArea = Constants.AccessArea.TableConfiguration;
    api.pathVariables = "{tableConfigurationId}";
    api.pathModelProperties = "TableConfigurationId";
    api.cacheName = "systemCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Table}/${m.RouteSegment.Configurations}`, null, "", true);
    return api;
  };

  public static Favorite(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Favorite");
    api.version = version;
    api.documentation.objectDescription = "Favorite";
    api.documentation.objectPrimaryKey = "FavoriteId";
    api.documentation.objectDescriptionPropertyNames = ["FavoriteResourceType"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Favorite;
    api.documentation.requestAndResponseDataModelObject = new m5web.FavoriteEditViewModel();
    api.documentation.documentationUrlBase = "/favorites/";
    api.documentation.securityAccessArea = Constants.AccessArea.Favorite;
    api.pathVariables = "{favoriteId}";
    api.pathModelProperties = "FavoriteId";
    api.cacheName = "favoriteObjectCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Favorites}`, null, "", false);
    return api;
  };

  public static FavoriteForContact(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("FavoriteForContact");
    api.version = version;
    api.documentation.objectDescription = "Favorite";
    api.documentation.objectPrimaryKey = "FavoriteId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Favorite;
    api.documentation.requestAndResponseDataModelObject = new m5web.FavoriteEditViewModel();
    api.documentation.documentationUrlBase = "/favorites-for-contact/";
    api.documentation.securityAccessArea = Constants.AccessArea.Favorite;
    api.pathVariables = ["{contactId}", "{favoriteId}"];
    api.pathModelProperties = ["ContactId", "FavoriteId"];
    api.cacheName = "favoriteObjectCache";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Favorites}/${m.RouteSegment.Contacts}/{contactId}?page={page}&size={size}&sort={sort}&filterId={filterId}&filter={filter}&q={q}&expand={expand}`, ApiOperationType.List));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "List Favorites for Contact";
    api.endpoints.slice(-1)[0].documentation.menuText = "List Favorites for Contact";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This api endpoint gets favorites for the specified contact id.";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static Form(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Form");
    api.version = version;
    api.documentation.objectDescription = "Form";
    api.documentation.objectPrimaryKey = "FormId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalFormId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Form;
    api.documentation.requestAndResponseDataModelObject = new m5web.FormEditViewModel();
    api.documentation.documentationUrlBase = "/forms/";
    api.documentation.securityAccessArea = Constants.AccessArea.Form;
    api.pathVariables = "{formId}";
    api.pathModelProperties = "FormId";
    api.cacheName = "staticObjectCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Forms}`, null, "", true);
    return api;
  };

  public static FormControlGroup(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("FormControlGroup");
    api.version = version;
    api.documentation.objectDescription = "Form Control Group";
    api.documentation.objectPrimaryKey = "FormControlGroupId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.FormControlGroup;
    api.documentation.requestAndResponseDataModelObject = new m5web.FormControlGroupEditViewModel();
    api.documentation.documentationUrlBase = "/forms/groups/";
    api.documentation.securityAccessArea = Constants.AccessArea.FormControlGroup;
    api.pathVariables = ["{formId}", "{formControlGroupId}"];
    api.pathModelProperties = ["FormId", "FormControlGroupId"];
    api.cacheName = "staticObjectCache";
    api.parentApi = "Form";
    api.useStandardEndpoints(`/${m.RouteSegment.Forms}/{formId}/${m.RouteSegment.Groups}`, null, "", true);
    return api;
  };

  public static FormControl(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("FormControl");
    api.version = version;
    api.documentation.objectDescription = "Form Control";
    api.documentation.objectPrimaryKey = "FormControlId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.FormControl;
    api.documentation.requestAndResponseDataModelObject = new m5web.FormControlEditViewModel();
    api.documentation.documentationUrlBase = "/forms/groups/controls/";
    api.documentation.securityAccessArea = Constants.AccessArea.FormControl;
    api.pathVariables = ["{formId}", "{formControlGroupId}", "{formControlId}"];
    api.pathModelProperties = ["FormId", "FormControlGroupId", "FormControlId"];
    api.cacheName = "staticObjectCache";
    api.parentApi = "Form";
    api.useStandardEndpoints(`/${m.RouteSegment.Forms}/{formId}/${m.RouteSegment.Groups}/{formControlGroupId}/${m.RouteSegment.Controls}`, null, "", true);
    return api;
  };

  public static FormHtml(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("FormHtml");
    api.version = version;
    api.documentation.objectDescription = "Form Html";
    api.documentation.objectPrimaryKey = "FormId";
    api.documentation.requestAndResponseDataModelDocumentationName = null;
    api.documentation.requestAndResponseDataModelObject = "";
    api.documentation.documentationUrlBase = "/form-html/";
    api.pathVariables = "{formId}";
    api.pathModelProperties = "FormId";
    api.cacheName = "staticObjectCache";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Forms}/{formId}/${m.RouteSegment.Html}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API endpoint gets html mark up for a form.";
    return api;
  };

  //#endregion

  //#region Webhooks

  public static Webhook(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Webhook");
    api.version = version;
    api.documentation.objectDescription = "Webhook";
    api.documentation.objectPrimaryKey = "WebhookId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalWebhookId", "Type"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Webhook;
    api.documentation.requestAndResponseDataModelObject = new m5web.WebhookEditViewModel();
    api.documentation.documentationUrlBase = "/webhook/";
    api.documentation.securityAccessArea = Constants.AccessArea.Webhook;
    api.pathVariables = "{webhookId}";
    api.pathModelProperties = "WebhookId";
    api.cacheName = "systemCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Webhooks}`, new m5web.WebhookListViewModel(), Constants.DataModelName.WebhookList, true);
    return api;
  };

  public static WebhookTarget(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("WebhookTarget");
    api.version = version;
    api.documentation.objectName = "WebhookTarget";
    api.documentation.objectDescription = "Webhook Target";
    api.documentation.objectPrimaryKey = "";
    api.documentation.documentationUrlBase = "/webhook/target/";
    api.pathVariables = ["{apiKey}", "{webhookType}"];
    api.pathModelProperties = ["ApiKey", "WebhookType"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Webhooks}/${m.RouteSegment.Target}/{apiKey}/{webhookType}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Webhook Target";
    api.endpoints.slice(-1)[0].documentation.menuText = "Webhook Target";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API is utilized to facilitate logging of various webhook responses desired from " +
      "external services.  This API will log posts to this url but performs no other operations unless explicitly configured to do so.  " +
      "For example, the Amazon Payment API can be configured to post Instant Payment Notification (IPN) objects when a payment is processed and this " +
      "API endpoint could be provided to Amazon as the target for that post." +
      "<br/><br/>" +
      "The api key used in the url for this api endpoint <strong>must be properly secured</strong> to only provide access to resources required to accomplish the " +
      "purposes of the webhook post.  The api key flags must to contain 'webhook' as well as the allowed webhook types.  For example, if using this for " +
      "Amazon Payment API IPN the url for posts might look like this: " +
      `'/${m.RouteSegment.Webhooks}/${m.RouteSegment.Target}/78-sapfvi-3f8sfz-sa8fubg8/amazon-ipn' ` +
      "and the flags for api key 78-sapfvi-3f8sfz-sa8fubg8 would contain: 'webhook,amazon-ipn'.";
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.requestDataModelObject = { dynamic: "No specific format is required.  Any data posted is logged with no assumptions about format or contents." };
    api.endpoints.slice(-1)[0].documentation.requestDataModelDocumentationName = "";
    api.endpoints.slice(-1)[0].documentation.responseDataModelIsNull = true;
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = "";
    let testForm: ApiDocTestFormProperty[] = [];
    testForm.push(new ApiDocTestFormProperty("ApiKey"));
    testForm.push(new ApiDocTestFormProperty("WebhookType"));
    testForm.push(new ApiDocTestFormProperty("Body", ApiDocTestFormPropertyType.TextArea));
    testForm.slice(-1)[0].height = 10;
    testForm.slice(-1)[0].isHttpRequestBody = true;
    api.endpoints.slice(-1)[0].documentation.testFormProperties = testForm;
    api.endpoints.slice(-1)[0].documentation.testFormNoJson = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressAuthenticationHeaders = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressEncryptionHeaders = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressContentTypeHeaders = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressApiVersionHeaders = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressLocalDeviceHeaders = true;
    return api;
  };

  public static WebhookEvent(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("WebhookEvent");
    api.version = version;
    api.documentation.objectDescription = "Webhook Event";
    api.documentation.objectPrimaryKey = "WebhookEventId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ResourceType", "EventType", "EventDateTime"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.WebhookEvent;
    api.documentation.requestAndResponseDataModelObject = new m5web.WebhookEventEditViewModel();
    api.documentation.documentationUrlBase = "/webhook-events/";
    api.documentation.securityAccessArea = Constants.AccessArea.WebhookEvent;
    api.pathVariables = "{webhookEventId}";
    api.pathModelProperties = "WebhookEventId";
    api.cacheName = "logCache";
    api.useStandardEndpoints(`/${m.RouteSegment.WebhookEvents}`, new m5web.WebhookEventListViewModel(), Constants.DataModelName.WebhookEventList);
    return api;
  };


  //#endregion

}
