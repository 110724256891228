import { Component, OnInit, OnChanges, OnDestroy, SimpleChanges, Input, AfterViewInit, AfterContentInit } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { DataEditorBaseClass } from 'projects/common-lib/src/lib/ux/data-editor/data-editor-base-class';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { TableColumnOptions } from 'projects/common-lib/src/lib/table/table-column-options';
import { ModalCommonOptions } from 'projects/common-lib/src/lib/modal/modal-common-options';
import { EventModel, Action, EventModelTyped, ButtonItem } from 'projects/common-lib/src/lib/ux-models';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { ApiOperationType, IApiResponseWrapperTyped, ApiProperties, ApiCall } from 'projects/core-lib/src/lib/api/ApiModels';
import { DomSanitizer } from '@angular/platform-browser';
import { IconData, IconHelper } from 'projects/common-lib/src/lib/image/icon/icon-helper';
import { ApiModuleSecurity } from 'projects/core-lib/src/lib/api/Api.Module.Security';
import { AlertItemType } from 'projects/common-lib/src/lib/alert/alert-manager';
import { FormGroupFluentModel } from 'projects/common-lib/src/lib/form/form-fluent-model';
import { ApiModuleWeb } from 'projects/core-lib/src/lib/api/Api.Module.Web';
import { CaseService } from 'projects/core-lib/src/lib/services/case.service';
import { ApiDocsService } from 'projects/core-lib/src/lib/services/api-docs.service';
import { FormHelper } from 'projects/common-lib/src/lib/form/form-helper';
import { UxService } from 'projects/common-lib/src/lib/services/ux.service';
import { LogicalFileSystem } from '@angular/compiler-cli/src/ngtsc/file_system';
import { HighlightSpanKind } from 'typescript';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ib-dynamic-editor',
  templateUrl: './dynamic-editor.component.html',
  styleUrls: ['./dynamic-editor.component.css']
})
export class DynamicEditorComponent extends DataEditorBaseClass<any, any> implements OnInit, OnChanges, OnDestroy, AfterContentInit {

  @Input() formId: number = null;
  @Input() allowAnonymousAccess: boolean = false;
  @Input() properties: any = {};
  @Input() routeModel: m5core.ApplicationInformationRouteModel = null;

  formModel: m5web.FormEditViewModel = null;

  attributeSet: m5.AttributeSetEditViewModel = null;



  constructor(
    protected appService: AppService,
    protected uxService: UxService,
    protected apiService: ApiService,
    protected docsService: ApiDocsService,
    protected caseService: CaseService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected sanitizer: DomSanitizer,
    protected ngbModalService: NgbModal) {

    super(appService, uxService, apiService, route, router, ngbModalService, ApiModuleWeb.Form());

    // We don't have typical route data for dynamic editors so set flag so we don't expect it.
    //this.ignoreRouteData = true;

    // We want route input method to be our default
    this.inputMethod = "route";

  }

  ngOnInit() {

    super.ngOnInit();

    // Clear our editor options and table options until the form gets loaded so they can be built out
    this.editorOptions = null;
    this.tableOptions = null;

    if (this.mode) {
      // On route change sometimes we're getting "List" instead of "list"
      this.mode = this.mode.toLowerCase() as any;
    }

    if (this.routeModel) {
      this.buildRoutes();
    }

    // Use the url to determine editor mode and, if in edit mode, the object id
    this.parseRoute();
    this.router.events.pipe(takeUntil(this.ngUnsubscribe), filter(event => event instanceof NavigationEnd)).subscribe((value: RouterEvent) => {
      // When the route changes we need to parse it again
      this.parseRoute();
    });

    if (this.formId) {
      this.loadForm();
    }

  }


  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.mode && this.mode) {
      // On route change sometimes we're getting "List" instead of "list"
      //console.error("mode change", this.mode);
      this.mode = this.mode.toLowerCase() as any;
    }
    if (changes.routeModel && this.routeModel) {
      this.buildRoutes();
    }
    if (changes.formId && this.formId) {
      this.loadForm();
    }
  }

  // ngOnDestroy() {
  //   super.ngOnDestroy();
  // }

  // ngAfterContentInit() {
  //   super.ngAfterContentInit();
  // }


  protected loadForm() {
    if (!this.formId) {
      return;
    }
    const apiProp: ApiProperties = ApiModuleWeb.Form();
    const apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
    this.apiService.execute(apiCall, this.formId).subscribe((result: IApiResponseWrapperTyped<m5web.FormEditViewModel>) => {
      if (result.Data.Success) {
        // Save form model
        this.formModel = result.Data.Data;
        // Set api properties for the form
        if (Helper.startsWith(this.formModel.TableNameReference, "_AttributeSet", true)) {
          this.apiProperties = Api.AttributeSetAttribute();
        } else {
          this.apiProperties = this.docsService.findApiProperties(Helper.getFirstDefinedString(this.formModel.ApiNameReference, this.formModel.TableNameReference.replace("YYYYMM", "").replace("YYYY", "")));
        }
        // Build the editor options and table options
        this.buildEditorOptions();
        this.buildTableOptions();
        this.applyAttributeSettings();
        // If our route had an id in it then load the data associated with that id
        if (this.routeParamId) {
          this.editLoadData(this.routeParamId, -1);
        }
      } else {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
      }
    });
  }


  protected buildEditorOptions() {

    this.editorOptions = this.getDefaultEditorOptions();

    if (this.allowAnonymousAccess) {
      // Allowing anonymous access
      this.editorOptions.requiresContactType = ""; // No required contact type
    } else {
      // Not allowing anonymous access
      this.editorOptions.requiresContactType = Constants.ContactType.Directory;
      this.editorOptions.accessArea = Constants.AccessArea.None;
    }

    this.editorOptions.objectTemplate = FormHelper.buildFormDataObject(this.formModel);
    // Dynamic objects have data stored under a property matching the table name reference.
    this.editorOptions.objectEnvelopePropertyName = Helper.getFirstDefinedString(this.formModel.TableNameReference, this.apiProperties.id);

    // With updated objectTemplate we need to refresh our add data object when in add mode
    if (this.mode === "add") {
      this.addData = this.getNewDataObject();
    }

  }


  protected buildTableOptions() {

    this.tableOptions = this.getDefaultTableOptions();
    this.tableOptions.columns = [];

    this.formModel.Groups.forEach(group => {
      if (Helper.equals(group.GroupScope, "L", true)) {
        group.Controls.forEach(control => {
          this.tableOptions.columns.push(new TableColumnOptions(control.PropertyName, control.Label, control.PropertyDataType.toLowerCase(), control.OptionsPickListId));
          let col = this.tableOptions.columns.slice(-1)[0];
          col.sortable = control.Sortable;
          col.resizable = control.Resizable;
          col.wrap = control.Wrap;
          col.allowLineBreaks = control.AllowLineBreaks;
          col.allowHtml = control.AllowMarkUp;
          col.includeInGlobalFilter = control.IncludeInGlobalFilter;
          col.filterType = <any>control.FilterType.toLowerCase();
          col.filterMatchMode = control.FilterMatchMode;
          col.filterPickListId = control.FilterPickListId;
        });
      }
    });

  }

  protected applyAttributeSettings() {

    // If this is for an attribute set do some extra config
    if (!Helper.startsWith(this.formModel.TableNameReference, "_AttributeSet", true)) {
      // Not an attribute set
      return;
    }

    // Get our attributeSetId
    const attributeSetId: number = parseInt(this.formModel.TableNameReference.replace("_AttributeSet", ""), 10);

    // Set scope
    this.scope = { AttributeSetId: attributeSetId };
    //console.error("attribute set scope", this.scope);

    // Load the attribute set if we don't already have it
    if (!this.attributeSet || this.attributeSet.AttributeSetId !== attributeSetId) {
      const attribSetApiProp: ApiProperties = Api.AttributeSet();
      const attribSetApiCall: ApiCall = ApiHelper.createApiCall(attribSetApiProp, ApiOperationType.Get);
      this.apiService.execute(attribSetApiCall, attributeSetId).subscribe((result: IApiResponseWrapperTyped<m5.AttributeSetEditViewModel>) => {
        if (result.Data.Success) {
          // Save attribute set
          this.attributeSet = result.Data.Data;
          // Set security area if we have one defined
          if (this.attributeSet.SecurityArea) {
            this.editorOptions.accessArea = this.attributeSet.SecurityArea;
          }
          // Look for a description attribute
          const descriptions = this.attributeSet.Details.filter(x => x.DescriptionAttribute);
          if (descriptions && descriptions.length > 0) {
            this.apiProperties.documentation.objectDescriptionPropertyNames = descriptions.map(x => x.AttributeName);
          }
        } else {
          console.error(result);
        }
      });
    }

    // Setup editor options
    const description = this.formModel.Description;
    this.editorOptions.objectFriendlyName = description;
    this.editorOptions.headerTextListMode = Helper.plural(description, 2);
    this.editorOptions.headerTextAddMode = `New ${description}`;
    this.editorOptions.headerTextEditModePrefix = `${description} - `;

    // Adjust table options
    this.tableOptions.actionButtonRight1.label = `Add ${description}`;

  }


  protected buildRoutes() {
    if (!this.routeModel) {
      return;
    }
    this.baseRoute = this.routeModel.RouteUrl;
    this.listRoute = this.baseRoute;
    this.addRoute = this.baseRoute + "/add";
    this.editRouteBase = this.baseRoute + "/edit";
    this.viewRouteBase = this.baseRoute + "/view";
  }


  protected parseRoute() {

    // Determine editor mode from the url
    if (Helper.contains(location.href, "/add", true)) {
      this.mode = "add";
    } else if (Helper.contains(location.href, "/edit", true)) {
      this.mode = "edit";
    } else if (Helper.contains(location.href, "/view", true)) {
      this.mode = "view";
    } else {
      this.mode = "list";
    }

    // If in edit or view mode then try to parse id and name from the url
    if (Helper.contains(location.href, "/edit", true) || Helper.contains(location.href, "/view", true)) {
      const parts: string[] = location.href.split("/");
      //console.error(parts);
      let index = parts.findIndex(x => Helper.equals(x, "edit", true));
      if (index === -1) {
        index = parts.findIndex(x => Helper.equals(x, "view", true));
      }
      //console.error("edit index", index, parts);
      if (index > -1) {
        try {
          const id = parts[index + 1];
          this.routeParamId = parseInt(id.toString(), 10);
          const name = parts[index + 2];
          //console.error("route params", "id", id, "name", name);
          this.routeParamName = name || "";
          this.routeParamFriendlyName = Helper.toTitleCase(Helper.replaceAll(name, "-", " "));
          // If our route had an id in it then load the data associated with that id
          if (this.routeParamId) {
            this.editLoadData(this.routeParamId, -1);
          }
        } catch (err) {
          console.error(err);
        }
      }
    }

    //console.error("parse route", location.href, this.mode, this.routeParamId, this.routeParamName, this.editData, this.addData);

  }

  //protected onEditLoadSuccess() {
  //  super.onEditLoadSuccess();
  //  // Dynamic forms can have multiple object references so move editData that was just loaded to a child property.
  //  let obj = Helper.deepCopy(this.editorOptions.objectTemplate);
  //  obj[this.apiProperties.id] = this.editData;
  //  this.editData = obj;
  //  console.error(this.editData);
  //}

}
