<div class="row mt-5">
  <div class="{{Constants.Layout.split4column}}"></div>
  <div class="{{Constants.Layout.split2column}}">
    <div class="login-panel card card-light">
      <div class="card-header">
        <h4 class="card-title mt-0 mb-0">Please Sign In</h4>
      </div>
      <div class="card-body">
        <form class="form-horizontal"
              role="form">
          <fieldset>
            <ib-input-select name="Api Host"
                             label="API Host"
                             [(ngModel)]="apiCall.baseUrl"
                             [optionsIncludeNone]="false"
                             [optionsPickList]="apiHostPickList"
                             (change)="onSelectApiHost($event)"></ib-input-select>
            <div class="alert alert-danger mt-3"
                 role="alert"
                 [hidden]="!apiService.selectedHost.isProduction">
              <strong>Warning:</strong> This is using your <strong>production</strong> environment. Actions taken while testing the API
              in a <strong>production</strong> environment are functionally the same as a live user performing the related action in the application.
            </div>
            <ib-input-wrapper label=" ">
              Please provide an API Key or Login and Password that is valid for the specified API host.
            </ib-input-wrapper>
            <ib-input-text name="API Key"
                           label="API Key"
                           [(ngModel)]="apiCall.apiKey"></ib-input-text>
            <ib-input-wrapper label=" ">
              OR
            </ib-input-wrapper>
            <ib-input-text name="Login"
                           label="Login"
                           [(ngModel)]="data.Login"></ib-input-text>
            <ib-input-text name="Password"
                           label="Password"
                           type="password"
                           [(ngModel)]="data.Password"></ib-input-text>
            <ib-input-checkbox name="Remember Me"
                               label="Remember Me"
                               [(ngModel)]="data.RememberMe"></ib-input-checkbox>
            <ib-button label="Login"
                       color="success"
                       size="large"
                       [block]="true"
                       (click)="login($event)"></ib-button>
            <div class="alert alert-danger mt-3"
                 role="alert"
                 [hidden]="!apiService.selectedHost.isProduction">
              <strong>Warning:</strong> This is using your <strong>production</strong> environment. Actions taken while testing the API
              in a <strong>production</strong> environment are functionally the same as a live user performing the related action in the application.
            </div>
          </fieldset>
        </form>
        <div [hidden]="!errorMessage">
          <div class="alert alert-danger mt-2"
               role="alert">
            {{errorMessage}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="{{Constants.Layout.split4column}}"></div>
</div>
