import * as Enumerable from "linq";

import { Helper, Log } from "projects/core-lib/src/lib/helpers/helper";

import { IApiResponseWrapper, ApiCall, ApiOperationType } from "projects/core-lib/src/lib/api/ApiModels";
import { ButtonRole, ButtonItem } from "projects/common-lib/src/lib/ux-models";
import { ModalCommonOptions } from "../modal/modal-common-options";
import { Router } from '@angular/router';

/**
This class manages alerts that are displayed to the user.  This should be instantiated just one
time in the app so it maintains the list.  Typically we attach this to our AppService.
*/
export class AlertManager {

  public alerts: AlertItem[] = [];


  constructor(private router?: Router) {
    if (router) {
      router.events.subscribe(() => {
        this.routeNavigation();
      });
    }
  }


  /**
  Clears the alert list.
  */
  public clear() {
    this.alerts = [];
  }

  /**
  Close the specified alert.
  */
  public close(alertId: string) {
    this.alerts = this.alerts.filter(x => x.id !== alertId);
  }

  /**
  Removes alerts that can close on route navigation
  */
  public routeNavigation() {
    this.alerts = this.alerts.filter(x => !x.dismissOnRouteNavigation);
  }

  /**
  Adds an item to the alert list.  To the degree possible we avoid letting duplicates in the list.
  @param {AlertItem} alert The alert item object to add to our alerts.
  */
  public add = (alert: AlertItem): void => {

    // Sanitize the alert
    if (!alert.id) {
      alert.id = Helper.createBase36Guid();
    }
    if (!alert.type) {
      alert.type = AlertItemType.Info;
    }

    // Try to avoid duplicate alerts
    // First look for an id match and, if found, update our alert as the message may have morphed in scenarios like form validation
    let match = this.alerts.filter(x => x.id === alert.id);
    if (match && match.length > 0) {
      match[0].type = alert.type;
      match[0].title = alert.title;
      match[0].message = alert.message;
      match[0].buttons = alert.buttons;
      return;
    }
    // Last duplicate check effort
    if (Enumerable.from(this.alerts).any(x => x.id === alert.id || x.message === alert.message)) {
      // Duplicate alert so don't add it
      return;
    }

    // Add the alert to the list
    this.alerts.push(alert);

    // If we have auto timeout then set that
    if (alert.timeoutSeconds) {
      setTimeout(() => {
        this.alerts = Enumerable.from(this.alerts).where(x => x.id !== alert.id).toArray();
      }, (alert.timeoutSeconds * 1000));
    }

    // For danger or warning alerts position our window
    // to the top of the page so the message can be seen.
    if (alert.type === AlertItemType.Danger || alert.type === AlertItemType.Warning) {
      Helper.scrollToTop();
    }

  }

  /***
  Adds an alert message.
  @param type - success, info, danger, warning
  */
  public addAlertMessage = (type: AlertItemType, message: string, timeoutSeconds: number = 0, dismissOnRouteNavigation: boolean = true): void => {
    // Make sure the timeout wasn't specified in milliseconds like we did in legacy code
    if (timeoutSeconds >= 1000) {
      timeoutSeconds = (timeoutSeconds / 1000);
    }
    const alert: AlertItem = new AlertItem();
    alert.type = type;
    alert.message = message;
    alert.timeoutSeconds = timeoutSeconds;
    alert.dismissOnRouteNavigation = dismissOnRouteNavigation;
    this.add(alert);
  }

  /**
  Adds an alert based on an api response and api call.
  @param {m5.IResponse} response The api response object.
  @param {ApiCall} api The api call object that triggered the response.
  @param {ButtonItem[]} buttons An optional collection of buttons that might be used in the
  alert.  Note that only buttons matching the role that this method determines is appropriate
  will be included.  To force a button to be included the role should be set to All.
  @param {number} timeoutSeconds An optional number of seconds to use for the timeout on the
  alert.  If not specified this method will pick a number that it feels is appropriate for
  the scenario.
  */
  public addAlertFromApiResponse = (response: IApiResponseWrapper, api: ApiCall, buttons?: ButtonItem[], timeoutSeconds?: number, objectDescription?: string, dismissOnRouteNavigation: boolean = true): void => {
    let alert: AlertItem = new AlertItem();
    if (api) {
      alert.id = api.id;
    }
    if (buttons && buttons.length > 0) {
      alert.buttons = Enumerable.from(buttons).where(x => x.role === ButtonRole.All).toArray();
    }

    // Success is easier than failure
    if (response.Data.Success) {
      alert.type = AlertItemType.Success;
      alert.timeoutSeconds = timeoutSeconds || 3;
      if (api) {
        if (api.type === ApiOperationType.Add || api.type === ApiOperationType.Edit) {
          alert.message = "Successfully saved.";
        } else if (api.type === ApiOperationType.Delete) {
          alert.message = "Successfully deleted.";
        } else if (api.type === ApiOperationType.Copy) {
          alert.message = "Successfully copied.";
        } else if (api.type === ApiOperationType.Call) {
          alert.message = "Request was successful.";
        } else {
          // Read operations don't need an alert... we can see it was successful when data is displayed.
          return;
        }
      } else {
        alert.message = "Success";
      }
      alert.dismissOnRouteNavigation = dismissOnRouteNavigation;
      // Add the success alert
      this.add(alert);
      return;
    }

    // For errors that are authentication errors due to things like expired tokens don't need to be displayed if
    // we got redirected to the login page as a result.  The fact that we're on the login page sends the message.
    if (response.Data.ResultCode === 1501 || response.Data.ResultCode === 1511 || response.Data.ResultCode === 1512 || response.Data.ResultCode === 1513) {
      if (Helper.contains(location.href, "login", true)) {
        return;
      }
    }

    // Failure will get some tweaking as needed for different failure types
    alert.type = AlertItemType.Danger;
    alert.timeoutSeconds = timeoutSeconds || 0;

    if (!objectDescription) {
      if (api) {
        objectDescription = api.objectShortDescription.toLowerCase();
      } else {
        objectDescription = "object";
      }
    }
    let title: string = `Error with ${objectDescription}`;
    if (api) {
      title = `Error during ${api.typeName.toLowerCase()} ${objectDescription}`;
      if (api.type === ApiOperationType.Add || api.type === ApiOperationType.Edit || api.type === ApiOperationType.Merge) {
        title = `Error saving ${objectDescription}`;
      } else if (api.type === ApiOperationType.Delete) {
        title = `Error deleting ${objectDescription}`;
      } else if (api.type === ApiOperationType.Copy) {
        title = `Error copying ${objectDescription}`;
      } else if (api.type === ApiOperationType.Call) {
        title = `Error with ${objectDescription} request`;
      } else if (api.type === ApiOperationType.List) {
        title = `Error loading ${objectDescription} list`;
      } else if (api.type === ApiOperationType.Report) {
        title = `Error loading ${objectDescription} report`;
      } else if (api.type === ApiOperationType.Get) {
        title = `Error loading ${objectDescription}`;
      }
    }

    // Handle some specific scenarios
    if (response.Data.ResultCode === 1611) {
      let error: string = response.Data.Message;
      error = `<p>After loading this ${objectDescription} one or more users loaded and modified this same ${objectDescription}.  `;
      error += `We can't save your changes because they would overwrite the changes made by others.  `;
      if (response.Data.Meta && response.Data.Meta.VersionConflictInformation) {
        error += `This ${objectDescription} was last modified by ${response.Data.Meta.VersionConflictInformation.LastEditedByContactName} ${Helper.formatDateTime(response.Data.Meta.VersionConflictInformation.LastEditedDateTime, "[on] MMM D, YYYY [at] h:mm a ([ago])")}.`;
        error += "</p><ul style='max-height: 100px; overflow: auto'>";
        response.Data.Meta.VersionConflictInformation.Conflicts.forEach((conflict, index, conflicts) => {
          let serverValue = conflict.DataStoreValue;
          if (serverValue !== undefined && serverValue !== null) {
            if (conflict.Property.indexOf("Date") > -1) {
              serverValue = Helper.formatDateTime(serverValue, "MMM D, YYYY h:mm a");
            } else if (typeof serverValue === "number" || typeof serverValue === "boolean") {
              serverValue = serverValue.toString();
            } else {
              serverValue = Helper.left(serverValue, 150, true);
            }
          } else {
            serverValue = "empty";
          }
          let clientValue = conflict.SubmittedValue;
          if (clientValue !== undefined && clientValue !== null) {
            if (conflict.Property.indexOf("Date") > -1) {
              clientValue = Helper.formatDateTime(clientValue, "MMM D, YYYY h:mm a");
            } else if (typeof clientValue === "number" || typeof clientValue === "boolean") {
              clientValue = clientValue.toString();
            } else {
              clientValue = Helper.left(clientValue, 150, true);
            }
          } else {
            clientValue = "empty";
          }
          error += `<li><strong>${conflict.Description}</strong> is currently set to <strong>${serverValue}</strong> and you are trying to change it to <strong>${clientValue}</strong>.</li>`;
        });
        error += "</ul>";
      }
      alert.title = `${title}!`;
      alert.message = error;
      alert.buttons = Enumerable.from(buttons).where(x => x.role === ButtonRole.All || x.role === ButtonRole.VersionConflict).toArray();
      alert.dismissOnRouteNavigation = dismissOnRouteNavigation;
      this.add(alert);
      return;
    }

    // If we're still here then there was no special handling of our scenario so add a standard alert of the failure
    alert.message = `${title}: ${response.Data.Message}`;
    alert.dismissOnRouteNavigation = dismissOnRouteNavigation;
    this.add(alert);
    return;

  }

  /**
  This helper constructs common buttons to use when there is a version conflict error.  This provides a common
  UI experience for the user when this scenario is encountered.
  */
  public getButtonsForVersionConflict = (api: ApiCall, objectDisplayName: string, saveAction: () => any, discardAction: () => any): ButtonItem[] => {
    var buttons: ButtonItem[] = [];
    buttons.push(new ButtonItem("Save My Changes", "exclamation-triangle", "danger", () => {
      //let save = () => {
      //  this.close(api.id); // alert id is the same as api call id.
      //  api.overwriteChanges = true; // the caller might have this in their saveAction function but just in case we do it here as well
      //  saveAction(); // the save action should then resubmit the api call or do whatever the caller thinks is appropriate in this scenario
      //};
      //this.modal.confirmWarningYesNo(`Overwrite ${objectDisplayName}?`, `If you save your changes the changes made by others since you loaded ${objectDisplayName} will be overwritten.  Are you sure you want to do that?`, save);
      this.close(api.id); // alert id is the same as api call id.
      api.overwriteChanges = true; // the caller might have this in their saveAction function but just in case we do it here as well
      saveAction(); // the save action should then resubmit the api call or do whatever the caller thinks is appropriate in this scenario
    }, ButtonRole.VersionConflict));
    buttons.push(new ButtonItem("Discard My Changes", "exclamation-triangle", "warning", () => {
      //let discard = () => {
      //  this.close(api.id); // alert id is the same as api call id.
      //  discardAction(); // the discard action should then reload the data into the form or do whatever the caller thinks is appropriate in this scenario
      //};
      //this.modal.confirmWarningYesNo(`Discard ${objectDisplayName}?`, `If you discard your changes the changes made by others will be loaded and any changes you made will be lost.  Are you sure you want to do that?`, discard);
      this.close(api.id); // alert id is the same as api call id.
      discardAction(); // the discard action should then reload the data into the form or do whatever the caller thinks is appropriate in this scenario
    }, ButtonRole.VersionConflict));
    return buttons;
  }

}


/**
A class of recently used items including an id, a label, and a url to access the item.
*/
export class AlertItem {

  /**
  Duplicate alert messages are suppressed but in some cases the message is slightly tweaked and,
  therefore, unable to be detected as a duplicate.  This id can be used as another way of
  avoiding duplicate messages from being presented.  When messages are generated from an
  api call this id is the id of the api call.  It is also used to remove alerts from the list
  when an alert is closed so it should not be left undefined.
  */
  id: string = Helper.createBase36Guid();

  /**
  If the alert has a title it is specified here.
  */
  title: string = "";

  /**
  The alert message.  This may contain html and needs to be rendered as such.
  */
  message: string = "";

  /**
  The alert type.  Possible values include: success, info, danger, warning, primary, secondary, light, dark.
  */
  type: AlertItemType = AlertItemType.Info;
  get typeAsString(): string {
    return AlertItemType[this.type];
  }
  set typeAsString(type: string) {
    if (Helper.equals(type, "primary", true)) {
      this.type = AlertItemType.Primary;
    } else if (Helper.equals(type, "secondary", true)) {
      this.type = AlertItemType.Secondary;
    } else if (Helper.equals(type, "success", true)) {
      this.type = AlertItemType.Success;
    } else if (Helper.equals(type, "warning", true)) {
      this.type = AlertItemType.Warning;
    } else if (Helper.equals(type, "danger", true)) {
      this.type = AlertItemType.Danger;
    } else if (Helper.equals(type, "info", true)) {
      this.type = AlertItemType.Info;
    } else if (Helper.equals(type, "light", true)) {
      this.type = AlertItemType.Light;
    } else if (Helper.equals(type, "dark", true)) {
      this.type = AlertItemType.Dark;
    }
  }

  /**
   * We typically want the user to be able to dismiss the alert but in some rare cases like offline status or
   * new version reload message we don't want the user to be able to hide the message.
   */
  canClose: boolean = true;

  /**
  The timeout in seconds for the alert.  A value of 0 means there is no timeout and the alert
  will be displayed until it is dismissed manually or otherwise cleared programatically.
  */
  timeoutSeconds: number = 0;

  /**
   * Not all errors can auto-dismiss since some trigger route navigation for things like auth error
   * redirecting to login page or object not found error redirecting to object list, etc.
   * Flag indicates if an error can be auto-dismissed.
   */
  dismissOnRouteNavigation: boolean = true;

  /**
  A collection of buttons to use for this alert.
  */
  buttons: ButtonItem[] = [];

}

export enum AlertItemType {
  Success = 1, // 0 is falsy so not a fan of that as enum value in js world
  Info,
  Danger,
  Warning,
  Primary,
  Secondary,
  Light,
  Dark
}

