import * as Enumerable from "linq";
import * as Handlerbars from 'handlebars';

import * as Constants from "projects/core-lib/src/lib/helpers/constants";

declare var AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";

import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { Routes } from "@angular/router";
import { CanDeactivateGuard } from "projects/core-lib/src/lib/services/can-deactivate-guard.service";
import { Dictionary } from "projects/core-lib/src/lib/models/dictionary";
import { Log, Helper } from "projects/core-lib/src/lib/helpers/helper";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MissingTranslationHandler, MissingTranslationHandlerParams } from "@ngx-translate/core";
import { ApiService } from "projects/core-lib/src/lib/api/api.service";
import { AppService } from "projects/core-lib/src/lib/services/app.service";
import { ApiProperties, ApiCall, ApiOperationType, IApiResponseWrapperTyped } from "projects/core-lib/src/lib/api/ApiModels";
import { ApiHelper } from "projects/core-lib/src/lib/api/ApiHelper";
import { Injectable, Inject, Injector } from "@angular/core";
import { HandlebarsHelpers } from "projects/core-lib/src/lib/helpers/handlebars-helpers";
import { ApiModuleCore } from "../api/Api.Module.Core";

@Injectable({
  providedIn: 'root'
})
export class ApiMissingTranslationHandler extends MissingTranslationHandler {

  protected apiProp: ApiProperties;
  protected apiCall: ApiCall;
  protected submittedCache: Dictionary<boolean> = new Dictionary<boolean>();

  constructor(
    protected apiService: ApiService) {

    super();

    this.apiProp = ApiModuleCore.TranslationAddMissing();
    this.apiCall = ApiHelper.createApiCall(this.apiProp, ApiOperationType.Add);
    this.apiCall.silent = true;

  }

  handle(params: MissingTranslationHandlerParams) {

    //console.error(params);

    // Try catch so we always return the English text that we use for our translation key.
    try {

      // If we're not looking to report missing translations then we're done
      if (!AppConfig.languageReportMissingTranslations) {
        return this.valueToUse(params);
      }

      // See if we've already attempted adding this value otherwise we can grind to a halt on multiple
      // add missing translation attempts.
      if (this.submittedCache.containsKey(`${params.translateService.currentLang}-${params.key}`)) {
        return this.valueToUse(params);
      } else {
        this.submittedCache.add(`${params.translateService.currentLang}-${params.key}`, true);
      }

      // Submit this missing translation to our API in the background as silent
      // api call.  Even for English we may not have a "translation" as this is
      // how we register a new line of text that needs to be translated.
      //console.error("report missing translation", "current lang", params.translateService.currentLang, "default lang", params.translateService.defaultLang);
      let model = new m5core.TranslationAddMissingViewModel();
      model.NativeText = params.key;
      model.DesiredLanguage = params.translateService.currentLang;
      model.Scope = "UI";
      // We don't really need the result here but it won't execute without a subscriber.
      this.apiService.execute(this.apiCall, model).subscribe((result: IApiResponseWrapperTyped<m5core.TranslationEditViewModel>) => {
        if (!result.Data.Success) {
          if (result.Data.ResultCode === 1711) {
            Log.warningMessage(`Text missing from json but already in translation table for ${model.DesiredLanguage}: ${model.NativeText}`);
          } else {
            Log.errorMessage(result);
          }
        }
      });

    } catch (err) {
      Log.errorMessage(err);
    }

    // Since we're using the English text as our key we can just return that value
    // here to get the English text returned as our translation.
    return this.valueToUse(params);

  }


  valueToUse(params: MissingTranslationHandlerParams): string {

    try {
      if (Helper.contains(params.key, "{{")) {
        let value = params.translateService.parser.interpolate(params.key, params.interpolateParams);
        //console.error("interpolate", params.key, params.interpolateParams, value);
        return value;
      } else {
        return params.key;
      }
    } catch (err) {
      Log.errorMessage(err);
    }

    return params.key;

  }

}


// AoT requires an exported function for factories
// default loads from './assets/i18n/[lang].json'
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
//export function createTranslateLoader(http: HttpClient) {
//  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
//}
