// Import the core angular services.
import { Inject, Injectable } from "@angular/core";
import { InjectionToken } from "@angular/core";
import { Log, Helper } from "projects/core-lib/src/lib/helpers/helper";

// ----------------------------------------------------------------------------------- //
// See: https://www.bennadel.com/blog/3408-creating-a-dynamic-favicon-service-in-angular-5-2-4.htm
// ----------------------------------------------------------------------------------- //


export interface FaviconsConfig {
  icons: IconsConfig;
  cacheBusting?: boolean;
}

export interface IconsConfig {
  [name: string]: IconConfig;
}

export interface IconConfig {
  type: string;
  href: string;
  isDefault?: boolean;
}

export var BROWSER_FAVICONS_CONFIG = new InjectionToken<FaviconsConfig>("Favicons Configuration");

// This abstract class acts as both the interface for implementation (for any developer
// that wants to create an alternate implementation) and as the dependency-injection
// token that the rest of the application can use.
export abstract class Favicons {
  abstract activate(name: string): boolean;
  abstract reset(): void;
  abstract setCustomIcon(url: string, type: "icon" | "png" | "jpg"): void;
}

// I provide the browser-oriented implementation of the Favicons class.
@Injectable()
export class BrowserFavicons implements Favicons {

  private elementIdForFavIcon: string;
  private icons: IconsConfig;
  private useCacheBusting: boolean;

  // I initialize the Favicons service.
  constructor(@Inject(BROWSER_FAVICONS_CONFIG) config: FaviconsConfig) {

    // Exceptions inside constructors for classes that get injected cause hard to
    // trace errors so try/catch to make sure any errors are clearly reported.
    try {

      this.elementIdForFavIcon = "favicon-favicons-service-injected-node";
      this.icons = Object.assign(Object.create(null), config.icons);
      this.useCacheBusting = (config.cacheBusting || false);

      // Since the document may have a static favicon definition, we want to strip out
      // any existing elements that are attempting to define a favicon. This way, there
      // is only one favicon element on the page at a time.
      this.removeExternalLinkElements();

    } catch (err) {
      Log.errorMessage("Error in FavIcons service constructor.");
      Log.errorMessage(err);
    }

  }

  // ---
  // PUBLIC METHODS.
  // ---

  // I activate the favicon with the given name / identifier.
  public activate(name: string): boolean {

    // TODO note that this will not work for any icon names not in our list.  For custom icon we may
    // need to expand this.  We support "custom" as a name but that won't work for multiple custom
    // icons in a multi-tenant hosted scenario.

    if (!this.icons[name]) {
      Log.errorMessage(new Error(`Favicon for [ ${name} ] not found.`));
      return false;
    }

    this.setNode(this.icons[name].type, this.icons[name].href);
    return true;

  }


  public setCustomIcon(url: string, type: "icon" | "png" | "jpg") {

    if (!url) {
      return;
    }

    // image/x-icon or image/png or image/jpeg
    let fileType = "image/x-icon";
    if (Helper.equals(type, "icon", true)) {
      fileType = "image/x-icon";
    } else if (Helper.equals(type, "png", true)) {
      fileType = "image/png";
    } else if (Helper.equals(type, "jpg", true)) {
      fileType = "image/jpeg";
    } else if (Helper.endsWith(url, "ico", true)) {
      fileType = "image/x-icon";
    } else if (Helper.endsWith(url, "png", true)) {
      fileType = "image/png";
    } else if (Helper.endsWith(url, "jpg", true)) {
      fileType = "image/jpeg";
    } else if (Helper.endsWith(url, "jpg", true)) {
      fileType = "image/jpeg";
    } else {
      Log.errorMessage(`Unexpected icon type ${type} (expected types: icon, png, jpg) and unable to determine from url so defaulting to icon.`);
      fileType = "image/x-icon";
    }

    this.setNode(fileType, url);

  }


  // I activate the default favicon (with isDefault set to True).
  public reset(): void {

    for (const name of Object.keys(this.icons)) {

      const icon = this.icons[name];

      if (icon.isDefault) {

        this.setNode(icon.type, icon.href);
        return;

      }

    }

    // If we made it this far, none of the favicons were flagged as default. In that
    // case, let's just remove the favicon node altogether.
    this.removeNode();

  }

  // ---
  // PRIVATE METHODS.
  // ---

  // I inject the favicon element into the document header.
  private addNode(type: string, href: string): void {

    const linkElement = document.createElement("link");
    linkElement.setAttribute("id", this.elementIdForFavIcon);
    linkElement.setAttribute("rel", "icon");
    linkElement.setAttribute("type", type);
    linkElement.setAttribute("href", href);
    document.head.appendChild(linkElement);

  }


  // I return an augmented HREF value with a cache-busting query-string parameter.
  private cacheBustHref(href: string): string {

    const augmentedHref = (href.indexOf("?") === -1)
      ? `${href}?faviconCacheBust=${Date.now()}`
      : `${href}&faviconCacheBust=${Date.now()}`
      ;

    return (augmentedHref);

  }


  // Remove any favicon nodes that are not controlled by this service.
  private removeExternalLinkElements(): void {

    let linkElements: NodeListOf<Element>;
    let selector: string = "#linkFavIcon";

    // First pass look for #linkFavIcon elements which works on any browser
    // but if we didn't use that id then fall back to "link[ rel ~= 'icon' i]"
    // selector which will fail in IE/Edge but it's all we have left w/o an id.

    try {
      linkElements = document.querySelectorAll(selector);
    } catch (err) {
      Log.errorMessage(`Error trying to get icon using selector "${selector}".`);
      Log.errorMessage(err);
    }

    if (!linkElements || linkElements.length === 0) {
      selector = "link[ rel ~= 'icon' i]";
      try {
        linkElements = document.querySelectorAll(selector);
      } catch (err) {
        // Error is expected with IE or Edge so don't report it
        if (!Helper.isBrowserIeOrEdgeLegacy()) {
          Log.errorMessage(`Error trying to get icon using selector "${selector}".`);
          Log.errorMessage(err);
        }
      }
    }

    if (linkElements) {
      for (const linkElement of Array.from(linkElements)) {
        try {
          linkElement.parentNode.removeChild(linkElement);
        } catch (err) {
          Log.errorMessage(`Error trying to remove icon element.`);
          Log.errorMessage(err);
        }
      }
    }

  }


  // I remove the favicon node from the document header.
  private removeNode(): void {

    const linkElement = document.head.querySelector("#" + this.elementIdForFavIcon);

    if (linkElement) {

      document.head.removeChild(linkElement);

    }

  }


  // I remove the existing favicon node and inject a new favicon node with the given
  // element settings.
  private setNode(type: string, href: string): void {

    const augmentedHref = this.useCacheBusting
      ? this.cacheBustHref(href)
      : href
      ;

    this.removeNode();
    this.addNode(type, augmentedHref);

  }

}




// Add this to @NgModule.providers
// The Favicons is an abstract class that represents the dependency-injection
// token and the API contract. THe BrowserFavicon is the browser-oriented
// implementation of the service.
export const FavIconProvider = {
  provide: Favicons,
  useClass: BrowserFavicons
};

// Add this to @NgModule.providers
// The BROWSER_FAVICONS_CONFIG sets up the favicon definitions for the browser-
// based implementation. This way, the rest of the application only needs to know
// the identifiers (i.e., "happy", "default") - it doesn't need to know the paths
// or the types. This allows the favicons to be modified independently without
// coupling too tightly to the rest of the code.
// Type: image/x-icon or image/png or image/jpeg
export const FavIconConfigProvider = {
  provide: BROWSER_FAVICONS_CONFIG,
  useValue: {
    icons: {
      "default": {
        type: "image/x-icon",
        href: "assets/favicon/default/favicon.ico",
        isDefault: true
      },
      "netwisecrm": {
        type: "image/x-icon",
        href: "assets/favicon/netwisecrm/favicon.ico",
      },
      "nubill": {
        type: "image/x-icon",
        href: "assets/favicon/nubill/favicon.ico",
      },
      "reportcompiler": {
        type: "image/x-icon",
        href: "assets/favicon/reportcompiler/favicon.ico",
      },
      "intelliboss": {
        type: "image/x-icon",
        href: "assets/favicon/intelliboss/favicon.ico",
      },
      "qupport": {
        type: "image/x-icon",
        href: "assets/favicon/qupport/favicon.ico",
      },
      "custom": {
        type: "image/x-icon",
        href: "assets/favicon/custom/favicon.ico",
      }
    },

    // I determine whether or not a random token is auto-appended to the HREF
    // values whenever an icon is injected into the document.
    cacheBusting: true
  }
};

