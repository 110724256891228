import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from "moment";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";

@Injectable({
  providedIn: 'root'
})
export class NgbDateIsoStringAdapterService extends NgbDateAdapter<string> {

  constructor() {
    super();
  }

  // const date: NgbDateStruct = { day: 14, month: 7, year: 1789 }; // July, 14 1789

  // from your model -> internal model
  /**
   * Convert from ISO date time string to NgbDateStruct object.
   * @param value
   */
  fromModel(value: string): NgbDateStruct {
    if (!value) {
      return null;
    }
    let m = moment(value);
    return { year: m.year(), month: m.month() + 1, day: m.date() };
  }

  // from internal model -> your mode
  /**
   * Convert from NgbDateStruct object to ISO date time string
   * @param date
   */
  toModel(date: NgbDateStruct): string {
    if (!date) {
      return null;
    }
    let m = moment();
    m.year(date.year);
    m.month(date.month - 1);
    m.date(date.day);
    m.hour(12); // go mid-day to avoid timezone shifts of date.  Inside input control the time gets saved separately as it's separate inputs.
    return m.format(Constants.DateFormat.MomentIsoDateTime)
  }

}
