<div class="loading-container">
  <ib-standard-table [options]="tableOptions"
                     [data]="data"
                     [reloadCount]="tableReloadCount"
                     (rowSelect)="onRowSelected($event)">
  </ib-standard-table>
  <ngx-loading [show]="loading"
               [config]="{animationType: 'none'}"
               [template]="loadingTemplate"></ngx-loading>
  <ng-template #loadingTemplate>
    <div class="loading-template">
      <h3><i class="{{Constants.Layout.loadingIcon}}"></i>&nbsp;&nbsp;{{Constants.Layout.loadingText}}</h3>
    </div>
  </ng-template>
</div>
