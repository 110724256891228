import { Injectable } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import moment from "moment";
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { BaseService } from './base.service';
import { ApiService } from '../api/api.service';
import { AppService } from './app.service';
import { AppCacheService } from './app-cache.service';
import { Router } from '@angular/router';
import { StaticPickList } from '../models/model-helpers';

@Injectable({
  providedIn: 'root'
})
export class QueryService extends BaseService {

  constructor(
    protected apiService: ApiService,
    protected appService: AppService,
    protected cache: AppCacheService,
    protected sanitizer: DomSanitizer,
    protected router: Router) {

    super();

  }

  public buildExecutionRequest(query: m5.QueryEditViewModel, defaults: m5.QueryExecutionRequestOptionsViewModel): m5.QueryExecutionRequestOptionsViewModel {

    const request = new m5.QueryExecutionRequestOptionsViewModel();
    request.Variables = {};

    if (query) {
      request.QueryId = query.QueryId;
      if (query.Variables && (query.Variables.DateType === m5.QueryDateType.DateRange || query.Variables.DateType === m5.QueryDateType.DateTimeRange)) {
        request.DateRange = "LAST WEEK";
      }
      if (query.Variables && query.Variables.Variables) {
        query.Variables.Variables.forEach((value) => {
          request.Variables[value.PropertyName] = value.DefaultValue;
        });
      }
    }

    if (defaults) {
      request.FileTypes = defaults.FileTypes;
      if (defaults.DateRange) {
        request.DateRange = defaults.DateRange;
        if (Helper.equals(defaults.DateRange, "CUSTOM", true)) {
          request.BeginningDateTime = defaults.BeginningDateTime;
          request.EndingDateTime = defaults.EndingDateTime;
        }
      }
      request.AsOfDateTime = defaults.AsOfDateTime;
      request.IncludeCoverPage = defaults.IncludeCoverPage;
      request.IncludeRowShading = defaults.IncludeRowShading;
      request.IncludeDailySubtotal = defaults.IncludeDailySubtotal;
      request.IncludeCharts = defaults.IncludeCharts;
      request.ReinitializeSummaryData = defaults.ReinitializeSummaryData;
      request.UploadTargets = defaults.UploadTargets || [];
      request.EmailMessageAssetId = defaults.EmailMessageAssetId;
      request.EmailSubject = defaults.EmailSubject;
      request.EmailBody = defaults.EmailBody;
      request.EmailRecipients = defaults.EmailRecipients || [];
    }

    return request;

  }

  public getEventStatusIcon(event: m5.QueryEventViewModel): string {
    let icon: string = "question"; // unknown
    if (event) {
      if (event.ResultCode === m5.QueryEventStatus.Success) {
        icon = "check";
      } else if (event.ResultCode === m5.QueryEventStatus.Pending) {
        icon = "spinner fa-spin";
      } else if (event.ResultCode === m5.QueryEventStatus.Started) {
        icon = "spinner fa-spin";
      } else if (event.ResultCode === m5.QueryEventStatus.Querying) {
        icon = "database faa-tada animated";
      } else if (event.ResultCode === m5.QueryEventStatus.Exporting) {
        icon = "file-alt faa-shake animated";
      } else if (event.ResultCode === m5.QueryEventStatus.Delivering) {
        icon = "envelope faa-passing animated";
      } else {
        icon = "ban"; // error
      }
    }
    return icon;
  }

  public getEventStatusText(event: m5.QueryEventViewModel): string {
    if (!event) {
      return "Unknown";
    } else if (event.ResultMessage) {
      return event.ResultMessage;
    } else if (event.ResultCode === m5.QueryEventStatus.Success) {
      return `Success`;
    } else if (event.ResultCode === m5.QueryEventStatus.Pending) {
      return `Pending`;
    } else if (event.ResultCode === m5.QueryEventStatus.Started) {
      return `Started`;
    } else if (event.ResultCode === m5.QueryEventStatus.Querying) {
      return `Querying`;
    } else if (event.ResultCode === m5.QueryEventStatus.Exporting) {
      return `Exporting`;
    } else if (event.ResultCode === m5.QueryEventStatus.Delivering) {
      return `Delivering`;
    } else {
      return `Error`;
    }
  }

  public getEventFileIcon(file: m5.QueryEventFileViewModel): string {
    let icon: string = "download";
    if (file && file.FileRole === "C") {
      icon = "file"; // Control file
    } else if (file && file.FileRole === "U") {
      icon = "file-upload"; // Upload file
    } else if (file && file.FileRole === "R") {
      icon = "file-download"; // Raw data file
    } else if (file && file.FileType) {
      if (Helper.equals(file.FileType, "xlsx", true) || Helper.equals(file.FileType, "xls", true)) {
        icon = "file-excel";
      } else if (Helper.equals(file.FileType, "docx", true) || Helper.equals(file.FileType, "doc", true)) {
        icon = "file-word";
      } else if (Helper.equals(file.FileType, "pptx", true) || Helper.equals(file.FileType, "ppt", true)) {
        icon = "file-powerpoint";
      } else if (Helper.equals(file.FileType, "csv", true)) {
        icon = "file-csv";
      } else if (Helper.equals(file.FileType, "tsv", true)) {
        icon = "text:t over file";
      } else if (Helper.equals(file.FileType, "psv", true)) {
        icon = "text:| over file";
      } else if (Helper.equals(file.FileType, "txt", true)) {
        icon = "file";
      } else if (Helper.equals(file.FileType, "xml", true)) {
        icon = "file-code";
      } else if (Helper.equals(file.FileType, "pdf", true)) {
        icon = "file-pdf";
      } else if (Helper.equals(file.FileType, "json", true)) {
        icon = "text:{} over file";
      } else if (Helper.equals(file.FileType, "zip", true)) {
        icon = "file-archive";
      }
    }
    return icon;
  }

  public getEventFileDescriptionTooltipText(file: m5.QueryEventFileViewModel): string {
    if (!file) {
      return "";
    }
    let html: string = `<center><strong><em>Click to Download</em></strong></center>`;
    html += `<br/><strong>File Name:</strong> ${file.FriendlyName}`;
    if (file.FileRole === "C") {
      html += `<br/><strong>Control File:</strong> ${file.FileType}`;
    } else if (file.FileRole === "U") {
      html += `<br/><strong>Uploaded File:</strong> ${file.FileType}`;
    } else if (file.FileRole === "R") {
      html += `<br/><strong>Raw Data File:</strong> ${file.FileType}`;
    } else if (file.FileType) {
      html += `<br/><strong>File Type:</strong> ${file.FileType}`;
    }
    if (file.SizeRows !== undefined && file.SizeRows !== null) {
      // Can't just use truthy test as 0 is valid value
      html += `<br/><strong>Rows:</strong> ${file.SizeRows.toLocaleString()}`;
    }
    if (file.SizePages !== undefined && file.SizePages !== null) {
      // Can't just use truthy test as 0 is valid value
      html += `<br/><strong>Pages:</strong> ${file.SizePages.toLocaleString()}`;
    }
    if (file.SizeBytes) {
      html += `<br/><strong>Size:</strong> ${(file.SizeBytes / 1024).toFixed(1)} KB`;
    }
    if (file.HitCount) {
      html += `<br/><strong>Delivery Count:</strong> ${file.HitCount}`;
    }
    if (file.LastHitDateTime) {
      html += `<br/><strong>Last Delivery:</strong> ${file.LastHitDateTime}`;
    }
    return html;
  }

  public getEventDescriptionTooltipText(event: m5.QueryEventViewModel): string {
    if (!event) {
      return "";
    }
    let html: string = `<strong>Description:</strong> ${event.Description}`;
    const queryDescription: string = Helper.tryGetValue(event, x => x.Properties.Information.QueryDescription, null);
    if (queryDescription) {
      html += `<br/><strong>Query:</strong> ${queryDescription}`;
    }
    const filterDescription: string = Helper.tryGetValue(event, x => x.Properties.Information.FilterDescription, null);
    if (filterDescription) {
      html += `<br/><strong>Filter:</strong> ${filterDescription}`;
    }
    const dataSourceDescription: string = Helper.tryGetValue(event, x => x.Properties.Information.DataSourceDescription, null);
    if (dataSourceDescription) {
      html += `<br/><strong>Data Source:</strong> ${dataSourceDescription}`;
    }
    const outputDescription: string = Helper.tryGetValue(event, x => x.Properties.Information.OutputConfigurationDescription, null);
    if (outputDescription) {
      html += `<br/><strong>Configuration:</strong> ${outputDescription}`;
    }
    const templateDescription: string = Helper.tryGetValue(event, x => x.Properties.Information.ReportTemplateDescription, null);
    if (templateDescription) {
      html += `<br/><strong>Template:</strong> ${templateDescription}`;
    }
    if (event.BeginningDateTime && event.EndingDateTime) {
      html += `<br/><strong>Date Range:</strong> ${event.BeginningDateTime} - ${event.EndingDateTime}`;
    }
    if (event.AsOfDateTime) {
      html += `<br/><strong>As-Of:</strong> ${event.AsOfDateTime}`;
    }
    const variables: any = Helper.tryGetValue(event, x => x.Properties.Variables, null);
    if (variables && !Helper.isEmpty(variables)) {
      html += `<br/><br/><strong>Variables:</strong>`;
      const properties: string[] = Helper.objectGetPropertyNameList(variables);
      properties.forEach(property => {
        if (Helper.isObject(variables[property])) {
          html += `<br/>${property}: ${JSON.stringify(variables[property])}`;
        } else {
          html += `<br/>${property}: ${variables[property]}`;
        }
      });
    }
    return html;
  }




  public hasFilterConditions(filter: m5.FilterConditionGroupViewModel): boolean {
    if (!filter) {
      return false;
    }
    if (filter.Conditions && filter.Conditions.length > 0) {
      return true;
    }
    if (filter.Groups && filter.Groups.length > 0) {
      return true;
    }
    return false;
  }

  filterConditionIsContactId(condition: m5.FilterConditionViewModel) {
    if (!condition) {
      return false;
    }
    if (condition.DataType !== m.System.TypeCode.Int64 && condition.DataType !== m.System.TypeCode.Int32) {
      return false;
    }
    if (!Helper.endsWith(condition.PropertyName, "ContactId", true)) {
      return false;
    }
    return true;
  }

  public filterConditionsWireUpMeta(filter: m5.FilterConditionGroupViewModel, dataModel: any): void {
    if (!filter || !dataModel) {
      return;
    }
    // Step through any conditions for this filter and wire up meta for the property
    if (filter.Conditions) {
      filter.Conditions.forEach((condition) => {
        const col = Helper.firstOrDefault<any>(dataModel.Columns, x => Helper.equals(x.Name, condition.PropertyName, true));
        if (col) {
          // Stick some data in a meta object which we won't save to help with ux
          if (!(condition as any).Meta) {
            (condition as any).Meta = {};
          }
          //(condition as any).Meta.Column = col;
          (condition as any).Meta.Description = col.Notes;
          (condition as any).Meta.PickListOptions = StaticPickList.DataModelColumnOptions(col.Options);
          (condition as any).Meta.PickListId = col.PickListId;
        } else {
          Log.errorMessage(`Unable to find column named '${condition.PropertyName}' in object documentation for '${dataModel.Name}'.`);
        }
      });
    }
    // Step through any groups for this filter and make recursive calls
    if (filter.Groups) {
      filter.Groups.forEach((group) => {
        this.filterConditionsWireUpMeta(group, dataModel);
      });
    }
    return;
  }

  public filterConditionsWireUpMetaOne(filter: m5.FilterConditionGroupViewModel, dataModel: any, propertyName: string): void {
    if (!filter || !dataModel || !propertyName || !filter.Conditions) {
      return;
    }
    const col = Helper.firstOrDefault<any>(dataModel.Columns, x => Helper.equals(x.Name, propertyName, true));
    if (!col) {
      Log.errorMessage(`Unable to find column named '${propertyName}' in object documentation for '${dataModel.Name}'.`);
      return;
    }
    filter.Conditions.forEach((condition) => {
      if (Helper.equals(condition.PropertyName, propertyName, true)) {
        // Save our data type since the column selected may have a new data type
        condition.DataType = col.DataTypeCode;
        // Stick some data in a meta object which we won't save to help with ux
        if (!(condition as any).Meta) {
          (condition as any).Meta = {};
        }
        //(condition as any).Meta.Column = col;
        (condition as any).Meta.Description = col.Notes;
        (condition as any).Meta.PickListOptions = StaticPickList.DataModelColumnOptions(col.Options);
        (condition as any).Meta.PickListId = col.PickListId;
        // Save value meta data if needed
        if (condition.DataType === m.System.TypeCode.DateTime && !condition.ValueMetaData) {
          condition.ValueMetaData = "CUSTOM";
        } else if (this.filterConditionIsContactId(condition) && !condition.ValueMetaData) {
          condition.ValueMetaData = "Me";
        }
      }
    });
    return;
  }

  public filterConditionsPurgeMeta(filter: m5.FilterConditionGroupViewModel): void {
    if (!filter) {
      return;
    }
    // Step through any conditions for this filter and delete meta
    if (filter.Conditions) {
      filter.Conditions.forEach((condition) => {
        delete (condition as any).Meta;
      });
    }
    // Step through any groups for this filter and make recursive calls
    if (filter.Groups) {
      filter.Groups.forEach((group) => {
        this.filterConditionsPurgeMeta(group);
      });
    }
    return;
  }



  // TS versions of C# helpers in Sql.cs and FilterService.cs that we need to convert filter builder object tree
  // to filter string which we can submit with queries when the filter is not saved and, therefore, no FilterId
  // is available to submit.

  public buildFilterExpressionFromConditionModel(filter: m5.FilterConditionGroupViewModel): string {

    if (!filter) {
      return "";
    }

    let sql = "";
    const conditionsSql = this.buildFilterExpressionFromConditionModelList(filter.ConditionBooleanOperator, filter.Conditions);

    if (conditionsSql) {
      if (!filter.Groups || filter.Groups.length === 0) {
        sql += conditionsSql;
      }
      else {
        sql += `( ${conditionsSql} )`;
      }
    }

    if (filter.Groups && filter.Groups.length > 0) {
      filter.Groups.forEach((group) => {
        const groupSql = this.buildFilterExpressionFromConditionModel(group);
        if (sql && groupSql) {
          sql += ` ${Helper.getFirstDefinedString(filter.GroupBooleanOperator, "And").toUpperCase()} `;
        }
        if (groupSql) {
          sql += `( ${groupSql} )`;
        }
      });
    }

    return sql;

  }

  public buildFilterExpressionFromConditionModelList(conditionBooleanOperator: string, conditions: m5.FilterConditionViewModel[]): string {

    if (!conditions || conditions.length === 0) {
      return "";
    }
    if (!conditionBooleanOperator) {
      conditionBooleanOperator = "And";
    }

    let sql = "";
    conditions.forEach((condition) => {
      if (sql) {
        sql += ` ${conditionBooleanOperator.toUpperCase()} `;
      }
      if (condition.DataType === m.System.TypeCode.DateTime && condition.ValueMetaData && !Helper.equals(condition.ValueMetaData, "Custom", true)) {
        sql += this.buildWhereClauseExpression(condition.PropertyName, condition.DataType, condition.ComparisonOperator, condition.ValueMetaData, true, false);
      } else if ((condition.DataType === m.System.TypeCode.Int64 || condition.DataType === m.System.TypeCode.Int32) &&
        Helper.endsWith(condition.PropertyName, "ContactId", true) &&
        !Helper.equals(condition.ValueMetaData, "Other", true)) {
        sql += this.buildWhereClauseExpression(condition.PropertyName, condition.DataType, condition.ComparisonOperator, condition.ValueMetaData, false, true);
      } else {
        sql += this.buildWhereClauseExpression(condition.PropertyName, condition.DataType, condition.ComparisonOperator, condition.Value, false, false);
      }
    });

    return sql;

  }

  public buildWhereClauseExpression(propertyName: string, dataType: m.System.TypeCode, compare: string, value: any, isDateRange: boolean, isContactId: boolean) {

    if (!propertyName) {
      return "";
    }

    //console.error(propertyName, dataType, compare, value);

    if (value === undefined || value === null) {
      if (Helper.equals(compare, "NotEquals", true)) {
        return `${propertyName} IS NOT NULL`;
      } else {
        return `${propertyName} IS NULL`;
      }
    }

    if (dataType === m.System.TypeCode.String) {
      if (this.isLikeOperator(compare)) {
        return `${propertyName} ${this.convertOperatorNameToOperatorExpression(compare)} ${this.sqlPrep(this.prepareValueForLikeOperator(compare, value.toString()), dataType, compare)}`;
      } else {
        return `${propertyName} ${this.convertOperatorNameToOperatorExpression(compare)} ${this.sqlPrep(value, dataType, compare)}`;
      }
    }

    if (dataType === m.System.TypeCode.Boolean) {
      return `${propertyName} ${this.convertOperatorNameToOperatorExpression(compare)} ${this.sqlPrep(value, dataType, compare)}`;
    }

    if (dataType === m.System.TypeCode.DateTime) {
      if (isDateRange) {
        const range = Helper.getDateRange(value);
        if (this.isBetweenOperator(compare)) {
          return `${propertyName} ${this.convertOperatorNameToOperatorExpression(compare)} ${this.sqlPrep([range.beginningDateTime, range.endingDateTime], dataType, compare)}`;
        } else {
          return `${propertyName} ${this.convertOperatorNameToOperatorExpression(compare)} ${this.sqlPrep(range.beginningDateTime, dataType, compare)}`;
        }
      } else {
        //console.error(value);
        if (this.isBetweenOperator(compare) && Helper.isArray(value)) {
          const m1 = moment(value[0]);
          const date1: string = Helper.replaceAll(m1.format(Constants.DateFormat.MomentIsoDateTime), "T", " ");
          const m2 = moment(value[1]);
          const date2: string = Helper.replaceAll(m2.format(Constants.DateFormat.MomentIsoDateTime), "T", " ");
          return `${propertyName} ${this.convertOperatorNameToOperatorExpression(compare)} ${this.sqlPrep([date1, date2], dataType, compare)}`;
        } else {
          const m = moment(value);
          const date: string = Helper.replaceAll(m.format(Constants.DateFormat.MomentIsoDateTime), "T", " ");
          return `${propertyName} ${this.convertOperatorNameToOperatorExpression(compare)} ${this.sqlPrep(date, dataType, compare)}`;
        }
      }
    }

    if (isContactId) {
      if (Helper.equals(value, "NotMe", true)) {
        if (this.isNotOperator(compare)) {
          // Not Not Me = Me?
          return `${propertyName} = ${this.appService.userOrDefault.ContactId}`;
        } else {
          return `${propertyName} <> ${this.appService.userOrDefault.ContactId}`;
        }
      } else if (Helper.equals(value, "MyGroups", true) || Helper.equals(value, "MeAndMyGroups", true)) {
        const subselect = "SELECT GroupContactId FROM ContactGroupMembership WITH (NOLOCK) " +
          `WHERE MemberContactId = ${this.appService.userOrDefault.ContactId} ` +
          "AND MarkedForDeletionDateTime IS NULL AND Status IN ( 'A' , 'I' , 'L' ) " +
          "AND ( ValidStartDateTime IS NULL OR ValidStartDateTime < GetDate() ) " +
          "AND ( ValidEndDateTime IS NULL OR ValidEndDateTime > GetDate() ) " +
          `AND PartitionId = ${this.appService.userOrDefault.PartitionId} `;
        const notsubselect = "SELECT Contact_Id_2 FROM Contact WITH (NOLOCK) " +
          "WHERE Contact_Type = 'G' AND Marked_For_Deletion_Date_Time IS NULL " +
          `AND Contact_Id_2 NOT IN ( ${subselect} ) ` +
          `AND Partition_Id = ${this.appService.userOrDefault.PartitionId} `;
        if (Helper.equals(value, "MyGroups", true)) {
          if (this.isNotOperator(compare)) {
            // IN instead of NOT IN because subselect is already negated with it's own NOT IN subselect
            return `${propertyName} IN ( ${notsubselect} )`;
          }
          else {
            return `${propertyName} IN ( ${subselect} )`;
          }
        }
        else // MeAndMyGroups
        {
          if (this.isNotOperator(compare)) {
            return `( ${propertyName} <> ${this.appService.userOrDefault.ContactId} AND ${propertyName} NOT IN ( ${subselect} ) )`;
          }
          else {
            return `( ${propertyName} = ${this.appService.userOrDefault.ContactId} OR ${propertyName} IN ( ${subselect} ) )`;
          }
        }
      } else { // Me
        if (this.isNotOperator(compare)) {
          return `${propertyName} <> ${this.appService.userOrDefault.ContactId}`;
        } else {
          return `${propertyName} = ${this.appService.userOrDefault.ContactId}`;
        }
      }
    }

    // Mostly what is left is numbers but if there are any other odd data types here they'll get treated the same
    return `${propertyName} ${this.convertOperatorNameToOperatorExpression(compare)} ${this.sqlPrep(value, dataType, compare)}`;

  }

  public sqlPrep(value: string | string[] | number | number[] | boolean | boolean[] | Date | Date[], dataType: m.System.TypeCode, operator: string): string {

    // TODO what if value is an array?  check operator In/NotIn / Between are we first or last instance?

    if (dataType === m.System.TypeCode.Boolean) {
      if (this.isBetweenOperator(operator)) {
        if (Helper.isArray(value) && (value as any).length >= 2) {
          return `${(value[0] ? "1" : "0")} AND ${(value[1] ? "1" : "0")}`;
        } else {
          return `${(value ? "1" : "0")} AND ${(value ? "1" : "0")}`;
        }
      } else {
        return (value ? "1" : "0");
      }
    }

    if (dataType === m.System.TypeCode.DateTime && (value instanceof Date || (Helper.isArray(value) && (value as any).length >= 1 && value[0] instanceof Date))) {
      if (value) {
        if (this.isBetweenOperator(operator)) {
          if (Helper.isArray(value) && (value as any).length >= 2) {
            //console.error("array of dates", value);
            //console.error(Helper.formatDateTime(value[0], "YYYY-MM-DD HH:mm:ss"));
            //console.error(Helper.formatDateTime(value[1], "YYYY-MM-DD HH:mm:ss"));
            return `Convert( DateTime , '${Helper.formatDateTime(value[0], "YYYY-MM-DD HH:mm:ss")}' , 120 ) AND Convert( DateTime , '${Helper.formatDateTime(value[1], "YYYY-MM-DD HH:mm:ss")}' , 120 )`;
          } else {
            return `Convert( DateTime , '${Helper.formatDateTime(value as Date, "YYYY-MM-DD HH:mm:ss")}' , 120 ) AND Convert( DateTime , '${Helper.formatDateTime(value as Date, "YYYY-MM-DD HH:mm:ss")}' , 120 )`;
          }
        } else {
          return `Convert( DateTime , '${Helper.formatDateTime(value as Date, "YYYY-MM-DD HH:mm:ss")}' , 120 )`;
        }
      } else {
        return "null";
      }
    } else if (dataType === m.System.TypeCode.DateTime) {
      if (value) {
        if (this.isBetweenOperator(operator)) {
          if (Helper.isArray(value) && (value as any).length >= 2) {
            return `Convert( DateTime , ${this.sqlPrep(value[0].toString(), m.System.TypeCode.String, "")} , 120 ) AND Convert( DateTime , ${this.sqlPrep(value[1].toString(), m.System.TypeCode.String, "")} , 120 )`;
          } else {
            return `Convert( DateTime , ${this.sqlPrep(value.toString(), m.System.TypeCode.String, "")} , 120 ) AND Convert( DateTime , ${this.sqlPrep(value.toString(), m.System.TypeCode.String, "")} , 120 )`;
          }
        } else {
          return `Convert( DateTime , ${this.sqlPrep(value.toString(), m.System.TypeCode.String, "")} , 120 )`;
        }
      } else {
        return "null";
      }
    }

    if (dataType === m.System.TypeCode.String) {
      if (this.isBetweenOperator(operator)) {
        if (Helper.isArray(value) && (value as any).length >= 2) {
          return `'${Helper.replaceAll(value[0].toString(), "'", "''")}' AND '${Helper.replaceAll(value[1].toString(), "'", "''")}'`;
        } else {
          return `'${Helper.replaceAll(value.toString(), "'", "''")}' AND '${Helper.replaceAll(value.toString(), "'", "''")}'`;
        }
      } else if (this.isInOperator(operator)) {
        let values: string[] = [];
        if (Helper.isArray(value)) {
          values = value as string[];
        } else {
          values = value.toString().split(",");
        }
        let sql = "";
        values.forEach((one) => {
          if (one) {
            if (sql) {
              sql += " , ";
            }
            sql += this.sqlPrep(one, m.System.TypeCode.String, "");
          }
        });
        //console.error("IN", values, sql);
        return `( ${sql} )`;
      } else {
        return `'${Helper.replaceAll(value.toString(), "'", "''")}'`;
      }
    }

    // Catch all ... works for numbers and hope it works anything else that got this far
    if (this.isBetweenOperator(operator)) {
      if (Helper.isArray(value) && (value as any).length >= 2) {
        return `${value[0]} AND ${value[1]}`;
      } else {
        return `${value} AND ${value}`;
      }
    } else if (this.isInOperator(operator)) {
      let values: string[] = [];
      if (Helper.isArray(value)) {
        values = value as string[];
      } else {
        values = value.toString().split(",");
      }
      let sql = "";
      values.forEach((one) => {
        if (one) {
          if (sql) {
            sql += " , ";
          }
          sql += one.toString();
        }
      });
      //console.error("IN", values, sql);
      return `( ${sql} )`;
    } else {
      return value.toString();
    }

  }

  public convertOperatorNameToOperatorExpression(operator: string): string {
    if (Helper.equals(operator, "Equals", true)) {
      return "=";
    } else if (Helper.equals(operator, "NotEquals", true)) {
      return "<>";
    } else if (Helper.equals(operator, "LessThan", true)) {
      return "<";
    } else if (Helper.equals(operator, "LessThanOrEqualTo", true)) {
      return "<=";
    } else if (Helper.equals(operator, "GreaterThan", true)) {
      return ">";
    } else if (Helper.equals(operator, "GreaterThanOrEqualTo", true)) {
      return ">=";
    } else if (Helper.equals(operator, "StartsWith", true) || Helper.equals(operator, "EndsWith", true) || Helper.equals(operator, "Contains", true)) {
      return "LIKE";
    } else if (Helper.equals(operator, "NotStartsWith", true) || Helper.equals(operator, "NotEndsWith", true) || Helper.equals(operator, "NotContains", true)) {
      return "NOT LIKE";
    } else if (Helper.equals(operator, "In", true)) {
      return "IN";
    } else if (Helper.equals(operator, "NotIn", true)) {
      return "NOT IN";
    } else if (Helper.equals(operator, "Between", true)) {
      return "BETWEEN";
    } else if (Helper.equals(operator, "NotBetween", true)) {
      return "NOT BETWEEN";
    } else {
      Log.errorMessage(`Unable to convert ${operator} to a sql operation string.`);
      return operator;
    }
  }

  public isLikeOperator(operator: string): boolean {
    return (Helper.contains(operator, "StartsWith", true) || Helper.contains(operator, "EndsWith", true) || Helper.contains(operator, "Contains", true));
  }

  public isInOperator(operator: string): boolean {
    return (Helper.equals(operator, "In", true) || Helper.equals(operator, "NotIn", true));
  }

  public isNotOperator(operator: string): boolean {
    return Helper.startsWith(operator, "Not", true);
  }

  public isBetweenOperator(operator: string): boolean {
    return Helper.contains(operator, "Between", true);
  }

  public prepareValueForLikeOperator(operator: string, value: string): string {
    // See https://stackoverflow.com/a/6030564
    const escaped: string = Helper.replaceAll(Helper.replaceAll((value || ""), "[", "[[]"), "%", "[%]");
    if (Helper.contains(operator, "StartsWith", true)) {
      return `${escaped}%`;
    } else if (Helper.contains(operator, "EndsWith", true)) {
      return `%${escaped}`;
    } else if (Helper.contains(operator, "Contains", true)) {
      return `%${escaped}%`;
    } else {
      return value;
    }
  }

  public isNumericDataType(dataType: m.System.TypeCode): boolean {

    const type = m.System.TypeCode[dataType]; // TypeCode enum as string

    if (Helper.startsWith(type, "Int", true) || Helper.startsWith(type, "UInt", true)) {
      return true;
    } else if (dataType === m.System.TypeCode.Byte) {
      return true;
    } else if (dataType === m.System.TypeCode.SByte) {
      return true;
    } else if (dataType === m.System.TypeCode.Double) {
      return true;
    } else if (dataType === m.System.TypeCode.Single) {
      return true;
    } else if (dataType === m.System.TypeCode.Decimal) {
      return true;
    }

    return false;

  }



  /**
   * Filter expressions can be provided in API format or SQL format but not a mix of both
   * since we need to convert from API format to SQL format server side and when it detects
   * to do that it escapes ' as '' , etc. which doesn't work when we're using sql format
   * in some of the filter expression.  Our filter builder produces SQL format and our table
   * header filters and hard coded filters often produce API format.  This method will
   * translate SQL format to API format we can submit a filter expression in a single format.
   * @param expression
   * @returns
   */
  public translateFilterFromSqlFormatToApiFormat(expression: string): string {

    if (!expression) {
      return "";
    }

    let output: string = expression;

    output = Helper.replaceAll(output, "'", '"');
    output = Helper.replaceAll(output, " AND ", " && ");
    output = Helper.replaceAll(output, " OR ", " || ");

    // We will be ok not translating LIKE to StartsWith, EndsWith, or Contains since server side can consume both

    return output;

  }


}
