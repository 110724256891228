<div>

  <h3>Rate Limits</h3>

  <br />

  <p>
    Usage of the API is subject to rate limits. The limits are based on a moving window that tracks the number of requests you send per hour.
  </p>

  <div class="alert alert-warning"
       role="alert">
    <span><strong>Note:</strong> Rate limits are not currently being enforced but will be at some point in the future.</span>
  </div>

  <br />
  <h4>Overview</h4>

  <p>
    An application can make up to [TBD] userless (i.e. API Key based) requests per hour to each endpoint group.
  </p>

  <p>
    An application can make up to [TBD] authenticated requests per hour per token.
    For example, if your app has 5 connected users, it could make at most [TBD] requests on behalf
    of the each connected user, for a maximum of [TBDx5] authenticated requests per hour.
  </p>

  <p>
    Please note that rate limits are not per endpoint, but per top-level endpoint group.
    For example, in an hour, if you make [TBD] userless requests to add customers and
    [TBD] userless requests to get customers, you would have exhausted your customers/*
    rate limits for that hour; however, you would still have [TBD] requests left for
    inventory/*, etc.
  </p>

  <p>
    Generally rate limits follow the rules above; however, some endpoints may have different
    limits due to security or other considerations. For the most current and accurate rate
    limit usage information for a particular request type, inspect the X-RateLimit-Remaining and
    X-RateLimit-Limit HTTP headers of API responses.
  </p>

  <p>
    If you are currently over limits, our API will return a 403 error, and the response object
    returned by our API will be empty. We will also include a X-RateLimit-Reset header in the response,
    which is a timestamp that corresponds to when your rate limits will reset.
  </p>

  <br />
  <h4>Increases</h4>

  <p>
    Userless rate limits can be increased by request; however, we highly encourage you to first consider
    offering authentication in your application as a way to automatically and naturally have much more
    favorable rate limits. In addition to the [TBD] requests/hour you can make on each user's behalf,
    you'll also receive the benefit of each API call running under that user's security context.
  </p>

  <p>
    If you still decide that your application needs more userless requests per hour, please contact support
    and request an increase. We will evaluate your request and reach back out to you with more details
    on how to proceed next.
  </p>

</div>
