<div class="float-start">
  <h3>{{title}}</h3>
</div>

<div *ngIf="apiProperties"
     class="float-end"
     style="margin: 10px 10px 10px 0;">
  <div ngbDropdown
       class="d-inline-block">
    <button class="btn btn-primary btn-sm"
            id="dropdownBasic1"
            ngbDropdownToggle><i class="far fa-cog"></i>&nbsp;Version</button>
    <div ngbDropdownMenu
         aria-labelledby="dropdownBasic1">
      <button ngbDropdownItem
              (click)="onVersion(version)"
              *ngFor="let version of apiProperties.supportedVersions">
        v{{version}}
      </button>
    </div>
  </div>
</div>

<div class="clearfix"></div>

<small>
  <ul *ngIf="apiCall">
    <li>
      <strong>API Endpoint:</strong> {{apiCall.url}}
      <span [hidden]="apiService.selectedHost.isProduction"
            class="badge bg-success">Sandbox</span>
      <span [hidden]="apiService.selectedHost.isSandbox"
            class="badge bg-danger">Production</span>
    </li>
    <li><strong>HTTP Method:</strong> {{httpMethod | uppercase}}</li>
    <li><strong>Version:</strong> {{apiCall.version}}</li>
  </ul>
</small>
