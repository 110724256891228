<table class="table table-striped table-bordered table-sm table-hover">
  <thead>
    <tr>
      <th>
        Property
        <i *ngIf="!sortedProperties || sortedProperties.length === 0"
           class="far fa-spinner fa-pulse fa-fw"></i> <!-- lazy loading spinner -->
      </th>
      <th>Type</th>
      <th>Length</th>
      <th>Key</th>
      <th>Comments</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let col of sortedProperties;trackBy: trackByIndex">
      <ng-container *ngIf="showPartitionId || col.Name !=='PartitionId'">
        <tr>
          <td>{{col.Name}}</td>
          <td>
            <div class="float-start">
              {{typeDescription(col)}}
            </div>
            <div class="float-end"
                 *ngIf="( col.Properties && col.Properties.length > 0 ) || col.PropertiesLazyLoaded">
              <button type="submit"
                      class="btn btn-xs btn-outline-secondary"
                      (click)="collapseProperties(col)">
                <i [hidden]="!col.PropertiesCollapsed"
                   class="far fa-angle-double-down"></i>
                <i [hidden]="col.PropertiesCollapsed"
                   class="far fa-angle-double-up"></i>
              </button>
            </div>
          </td>
          <td>
            <span *ngIf="col.IsInteger">
              {{col.MaximumLength | number : "1.0-0"}} bit
            </span>
            <span *ngIf="col.IsChar || col.IsText">
              {{col.MaximumLength | number : "1.0-0"}} <span *ngIf="col.MaximumLength==1">character</span><span *ngIf="col.MaximumLength>1">characters</span>
            </span>
          </td>
          <td style="text-align: center;">
            <i *ngIf="col.IsPrimaryKey"
               class="far fa-key"></i>
            <i *ngIf="col.IsForeignKey"
               class="far fa-external-link"></i>
          </td>
          <td>
            <p style="margin:0;"
               [innerHTML]="col.Notes | newLines | safe : 'html'"></p>
            <p style="margin:0;"
               *ngIf="col.AdditionalNotes">
              <span [innerHTML]="col.AdditionalNotes | safe : 'html'"></span>
            </p>
            <p style="margin:0;"
               *ngIf="col.IsReadOnly">This property is read-only.</p>
          </td>
        </tr>
        <tr *ngIf="!col.PropertiesCollapsed">
          <td></td>
          <td colspan="4">
            <!-- show child properties -->
            <app-data-model-property-table [properties]="col.Properties"
                                           [sort]="sort"
                                           [showPartitionId]="showPartitionId"></app-data-model-property-table>
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </tbody>
</table>
